/**
 * Editing form constants. Used to parse valid editing form field data
 * that gererates our editing form in the Waymark store.
 */

// SS TODO: Text selector support.
// SS TODO: These are changing soon - scene switch and logo will be combined.
// Killing theme.
export const editingFormTypes = {
  audio: 'audio',
  color: 'color',
  font: 'font',
  image: 'image',
  logo: 'logo',
  sceneSwitch: 'sceneSwitch',
  text: 'text',
  textSelector: 'textSelector',
  theme: 'theme',
  footageComposition: 'footageComposition',
  video: 'video',
};

/**
 * Properties that are required on all editing form types.
 */
const globalEditingFormProperties = {
  editingFieldKey: '',
  displayTime: '',
  label: '',
};

const audioEditingFormField = {
  ...globalEditingFormProperties,
  paths: [],
  type: editingFormTypes.audio,
  selectOptions: [],
};

const colorEditingFormField = {
  ...globalEditingFormProperties,
  paths: [],
  type: editingFormTypes.color,
};

const imageEditingFormField = {
  ...globalEditingFormProperties,
  paths: [],
  type: editingFormTypes.image,
  width: '',
  height: '',
};

const fontEditingFormField = {
  ...globalEditingFormProperties,
  type: editingFormTypes.font,
  fontOverrides: {},
  respectedPathMappings: {},
};

// SS TODO: change layout_selector in ivory
const sceneSwitchEditingFormField = {
  ...globalEditingFormProperties,
  paths: [],
  type: 'layout_selector',
  selectOptions: [],
};

const textEditingFormField = {
  ...globalEditingFormProperties,
  paths: [],
  type: editingFormTypes.text,
  characterLimit: '',
};

const footageCompositionEditingFormField = {
  ...globalEditingFormProperties,
  paths: [],
  type: 'footage_composition',
  selectOptions: [],
};

const videoEditingFormField = {
  ...globalEditingFormProperties,
  paths: [],
  type: editingFormTypes.video,
};

// SS TODO: Text Dropdown
export const editingFormTemplates = {
  audio: audioEditingFormField,
  color: colorEditingFormField,
  font: fontEditingFormField,
  image: imageEditingFormField,
  sceneSwitch: sceneSwitchEditingFormField,
  text: textEditingFormField,
  footageComposition: footageCompositionEditingFormField,
  video: videoEditingFormField,
};
