import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { selectors } from '../store/index.js';
import { WTSColorSelect } from './lib/index.js';

/**
 * Helper function for translating color overrides into DropdownMenu 
 * @param  {string} colorOverrideType   Color override type  -- e.g., `gradientFill`
 * @param  {Object[]} colorOverrides    List of color overrides
 * @param  {Object} selectedColorOverride     Selected color override
 */
export const getDropdownMenuDataFromColorOverrides = (colorOverrideType, colorOverrides, selectedColorOverride) => {
  // Parse the dropdown menu label
  // Split the camel case string by capitalized letter.
  let parsedColorType = colorOverrideType.split(/(?=[A-Z])/);
  // Convert all words to lowercase and then capitalize the
  // first word.
  parsedColorType = parsedColorType.map((word) => _.toLower(word));
  const label = `${_.upperFirst(parsedColorType.join(' '))}`;

  const items = colorOverrides.map((override) => ({
    label: override.name,
    value: override.id,
  }));
  items.unshift({
    label: 'No override',
    value: null,
  });

  let selectedItem = items[0]
  let selectedHexcode = null;
  if (selectedColorOverride) {
    selectedItem = _.find(items, (item) => item.value === selectedColorOverride.id);
    selectedHexcode = selectedColorOverride.placeholder;
  }

  return { label, items, selectedItem, selectedHexcode };
};


const ColorOverrideSelect = ({ colorOverrideType, colorOverrides, onColorOverrideSelect }) => {
  const selectedLayers = useSelector(selectors.getSelectedLayers);
  const sharedOverrideUUID = useSelector((state) => selectors.getSharedOverrideUUID(state, colorOverrideType));
  const override = useSelector((state) => selectors.getOverrideByUUID(state, sharedOverrideUUID));

  const [selectedColorOverride, setSelectedColorOverride] = useState(null);

  useDeepCompareEffect(() => {
    setSelectedColorOverride(override);
  }, [selectedLayers])

  const onItemSelect = (color) => {
    const foundOverride = _.find(colorOverrides, (colorOverride) => colorOverride.id === color.value);
    setSelectedColorOverride(foundOverride || null);
    onColorOverrideSelect(colorOverrideType, color.value);
  };

  const dropdownMenuData = getDropdownMenuDataFromColorOverrides(colorOverrideType, colorOverrides, selectedColorOverride);

  return (
    <WTSColorSelect
      label={dropdownMenuData.label}
      labelSubtext="Leave unassigned to disable editing"
      menuItems={dropdownMenuData.items}
      onItemSelect={onItemSelect}
      value={dropdownMenuData.selectedItem}
      selectedHexcode={dropdownMenuData.selectedHexcode}
    />
  );
};

ColorOverrideSelect.propTypes = {
  colorOverrideType: PropTypes.string.isRequired,
  colorOverrides: PropTypes.arrayOf(PropTypes.object).isRequired,
  onColorOverrideSelect: PropTypes.func.isRequired,
};

export default ColorOverrideSelect;
