import cxs from 'cxs';
import React, { useState } from 'react';
import { Flex, Box } from 'reflexbox';

import { templateGroups } from '../constants/index.js';
import { defaultBackground, panelBackground } from './lib/colors.js';
import TemplateResultsGrid from './TemplateResultsGrid.js';
import TemplateSelectionSidePanel from './TemplateSelectionSidePanel.js';

const wrapperStyle = cxs({
  maxWidth: '1478px',
  margin: '0 auto',
});

const templatesFlexStyle = cxs({
  backgroundColor: panelBackground,
  boxShadow: '0 6px 24px 0 rgba(0, 0, 0, 0.5)',
});

const searchPanelStyle = cxs({
  backgroundColor: defaultBackground,
});

const templateListPanelStyle = cxs({
  overflowY: 'scroll',
  // On smaller screens we'll remove the empty space around the container to maximize functional screen real estate.
  height: '100vh',
  '@media screen and (min-width: 768px)': {
    height: '85vh',
  },
});

/**
 * Top-level component for the /templates route.
 */
const TemplateSelection = () => {
  const [templateQuery, setTemplateQuery] = useState('');
  const [activeTemplateGroup, setActiveTemplateGroup] = useState(templateGroups.all);

  return (
    <div className={wrapperStyle}>
      <Flex mx={[1, 2, 3, 4]} my={[0, 1, 2, 3]} justify="center" className={templatesFlexStyle}>
        <Box w={[1 / 2, 1 / 3, 1 / 5]} className={searchPanelStyle}>
          <TemplateSelectionSidePanel
            activeTemplateGroup={activeTemplateGroup}
            onChangeSearchQuery={setTemplateQuery}
            onChangeActiveTemplateGroup={setActiveTemplateGroup}
          />
        </Box>
        <Box w={[1 / 2, 2 / 3, 4 / 5]} px={[1, 2, 3]} className={templateListPanelStyle}>
          <TemplateResultsGrid
            templateQuery={templateQuery}
            activeTemplateGroup={activeTemplateGroup}
          />
        </Box>
      </Flex>
    </div>
  );
};
export default TemplateSelection;
