/* eslint-env browser */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

/**
 * A wrapper around the renderer player that resizes it so it's always in view
 *
 * @param      {Object}  props               The props
 * @param      {number}  props.playerWidth   The unscaled player width
 * @param      {number}  props.playerHeight  The unscaled player height
 * @param      {<type>}  props.children      The children
 */
const PlayerSizeWrapper = ({ playerWidth, playerHeight, children }) => {
  const playerWrapperEl = useRef(null);
  const [wrapperWidth, setWrapperWidth] = useState(null);
  const [wrapperHeight, setWrapperHeight] = useState(null);

  const onWindowResize = () => {
    const { width: currentWrapperWidth, height: currentWrapperHeight } = playerWrapperEl.current.parentElement.getBoundingClientRect();

    setWrapperWidth(currentWrapperWidth);
    // Either the height of the window, or the wrapper, whatever is smaller.
    setWrapperHeight(Math.min(currentWrapperHeight, window.innerHeight));
  };

  // 38px for the controls, 48px for control margins
  // TODO: fetch this right from the element size
  const wrapperAspectRatio = wrapperWidth / (wrapperHeight - 86);
  const playerAspectRatio = playerWidth / playerHeight;

  let width = '100%';
  // If the wrapper is shorter than the player, shrink the width to contrain the height of the player
  if (wrapperAspectRatio > playerAspectRatio) {
    width = `${(playerAspectRatio / wrapperAspectRatio) * 100}%`;
  }

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    onWindowResize();

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);

  return (
    <div
      ref={playerWrapperEl}
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      <div
        style={{
          height: 'auto',
          margin: '0 auto',
          width,
        }}
      >
        {children}
      </div>
    </div>
  );
};

PlayerSizeWrapper.propTypes = {
  playerWidth: PropTypes.number,
  playerHeight: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

PlayerSizeWrapper.defaultProps = {
  playerWidth: 960,
  playerHeight: 540,
};

export default PlayerSizeWrapper;
