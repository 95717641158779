/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { createSelector, createSlice } from 'redux-starter-kit';
import { assetTypes } from '@stikdev/waymark-author-web-renderer/manifest.js';

import { fetchVersion, updateVersion, getOrCreateUnpublishedVersion } from '../api/index.js';
import { layerTypes } from '../constants/TemplateManifest.js';
import { getLayersByType, parseProjectManifest } from '../utils/projectManifest.js';
import { supportLoadingStates, getUniqueVersionKey } from './utils.js';

/**
 * `workspaceVersions` slice for managing version fetching and projectManifest updates within the studio.
 * A dependency of our main `workspace` slice and the supporting `workspaceTemplateManifest` slice.
 *
 * SLICE DEPENDENCIES:
 *  [ NONE ]
 */

const { initialState, caseReducers } = supportLoadingStates(['fetchVersion']);

const workspaceVersions = createSlice({
  slice: 'workspaceVersions',
  initialState: {
    ...initialState,
    manifestChangeId: 0,
    // What template and version is being worked on?
    activeTemplateId: null,
    activeVersionNumber: null,
    // Cached versions
    items: {},
  },
  extraReducers: {
    // When we exit the workspace, clear out the active template / version info.
    'workspace/leavingWorkspace': (state) => {
      state.activeTemplateId = null;
      state.activeVersionNumber = null;
    },
  },
  reducers: {
    ...caseReducers,
    setWorkspaceVersion: (state, action) => {
      const { templateId, versionNumber } = action.payload;
      state.activeTemplateId = templateId;
      state.activeVersionNumber = versionNumber;
    },
    receivedVersion: (state, action) => {
      const { templateId, version } = action.payload;
      state.items[getUniqueVersionKey(templateId, version.version)] = version;
    },
    updatedVersionProjectManifest: (state, action) => {
      const { templateId, version } = action.payload;
      state.items[getUniqueVersionKey(templateId, version.version)] = version;
      state.manifestChangeId += 1;
    },
  },
});

workspaceVersions.selectors.getAllVersions = createSelector(['workspaceVersions.items']);
workspaceVersions.selectors.getVersionByKey = (state, key) => {
  const versions = workspaceVersions.selectors.getAllVersions(state);
  return versions[key];
};

workspaceVersions.selectors.getActiveTemplateId = createSelector([
  'workspaceVersions.activeTemplateId',
]);
workspaceVersions.selectors.getActiveVersionNumber = createSelector([
  'workspaceVersions.activeVersionNumber',
]);
workspaceVersions.selectors.getActiveVersion = createSelector(
  [
    workspaceVersions.selectors.getActiveTemplateId,
    workspaceVersions.selectors.getActiveVersionNumber,
    workspaceVersions.selectors.getAllVersions,
  ],
  (templateId, versionNumber, allVersions) =>
    allVersions[getUniqueVersionKey(templateId, versionNumber)],
);

workspaceVersions.selectors.getActiveProjectManifest = createSelector(
  [workspaceVersions.selectors.getActiveVersion],
  (version) => version.projectManifest,
);

workspaceVersions.selectors.getActiveProjetManifestFonts = createSelector(
  [workspaceVersions.selectors.getActiveProjectManifest],
  (projectManifest) => projectManifest.fonts.rendererFonts,
);

workspaceVersions.selectors.getActiveProjetManifestVideoAssets = createSelector(
  [workspaceVersions.selectors.getActiveProjectManifest],
  (projectManifest) => projectManifest.assets.filter(({ type }) => (type === assetTypes.video)),
);

workspaceVersions.selectors.getAllCompositions = createSelector(
  [workspaceVersions.selectors.getActiveVersion],
  (version) => {
    if (!version) return [];
    return parseProjectManifest(version.projectManifest);
  },
);

workspaceVersions.selectors.getTextLayers = createSelector(
  [workspaceVersions.selectors.getAllCompositions],
  (allLayers) => {
    let layers = [];
    allLayers.forEach((layer) => {
      const childLayers = _.filter(
        layer.childLayers,
        (childLayer) => childLayer.type === layerTypes.text,
      );

      layers = layers.concat(childLayers);

      if (layer.type === layerTypes.text) {
        layers.push(layer);
      }
    });

    return layers;
  },
);

/**
 * Generates a function used by a selector to fetch all layers of a particular type
 *
 * @param      {string}  layerType  The layer type
 * @return     {function}  Function that takes "version" as an input and returns an array of layers.
 */
const generateLayersByTypeFunction = (layerType) => (
  (version) => {
    if (!version) return [];
    const parsedLayers = parseProjectManifest(version.projectManifest);
    return getLayersByType(parsedLayers, layerType);
  }
)

workspaceVersions.selectors.getProjectManifestImageLayers = createSelector(
  [workspaceVersions.selectors.getActiveVersion],
  generateLayersByTypeFunction(layerTypes.image),
);

workspaceVersions.selectors.getProjectManifestWaymarkVideoLayers = createSelector(
  [workspaceVersions.selectors.getActiveVersion],
  generateLayersByTypeFunction(layerTypes.waymarkVideo),
);

// We're wrapping the createSelector call in a closure so it has access to the
// named argument layerUUID. The downside is we’re re-building the selector function
// every time here, but we could improve on that by adding `reselect` to the wrapping
// function if we wanted to and memoize the returned function value per UUID.
function getLayerByUUID(state, layerUUID) {
  return createSelector([workspaceVersions.selectors.getAllCompositions], (allLayers) => {
    const findMatchingLayer = (layersToSearch) =>
      _.find(layersToSearch, (layer) => layer.uuid === layerUUID);
    let foundLayer = findMatchingLayer(allLayers);

    if (!foundLayer) {
      const layersWithChildren = _.filter(allLayers, (layer) => Boolean(layer.childLayers));
      for (let assetIndex = 0; assetIndex < layersWithChildren.length; assetIndex += 1) {
        foundLayer = findMatchingLayer(layersWithChildren[assetIndex].childLayers);
        if (foundLayer) {
          break;
        }
      }
    }

    return foundLayer;
  })(state);
}
workspaceVersions.selectors.getLayerByUUID = getLayerByUUID;

workspaceVersions.selectors.getIsFetchingVersion = createSelector([
  'workspaceVersions.isFetchVersionInProgress',
]);
workspaceVersions.selectors.getManifestChangeId = createSelector([
  'workspaceVersions.manifestChangeId',
]);

workspaceVersions.operations = {
  getOrCreateUnpublishedVersion(templateId) {
    return async (dispatch) => {
      dispatch(workspaceVersions.actions.fetchVersionInProgress());
      try {
        const response = await getOrCreateUnpublishedVersion(templateId);
        const version = response.data;
        dispatch(workspaceVersions.actions.receivedVersion({ templateId, version }));
        dispatch(workspaceVersions.actions.fetchVersionCompleted());
        return version;
      } catch (e) {
        dispatch(workspaceVersions.actions.fetchVersionFailed(e.message));
        return null;
      }
    };
  },
  fetchTemplateVersion(templateId, versionNumber, shouldForceFetch = false) {
    return async (dispatch, getState) => {
      const versionKey = getUniqueVersionKey(templateId, versionNumber);
      const foundVersion = workspaceVersions.selectors.getVersionByKey(getState(), versionKey);

      if (!shouldForceFetch && foundVersion) {
        return foundVersion;
      }

      dispatch(workspaceVersions.actions.fetchVersionInProgress());
      try {
        // MB TODO: For now, always include `projectManifest` on first fetch. Can make this smarter
        // in the future if need be.
        const response = await fetchVersion(templateId, versionNumber, [
          'projectManifest',
          'templateManifest',
        ]);
        const version = response.data;
        dispatch(workspaceVersions.actions.receivedVersion({ templateId, version }));
        dispatch(workspaceVersions.actions.fetchVersionCompleted());
        return version;
      } catch (e) {
        dispatch(workspaceVersions.actions.fetchVersionFailed(e.message));
        return null;
      }
    };
  },
  updateActiveProjectManifest(updatedManifest) {
    return async (dispatch, getState) => {
      const storeState = getState();
      const templateId = workspaceVersions.selectors.getActiveTemplateId(storeState);
      const versionNumber = workspaceVersions.selectors.getActiveVersionNumber(storeState);
      const version = workspaceVersions.selectors.getActiveVersion(storeState);

      // Sync the project manifest update
      await updateVersion(templateId, versionNumber, { projectManifest: updatedManifest });

      // Update the store state
      const newVersion = {
        ...version,
        projectManifest: updatedManifest,
      };
      dispatch(
        workspaceVersions.actions.updatedVersionProjectManifest({
          templateId,
          version: newVersion,
        }),
      );
    };
  },
};

export default workspaceVersions;
