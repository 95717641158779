import _ from 'lodash';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import CollapsibleMenu from './CollapsibleMenu.js';
import ColorOverrideSelect, { getDropdownMenuDataFromColorOverrides } from './ColorOverrideSelect.js';
import { DynamicPropertyUpdateDispatch } from './contextProviders/DynamicPropertyUpdateProvider.js';
import { selectors } from '../store/index.js';
import { overrideTypes, templateManifestOverrideKeys } from '../constants/index.js';
import { overridePropType } from '../constants/propTypes.js';
import { collapsibleMenuContentContainer, WTSFormGroup, WTSColorSelect } from './lib/index.js';


const GradientFillColorSelection = ({ selectedOverride, colorOverrides, stepIndex, onChangeGradientFillOverride }) => {
  const { items, selectedItem, selectedHexcode } = getDropdownMenuDataFromColorOverrides(templateManifestOverrideKeys.color.gradientFill, colorOverrides, selectedOverride)

  const onSelectOverride = (overrideItem) => {
    onChangeGradientFillOverride(overrideItem.value, stepIndex);
  }

  return (
    <WTSColorSelect
      labelSubtext={`Step ${stepIndex + 1}`}
      menuItems={items}
      onItemSelect={onSelectOverride}
      value={selectedItem}
      selectedHexcode={selectedHexcode}
    />
  )
};

GradientFillColorSelection.propTypes = {
  colorOverrides: PropTypes.arrayOf(overridePropType).isRequired,
  onChangeGradientFillOverride: PropTypes.func.isRequired,
  selectedOverride: overridePropType,
  stepIndex: PropTypes.number.isRequired,
}

GradientFillColorSelection.defaultProps = {
  selectedOverride: null,
};


const GradientFillOverrideSelections = ({ colorOverrides, onColorOverrideSelect }) => {
  const selectedLayersKey = useSelector(selectors.getSelectedLayersKey);
  const sharedOverridesByStep = useSelector(selectors.getSharedGradientFillOverridesByStep);

  const overrideType = templateManifestOverrideKeys.color.gradientFill;

  const onChangeGradientFillOverride = (overrideUUID, stepIndex) => {
    const overrideDotPath = `${overrideType}.${stepIndex}`;

    onColorOverrideSelect(overrideDotPath, overrideUUID);
  };

  return (
    <WTSFormGroup
      label="Gradient fill"
      labelSubtext="Configure editable colors for each step in the gradient"
    >
      {sharedOverridesByStep.map((selectedOverride, stepIndex) => {
        const uniqueKey = `${selectedOverride ? selectedOverride.id : ''}:${selectedLayersKey}:${stepIndex}`;
        return (
          <GradientFillColorSelection
            key={uniqueKey}
            selectedOverride={selectedOverride}
            colorOverrides={colorOverrides}
            stepIndex={stepIndex}
            onChangeGradientFillOverride={onChangeGradientFillOverride}
          />
        )
      })}
    </WTSFormGroup>
  );
};

GradientFillOverrideSelections.propTypes = {
  colorOverrides: PropTypes.arrayOf(PropTypes.object).isRequired,
  onColorOverrideSelect: PropTypes.func.isRequired,
};


/**
 * Sets color properties on individual or multiple layers. Dropdown menus are
 * dynamically rendered based on the color properties present on all selected
 * layers.
 *
 * @param  {func}   onOverrideSelect  Function to be called when an override is selected.
 */
const DynamicLayerColorOverrideMenu = () => {
  const sharedColorFields = useSelector(selectors.getSharedColorFields);
  const allOverrides = useSelector(selectors.getTemplateManifestOverrides);
  const onOverrideSelect = useContext(DynamicPropertyUpdateDispatch);

  const onColorPropertySelect = async (overrideDotPath, colorOverrideID) => {
    const updateValue = { [overrideDotPath]: colorOverrideID };
    onOverrideSelect(updateValue);
  };

  // Get the color override dropdown options for each valid color type.
  const colorOverrides = _.filter(allOverrides, (override) => override.type === overrideTypes.color);

  return (
    <CollapsibleMenu title="Color overrides">
      <div className={collapsibleMenuContentContainer}>
        {sharedColorFields.map((colorType) => {
          if (colorType === templateManifestOverrideKeys.color.gradientFill) {
            return (
              <GradientFillOverrideSelections
                key={colorType}
                colorOverrides={colorOverrides}
                onColorOverrideSelect={onColorPropertySelect}
              />
            );
          }
          return (
            <ColorOverrideSelect
              key={colorType}
              colorOverrideType={colorType}
              colorOverrides={colorOverrides}
              onColorOverrideSelect={onColorPropertySelect}
            />
          )
        })}
      </div>
    </CollapsibleMenu>
  );
};

export default DynamicLayerColorOverrideMenu;
