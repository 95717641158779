/* eslint-env browser */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import { selectors } from '../store/index.js';
import LayerListItem from './LayerListItem.js';
import usePanelResize from './usePanelResize.js';
import useSharedRenderer from './useSharedRenderer.js';
import useSelectLayers from './useSelectLayers.js';
import useLayerVisibility from './useLayerVisibility.js';
import useOpenCompositionPanel from './useOpenCompositionPanel.js';

/**
 * { function_description }
 *
 * @class      CompositionPanel (name)
 * @param      {Object}    props                     The props
 * @param      {string}    props.panelCompositionId  The id for this panel (referenced on resizing and opening/closing panels)
 * @param      {Function}  props.openPanel           The BlueprintJS-supplied API for opening new panels: https://blueprintjs.com/docs/#core/components/panel-stack
 * @param      {Function}  props.onPanelResize       Callback function for when the panel chanes in size
 */
const CompositionPanel = ({ panelCompositionId, openPanel, onPanelResize }) => {
  const selectedLayerUUIDs = useSelector(selectors.getSelectedLayers);
  const filteredCompositions = useSelector(selectors.getFilteredCompositions);
  const { renderer, isRendererSetup } = useSharedRenderer()
  const layers = filteredCompositions.find(({ id }) => id === panelCompositionId).childLayers;
  const panelRef = useRef(null);
  usePanelResize({ panelRef, panelCompositionId, onPanelResize });
  const selectLayer = useSelectLayers(_.map(layers, 'uuid'))

  const { visibleLayerUUIDs, toggleVisibility } = useLayerVisibility(renderer, layers)

  const openComposition = useOpenCompositionPanel(openPanel, onPanelResize)

  const onGoToFrame = (frameNumber) => {
    renderer.goToFrame(frameNumber)
  }

  return (
    <ul
      css={{
        marginBlockStart: '0px',
        marginBlockEnd: '0px',
        padding: '0px',
      }}
      ref={panelRef}
    >
      {layers.map((layer) => (
        <LayerListItem
          key={`${layer.name}${layer.uuid}`}
          layer={layer}
          isSelected={selectedLayerUUIDs.includes(layer.uuid)}
          isVisible={visibleLayerUUIDs.includes(layer.uuid)}
          onSelectPreComp={openComposition}
          onSelectLayer={selectLayer}
          onToggleVisibility={isRendererSetup ? toggleVisibility : null}
          onGoToFrame={isRendererSetup ? onGoToFrame : null}
        />
      ))}
    </ul>
  );
};

CompositionPanel.propTypes = {
  panelCompositionId: PropTypes.string.isRequired,
  openPanel: PropTypes.func.isRequired,
  onPanelResize: PropTypes.func.isRequired,
};

export default CompositionPanel;
