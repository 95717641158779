import _ from 'lodash';
import { editingActionTypes, editingActionTemplates } from '../constants/EditingActions.js';

/**
 * Get editing action by type
 * @param  {string} attributeType   Attribute action will change.
 * @param  {string} layerType       Type of layer attribute belongs to.
 */
export const getEditingActionType = (attributeType, layerType) =>
  editingActionTypes[layerType][attributeType];

/**
 * Create an editing action with a 'setExplicit' operation.
 * @param  {string} editingActionType  Editing action type.
 * @param  {array}  targets            Layer UUIDs to target with action.
 * @param  {string|object|array|int}   Value to apply to targets.
 */
const createExplicitEditingAction = (editingActionType, targets, payload) => {
  const editingAction = _.cloneDeep(editingActionTemplates.explicit);
  editingAction.type = editingActionType;
  targets.forEach((layer) => {
    editingAction.targets.push(layer);
  });
  editingAction.value.payload = payload;

  return editingAction;
};

/**
 * Create an editing action with a passthrough operation.
 * @param  {string} actionType    Editing action type.
 * @param  {array}  targetUUIDs   Editing action targets.
 */
const createPassthroughEditingAction = (actionType, targetUUIDs) => {
  const editingAction = _.cloneDeep(editingActionTemplates.passthrough);
  editingAction.type = actionType;
  targetUUIDs.forEach((targetUUID) => {
    editingAction.targets.push(targetUUID);
  });

  return editingAction;
};

/**
 * Create editing action based on the editing action type.
 * @param  {string} editingActionType    Editing action type.
 * @param  {array}  targets              Layer UUIDs the editing action should
 *                                       target.
 * @param  {string|object|array|int}     Payload for set explicit editing actions.
 */
export const createEditingAction = (editingActionType, targets, payload = null) => {
  // SS TODO: Make sure all editing experiences are reflected here.
  // Text selectors are definitely not.
  let editingAction;
  if (editingActionType === editingActionTypes.displayObjectVisibility) {
    editingAction = createExplicitEditingAction(editingActionType, targets, payload);
  } else {
    editingAction = createPassthroughEditingAction(editingActionType, targets);
  }

  return editingAction;
};
