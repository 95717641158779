/**
 * Core/shared components and styles intended specifically for layout purposes.
 */
import cxsComponent from 'cxs/component.js';
import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'reflexbox';

/**
 * Utility component to make it simple to vertically and horizonally center content.
 * @param  {node}     children    Children content
 */
const Center = ({ children, ...props }) => (
  <Flex align="center" justify="center" {...props}>
    <Box>{children}</Box>
  </Flex>
);

Center.propTypes = {
  children: PropTypes.node.isRequired,
};

// eslint-disable-next-line import/prefer-default-export
export const VerticallyAndHorizontallyCenter = cxsComponent(Center)((props) => ({
  height: props.containerHeight || 'inherit',
  width: '100%',
}));

VerticallyAndHorizontallyCenter.propTypes = {
  containerHeight: PropTypes.string,
};
