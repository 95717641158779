import _ from 'lodash';

import { templateManifestAttributes } from './templateManifestAttributes.js';

/**
 * Provided an object of layersExtendedAttributes from a template manifest and an override id,
 * returns a list of mappings between layer UUIDs and the overrides that match the provided UUID.
 *
 * @param  {Object}     layersExtendedAttributes
 * @param  {string}     overrideUUID
 * @returns {Object[]}  [{ [layerUUID]: overrides }, ...]
 */
/* eslint-disable-next-line import/prefer-default-export */
export const getOverrideMembersFromLayersExtendedAttributes = (layersExtendedAttributes, overrideUUID) => (
  _.reduce(
    layersExtendedAttributes,
    (accum, layerProperties, layerUUID) => {
      const nonNullProperties = _.pickBy(layerProperties, _.identity);
      const matchingOverrides = _.reduce(
        nonNullProperties,
        (overrideProperties, propertyValue, propertyKey) => {
          const attributeHelper = new templateManifestAttributes[propertyKey](propertyKey, propertyValue);

          if (attributeHelper.overrideUUIDs.includes(overrideUUID)) {
            overrideProperties.push(propertyKey);
          }

          return overrideProperties;
        },
        [],
      );

      if (matchingOverrides.length) accum.push({ [layerUUID]: matchingOverrides });
      return accum;
    },
    [],
  )
);
