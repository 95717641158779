import React, { useContext, useState, createContext } from 'react';
import PropTypes from 'prop-types';

/**
 * Shared Renderer Context & Custom Hook to provide access to a renderer instance across components
 *
 * The Provider can be simply imported and wrapped around parent components who need access to the context
 *
 * ex:
 * import { SharedRendererProvider } from './useSharedRenderer.js';
 *
 * <SharedRendererProvider>
 *  <App />
 * </SharedRendererProvider>
 *
 *
 *
 * Components setting up the renderer should us the `useSharedRenderer` custom hook
 * to set the renderer and its `isSetup` state
 *
 * ex:
 * const { setRenderer, setIsRendererSetup } = useSharedRenderer()
 *
 * Consumers can use the renderer the the state similarly
 * ex:
 * const { renderer, isRendererSetup } = useSharedRenderer()
 *
 *
 */
export const SharedRendererContext = createContext()

export const SharedRendererProvider = ({ children }) => {
  const [renderer, setRenderer] = useState(null)
  const [isRendererSetup, setIsRendererSetup] = useState(false)

  return (
    <SharedRendererContext.Provider value={{renderer, setRenderer, isRendererSetup, setIsRendererSetup}}>
      {children}
    </SharedRendererContext.Provider>
  );
}

SharedRendererProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const useSharedRenderer = () => {
  const {renderer, setRenderer, isRendererSetup, setIsRendererSetup} = useContext(SharedRendererContext)

  return {
    renderer,
    setRenderer,
    isRendererSetup,
    setIsRendererSetup,
  }
}
export default useSharedRenderer