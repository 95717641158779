/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Collapse } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import PropTypes from 'prop-types';
import React from 'react';

import {
  projectManifestLayerPropType,
  projectManifestCompositionPropType,
} from '../constants/propTypes.js';
import LayerListItem from './LayerListItem.js';

const compositionListChildStyles = {
  paddingLeft: 15
}

/**
 * Renders a composition as a list item with a collapse for its child layers
 *
 * @class      CompositionListItem
 * @param      {Object}    props                    The argument 1
 * @param      {<type>}    props.composition        The composition
 * @param      {<type>}    props.onOpenComposition  When a user selects a composition for a detail view
 * @param      {Boolean}   props.areLayersExpanded  Indicates if the composition's layers are expanded (open)
 * @param      {Function}  props.onExpandLayers     On expand (open) layers
 * @param      {Function}  props.onCollapseLayers   On collapse (close) layers
 */
const CompositionListItem = ({
  composition,
  onOpenComposition,
  areLayersExpanded,
  isRendererSetup,
  selectedLayerUUIDs,
  visibleLayerUUIDs,
  onExpandLayers,
  onCollapseLayers,
  onGoToFrame,
  onSelectLayer,
  onToggleVisibility,
}) => {
  const onToggleChildLayers = () => {
    if (areLayersExpanded) {
      onCollapseLayers(composition.id);
    } else {
      onExpandLayers(composition.id);
    }
  };

  return (
    <LayerListItem
      layer={composition}
      onToggleChildLayers={onToggleChildLayers}
      collapseIcon={areLayersExpanded ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN}
    >
      {composition.childLayers && (
        <Collapse isOpen={areLayersExpanded}>
          <ul
            css={{
              paddingLeft: 0,
              listStyle: 'none',
            }}
          >
            {composition.childLayers.map((childLayer) => (
              <LayerListItem
                key={composition.id + childLayer.uuid}
                layer={childLayer}
                isSelected={selectedLayerUUIDs.includes(childLayer.uuid)}
                isVisible={visibleLayerUUIDs.includes(childLayer.uuid)}
                itemStyles={compositionListChildStyles}
                onSelectPreComp={onOpenComposition}
                onSelectLayer={onSelectLayer}
                onToggleVisibility={isRendererSetup ? onToggleVisibility : null}
                onGoToFrame={isRendererSetup ? onGoToFrame : null}
              />
            ))}
          </ul>
        </Collapse>
      )}
    </LayerListItem>
  );
};

CompositionListItem.propTypes = {
  composition: PropTypes.oneOfType([
    projectManifestLayerPropType,
    projectManifestCompositionPropType,
  ]).isRequired,
  visibleLayerUUIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedLayerUUIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  isRendererSetup: PropTypes.bool.isRequired,
  onOpenComposition: PropTypes.func.isRequired,
  onExpandLayers: PropTypes.func.isRequired,
  onCollapseLayers: PropTypes.func.isRequired,
  onSelectLayer: PropTypes.func.isRequired,
  onToggleVisibility: PropTypes.func.isRequired,
  onGoToFrame: PropTypes.func.isRequired,
  areLayersExpanded: PropTypes.bool,
};

CompositionListItem.defaultProps = {
  areLayersExpanded: false,
};

export default CompositionListItem;
