/* global API_SERVER */
import axios from 'axios';

/**
 * Upload an after effects project.
 * @param  {FormData} formData  Multipart form data from .zip import
 */
export const uploadAfterEffectsProject = (confirmationCode) =>
  axios.post(`${API_SERVER}/api/after-effects-imports/`, {confirmationCode});

/**
 * Get an after effects project.
 * @param  {string}  id  Public ID for After Effects import
 */
export const getAfterEffectsProject = (id, isDetail) =>
  axios.get(`${API_SERVER}/api/after-effects-imports/${id}/${isDetail ? '?isDetail=true' : ''}`);

/**
 * Get after effects projects.
 */
export const getAfterEffectsProjects = () =>
  axios.get(`${API_SERVER}/api/after-effects-imports/?statuses=waiting,inProgress`);


/**
 * Returns a url for a project exported from the extension
 */
export const getProjectDownload = (confirmationCode) =>
  axios.post(`${API_SERVER}/api/project-download/`, {confirmationCode})
