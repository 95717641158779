import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { DynamicPropertyUpdateDispatch, DynamicPropertyUpdateState } from './contextProviders/DynamicPropertyUpdateProvider.js';
import { overrideTypes, templateManifestOverrideKeys } from '../constants/index.js';
import { selectors } from '../store/index.js';
import { collapsibleMenuContentContainer, WTSDropdownMenu } from './lib/index.js';


/**
 * Sets content overrides on individual or multiple layers.
 * @param  {func}   onOverrideSelect  Function to be called when an override is selected.
 * @param  {string} overrideType      Image or text.
 */
const ContentOverrideSelect = ({ overrideType }) => {
  const selectedLayersKey = useSelector(selectors.getSelectedLayersKey);
  const [dropdownMenuValues, setDropdownMenuValues] = useState({})
  const [contentOverrideSelection, setContentOverrideSelection] = useState(null)
  const overrides = useSelector(selectors.getTemplateManifestOverrides);
  const selectedOverride = useSelector((state) => selectors.getSharedOverrideUUID(state, templateManifestOverrideKeys.content));

  useEffect(() => {
    setContentOverrideSelection(selectedOverride);
  }, [selectedLayersKey])

  const shouldDeselectOverride = !useContext(DynamicPropertyUpdateState);

  useEffect(() => {
    if (shouldDeselectOverride) {
      setContentOverrideSelection(null);
    }
  }, [shouldDeselectOverride])

  const getDropdownMenuData = () => {
    // Get content overrides, format them into dropdown menu items, 
    // add the 'No override option', and calculate the default value.
    const contentOverrides = _.filter(
      overrides,
      (override) => override.type === overrideTypes[overrideType],
    );

    const menuItems = _.map(contentOverrides, (override) => ({
      label: override.name,
      value: override.id,
    }));
    menuItems.unshift({ label: 'No override', value: null });
    const value = _.find(
      menuItems,
      (menuItem) => menuItem.value === contentOverrideSelection,
    )

    setDropdownMenuValues({ menuItems, value })
  }

  // If we update either the selected override or the selected layer,
  // let's be sure to update our form.
  // MB TODO: Can this be dried up with the `useDeepCompareEffect` above?
  useEffect(() => {
    getDropdownMenuData();
  }, [contentOverrideSelection, selectedLayersKey]);

  const onOverrideSelect = useContext(DynamicPropertyUpdateDispatch)
  const onContentOverrideSelect = async (contentOverride) => {
    const updateValue = { content: contentOverride.value };
    onOverrideSelect(updateValue);
    setContentOverrideSelection(contentOverride.value);
  };

  return (
    <div className={collapsibleMenuContentContainer}>
      <WTSDropdownMenu
        label="Content override"
        labelSubtext={`Override with ${
          overrideType === overrideTypes.image ? 'an' : ''
        } ${overrideType} from a content group`}
        menuItems={dropdownMenuValues.menuItems}
        onItemSelect={onContentOverrideSelect}
        value={dropdownMenuValues.value}
      />
    </div>
  );
};

ContentOverrideSelect.defaultProps = {
  overrideType: overrideTypes.text,
};

ContentOverrideSelect.propTypes = {
  overrideType: PropTypes.string,
};

export default ContentOverrideSelect;
