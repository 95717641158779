/* eslint-env browser */
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import cxs from 'cxs';
import { FileDrop } from 'react-file-drop';
import { useSelector, useDispatch } from 'react-redux';
import { FileInput } from '@blueprintjs/core';

import { blurIfEnterKeyPress } from '../utils/dom.js';
import { useMirroredState } from '../utils/hooks.js';
import { createAssetFromVideoTemplateAudio } from '../utils/projectManifest.js';
import { audioTrackPropType, wtsIntent } from '../constants/index.js';
import { actions, operations, selectors } from '../store/index.js';
import { successBackground } from './lib/colors.js';
import { collapsibleMenuContentContainer, WTSButton, WTSFormGroup, WTSInputGroup, WTSDropdownMenu } from './lib/index.js';
import CollapsibleMenu from './CollapsibleMenu.js';
import ListManager from './ListManager.js';


const AudioTrackDetailForm = ({ audioTrack }) => {
  const audioTrackName = audioTrack ? audioTrack.name : '';
  const [localName, setLocalName] = useMirroredState(audioTrackName);

  const dispatch = useDispatch();

  const updateAudioOption = (updatedSwitch) => {
    dispatch(operations.updateTemplateManifest(actions.updateAudioOption, updatedSwitch));
  };

  const onChangeTrackName = (e) => {
    setLocalName(e.currentTarget.value);
  };

  const onSubmitTrackName = () => {
    if (localName !== audioTrackName) {
      const modifiedTrack = {
        ...audioTrack,
        name: localName,
      };
      updateAudioOption(modifiedTrack);
    }
  };

  if (!audioTrack) {
    return null;
  }

  return (
    <div className={collapsibleMenuContentContainer}>
      <WTSFormGroup label="Track name" labelFor="name" labelSubtext="Describe the audio track">
        <WTSInputGroup
          id="name"
          type="text"
          value={localName}
          onChange={onChangeTrackName}
          onBlur={onSubmitTrackName}
          onKeyPress={blurIfEnterKeyPress}
        />
      </WTSFormGroup>
    </div>
  );
};

AudioTrackDetailForm.propTypes = {
  audioTrack: audioTrackPropType,
};

AudioTrackDetailForm.defaultProps = {
  audioTrack: null,
};

const inputStyles = cxs({
  maxWidth: '100%',
  '> input': {
    maxWidth: '100%',
  },
});

const uploadButtonStyles = cxs({
  marginTop: '12px',
});

const confirmMessageStyles = cxs({
  color: successBackground,
  fontSize: '11px',
  marginTop: '12px',
  textAlign: 'center',
});

const MusicPanelContent = () => {
  const [selectedAudioTrackId, setSelectedAudioTrackId] = useState(null);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [isUploadInProgress, setIsUploadInProgress] = useState(false);
  const [uploadConfirmMessage, setUploadConfirmMessage] = useState(null);
  const [lastUploadedAudioFile, setLastUploadedAudioFile] = useState(null);

  const dispatch = useDispatch();

  const audioOptions = useSelector(selectors.getTemplateManifestBackgroundAudioOptions);
  const defaultSelectedId = useSelector(selectors.getTemplateManifestBackgroundAudioDefaultSelection);
  const selectedAudioTrack = _.find(audioOptions, { id: selectedAudioTrackId });

  const onDrop = useCallback((files) => {
    setFilesToUpload([ ...files ]);
    setUploadConfirmMessage(null);
  }, []);

  const onClickAudioTrack = (audioTrackId) => {
    setSelectedAudioTrackId(audioTrackId);
  };

  const onDeleteAudioTrack = (audioTrackId) => {
    dispatch(operations.updateTemplateManifest(operations.removeVideoTemplateAudio, audioTrackId));
  };

  const onChangeFiles = (e) => {
    setFilesToUpload([ ...e.currentTarget.files ]);
    setUploadConfirmMessage(null);
  };

  const onCreateAudioTrack = async () => {
    setIsUploadInProgress(true);

    const formData = new FormData();
    filesToUpload.forEach((file, index) => {
      formData.append(`audioFile[${index}]`, file);
    });

    const videoTemplateAudios = await dispatch(operations.createVideoTemplateAudio(formData));
    const assets = [];
    for (let i = 0; i < videoTemplateAudios.length; i += 1) {
      const asset = createAssetFromVideoTemplateAudio(videoTemplateAudios[i]);
      // eslint-disable-next-line no-await-in-loop
      await dispatch(operations.updateTemplateManifest(actions.createdAudioOption, asset));
      assets.push(asset);
    }

    const assetNames = assets.map(({name}) => name).join(', ');
    setUploadConfirmMessage(
      `${assetNames.length > 1 ? 'Files' : 'File'} ${assetNames} uploaded successfully!`,
    );
    setIsUploadInProgress(false);

    /* We need to tell our ListManager to update the selected item for us.
    So in addition to selecting the audio id to show in the AudioTrackDetailForm,
    we store the lastUploadedAudioFile to do a one-time "override" in the ListManager. */
    const { id } = assets[assets.length - 1];
    setLastUploadedAudioFile(id);
    setSelectedAudioTrackId(id);
  };

  const onChangeDefaultSelection = (menuOption) => {
    dispatch(
      operations.updateTemplateManifest(
        operations.updateBackgroundAudioDefaultSelection,
        menuOption.value,
      ),
    );
  };

  const menuItems = audioOptions.map((option) => ({
    label: option.name,
    value: option.id,
  }))

  const selectedAudioOption = _.find(menuItems, { value: defaultSelectedId });

  return (
    <div>
      <CollapsibleMenu title="Music Tracks">
        <FileDrop onDrop={onDrop}>
          <ListManager
            items={audioOptions}
            listItemName="music track"
            onDelete={onDeleteAudioTrack}
            onItemSelected={onClickAudioTrack}
            selectedItemIdOverride={lastUploadedAudioFile}
          />
          <div className={collapsibleMenuContentContainer}>
            <WTSFormGroup label="Upload a track" labelSubtext="Browse for a track and upload it">
              <FileInput
                className={inputStyles}
                inputProps={{ multiple: true }}
                onInputChange={onChangeFiles}
                text={filesToUpload.length ? filesToUpload.map(({ name }) => name).join(', ') : 'Choose file(s)'}
              />
              <WTSButton
                onClick={onCreateAudioTrack}
                wtsIntent={wtsIntent.default}
                disabled={!filesToUpload.length || isUploadInProgress}
                loading={isUploadInProgress}
                className={uploadButtonStyles}
                fullWidth
              >
                Upload
              </WTSButton>
              <div className={confirmMessageStyles}>{uploadConfirmMessage}</div>
            </WTSFormGroup>
            <WTSDropdownMenu
              menuItems={menuItems}
              onItemSelect={onChangeDefaultSelection}
              label="Default selection"
              labelSubtext="The initially selected music track"
              value={selectedAudioOption}
            />
          </div>
        </FileDrop>
      </CollapsibleMenu>
      <CollapsibleMenu title="Track properties">
        <AudioTrackDetailForm audioTrack={selectedAudioTrack} />
      </CollapsibleMenu>
    </div>
  );
};

export default MusicPanelContent;
