import _ from 'lodash';
import cxs from 'cxs';
import { Intent } from '@blueprintjs/core';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions, selectors, operations } from '../store/index.js';
import CollapsibleMenu from './CollapsibleMenu.js';
import EditableFontOverrideContent from './EditableFontOverrideContent.js';
import { DynamicPropertyUpdateDispatch } from './contextProviders/DynamicPropertyUpdateProvider.js';
import {
  overrideTypes,
  overrideDetailFormLabels,
  templateManifestOverrideKeys,
} from '../constants/index.js';
import { overridePropType } from '../constants/propTypes.js';
import RemovableLayerListItem from './RemovableLayerListItem.js';
import {
  collapsibleMenuContentContainer,
  WTSFormGroup,
  WTSInputGroup,
  WTSDropdownMenu,
} from './lib/index.js';
import { fitFillAlignments } from '@stikdev/waymark-author-web-renderer';

const layerListStyles = cxs({
  marginTop: '12px',
});

/**
 * Menu to define or edit override properties.
 * @param  {object}   override      Override to edit.
 */
const OverrideDetailForm = ({ override }) => {
  const [formValues, setFormValues] = useState({});
  const overrideLayers = useSelector((state) => selectors.getOverrideMembers(state, override.id));
  const updateOverrideLayers = useContext(DynamicPropertyUpdateDispatch);
  const dispatch = useDispatch();

  useEffect(() => {
    const initialFormValues = {
      id: _.get(override, 'id'),
      name: _.get(override, 'name'),
      placeholder: _.get(override, 'placeholder'),
      frameNumber: _.get(override, 'frameNumber'),
    };

    if (override && override.type === overrideTypes.image) {
      // We default to true for freeform cropping (which is equivalent to "fit my entire image" in the UI) here because:
      // 1. That matches the editor's default behavior for when a freeformCropping value is not provided.
      // 2. There are old templates that don't have this property set, and we want to default to the editor's behavior in those cases.
      // On enabling content editable, or creating a new override, we will however set the default to false (fill the whole container).
      initialFormValues.freeformCropping = _.get(override, 'freeformCropping', true);

      initialFormValues.fitFillAlignment = _.get(
        override,
        'fitFillAlignment',
        fitFillAlignments.centerCenter,
      );
    }

    if (override && override.type === overrideTypes.color) {
      // Default to `null`
      initialFormValues.displayName = _.get(override, 'displayName', null);
    }

    setFormValues(initialFormValues);
  }, [override.id]);

  const overrideType = override ? override.type : null;

  const updateOverride = async (updatePayload) => {
    await dispatch(operations.updateTemplateManifest(actions.updateOverride, updatePayload));
  };

  const updateFormValues = (newFormValues) => {
    const updatePayload = {
      ...newFormValues,
      type: overrideType,
    };

    updateOverride(updatePayload);
  };

  const onBlur = (event) => {
    event.preventDefault();

    updateFormValues(formValues);
  };

  const onChangeOverrideName = (event) => {
    const newFormValues = {
      ...formValues,
      name: event.currentTarget.value,
    };
    setFormValues(newFormValues);
  };

  const onChangeOverrideDisplayName = (event) => {
    const newFormValues = {
      ...formValues,
      // If it's an empty string, set it to null
      displayName: event.currentTarget.value || null,
    };
    setFormValues(newFormValues);
  };

  const onChangeOverridePlaceholder = (placeholder) => {
    const updateValue = _.get(placeholder, 'currentTarget.value', placeholder.value);
    setFormValues({
      ...formValues,
      placeholder: updateValue,
    });
  };

  const onChangeImageOverridePlaceholder = (placeholder) => {
    const updateValue = _.get(placeholder, 'currentTarget.value', placeholder.value);
    const newFormValues = {
      ...formValues,
      placeholder: updateValue,
    };
    setFormValues(newFormValues);
    updateFormValues(newFormValues);
  };

  const onChangeOverrideFrameNumber = (event) => {
    setFormValues({
      ...formValues,
      frameNumber: parseInt(event.currentTarget.value, 10),
    });
  };

  const onSelectFreeformCropping = (freeformCroppingSelection) => {
    const newFormValues = {
      ...formValues,
      freeformCropping: freeformCroppingSelection.value,
    };
    setFormValues(newFormValues);
    updateFormValues(newFormValues);
  };

  const onSelectFitFillAlignment = async (fitFillAlignmentSelection) => {
    const newFormValues = {
      ...formValues,
      fitFillAlignment: fitFillAlignmentSelection.value,
    };

    setFormValues(newFormValues);
    updateFormValues(newFormValues);
  };

  const onRemoveOverrideLayer = (layerUUID, property = null) => {
    // Update the override
    const layers = _.without(override.layers, layerUUID);
    const modifiedOverride = {
      ...override,
      layers,
    };
    updateOverride(modifiedOverride);

    // Update the layer.
    // A layer can have the same override applied to multiple color attributes,
    // but only one override applied to their content, so if a specific color
    // attribute was not passed it, remove the content override.
    const propertyToDelete = property || templateManifestOverrideKeys.content;
    updateOverrideLayers({ [propertyToDelete]: null }, [layerUUID]);
  };

  const croppingDropdownMenuItems = [
    {
      label: 'Fit my entire image',
      value: true,
    },
    {
      label: 'Fill the whole container',
      value: false,
    },
  ];

  const croppingDropdownValue = _.get(formValues, 'freeformCropping')
    ? croppingDropdownMenuItems[0]
    : croppingDropdownMenuItems[1];

  const alignmentMenuItems = Object.keys(fitFillAlignments).map((alignment) => ({
    label: alignment,
    value: fitFillAlignments[alignment],
  }));

  const defaultAlignmentCroppingValue = _.get(
    formValues,
    'fitFillAlignment',
    fitFillAlignments.centerCenter,
  );

  const alignmentCroppingValue = alignmentMenuItems.find(
    (menuItem) => menuItem.value === defaultAlignmentCroppingValue,
  );

  const layerListLabel = `Overrides ${override.type} on ${overrideLayers.length} layer${
    overrideLayers.length > 1 || overrideLayers.length === 0 ? 's' : ''
  }`;
  const imageLayers = useSelector(selectors.getProjectManifestImageLayers);

  const imageDropdownMenuItems = _.map(imageLayers, (layer) => ({
    label: layer.name,
    value: {
      id: layer.location.id,
      location: layer.location,
      modifications: layer.modifications,
      name: layer.name,
      type: layer.type,
    },
  }));

  const imagePlaceholderDropdownValue = _.find(
    imageDropdownMenuItems,
    (item) => _.get(item, 'value.id') === _.get(formValues, 'placeholder.id'),
  );

  const renderOverrideForm = () => {
    if (overrideType === 'font') {
      return <EditableFontOverrideContent override={override} />;
    }

    return (
      /* MB TODO: When we get into a release cycle mode, remove this. If we wanted to keep it, this should at least
      be a form element. But trying to use one blur handler for all the form elements here is getting us into trouble. */
      <div onBlur={onBlur}>
        <WTSFormGroup
          intent={Intent.NONE}
          label={overrideDetailFormLabels[overrideType].name.label}
          labelFor="name"
          labelSubtext={overrideDetailFormLabels[overrideType].name.labelSubtext}
        >
          <WTSInputGroup
            id="name"
            type="name"
            intent={Intent.None}
            value={formValues.name}
            onChange={onChangeOverrideName}
          />
        </WTSFormGroup>
        {/* SS TODO: The image menuitems are temporary until the image endpoint is completed */}
        {overrideType === overrideTypes.image ? (
          <WTSDropdownMenu
            label={overrideDetailFormLabels[overrideType].placeholder.label}
            labelSubtext={overrideDetailFormLabels[overrideType].placeholder.labelSubtext}
            menuItems={imageDropdownMenuItems}
            onItemSelect={onChangeImageOverridePlaceholder}
            value={imagePlaceholderDropdownValue}
          />
        ) : (
          <WTSFormGroup
            intent={Intent.NONE}
            label={overrideDetailFormLabels[overrideType].placeholder.label}
            labelFor="placeholder"
            labelSubtext={overrideDetailFormLabels[overrideType].placeholder.labelSubtext}
          >
            <WTSInputGroup
              id="placeholder"
              type="text"
              intent={Intent.None}
              value={formValues.placeholder}
              onChange={onChangeOverridePlaceholder}
            />
          </WTSFormGroup>
        )}
        {overrideType === overrideTypes.color && (
          <WTSFormGroup
            intent={Intent.NONE}
            label={overrideDetailFormLabels[overrideType].displayName.label}
            labelFor="displayName"
            labelSubtext={overrideDetailFormLabels[overrideType].displayName.labelSubtext}
          >
            <WTSInputGroup
              id="displayName"
              type="displayName"
              intent={Intent.None}
              // Handle if value is `null`. Write as empty string if it is.
              value={formValues.displayName || ''}
              onChange={onChangeOverrideDisplayName}
            />
          </WTSFormGroup>
        )}
        <WTSFormGroup
          intent={Intent.NONE}
          label={overrideDetailFormLabels[overrideType].frameNumber.label}
          labelFor="frameNumber"
          labelSubtext={overrideDetailFormLabels[overrideType].frameNumber.labelSubtext}
        >
          <WTSInputGroup
            id="frameNumber"
            type="number"
            intent={Intent.None}
            value={formValues.frameNumber}
            onChange={onChangeOverrideFrameNumber}
          />
        </WTSFormGroup>
        {overrideType === overrideTypes.image && (
          <>
            <WTSDropdownMenu
              label="Default cropping behavior"
              labelSubtext="Fill container, or fit entire image"
              menuItems={croppingDropdownMenuItems}
              onItemSelect={onSelectFreeformCropping}
              value={croppingDropdownValue}
            />
            <WTSDropdownMenu
              label="Image Alignment for 'Fit My Entire Image'"
              labelSubtext="The alignment within the container when a user opts to 'fit my entire image'"
              menuItems={alignmentMenuItems}
              onItemSelect={onSelectFitFillAlignment}
              value={alignmentCroppingValue}
            />
          </>
        )}
        <WTSFormGroup
          label={layerListLabel}
          labelSubtext="Select layers in layer list to manage properties"
        >
          <div className={layerListStyles}>
            {_.map(overrideLayers, (layer) => {
              const layerUUID = Object.keys(layer)[0];
              const overrideProperties = Object.values(layer)[0];
              return override.type === overrideTypes.color ? (
                <RemovableLayerListItem
                  key={layerUUID}
                  layerUUID={layerUUID}
                  onRemove={onRemoveOverrideLayer}
                  displayProperties={overrideProperties}
                />
              ) : (
                <RemovableLayerListItem
                  key={layerUUID}
                  layerUUID={layerUUID}
                  onRemove={onRemoveOverrideLayer}
                />
              );
            })}
          </div>
        </WTSFormGroup>
      </div>
    );
  };

  return (
    <CollapsibleMenu title="Override properties">
      {override ? (
        <div className={collapsibleMenuContentContainer}>
          {overrideType && renderOverrideForm()}
        </div>
      ) : (
        <div />
      )}
    </CollapsibleMenu>
  );
};

OverrideDetailForm.propTypes = {
  override: overridePropType.isRequired,
};

export default OverrideDetailForm;
