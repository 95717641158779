/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Callout, Card, Intent, Position, Toast, Toaster } from '@blueprintjs/core';
import { useSelector, useDispatch } from 'react-redux';

import { actions, operations, selectors } from '../store/index.js';
import { makeImportant } from '../utils/styles.js';
import {
  Paragraph,
  SectionTitle,
  VerticallyAndHorizontallyCenter,
  WTSButton,
  WTSFormGroup,
  WTSInputGroup,
} from './lib/index.js';
import { panelBackground } from './lib/colors.js';
import DownloadProjectForm from './DownloadProjectForm.js';

const SharedAuthForm = ({
  buttonText,
  errorMessage,
  intent,
  isLoading,
  onFieldBlur,
  onFieldChange,
  onFormSubmit,
  formType,
}) => (
  <form id={`${formType}-form`} onSubmit={onFormSubmit}>
    <WTSFormGroup intent={intent} label="Username" labelFor="username">
      <WTSInputGroup
        id="username"
        type="username"
        intent={intent}
        onBlur={onFieldBlur}
        onChange={onFieldChange}
      />
    </WTSFormGroup>
    <WTSFormGroup intent={intent} label="Password" labelFor="password">
      <WTSInputGroup
        id="password"
        type="password"
        intent={intent}
        onBlur={onFieldBlur}
        onChange={onFieldChange}
      />
    </WTSFormGroup>
    {errorMessage && (
      <Callout
        intent={intent}
        css={{
          marginBottom: '12px',
          textAlign: 'left'
        }}
      >
        {errorMessage}
      </Callout>
    )}
    <WTSButton type="submit" wtsIntent="primary" loading={isLoading} fullWidth>
      {buttonText}
    </WTSButton>
  </form>
);

SharedAuthForm.propTypes = {
  buttonText: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  intent: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onFieldBlur: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  formType: PropTypes.string.isRequired,
};

SharedAuthForm.defaultProps = {
  errorMessage: null,
};

/**
 * Data wrapper around shared AuthForm component for purposes of Login.
 */
const LoginForm = () => {
  const loginError = useSelector(selectors.getLoginError);
  const isLoading = useSelector(selectors.getIsLoggingIn);

  const intent = loginError ? Intent.DANGER : Intent.NONE;
  const errorMessage = loginError ? loginError.message : '';
  const dispatch = useDispatch();

  const maybeClearErrorMessage = () => {
    if (errorMessage) {
      dispatch(actions.clearLoginError());
    }
  };

  const onFieldChange = () => {
    maybeClearErrorMessage();
  };

  const onFieldBlur = () => {
    maybeClearErrorMessage();
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    const requestData = {
      username: e.currentTarget.username.value,
      password: e.currentTarget.password.value,
    };
    dispatch(operations.attemptLogin(requestData));
  };

  return (
    <SharedAuthForm
      buttonText="Login"
      errorMessage={errorMessage}
      formType="login"
      intent={intent}
      isLoading={isLoading}
      onFieldBlur={onFieldBlur}
      onFieldChange={onFieldChange}
      onFormSubmit={onFormSubmit}
    />
  );
};

const LoginPreamble = () => (
  <>
    <SectionTitle
      css={{ marginTop: 0 }}
    >
      Log in
    </SectionTitle>
    <Paragraph>
      Login allows us to keep track of who is making updates to a&nbsp;template.
    </Paragraph>
  </>
);

/**
 * Top-level component for the /login route.
 */
const Authentication = () => {
  const isUserAuthenticated = useSelector(selectors.getIsUserAuthenticated);
  const hasBeenLoggedOut = useSelector(selectors.getHasBeenLoggedOut);

  const [isLoginVisible, setIsLoginVisible] = useState(false);
  const [shouldHideToast, setShouldHideToast] = useState(false);
  const toggleLoginVisibility = () => setIsLoginVisible(!isLoginVisible);

  const onDismissLogoutNotification = () => setShouldHideToast(true);

  return (
    <div>
      {/* If we're already authenticated, redirect to template selection. */}
      {isUserAuthenticated && <Redirect from="/login" to="/templates" />}
      {hasBeenLoggedOut && (
        <Toaster position={Position.TOP}>
          {!shouldHideToast && <Toast message="Your session has expired. Please log in to continue." onDismiss={onDismissLogoutNotification} />}
        </Toaster>
      )}
      <VerticallyAndHorizontallyCenter
        containerHeight="85vh"
        css={{
          padding: 12
        }}
      >
        <>
          <h1
            css={{
              textAlign: 'center'
            }}
          >
            Waymark Template Studio
          </h1>
          {isLoginVisible ? (
            <Card
              css={{
                backgroundColor: makeImportant(panelBackground),
                boxShadow: '0 6px 24px 0 rgba(0, 0, 0, 0.5)',
              }}
            >
              <LoginPreamble />
              <LoginForm />
            </Card>
          ) : (
            <Card>
              <DownloadProjectForm
                css={{
                  margin: `-20px`,
                }}
              />
            </Card>
          )}
          <div
            css={{
              textAlign: 'center',
              fontSize: '12px',
              margin: '12px auto',
            }}
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              onClick={toggleLoginVisibility}
              onKeyPress={toggleLoginVisibility}
              role="link"
              tabIndex={0}
            >
              { isLoginVisible ? 'Go back to download' : 'Have an account? Login.' }
            </a>
          </div>


        </>
      </VerticallyAndHorizontallyCenter>
    </div>
  );
};

export default Authentication;
