import _ from 'lodash';

import { colorArrayToHex } from './colors.js';
import { layerTypes } from '../constants/TemplateManifest.js';

const shapePropertyTypes = {
  fl: 'fillColor',
  st: 'strokeColor',
  gf: 'gradientFill',
  gr: 'group',
};

const fillEffectType = 21;
const fillEffectColorType = 2;

/**
 * Parse a layer's effects and return fill effect data.
 * @param  {array}  layerEffects   All effects associated with a layer.
 * @return {object|undefined}      A fill effect associated with a layer or undefined.
 *
 * SS TODO: When we add support for multiple fill effects to the renderer,
 * update this logic.
 */
const getFillEffect = (layerEffects) => {
  const layerFillEffect = _.filter(layerEffects, (effect) => effect.ty === fillEffectType);
  return layerFillEffect[0];
};

/**
 * Parse a layer's effects and return fill effect data.
 * @param  {object}  fillEffect   A fill effect associated with a layer.
 * @return {string}               Color hex code.
 *
 * SS TODO: When we add support for multiple fill effects to the renderer,
 * update this logic.
 */
const parseFillEffect = (fillEffect) => {
  if (Array.isArray(fillEffect)) {
    // eslint-disable-next-line no-param-reassign
    [fillEffect] = fillEffect;
  }
  const colorEffect = _.find(
    fillEffect.ef,
    (nestedEffect) => nestedEffect.ty === fillEffectColorType,
  );

  const hexCode = colorArrayToHex(colorEffect.v.k);
  return hexCode;
};

/**
 * Parse a project manifest text layer.
 * @param  {object} textLayer      Project manifest text layer.
 * @param  {object} rendererFonts  Project manifest renderer fonts.
 * @return {object}                Text layer data.
 */
export const parseTextLayerAttributes = (textLayer, rendererFonts) => {
  let fillColor = null;
  let fillEffect = null;
  let strokeColor = null;
  let rendererFont = null;
  let resizingStrategy = 'none';
  let verticalAlignment = 'top';

  const textStyleProperties = textLayer.t.d.k[0].s;
  if (textStyleProperties.fc) {
    fillColor = colorArrayToHex(textStyleProperties.fc);
  }

  if (textStyleProperties.f) {
    const fontKey = textStyleProperties.f;
    const foundRendererFont = rendererFonts[fontKey];
    if (foundRendererFont) {
      rendererFont = foundRendererFont;
    }
  }

  const foundFillEffect = getFillEffect(textLayer.ef);
  if (foundFillEffect) {
    fillEffect = parseFillEffect(foundFillEffect);
  }

  if (textStyleProperties.sc) {
    strokeColor = colorArrayToHex(textStyleProperties.sc);
  }

  if (textLayer.meta.textOptions && textLayer.meta.textOptions.verticalAlignment) {
    ({ verticalAlignment } = textLayer.meta.textOptions);
  }

  if (textLayer.meta.textOptions && textLayer.meta.textOptions.resizingStrategy) {
    ({ resizingStrategy } = textLayer.meta.textOptions);
  }

  return {
    fillColor,
    fillEffect,
    rendererFont,
    resizingStrategy,
    strokeColor,
    verticalAlignment,
    textContent: textStyleProperties.t,
  };
};

/**
 * Parse a project manifest solid layer.
 * @param  {object} solidLayer     Project manifest solid layer.
 * @return {object}                Solid layer data.
 */
export const parseSolidAttributes = (solidLayer) => {
  const hexCode = solidLayer.sc;
  const solidLayerData = { fillColor: hexCode };

  const fillEffect = getFillEffect(solidLayer.ef);
  if (fillEffect) {
    solidLayerData.fillEffect = parseFillEffect(fillEffect);
  }

  return solidLayerData;
};

/**
 * A recursive funciton that loops through shape properties and returns a
 * displayProperties object with properties that are editable by the Author
 *
 * NOTE/TODO: Currently, if there is more than one fill/stroke property in a shape,
 *            the renderer will update all properties to the same value.
 *
 * @param      {Object[]}  shapeProperties        An array of shape properties on the bodymovin shape object
 */
const parseShapeProperties = (shapeProperties) => {
  let displayProperties = {}
  shapeProperties.forEach((property) => {
    if (!property.hd && property.ty in shapePropertyTypes) {
      if (property.ty === _.invert(shapePropertyTypes).group) {
        // If the property is a group, loop through all of the child properties and add them to the object
        displayProperties = {
          ...displayProperties,
          ...parseShapeProperties(property.it),
        }
      } else if (property.ty === _.invert(shapePropertyTypes).gradientFill) {
        // Get the full array of colors -- this is a flat list of muneric values between 0 and 1,
        // where each color is represented by the 4 values (position, r, g, b).
        const gradientColorsArray = property.g.k.k;
        const stepsPlusMidpointsCount = property.g.p;

        // The bodymoving export includes colors for gradient steps (S) and midpoints (M) in
        // the pattern [S - M - S], or [S - M - S - M - S], etc.
        // But we're only interested in the step values, not the midpoints.
        const stepCount = Math.ceil((stepsPlusMidpointsCount) / 2);
        const gradientColors = [];
        for (let loopIndex = 0; loopIndex < stepCount; loopIndex +=1) {
          const startingColorIndex = loopIndex * 4;
          const colorArray = gradientColorsArray.slice(startingColorIndex, startingColorIndex + 4);
          // The first value is the position in the gradient, which is immutable from our perspective,
          // so we don't pay it any attention.
          const rbgArray = colorArray.slice(1);
          gradientColors.push(colorArrayToHex(rbgArray));
        }

        // eslint-disable-next-line no-param-reassign
        displayProperties[shapePropertyTypes[property.ty]] = gradientColors;
      } else {
        const hexCode = colorArrayToHex(property.c.k);
        // eslint-disable-next-line no-param-reassign
        displayProperties[shapePropertyTypes[property.ty]] = hexCode;
      }
    }
  })

  return displayProperties
}

/**
 * Parse a project manifest shape layer.
 * @param  {object} shapeLayer     Project manifest shape layer.
 * @return {object}                Shape layer data.
 */
export const parseShapeAttributes = (shapeLayer) => {
  const displayProperties = {
    // Default gradientFill to null, since not all shapes have it.
    gradientFill: null,
    // Update our display properties with the possible editable properties
    ...parseShapeProperties(shapeLayer.shapes)
  };

  const fillEffect = getFillEffect(shapeLayer.ef);
  if (fillEffect) {
    displayProperties.fillEffect = parseFillEffect(fillEffect);
  }

  return displayProperties;
};

/**
 * Parse a project manifest image layer.
 * @param  {object} imageLayer     Project manifest image layer.
 * @return {object}                Image layer data.
 */
export const parseImageAttributes = (imageLayer, assets) => {
  const imageData = {};
  const imageAsset = _.find(assets, { refId: imageLayer.refId });

  imageData.height = imageAsset.height;
  imageData.location = imageAsset.location;
  imageData.modifications = imageAsset.modifications;
  imageData.type = imageAsset.type;
  imageData.width = imageAsset.width;

  const fillEffect = getFillEffect(imageLayer.ef);
  if (fillEffect) {
    imageData.fillEffect = parseFillEffect(fillEffect);
  }

  if (imageLayer.fitFillAlignment) {
    imageData.fitFillAlignment = imageLayer.fitFillAlignment
  }

  return imageData;
};

/**
 * Parse a project manifest video layer.
 * @param  {object} videoLayer     Project manifest video layer.
 * @return {object}                Image layer data.
 */
export const parseVideoAttributes = (videoLayer, assets) => {
  const videoData = {};
  const videoAsset = _.find(assets, { refId: videoLayer.refId });

  videoData.height = videoAsset.height;
  videoData.location = videoAsset.location;
  videoData.modifications = videoAsset.modifications;
  // Should this be `videoAsset.type` and force all videoAssets exported to be `waymarkVideo`
  videoData.type = layerTypes.waymarkVideo;
  videoData.width = videoAsset.width;
  // Older project imports won't have a name assigned to the asset, so we need to make sure we don't override the layer name in that case
  if (videoAsset.name) {
    videoData.name = videoAsset.name;
  }

  const fillEffect = getFillEffect(videoLayer.ef);
  if (fillEffect) {
    videoData.fillEffect = parseFillEffect(fillEffect);
  }

  return videoData;
};

/**
 * Parse a project manifest preComp layer.
 * @param  {object} preCompLayer   Project manifest preComp layer.
 * @return {object}                PreComp layer data.
 */
export const parsePreCompAttributes = (preCompLayer) => {
  const preCompData = {}
  preCompData.referencedComposition = preCompLayer.refId;

  const fillEffect = getFillEffect(preCompLayer.ef);
  if (fillEffect) {
    preCompData.fillEffect = parseFillEffect(fillEffect);
  }

  return preCompData
};
