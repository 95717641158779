// From: https://util.unicode.org/UnicodeJsps/list-unicodeset.jsp?a=%5B%3AIndic_Positional_Category%3DLeft%3A%5D&g=&i=

export const IndicPositionalCategories = {
  left: [
    // ========
    // Devanagari — Dependent vowel sign items: 2
    // ========

    //  ि 	DEVANAGARI VOWEL SIGN I
    '\u093F',
    //  ॎ 	DEVANAGARI VOWEL SIGN PRISHTHAMATRA E
    '\u094E',

    // ========
    // Bengali — Dependent vowel sign items: 3
    // ========

    //  ি 	BENGALI VOWEL SIGN I
    '\u09BF',
    //  ে 	BENGALI VOWEL SIGN E
    '\u09C7',
    //  ৈ 	BENGALI VOWEL SIGN AI
    '\u09C8',

    // ========
    // Gurmukhi — Dependent vowel sign items: 1
    // ========

    //  ਿ 	GURMUKHI VOWEL SIGN I
    '\u0A3F',

    // ========
    // Gujarati — Dependent vowel sign items: 1
    // ========

    //  િ 	GUJARATI VOWEL SIGN I
    '\u0ABF',

    // ========
    // Oriya — Dependent vowel sign items: 1
    // ========

    //  େ 	ORIYA VOWEL SIGN E
    '\u0B47',

    // ========
    // Tamil — Dependent vowel sign items: 3
    // ========

    //  ெ 	TAMIL VOWEL SIGN E
    '\u0BC6',
    //  ே 	TAMIL VOWEL SIGN EE
    '\u0BC7',
    //  ை 	TAMIL VOWEL SIGN AI
    '\u0BC8',

    // ========
    // Malayalam — Dependent vowel sign items: 3
    // ========

    //  െ 	MALAYALAM VOWEL SIGN E
    '\u0D46',
    //  േ 	MALAYALAM VOWEL SIGN EE
    '\u0D47',
    //  ൈ 	MALAYALAM VOWEL SIGN AI
    '\u0D48',

    // ========
    // Sinhala — Dependent vowel sign items: 2
    // ========

    //  ෙ 	SINHALA VOWEL SIGN KOMBUVA
    '\u0DD9',
    //  ෛ 	SINHALA VOWEL SIGN KOMBU DEKA
    '\u0DDB',

    // ========
    // Tibetan — Astrological signs items: 1
    // ========

    //  ༿ 	TIBETAN SIGN MAR TSHES
    '\u0F3F',

    // ========
    // Myanmar — Dependent vowel sign items: 1
    // ========

    //  ေ 	MYANMAR VOWEL SIGN E
    '\u1031',

    // ========
    // Myanmar — Extensions for Shan items: 1
    // ========

    //  ႄ 	MYANMAR VOWEL SIGN SHAN E
    '\u1084',

    // ========
    // Khmer — Dependent vowel sign items: 3
    // ========

    //  េ 	KHMER VOWEL SIGN E
    '\u17C1',
    //  ែ 	KHMER VOWEL SIGN AE
    '\u17C2',
    //  ៃ 	KHMER VOWEL SIGN AI
    '\u17C3',

    // ========
    // Buginese — Vowel items: 1
    // ========

    //  ᨙ 	BUGINESE VOWEL SIGN E
    '\u1A19',

    // ========
    // Tai Tham — Consonant signs items: 1
    // ========

    //  ᩕ 	TAI THAM CONSONANT SIGN MEDIAL RA
    '\u1A55',

    // ========
    // Tai Tham — Dependent vowel sign items: 5
    // ========

    //  ᩮ 	TAI THAM VOWEL SIGN E
    '\u1A6E',
    //  ᩯ 	TAI THAM VOWEL SIGN AE
    '\u1A6F',
    //  ᩰ 	TAI THAM VOWEL SIGN OO
    '\u1A70',
    //  ᩱ 	TAI THAM VOWEL SIGN AI
    '\u1A71',
    //  ᩲ 	TAI THAM VOWEL SIGN THAM AI
    '\u1A72',

    // ========
    // Balinese — Dependent vowel sign items: 2
    // ========

    //  ᬾ 	BALINESE VOWEL SIGN TALING
    '\u1B3E',
    //  ᬿ 	BALINESE VOWEL SIGN TALING REPA
    '\u1B3F',

    // ========
    // Sundanese — Vowel sign items: 1
    // ========

    //  ᮦ 	SUNDANESE VOWEL SIGN PANAELAENG
    '\u1BA6',

    // ========
    // Lepcha — Dependent vowels items: 2
    // ========

    //  ᰧ 	LEPCHA VOWEL SIGN I
    '\u1C27',
    //  ᰨ 	LEPCHA VOWEL SIGN O
    '\u1C28',

    // ========
    // Lepcha — Consonant signs items: 2
    // ========

    //  ᰴ 	LEPCHA CONSONANT SIGN NYIN-DO
    '\u1C34',
    //  ᰵ 	LEPCHA CONSONANT SIGN KANG
    '\u1C35',

    // ========
    // Javanese — Dependent vowel sign items: 2
    // ========

    //  ꦺ 	JAVANESE VOWEL SIGN TALING
    '\uA9BA',
    //  ꦻ 	JAVANESE VOWEL SIGN DIRGA MURE
    '\uA9BB',

    // ========
    // Cham — Dependent vowel sign items: 2
    // ========

    //  ꨯ 	CHAM VOWEL SIGN O
    '\uAA2F',
    //  ꨰ 	CHAM VOWEL SIGN AI
    '\uAA30',

    // ========
    // Cham — Consonant signs items: 1
    // ========

    //  ꨴ 	CHAM CONSONANT SIGN RA
    '\uAA34',

    // ========
    // Meetei Mayek Extensions — Dependent vowel sign items: 2
    // ========

    //  ꫫ 	MEETEI MAYEK VOWEL SIGN II
    '\uAAEB',
    //  ꫮ 	MEETEI MAYEK VOWEL SIGN AU
    '\uAAEE',

    // ========
    // Kaithi — Dependent vowel sign items: 1
    // ========

    //  𑂱 	KAITHI VOWEL SIGN I
    '\u110B1',

    // ========
    // Chakma — Dependent vowel sign items: 1
    // ========

    //  𑄬 	CHAKMA VOWEL SIGN E
    '\u1112C',

    // ========
    // Sharada — Dependent vowel sign items: 1
    // ========

    //  𑆴 	SHARADA VOWEL SIGN I
    '\u111B4',

    // ========
    // Sharada — Historic vowel sign items: 1
    // ========

    //  𑇎 	SHARADA VOWEL SIGN PRISHTHAMATRA E
    '\u111CE',

    // ========
    // Khudawadi — Dependent vowel sign items: 1
    // ========

    //  𑋡 	KHUDAWADI VOWEL SIGN I
    '\u112E1',

    // ========
    // Grantha — Dependent vowel sign items: 2
    // ========

    //  𑍇 	GRANTHA VOWEL SIGN EE
    '\u11347',
    //  𑍈 	GRANTHA VOWEL SIGN AI
    '\u11348',

    // ========
    // Newa — Dependent vowel sign items: 1
    // ========

    //  𑐶 	NEWA VOWEL SIGN I
    '\u11436',

    // ========
    // Tirhuta — Dependent vowel sign items: 2
    // ========

    //  𑒱 	TIRHUTA VOWEL SIGN I
    '\u114B1',
    //  𑒹 	TIRHUTA VOWEL SIGN E
    '\u114B9',

    // ========
    // Siddham — Dependent vowel sign items: 2
    // ========

    //  𑖰 	SIDDHAM VOWEL SIGN I
    '\u115B0',
    //  𑖸 	SIDDHAM VOWEL SIGN E
    '\u115B8',

    // ========
    // Takri — Dependent vowel sign items: 1
    // ========

    //  𑚮 	TAKRI VOWEL SIGN I
    '\u116AE',

    // ========
    // Ahom — Vowel sign items: 1
    // ========

    //  𑜦 	AHOM VOWEL SIGN E
    '\u11726',

    // ========
    // Dogra — Dependent vowel sign items: 1
    // ========

    //  𑠭 	DOGRA VOWEL SIGN I
    '\u1182D',

    // ========
    // Dives Akuru — Dependent vowel sign items: 2
    // ========

    //  𑤵 	DIVES AKURU VOWEL SIGN E
    '\u11935',
    //  𑤷 	DIVES AKURU VOWEL SIGN AI
    '\u11937',

    // ========
    // Nandinagari — Dependent vowel sign items: 2
    // ========

    //  𑧒 	NANDINAGARI VOWEL SIGN I
    '\u119D2',
    //  𑧤 	NANDINAGARI VOWEL SIGN PRISHTHAMATRA E
    '\u119E4',

    // ========
    // Marchen — Dependent vowel sign items: 1
    // ========

    //  𑲱 	MARCHEN VOWEL SIGN I
    '\u11CB1',

    // ========
    // Makasar — Vowel sign items: 1
    // ========

    //  𑻵 	MAKASAR VOWEL SIGN E
    '\u11EF5',
  ],
  top: [
    // ========
    //     Devanagari — Various signs items: 3
    // ========

    //  ऀ  DEVANAGARI SIGN INVERTED CANDRABINDU,
    '\u0900',
    //  ँ 	 DEVANAGARI SIGN CANDRABINDU
    '\u0901',
    //  ं 	 DEVANAGARI SIGN ANUSVARA
    '\u0902',

    // ========
    //  Devanagari — Dependent vowel sign items: 6
    // ========

    //  ऺ 	 DEVANAGARI VOWEL SIGN OE
    '\u093A',
    //  ॅ 	 DEVANAGARI VOWEL SIGN CANDRA E
    '\u0945',
    //  ॆ 	 DEVANAGARI VOWEL SIGN SHORT E
    '\u0946',
    //  े 	 DEVANAGARI VOWEL SIGN E
    '\u0947',
    //  ै 	 DEVANAGARI VOWEL SIGN AI
    '\u0948',
    //  ॕ 	 DEVANAGARI VOWEL SIGN CANDRA LONG E
    '\u0955',

    // ========
    //  Devanagari — Vedic tone marks items: 1
    // ========

    //  ॑ 	 DEVANAGARI STRESS SIGN UDATTA
    '\u0951',

    // ========
    //  Devanagari — Accent marks items: 2
    // ========

    //  ॓ 	 DEVANAGARI GRAVE ACCENT
    '\u0953',
    //  ॔ 	 DEVANAGARI ACUTE ACCENT
    '\u0954',

    // ========
    //  Bengali — Various signs items: 1
    // ========

    //  ঁ 	 BENGALI SIGN CANDRABINDU
    '\u0981',

    // ========
    //  Bengali — Sign items: 1
    // ========

    //  ৾ 	 BENGALI SANDHI MARK
    '\u09FE',

    // ========
    //  Gurmukhi — Various signs items: 2
    // ========

    //  ਁ 	 GURMUKHI SIGN ADAK BINDI
    '\u0A01',
    //  ਂ 	 GURMUKHI SIGN BINDI
    '\u0A02',

    // ========
    //  Gurmukhi — Dependent vowel sign items: 4
    // ========

    //  ੇ 	 GURMUKHI VOWEL SIGN EE
    '\u0A47',
    //  ੈ 	 GURMUKHI VOWEL SIGN AI
    '\u0A48',
    //  ੋ 	 GURMUKHI VOWEL SIGN OO
    '\u0A4B',
    //  ੌ 	 GURMUKHI VOWEL SIGN AU
    '\u0A4C',

    // ========
    //  Gurmukhi — Sign items: 2
    // ========

    //  ੰ 	 GURMUKHI TIPPI
    '\u0A70',
    //  ੱ 	 GURMUKHI ADDAK
    '\u0A71',

    // ========
    //  Gujarati — Various signs items: 2
    // ========

    //  ઁ 	 GUJARATI SIGN CANDRABINDU
    '\u0A81',
    //  ં 	 GUJARATI SIGN ANUSVARA
    '\u0A82',

    // ========
    //  Gujarati — Dependent vowel sign items: 3
    // ========

    //  ૅ 	 GUJARATI VOWEL SIGN CANDRA E
    '\u0AC5',
    //  ે 	 GUJARATI VOWEL SIGN E
    '\u0AC7',
    //  ૈ 	 GUJARATI VOWEL SIGN AI
    '\u0AC8',

    // ========
    //  Gujarati — Transliteration signs items: 6
    // ========

    //  ૺ 	 GUJARATI SIGN SUKUN
    '\u0AFA',
    //  ૻ 	 GUJARATI SIGN SHADDA
    '\u0AFB',
    //  ૼ 	 GUJARATI SIGN MADDAH
    '\u0AFC',
    //  ૽ 	 GUJARATI SIGN THREE-DOT NUKTA ABOVE
    '\u0AFD',
    //  ૾ 	 GUJARATI SIGN CIRCLE NUKTA ABOVE
    '\u0AFE',
    //  ૿ 	 GUJARATI SIGN TWO-CIRCLE NUKTA ABOVE
    '\u0AFF',

    // ========
    //  Oriya — Various signs items: 3
    // ========

    //  ଁ 	 ORIYA SIGN CANDRABINDU
    '\u0B01',
    //  ୕ 	 ORIYA SIGN OVERLINE
    '\u0B55',
    //  ୖ 	 ORIYA AI LENGTH MARK
    '\u0B56',

    // ========
    //  Oriya — Dependent vowel sign items: 1
    // ========

    //  ି 	 ORIYA VOWEL SIGN I
    '\u0B3F',

    // ========
    //  Tamil — Various signs items: 1
    // ========

    //  ஂ 	 TAMIL SIGN ANUSVARA
    '\u0B82',

    // ========
    //  Tamil — Dependent vowel sign items: 1
    // ========

    //  ீ 	 TAMIL VOWEL SIGN II
    '\u0BC0',

    // ========
    //  Tamil — Virama items: 1
    // ========

    //  ் 	 TAMIL SIGN VIRAMA
    '\u0BCD',

    // ========
    //  Telugu — Various signs items: 3
    // ========

    //  ఀ 	 TELUGU SIGN COMBINING CANDRABINDU ABOVE
    '\u0C00',
    //  ఄ 	 TELUGU SIGN COMBINING ANUSVARA ABOVE
    '\u0C04',
    //  ౕ 	 TELUGU LENGTH MARK
    '\u0C55',

    // ========
    //  Telugu — Dependent vowel sign items: 8
    // ========

    //  ా 	 TELUGU VOWEL SIGN AA
    '\u0C3E',
    //  ి 	 TELUGU VOWEL SIGN I
    '\u0C3F',
    //  ీ 	 TELUGU VOWEL SIGN II
    '\u0C40',
    //  ె 	 TELUGU VOWEL SIGN E
    '\u0C46',
    //  ే 	 TELUGU VOWEL SIGN EE
    '\u0C47',
    //  ొ 	 TELUGU VOWEL SIGN O
    '\u0C4A',
    //  ో 	 TELUGU VOWEL SIGN OO
    '\u0C4B',
    //  ౌ 	 TELUGU VOWEL SIGN AU
    '\u0C4C',

    // ========
    //  Telugu — Virama items: 1
    // ========

    //  ్ 	 TELUGU SIGN VIRAMA
    '\u0C4D',

    // ========
    //  Kannada — Various signs items: 1
    // ========

    //  ಁ 	 KANNADA SIGN CANDRABINDU
    '\u0C81',

    // ========
    //  Kannada — Dependent vowel sign items: 3
    // ========

    //  ಿ 	 KANNADA VOWEL SIGN I
    '\u0CBF',
    //  ೆ 	 KANNADA VOWEL SIGN E
    '\u0CC6',
    //  ೌ 	 KANNADA VOWEL SIGN AU
    '\u0CCC',

    // ========
    //  Kannada — Virama items: 1
    // ========

    //  ್ 	 KANNADA SIGN VIRAMA
    '\u0CCD',

    // ========
    //  Malayalam — Various signs items: 2
    // ========

    //  ഀ 	 MALAYALAM SIGN COMBINING ANUSVARA ABOVE
    '\u0D00',
    //  ഁ 	 MALAYALAM SIGN CANDRABINDU
    '\u0D01',

    // ========
    //  Malayalam — Variant shape viramas items: 2
    // ========

    //  ഻ 	 MALAYALAM SIGN VERTICAL BAR VIRAMA
    '\u0D3B',
    //  ഼ 	 MALAYALAM SIGN CIRCULAR VIRAMA
    '\u0D3C',

    // ========
    //  Malayalam — Virama items: 1
    // ========

    //  ് 	 MALAYALAM SIGN VIRAMA
    '\u0D4D',

    // ========
    //  Malayalam — Dot reph items: 1
    // ========

    //  ൎ 	 MALAYALAM LETTER DOT REPH
    '\u0D4E',

    // ========
    //  Sinhala — Various signs items: 1
    // ========

    //  ඁ 	 SINHALA SIGN CANDRABINDU
    '\u0D81',

    // ========
    //  Sinhala — Sign items: 1
    // ========

    //  ් 	 SINHALA SIGN AL-LAKUNA
    '\u0DCA',

    // ========
    //  Sinhala — Dependent vowel sign items: 2
    // ========

    //  ි 	 SINHALA VOWEL SIGN KETTI IS-PILLA
    '\u0DD2',
    //  ී 	 SINHALA VOWEL SIGN DIGA IS-PILLA
    '\u0DD3',

    // ========
    //  Thai — Vowel items: 6
    // ========

    //  ั 	 THAI CHARACTER MAI HAN-AKAT
    '\u0E31',
    //  ิ 	 THAI CHARACTER SARA I
    '\u0E34',
    //  ี 	 THAI CHARACTER SARA II
    '\u0E35',
    //  ึ 	 THAI CHARACTER SARA UE
    '\u0E36',
    //  ื 	 THAI CHARACTER SARA UEE
    '\u0E37',
    //  ็ 	 THAI CHARACTER MAITAIKHU
    '\u0E47',

    // ========
    //  Thai — Tone marks items: 4
    // ========

    //  ่ 	 THAI CHARACTER MAI EK
    '\u0E48',
    //  ้ 	 THAI CHARACTER MAI THO
    '\u0E49',
    //  ๊ 	 THAI CHARACTER MAI TRI
    '\u0E4A',
    //  ๋ 	 THAI CHARACTER MAI CHATTAWA
    '\u0E4B',

    // ========
    //  Thai — Sign items: 3
    // ========

    //  ์ 	 THAI CHARACTER THANTHAKHAT
    '\u0E4C',
    //  ํ 	 THAI CHARACTER NIKHAHIT
    '\u0E4D',
    //  ๎ 	 THAI CHARACTER YAMAKKAN
    '\u0E4E',

    // ========
    //  Lao — Vowel items: 6
    // ========

    //  ັ 	 LAO VOWEL SIGN MAI KAN
    '\u0EB1',
    //  ິ 	 LAO VOWEL SIGN I
    '\u0EB4',
    //  ີ 	 LAO VOWEL SIGN II
    '\u0EB5',
    //  ຶ 	 LAO VOWEL SIGN Y
    '\u0EB6',
    //  ື 	 LAO VOWEL SIGN YY
    '\u0EB7',
    //  ົ 	 LAO VOWEL SIGN MAI KON
    '\u0EBB',

    // ========
    //  Lao — Tone marks items: 4
    // ========

    //  ່ 	 LAO TONE MAI EK
    '\u0EC8',
    //  ້ 	 LAO TONE MAI THO
    '\u0EC9',
    //  ໊ 	 LAO TONE MAI TI
    '\u0ECA',
    //  ໋ 	 LAO TONE MAI CATAWA
    '\u0ECB',

    // ========
    //  Lao — Sign items: 2
    // ========

    //  ໌ 	 LAO CANCELLATION MARK
    '\u0ECC',
    //  ໍ 	 LAO NIGGAHITA
    '\u0ECD',

    // ========
    //  Tibetan — Sign items: 5
    // ========

    //  ༹ 	 TIBETAN MARK TSA -PHRU
    '\u0F39',
    //  ྂ 	 TIBETAN SIGN NYI ZLA NAA DA
    '\u0F82',
    //  ྃ 	 TIBETAN SIGN SNA LDAN
    '\u0F83',
    //  ྆ 	 TIBETAN SIGN LCI RTAGS
    '\u0F86',
    //  ྇ 	 TIBETAN SIGN YANG RTAGS
    '\u0F87',

    // ========
    //  Tibetan — Dependent vowel sign items: 6
    // ========

    //  ི 	 TIBETAN VOWEL SIGN I
    '\u0F72',
    //  ེ 	 TIBETAN VOWEL SIGN E
    '\u0F7A',
    //  ཻ 	 TIBETAN VOWEL SIGN EE
    '\u0F7B',
    //  ོ 	 TIBETAN VOWEL SIGN O
    '\u0F7C',
    //  ཽ 	 TIBETAN VOWEL SIGN OO
    '\u0F7D',
    //  ྀ 	 TIBETAN VOWEL SIGN REVERSED I
    '\u0F80',

    // ========
    //  Tibetan — Vocalic modification items: 1
    // ========

    //  ཾ 	 TIBETAN SIGN RJES SU NGA RO
    '\u0F7E',

    // ========
    //  Myanmar — Dependent vowel sign items: 6
    // ========

    //  ိ 	 MYANMAR VOWEL SIGN I
    '\u102D',
    //  ီ 	 MYANMAR VOWEL SIGN II
    '\u102E',
    //  ဲ 	 MYANMAR VOWEL SIGN AI
    '\u1032',
    //  ဳ 	 MYANMAR VOWEL SIGN MON II
    '\u1033',
    //  ဴ 	 MYANMAR VOWEL SIGN MON O
    '\u1034',
    //  ဵ 	 MYANMAR VOWEL SIGN E ABOVE
    '\u1035',

    // ========
    //  Myanmar — Various signs items: 1
    // ========

    //  ံ 	 MYANMAR SIGN ANUSVARA
    '\u1036',

    // ========
    //  Myanmar — Virama and killer items: 1
    // ========

    //  ် 	 MYANMAR SIGN ASAT
    '\u103A',

    // ========
    //  Myanmar — Extension for Geba Karen items: 1
    // ========

    //  ၱ 	 MYANMAR VOWEL SIGN GEBA KAREN I
    '\u1071',

    // ========
    //  Myanmar — Extensions for Kayah items: 3
    // ========

    //  ၲ 	 MYANMAR VOWEL SIGN KAYAH OE
    '\u1072',
    //  ၳ 	 MYANMAR VOWEL SIGN KAYAH U
    '\u1073',
    //  ၴ 	 MYANMAR VOWEL SIGN KAYAH EE
    '\u1074',

    // ========
    //  Myanmar — Extensions for Shan items: 2
    // ========

    //  ႅ 	 MYANMAR VOWEL SIGN SHAN E ABOVE
    '\u1085',
    //  ႆ 	 MYANMAR VOWEL SIGN SHAN FINAL Y
    '\u1086',

    // ========
    //  Myanmar — Extensions for Aiton and Phake items: 1
    // ========

    //  ႝ 	 MYANMAR VOWEL SIGN AITON AI
    '\u109D',

    // ========
    //  Tagalog — Dependent vowel sign items: 1
    // ========

    //  ᜒ 	 TAGALOG VOWEL SIGN I
    '\u1712',

    // ========
    //  Hanunoo — Dependent vowel sign items: 1
    // ========

    //  ᜲ 	 HANUNOO VOWEL SIGN I
    '\u1732',

    // ========
    //  Buhid — Dependent vowel sign items: 1
    // ========

    //  ᝒ 	 BUHID VOWEL SIGN I
    '\u1752',

    // ========
    //  Tagbanwa — Dependent vowel sign items: 1
    // ========

    //  ᝲ 	 TAGBANWA VOWEL SIGN I
    '\u1772',

    // ========
    //  Khmer — Dependent vowel sign items: 4
    // ========

    //  ិ 	 KHMER VOWEL SIGN I
    '\u17B7',
    //  ី 	 KHMER VOWEL SIGN II
    '\u17B8',
    //  ឹ 	 KHMER VOWEL SIGN Y
    '\u17B9',
    //  ឺ 	 KHMER VOWEL SIGN YY
    '\u17BA',

    // ========
    //  Khmer — Various signs items: 9
    // ========

    //  ំ 	 KHMER SIGN NIKAHIT
    '\u17C6',
    //  ់ 	 KHMER SIGN BANTOC
    '\u17CB',
    //  ៌ 	 KHMER SIGN ROBAT
    '\u17CC',
    //  ៍ 	 KHMER SIGN TOANDAKHIAT
    '\u17CD',
    //  ៎ 	 KHMER SIGN KAKABAT
    '\u17CE',
    //  ៏ 	 KHMER SIGN AHSDA
    '\u17CF',
    //  ័ 	 KHMER SIGN SAMYOK SANNYA
    '\u17D0',
    //  ៑ 	 KHMER SIGN VIRIAM
    '\u17D1',
    //  ៝ 	 KHMER SIGN ATTHACAN
    '\u17DD',

    // ========
    //  Khmer — Consonant shifters items: 2
    // ========

    //  ៉ 	 KHMER SIGN MUUSIKATOAN
    '\u17C9',
    //  ៊ 	 KHMER SIGN TRIISAP
    '\u17CA',

    // ========
    //  Khmer — Lunar date sign items: 1
    // ========

    //  ៓ 	 KHMER SIGN BATHAMASAT
    '\u17D3',

    // ========
    //  Limbu — Dependent vowel sign items: 4
    // ========

    //  ᤠ 	 LIMBU VOWEL SIGN A
    '\u1920',
    //  ᤡ 	 LIMBU VOWEL SIGN I
    '\u1921',
    //  ᤧ 	 LIMBU VOWEL SIGN E
    '\u1927',
    //  ᤨ 	 LIMBU VOWEL SIGN O
    '\u1928',

    // ========
    //  Limbu — Various signs items: 1
    // ========

    //  ᤺ 	 LIMBU SIGN KEMPHRENG
    '\u193A',

    // ========
    //  Buginese — Vowel items: 2
    // ========

    //  ᨗ 	 BUGINESE VOWEL SIGN I
    '\u1A17',
    //  ᨛ 	 BUGINESE VOWEL SIGN AE
    '\u1A1B',

    // ========
    //  Tai Tham — Consonant signs items: 3
    // ========

    //  ᩘ 	 TAI THAM SIGN MAI KANG LAI
    '\u1A58',
    //  ᩙ 	 TAI THAM CONSONANT SIGN FINAL NGA
    '\u1A59',
    //  ᩚ 	 TAI THAM CONSONANT SIGN LOW PA
    '\u1A5A',

    // ========
    //  Tai Tham — Dependent vowel sign items: 8
    // ========

    //  ᩢ 	 TAI THAM VOWEL SIGN MAI SAT
    '\u1A62',
    //  ᩥ 	 TAI THAM VOWEL SIGN I
    '\u1A65',
    //  ᩦ 	 TAI THAM VOWEL SIGN II
    '\u1A66',
    //  ᩧ 	 TAI THAM VOWEL SIGN UE
    '\u1A67',
    //  ᩨ 	 TAI THAM VOWEL SIGN UUE
    '\u1A68',
    //  ᩫ 	 TAI THAM VOWEL SIGN O
    '\u1A6B',
    //  ᩳ 	 TAI THAM VOWEL SIGN OA ABOVE
    '\u1A73',
    //  ᩴ 	 TAI THAM SIGN MAI KANG
    '\u1A74',

    // ========
    //  Tai Tham — Tone marks items: 5
    // ========

    //  ᩵ 	 TAI THAM SIGN TONE-1
    '\u1A75',
    //  ᩶ 	 TAI THAM SIGN TONE-2
    '\u1A76',
    //  ᩷ 	 TAI THAM SIGN KHUEN TONE-3
    '\u1A77',
    //  ᩸ 	 TAI THAM SIGN KHUEN TONE-4
    '\u1A78',
    //  ᩹ 	 TAI THAM SIGN KHUEN TONE-5
    '\u1A79',

    // ========
    //  Tai Tham — Sign items: 3
    // ========

    //  ᩺ 	 TAI THAM SIGN RA HAAM
    '\u1A7A',
    //  ᩻ 	 TAI THAM SIGN MAI SAM
    '\u1A7B',
    //  ᩼ 	 TAI THAM SIGN KHUEN-LUE KARAN
    '\u1A7C',

    // ========
    //  Balinese — Various signs items: 4
    // ========

    //  ᬀ 	 BALINESE SIGN ULU RICEM
    '\u1B00',
    //  ᬁ 	 BALINESE SIGN ULU CANDRA
    '\u1B01',
    //  ᬂ 	 BALINESE SIGN CECEK
    '\u1B02',
    //  ᬃ 	 BALINESE SIGN SURANG
    '\u1B03',

    // ========
    //  Balinese — Sign items: 1
    // ========

    //  ᬴ 	 BALINESE SIGN REREKAN
    '\u1B34',

    // ========
    //  Balinese — Dependent vowel sign items: 3
    // ========

    //  ᬶ 	 BALINESE VOWEL SIGN ULU
    '\u1B36',
    //  ᬷ 	 BALINESE VOWEL SIGN ULU SARI
    '\u1B37',
    //  ᭂ 	 BALINESE VOWEL SIGN PEPET
    '\u1B42',

    // ========
    //  Balinese — Diacritical marks for musical symbols items: 8
    // ========

    //  ᭫ 	 BALINESE MUSICAL SYMBOL COMBINING TEGEH
    '\u1B6B',
    //  ᭭ 	 BALINESE MUSICAL SYMBOL COMBINING KEMPUL
    '\u1B6D',
    //  ᭮ 	 BALINESE MUSICAL SYMBOL COMBINING KEMPLI
    '\u1B6E',
    //  ᭯ 	 BALINESE MUSICAL SYMBOL COMBINING JEGOGAN
    '\u1B6F',
    //  ᭰ 	 BALINESE MUSICAL SYMBOL COMBINING KEMPUL WITH JEGOGAN
    '\u1B70',
    //  ᭱ 	 BALINESE MUSICAL SYMBOL COMBINING KEMPLI WITH JEGOGAN
    '\u1B71',
    //  ᭲ 	 BALINESE MUSICAL SYMBOL COMBINING BENDE
    '\u1B72',
    //  ᭳ 	 BALINESE MUSICAL SYMBOL COMBINING GONG
    '\u1B73',

    // ========
    //  Sundanese — Various signs items: 2
    // ========

    //  ᮀ 	 SUNDANESE SIGN PANYECEK
    '\u1B80',
    //  ᮁ 	 SUNDANESE SIGN PANGLAYAR
    '\u1B81',

    // ========
    //  Sundanese — Vowel sign items: 3
    // ========

    //  ᮤ 	 SUNDANESE VOWEL SIGN PANGHULU
    '\u1BA4',
    //  ᮨ 	 SUNDANESE VOWEL SIGN PAMEPET
    '\u1BA8',
    //  ᮩ 	 SUNDANESE VOWEL SIGN PANEULEUNG
    '\u1BA9',

    // ========
    //  Batak — Sign items: 1
    // ========

    //  ᯦ 	 BATAK SIGN TOMPI
    '\u1BE6',

    // ========
    //  Batak — Dependent vowel sign items: 4
    // ========

    //  ᯨ 	 BATAK VOWEL SIGN PAKPAK E
    '\u1BE8',
    //  ᯩ 	 BATAK VOWEL SIGN EE
    '\u1BE9',
    //  ᯭ 	 BATAK VOWEL SIGN KARO O
    '\u1BED',
    //  ᯯ 	 BATAK VOWEL SIGN U FOR SIMALUNGUN SA
    '\u1BEF',

    // ========
    //  Batak — Dependent consonant signs items: 2
    // ========

    //  ᯰ 	 BATAK CONSONANT SIGN NG
    '\u1BF0',
    //  ᯱ 	 BATAK CONSONANT SIGN H
    '\u1BF1',

    // ========
    //  Lepcha — Consonant signs items: 7
    // ========

    //  ᰭ 	 LEPCHA CONSONANT SIGN K
    '\u1C2D',
    //  ᰮ 	 LEPCHA CONSONANT SIGN M
    '\u1C2E',
    //  ᰯ 	 LEPCHA CONSONANT SIGN L
    '\u1C2F',
    //  ᰰ 	 LEPCHA CONSONANT SIGN N
    '\u1C30',
    //  ᰱ 	 LEPCHA CONSONANT SIGN P
    '\u1C31',
    //  ᰲ 	 LEPCHA CONSONANT SIGN R
    '\u1C32',
    //  ᰳ 	 LEPCHA CONSONANT SIGN T
    '\u1C33',

    // ========
    //  Lepcha — Various signs items: 1
    // ========

    //  ᰶ 	 LEPCHA SIGN RAN
    '\u1C36',

    // ========
    //  Vedic Extensions — Tone marks for the Samaveda items: 3
    // ========

    //  ᳐ 	 VEDIC TONE KARSHANA
    '\u1CD0',
    //  ᳑ 	 VEDIC TONE SHARA
    '\u1CD1',
    //  ᳒ 	 VEDIC TONE PRENKHA
    '\u1CD2',

    // ========
    //  Vedic Extensions — Sign for Yajurvedic items: 3
    // ========

    //  ᳚ 	 VEDIC TONE DOUBLE SVARITA
    '\u1CDA',
    //  ᳛ 	 VEDIC TONE TRIPLE SVARITA
    '\u1CDB',
    //  ᳴ 	 VEDIC TONE CANDRA ABOVE
    '\u1CF4',

    // ========
    //  Vedic Extensions — Tone mark for the Rigveda items: 1
    // ========

    //  ᳠ 	 VEDIC TONE RIGVEDIC KASHMIRI INDEPENDENT SVARITA
    '\u1CE0',

    // ========
    //  Combining Diacritical Marks Supplement — Miscellaneous mark items: 1
    // ========

    //  ᷻ 	 COMBINING DELETION MARK
    '\u1DFB',

    // ========
    //  Combining Diacritical Marks For Symbols — Additional diacritical mark for symbols items: 1
    // ========

    //  ⃰ 	 COMBINING ASTERISK ABOVE
    '\u20F0',

    // ========
    //  Syloti Nagri — Independent vowels and dvisvara items: 1
    // ========

    //  ꠂ 	 SYLOTI NAGRI SIGN DVISVARA
    '\uA802',

    // ========
    //  Syloti Nagri — Sign items: 1
    // ========

    //  ꠆ 	 SYLOTI NAGRI SIGN HASANTA
    '\uA806',

    // ========
    //  Syloti Nagri — Consonants and consonant signs items: 1
    // ========

    //  ꠋ 	 SYLOTI NAGRI SIGN ANUSVARA
    '\uA80B',

    // ========
    //  Syloti Nagri — Dependent vowel sign items: 1
    // ========

    //  ꠦ 	 SYLOTI NAGRI VOWEL SIGN E
    '\uA826',

    // ========
    //  Saurashtra — Sign items: 1
    // ========

    //  ꣅ 	 SAURASHTRA SIGN CANDRABINDU
    '\uA8C5',

    // ========
    //  Devanagari Extended — Cantillation marks (svara) for the Samaveda items: 18
    // ========

    //  ꣠ 	 COMBINING DEVANAGARI DIGIT ZERO
    '\uA8E0',
    //  ꣡ 	 COMBINING DEVANAGARI DIGIT ONE
    '\uA8E1',
    //  ꣢ 	 COMBINING DEVANAGARI DIGIT TWO
    '\uA8E2',
    //  ꣣ 	 COMBINING DEVANAGARI DIGIT THREE
    '\uA8E3',
    //  ꣤ 	 COMBINING DEVANAGARI DIGIT FOUR
    '\uA8E4',
    //  ꣥ 	 COMBINING DEVANAGARI DIGIT FIVE
    '\uA8E5',
    //  ꣦ 	 COMBINING DEVANAGARI DIGIT SIX
    '\uA8E6',
    //  ꣧ 	 COMBINING DEVANAGARI DIGIT SEVEN
    '\uA8E7',
    //  ꣨ 	 COMBINING DEVANAGARI DIGIT EIGHT
    '\uA8E8',
    //  ꣩ 	 COMBINING DEVANAGARI DIGIT NINE
    '\uA8E9',
    //  ꣪ 	 COMBINING DEVANAGARI LETTER A
    '\uA8EA',
    //  ꣫ 	 COMBINING DEVANAGARI LETTER U
    '\uA8EB',
    //  ꣬ 	 COMBINING DEVANAGARI LETTER KA
    '\uA8EC',
    //  ꣭ 	 COMBINING DEVANAGARI LETTER NA
    '\uA8ED',
    //  ꣮ 	 COMBINING DEVANAGARI LETTER PA
    '\uA8EE',
    //  ꣯ 	 COMBINING DEVANAGARI LETTER RA
    '\uA8EF',
    //  ꣰ 	 COMBINING DEVANAGARI LETTER VI
    '\uA8F0',
    //  ꣱ 	 COMBINING DEVANAGARI SIGN AVAGRAHA
    '\uA8F1',

    // ========
    //  Devanagari Extended — Additional vowel and vowel sign items: 1
    // ========

    //  ꣿ 	 DEVANAGARI VOWEL SIGN AY
    '\uA8FF',

    // ========
    //  Rejang — Vowel sign items: 1
    // ========

    //  ꥊ 	 REJANG VOWEL SIGN AI
    '\uA94A',

    // ========
    //  Rejang — Consonant signs items: 3
    // ========

    //  ꥏ 	 REJANG CONSONANT SIGN NG
    '\uA94F',
    //  ꥐ 	 REJANG CONSONANT SIGN N
    '\uA950',
    //  ꥑ 	 REJANG CONSONANT SIGN R
    '\uA951',

    // ========
    //  Javanese — Various signs items: 3
    // ========

    //  ꦀ 	 JAVANESE SIGN PANYANGGA
    '\uA980',
    //  ꦁ 	 JAVANESE SIGN CECAK
    '\uA981',
    //  ꦂ 	 JAVANESE SIGN LAYAR
    '\uA982',

    // ========
    //  Javanese — Sign items: 1
    // ========

    //  ꦳ 	 JAVANESE SIGN CECAK TELU
    '\uA9B3',

    // ========
    //  Javanese — Dependent vowel sign items: 3
    // ========

    //  ꦶ 	 JAVANESE VOWEL SIGN WULU
    '\uA9B6',
    //  ꦷ 	 JAVANESE VOWEL SIGN WULU MELIK
    '\uA9B7',
    //  ꦼ 	 JAVANESE VOWEL SIGN PEPET
    '\uA9BC',

    // ========
    //  Myanmar Extended B — Additions for Shan Pali items: 1
    // ========

    //  ꧥ 	 MYANMAR SIGN SHAN SAW
    '\uA9E5',

    // ========
    //  Cham — Dependent vowel sign items: 6
    // ========

    //  ꨩ 	 CHAM VOWEL SIGN AA
    '\uAA29',
    //  ꨪ 	 CHAM VOWEL SIGN I
    '\uAA2A',
    //  ꨫ 	 CHAM VOWEL SIGN II
    '\uAA2B',
    //  ꨬ 	 CHAM VOWEL SIGN EI
    '\uAA2C',
    //  ꨮ 	 CHAM VOWEL SIGN OE
    '\uAA2E',
    //  ꨱ 	 CHAM VOWEL SIGN AU
    '\uAA31',

    // ========
    //  Cham — Final consonants items: 2
    // ========

    //  ꩃ 	 CHAM CONSONANT SIGN FINAL NG
    '\uAA43',
    //  ꩌ 	 CHAM CONSONANT SIGN FINAL M
    '\uAA4C',

    // ========
    //  Myanmar Extended A — Tai Laing tone marks items: 1
    // ========

    //  ꩼ 	 MYANMAR SIGN TAI LAING TONE-2
    '\uAA7C',

    // ========
    //  Tai Viet — Vowels and finals items: 6
    // ========

    //  ꪰ 	 TAI VIET MAI KANG
    '\uAAB0',
    //  ꪲ 	 TAI VIET VOWEL I
    '\uAAB2',
    //  ꪳ 	 TAI VIET VOWEL UE
    '\uAAB3',
    //  ꪷ 	 TAI VIET MAI KHIT
    '\uAAB7',
    //  ꪸ 	 TAI VIET VOWEL IA
    '\uAAB8',
    //  ꪾ 	 TAI VIET VOWEL AM
    '\uAABE',

    // ========
    //  Tai Viet — Tones items: 2
    // ========

    //  ꪿ 	 TAI VIET TONE MAI EK
    '\uAABF',
    //  ꫁ 	 TAI VIET TONE MAI THO
    '\uAAC1',

    // ========
    //  Meetei Mayek Extensions — Dependent vowel sign items: 1
    // ========

    //  ꫭ 	 MEETEI MAYEK VOWEL SIGN AAI
    '\uAAED',

    // ========
    //  Meetei Mayek — Dependent vowel sign items: 1
    // ========

    //  ꯥ 	 MEETEI MAYEK VOWEL SIGN ANAP
    '\uABE5',

    // ========
    //  Kharoshthi — Vowel items: 1
    // ========

    //  𐨅 	 KHAROSHTHI VOWEL SIGN E
    '\u10A0',

    // ========
    //  Kharoshthi — Various signs items: 2
    // ========

    //  𐨏 	 KHAROSHTHI SIGN VISARGA
    '\u10A0',
    //  𐨸 	 KHAROSHTHI SIGN BAR ABOVE
    '\u10A3',

    // ========
    //  Brahmi — Various signs items: 1
    // ========

    //  𑀁 	 BRAHMI SIGN ANUSVARA
    '\u1100',

    // ========
    //  Brahmi — Dependent vowel sign items: 8
    // ========

    //  𑀸 	 BRAHMI VOWEL SIGN AA
    '\u1103',
    //  𑀹 	 BRAHMI VOWEL SIGN BHATTIPROLU AA
    '\u1103',
    //  𑀺 	 BRAHMI VOWEL SIGN I
    '\u1103',
    //  𑀻 	 BRAHMI VOWEL SIGN II
    '\u1103',
    //  𑁂 	 BRAHMI VOWEL SIGN E
    '\u1104',
    //  𑁃 	 BRAHMI VOWEL SIGN AI
    '\u1104',
    //  𑁄 	 BRAHMI VOWEL SIGN O
    '\u1104',
    //  𑁅 	 BRAHMI VOWEL SIGN AU
    '\u1104',

    // ========
    //  Brahmi — Virama items: 1
    // ========

    //  𑁆 	 BRAHMI VIRAMA
    '\u1104',

    // ========
    //  Kaithi — Various signs items: 2
    // ========

    //  𑂀 	 KAITHI SIGN CANDRABINDU
    '\u1108',
    //  𑂁 	 KAITHI SIGN ANUSVARA
    '\u1108',

    // ========
    //  Kaithi — Dependent vowel sign items: 2
    // ========

    //  𑂵 	 KAITHI VOWEL SIGN E
    '\u110B',
    //  𑂶 	 KAITHI VOWEL SIGN AI
    '\u110B',

    // ========
    //  Chakma — Various signs items: 4
    // ========

    //  𑄀 	 CHAKMA SIGN CANDRABINDU
    '\u1110',
    //  𑄁 	 CHAKMA SIGN ANUSVARA
    '\u1110',
    //  𑄂 	 CHAKMA SIGN VISARGA
    '\u1110',
    //  𑄴 	 CHAKMA MAAYYAA
    '\u1113',

    // ========
    //  Chakma — Dependent vowel sign items: 5
    // ========

    //  𑄧 	 CHAKMA VOWEL SIGN A
    '\u1112',
    //  𑄨 	 CHAKMA VOWEL SIGN I
    '\u1112',
    //  𑄩 	 CHAKMA VOWEL SIGN II
    '\u1112',
    //  𑄭 	 CHAKMA VOWEL SIGN AI
    '\u1112',
    //  𑄰 	 CHAKMA VOWEL SIGN OI
    '\u1113',

    // ========
    //  Sharada — Various signs items: 4
    // ========

    //  𑆀 	 SHARADA SIGN CANDRABINDU
    '\u1118',
    //  𑆁 	 SHARADA SIGN ANUSVARA
    '\u1118',
    //  𑇂 	 SHARADA SIGN JIHVAMULIYA
    '\u111C',
    //  𑇃 	 SHARADA SIGN UPADHMANIYA
    '\u111C',

    // ========
    //  Sharada — Dependent vowel sign items: 3
    // ========

    //  𑆼 	 SHARADA VOWEL SIGN E
    '\u111B',
    //  𑆽 	 SHARADA VOWEL SIGN AI
    '\u111B',
    //  𑆾 	 SHARADA VOWEL SIGN O
    '\u111B',

    // ========
    //  Sharada — Signs for Kashmiri items: 1
    // ========

    //  𑇋 	 SHARADA VOWEL MODIFIER MARK
    '\u111C',

    // ========
    //  Sharada — Sign items: 1
    // ========

    //  𑇏 	 SHARADA SIGN INVERTED CANDRABINDU
    '\u111C',

    // ========
    //  Khojki — Dependent vowel sign items: 2
    // ========

    //  𑈰 	 KHOJKI VOWEL SIGN E
    '\u1123',
    //  𑈱 	 KHOJKI VOWEL SIGN AI
    '\u1123',

    // ========
    //  Khojki — Various signs items: 3
    // ========

    //  𑈴 	 KHOJKI SIGN ANUSVARA
    '\u1123',
    //  𑈶 	 KHOJKI SIGN NUKTA
    '\u1123',
    //  𑈷 	 KHOJKI SIGN SHADDA
    '\u1123',

    // ========
    //  Khojki — Sign items: 1
    // ========

    //  𑈾 	 KHOJKI SIGN SUKUN
    '\u1123',

    // ========
    //  Khudawadi — Sign items: 1
    // ========

    //  𑋟 	 KHUDAWADI SIGN ANUSVARA
    '\u112D',

    // ========
    //  Khudawadi — Dependent vowel sign items: 4
    // ========

    //  𑋥 	 KHUDAWADI VOWEL SIGN E
    '\u112E',
    //  𑋦 	 KHUDAWADI VOWEL SIGN AI
    '\u112E',
    //  𑋧 	 KHUDAWADI VOWEL SIGN O
    '\u112E',
    //  𑋨 	 KHUDAWADI VOWEL SIGN AU
    '\u112E',

    // ========
    //  Grantha — Various signs items: 2
    // ========

    //  𑌀 	 GRANTHA SIGN COMBINING ANUSVARA ABOVE
    '\u1130',
    //  𑌁 	 GRANTHA SIGN CANDRABINDU
    '\u1130',

    // ========
    //  Grantha — Dependent vowel sign items: 1
    // ========

    //  𑍀 	 GRANTHA VOWEL SIGN II
    '\u1134',

    // ========
    //  Grantha — Cantillation marks (svara) for the Samaveda items: 12
    // ========

    //  𑍦 	 COMBINING GRANTHA DIGIT ZERO
    '\u1136',
    //  𑍧 	 COMBINING GRANTHA DIGIT ONE
    '\u1136',
    //  𑍨 	 COMBINING GRANTHA DIGIT TWO
    '\u1136',
    //  𑍩 	 COMBINING GRANTHA DIGIT THREE
    '\u1136',
    //  𑍪 	 COMBINING GRANTHA DIGIT FOUR
    '\u1136',
    //  𑍫 	 COMBINING GRANTHA DIGIT FIVE
    '\u1136',
    //  𑍬 	 COMBINING GRANTHA DIGIT SIX
    '\u1136',
    //  𑍰 	 COMBINING GRANTHA LETTER A
    '\u1137',
    //  𑍱 	 COMBINING GRANTHA LETTER KA
    '\u1137',
    //  𑍲 	 COMBINING GRANTHA LETTER NA
    '\u1137',
    //  𑍳 	 COMBINING GRANTHA LETTER VI
    '\u1137',
    //  𑍴 	 COMBINING GRANTHA LETTER PA
    '\u1137',

    // ========
    //  Newa — Dependent vowel sign items: 2
    // ========

    //  𑐾 	 NEWA VOWEL SIGN E
    '\u1143',
    //  𑐿 	 NEWA VOWEL SIGN AI
    '\u1143',

    // ========
    //  Newa — Various signs items: 3
    // ========

    //  𑑃 	 NEWA SIGN CANDRABINDU
    '\u1144',
    //  𑑄 	 NEWA SIGN ANUSVARA
    '\u1144',
    //  𑑞 	 NEWA SANDHI MARK
    '\u1145',

    // ========
    //  Tirhuta — Dependent vowel sign items: 1
    // ========

    //  𑒺 	 TIRHUTA VOWEL SIGN SHORT E
    '\u114B',

    // ========
    //  Tirhuta — Various signs items: 2
    // ========

    //  𑒿 	 TIRHUTA SIGN CANDRABINDU
    '\u114B',
    //  𑓀 	 TIRHUTA SIGN ANUSVARA
    '\u114C',

    // ========
    //  Siddham — Various signs items: 2
    // ========

    //  𑖼 	 SIDDHAM SIGN CANDRABINDU
    '\u115B',
    //  𑖽 	 SIDDHAM SIGN ANUSVARA
    '\u115B',

    // ========
    //  Modi — Dependent vowel sign items: 2
    // ========

    //  𑘹 	 MODI VOWEL SIGN E
    '\u1163',
    //  𑘺 	 MODI VOWEL SIGN AI
    '\u1163',

    // ========
    //  Modi — Various signs items: 2
    // ========

    //  𑘽 	 MODI SIGN ANUSVARA
    '\u1163',
    //  𑙀 	 MODI SIGN ARDHACANDRA
    '\u1164',

    // ========
    //  Takri — Various signs items: 1
    // ========

    //  𑚫 	 TAKRI SIGN ANUSVARA
    '\u116A',

    // ========
    //  Takri — Dependent vowel sign items: 5
    // ========

    //  𑚭 	 TAKRI VOWEL SIGN AA
    '\u116A',
    //  𑚲 	 TAKRI VOWEL SIGN E
    '\u116B',
    //  𑚳 	 TAKRI VOWEL SIGN AI
    '\u116B',
    //  𑚴 	 TAKRI VOWEL SIGN O
    '\u116B',
    //  𑚵 	 TAKRI VOWEL SIGN AU
    '\u116B',

    // ========
    //  Ahom — Medials items: 1
    // ========

    //  𑜟 	 AHOM CONSONANT SIGN MEDIAL LIGATING RA
    '\u1171',

    // ========
    //  Ahom — Vowel sign items: 6
    // ========

    //  𑜢 	 AHOM VOWEL SIGN I
    '\u1172',
    //  𑜣 	 AHOM VOWEL SIGN II
    '\u1172',
    //  𑜧 	 AHOM VOWEL SIGN AW
    '\u1172',
    //  𑜩 	 AHOM VOWEL SIGN AI
    '\u1172',
    //  𑜪 	 AHOM VOWEL SIGN AM
    '\u1172',
    //  𑜫 	 AHOM SIGN KILLER
    '\u1172',

    // ========
    //  Dogra — Dependent vowel sign items: 4
    // ========

    //  𑠳 	 DOGRA VOWEL SIGN E
    '\u1183',
    //  𑠴 	 DOGRA VOWEL SIGN AI
    '\u1183',
    //  𑠵 	 DOGRA VOWEL SIGN O
    '\u1183',
    //  𑠶 	 DOGRA VOWEL SIGN AU
    '\u1183',

    // ========
    //  Dogra — Various signs items: 1
    // ========

    //  𑠷 	 DOGRA SIGN ANUSVARA
    '\u1183',

    // ========
    //  Dives Akuru — Nasalization mark items: 2
    // ========

    //  𑤻 	 DIVES AKURU SIGN ANUSVARA
    '\u1193',
    //  𑤼 	 DIVES AKURU SIGN CANDRABINDU
    '\u1193',

    // ========
    //  Dives Akuru — Sign items: 1
    // ========

    //  𑤿 	 DIVES AKURU PREFIXED NASAL SIGN
    '\u1193',

    // ========
    //  Dives Akuru — Conjunct-specific letters items: 1
    // ========

    //  𑥁 	 DIVES AKURU INITIAL RA
    '\u1194',

    // ========
    //  Nandinagari — Dependent vowel sign items: 2
    // ========

    //  𑧚 	 NANDINAGARI VOWEL SIGN E
    '\u119D',
    //  𑧛 	 NANDINAGARI VOWEL SIGN AI
    '\u119D',

    // ========
    //  Zanabazar Square — Vowel sign items: 7
    // ========

    //  𑨁 	 ZANABAZAR SQUARE VOWEL SIGN I
    '\u11A0',
    //  𑨄 	 ZANABAZAR SQUARE VOWEL SIGN E
    '\u11A0',
    //  𑨅 	 ZANABAZAR SQUARE VOWEL SIGN OE
    '\u11A0',
    //  𑨆 	 ZANABAZAR SQUARE VOWEL SIGN O
    '\u11A0',
    //  𑨇 	 ZANABAZAR SQUARE VOWEL SIGN AI
    '\u11A0',
    //  𑨈 	 ZANABAZAR SQUARE VOWEL SIGN AU
    '\u11A0',
    //  𑨉 	 ZANABAZAR SQUARE VOWEL SIGN REVERSED I
    '\u11A0',

    // ========
    //  Zanabazar Square — Candrabindu and candra ornaments items: 3
    // ========

    //  𑨵 	 ZANABAZAR SQUARE SIGN CANDRABINDU
    '\u11A3',
    //  𑨶 	 ZANABAZAR SQUARE SIGN CANDRABINDU WITH ORNAMENT
    '\u11A3',
    //  𑨷 	 ZANABAZAR SQUARE SIGN CANDRA WITH ORNAMENT
    '\u11A3',

    // ========
    //  Zanabazar Square — Signs for Sanskrit items: 1
    // ========

    //  𑨸 	 ZANABAZAR SQUARE SIGN ANUSVARA
    '\u11A3',

    // ========
    //  Zanabazar Square — Cluster-initial consonant items: 1
    // ========

    //  𑨺 	 ZANABAZAR SQUARE CLUSTER-INITIAL LETTER RA
    '\u11A3',

    // ========
    //  Soyombo — Vowel sign items: 4
    // ========

    //  𑩑 	 SOYOMBO VOWEL SIGN I
    '\u11A5',
    //  𑩔 	 SOYOMBO VOWEL SIGN E
    '\u11A5',
    //  𑩕 	 SOYOMBO VOWEL SIGN O
    '\u11A5',
    //  𑩖 	 SOYOMBO VOWEL SIGN OE
    '\u11A5',

    // ========
    //  Soyombo — Alternate visarga signs items: 2
    // ========

    //  𑪄 	 SOYOMBO SIGN JIHVAMULIYA
    '\u11A8',
    //  𑪅 	 SOYOMBO SIGN UPADHMANIYA
    '\u11A8',

    // ========
    //  Soyombo — Cluster-initial letters items: 4
    // ========

    //  𑪆 	 SOYOMBO CLUSTER-INITIAL LETTER RA
    '\u11A8',
    //  𑪇 	 SOYOMBO CLUSTER-INITIAL LETTER LA
    '\u11A8',
    //  𑪈 	 SOYOMBO CLUSTER-INITIAL LETTER SHA
    '\u11A8',
    //  𑪉 	 SOYOMBO CLUSTER-INITIAL LETTER SA
    '\u11A8',

    // ========
    //  Soyombo — Various signs items: 1
    // ========

    //  𑪖 	 SOYOMBO SIGN ANUSVARA
    '\u11A9',

    // ========
    //  Soyombo — Gemination mark items: 1
    // ========

    //  𑪘 	 SOYOMBO GEMINATION MARK
    '\u11A9',

    // ========
    //  Bhaiksuki — Dependent vowel sign items: 6
    // ========

    //  𑰰 	 BHAIKSUKI VOWEL SIGN I
    '\u11C3',
    //  𑰱 	 BHAIKSUKI VOWEL SIGN II
    '\u11C3',
    //  𑰸 	 BHAIKSUKI VOWEL SIGN E
    '\u11C3',
    //  𑰹 	 BHAIKSUKI VOWEL SIGN AI
    '\u11C3',
    //  𑰺 	 BHAIKSUKI VOWEL SIGN O
    '\u11C3',
    //  𑰻 	 BHAIKSUKI VOWEL SIGN AU
    '\u11C3',

    // ========
    //  Bhaiksuki — Various signs items: 2
    // ========

    //  𑰼 	 BHAIKSUKI SIGN CANDRABINDU
    '\u11C3',
    //  𑰽 	 BHAIKSUKI SIGN ANUSVARA
    '\u11C3',

    // ========
    //  Marchen — Dependent vowel sign items: 1
    // ========

    //  𑲳 	 MARCHEN VOWEL SIGN E
    '\u11CB',

    // ========
    //  Marchen — Various signs items: 2
    // ========

    //  𑲵 	 MARCHEN SIGN ANUSVARA
    '\u11CB',
    //  𑲶 	 MARCHEN SIGN CANDRABINDU
    '\u11CB',

    // ========
    //  Masaram Gondi — Dependent vowel sign items: 9
    // ========

    //  𑴱 	 MASARAM GONDI VOWEL SIGN AA
    '\u11D3',
    //  𑴲 	 MASARAM GONDI VOWEL SIGN I
    '\u11D3',
    //  𑴳 	 MASARAM GONDI VOWEL SIGN II
    '\u11D3',
    //  𑴴 	 MASARAM GONDI VOWEL SIGN U
    '\u11D3',
    //  𑴵 	 MASARAM GONDI VOWEL SIGN UU
    '\u11D3',
    //  𑴺 	 MASARAM GONDI VOWEL SIGN E
    '\u11D3',
    //  𑴼 	 MASARAM GONDI VOWEL SIGN AI
    '\u11D3',
    //  𑴽 	 MASARAM GONDI VOWEL SIGN O
    '\u11D3',
    //  𑴿 	 MASARAM GONDI VOWEL SIGN AU
    '\u11D3',

    // ========
    //  Masaram Gondi — Various signs items: 3
    // ========

    //  𑵀 	 MASARAM GONDI SIGN ANUSVARA
    '\u11D4',
    //  𑵁 	 MASARAM GONDI SIGN VISARGA
    '\u11D4',
    //  𑵃 	 MASARAM GONDI SIGN CANDRA
    '\u11D4',

    // ========
    //  Gunjala Gondi — Dependent vowel sign items: 2
    // ========

    //  𑶐 	 GUNJALA GONDI VOWEL SIGN EE
    '\u11D9',
    //  𑶑 	 GUNJALA GONDI VOWEL SIGN AI
    '\u11D9',

    // ========
    //  Gunjala Gondi — Various signs items: 1
    // ========

    //  𑶕 	 GUNJALA GONDI SIGN ANUSVARA
    '\u11D9',

    // ========
    //  Makasar — Vowel sign items: 1
    // ========

    //  𑻳 	 MAKASAR VOWEL SIGN I
    '\u11EF',
  ],
  bottom: [
    // ========
    //     Devanagari — Various signs items: 1
    // ========

    // ़  DEVANAGARI SIGN NUKTA
    '\u093C',

    // ========
    // Devanagari — Dependent vowel sign items: 4
    // ========

    // ु  DEVANAGARI VOWEL SIGN U
    '\u0941',
    // ू  DEVANAGARI VOWEL SIGN UU
    '\u0942',
    // ृ  DEVANAGARI VOWEL SIGN VOCALIC R
    '\u0943',
    // ॄ  DEVANAGARI VOWEL SIGN VOCALIC RR
    '\u0944',

    // ========
    // Devanagari — Virama items: 1
    // ========

    // ्  DEVANAGARI SIGN VIRAMA
    '\u094D',

    // ========
    // Devanagari — Vedic tone marks items: 1
    // ========

    // ॒  DEVANAGARI STRESS SIGN ANUDATTA
    '\u0952',

    // ========
    // Devanagari — Dependent vowel signs for Kashmiri items: 2
    // ========

    // ॖ  DEVANAGARI VOWEL SIGN UE
    '\u0956',
    // ॗ  DEVANAGARI VOWEL SIGN UUE
    '\u0957',

    // ========
    // Devanagari — Additional vowels for Sanskrit items: 2
    // ========

    // ॢ  DEVANAGARI VOWEL SIGN VOCALIC L
    '\u0962',
    // ॣ  DEVANAGARI VOWEL SIGN VOCALIC LL
    '\u0963',

    // ========
    // Bengali — Various signs items: 1
    // ========

    // ়  BENGALI SIGN NUKTA
    '\u09BC',

    // ========
    // Bengali — Dependent vowel sign items: 4
    // ========

    // ু  BENGALI VOWEL SIGN U
    '\u09C1',
    // ূ  BENGALI VOWEL SIGN UU
    '\u09C2',
    // ৃ  BENGALI VOWEL SIGN VOCALIC R
    '\u09C3',
    // ৄ  BENGALI VOWEL SIGN VOCALIC RR
    '\u09C4',

    // ========
    // Bengali — Virama items: 1
    // ========

    // ্  BENGALI SIGN VIRAMA
    '\u09CD',

    // ========
    // Bengali — Additional vowels for Sanskrit items: 2
    // ========

    // ৢ  BENGALI VOWEL SIGN VOCALIC L
    '\u09E2',
    // ৣ  BENGALI VOWEL SIGN VOCALIC LL
    '\u09E3',

    // ========
    // Gurmukhi — Various signs items: 1
    // ========

    // ਼  GURMUKHI SIGN NUKTA
    '\u0A3C',

    // ========
    // Gurmukhi — Dependent vowel sign items: 2
    // ========

    // ੁ  GURMUKHI VOWEL SIGN U
    '\u0A41',
    // ੂ  GURMUKHI VOWEL SIGN UU
    '\u0A42',

    // ========
    // Gurmukhi — Virama items: 1
    // ========

    // ੍  GURMUKHI SIGN VIRAMA
    '\u0A4D',

    // ========
    // Gurmukhi — Sign items: 2
    // ========

    // ੑ  GURMUKHI SIGN UDAAT
    '\u0A51',
    // ੵ  GURMUKHI SIGN YAKASH
    '\u0A75',

    // ========
    // Gujarati — Various signs items: 1
    // ========

    // ઼  GUJARATI SIGN NUKTA
    '\u0ABC',

    // ========
    // Gujarati — Dependent vowel sign items: 4
    // ========

    // ુ  GUJARATI VOWEL SIGN U
    '\u0AC1',
    // ૂ  GUJARATI VOWEL SIGN UU
    '\u0AC2',
    // ૃ  GUJARATI VOWEL SIGN VOCALIC R
    '\u0AC3',
    // ૄ  GUJARATI VOWEL SIGN VOCALIC RR
    '\u0AC4',

    // ========
    // Gujarati — Virama items: 1
    // ========

    // ્  GUJARATI SIGN VIRAMA
    '\u0ACD',

    // ========
    // Gujarati — Additional vowels for Sanskrit items: 2
    // ========

    // ૢ  GUJARATI VOWEL SIGN VOCALIC L
    '\u0AE2',
    // ૣ  GUJARATI VOWEL SIGN VOCALIC LL
    '\u0AE3',

    // ========
    // Oriya — Various signs items: 1
    // ========

    // ଼  ORIYA SIGN NUKTA
    '\u0B3C',

    // ========
    // Oriya — Dependent vowel sign items: 4
    // ========

    // ୁ  ORIYA VOWEL SIGN U
    '\u0B41',
    // ୂ  ORIYA VOWEL SIGN UU
    '\u0B42',
    // ୃ  ORIYA VOWEL SIGN VOCALIC R
    '\u0B43',
    // ୄ  ORIYA VOWEL SIGN VOCALIC RR
    '\u0B44',

    // ========
    // Oriya — Virama items: 1
    // ========

    // ୍  ORIYA SIGN VIRAMA
    '\u0B4D',

    // ========
    // Oriya — Dependent vowels items: 2
    // ========

    // ୢ  ORIYA VOWEL SIGN VOCALIC L
    '\u0B62',
    // ୣ  ORIYA VOWEL SIGN VOCALIC LL
    '\u0B63',

    // ========
    // Telugu — Various signs items: 1
    // ========

    // ౖ  TELUGU AI LENGTH MARK
    '\u0C56',

    // ========
    // Telugu — Dependent vowels items: 2
    // ========

    // ౢ  TELUGU VOWEL SIGN VOCALIC L
    '\u0C62',
    // ౣ  TELUGU VOWEL SIGN VOCALIC LL
    '\u0C63',

    // ========
    // Kannada — Various signs items: 1
    // ========

    // ಼  KANNADA SIGN NUKTA
    '\u0CBC',

    // ========
    // Kannada — Dependent vowels items: 2
    // ========

    // ೢ  KANNADA VOWEL SIGN VOCALIC L
    '\u0CE2',
    // ೣ  KANNADA VOWEL SIGN VOCALIC LL
    '\u0CE3',

    // ========
    // Malayalam — Dependent vowel sign items: 2
    // ========

    // ൃ  MALAYALAM VOWEL SIGN VOCALIC R
    '\u0D43',
    // ൄ  MALAYALAM VOWEL SIGN VOCALIC RR
    '\u0D44',

    // ========
    // Malayalam — Dependent vowels items: 2
    // ========

    // ൢ  MALAYALAM VOWEL SIGN VOCALIC L
    '\u0D62',
    // ൣ  MALAYALAM VOWEL SIGN VOCALIC LL
    '\u0D63',

    // ========
    // Sinhala — Dependent vowel sign items: 2
    // ========

    // ු  SINHALA VOWEL SIGN KETTI PAA-PILLA
    '\u0DD4',
    // ූ  SINHALA VOWEL SIGN DIGA PAA-PILLA
    '\u0DD6',

    // ========
    // Thai — Vowel items: 3
    // ========

    // ุ  THAI CHARACTER SARA U
    '\u0E38',
    // ู  THAI CHARACTER SARA UU
    '\u0E39',
    // ฺ  THAI CHARACTER PHINTHU
    '\u0E3A',

    // ========
    // Lao — Vowel items: 2
    // ========

    // ຸ  LAO VOWEL SIGN U
    '\u0EB8',
    // ູ  LAO VOWEL SIGN UU
    '\u0EB9',

    // ========
    // Lao — Virama items: 1
    // ========

    // ຺  LAO SIGN PALI VIRAMA
    '\u0EBA',

    // ========
    // Lao — Sign items: 1
    // ========

    // ຼ  LAO SEMIVOWEL SIGN LO
    '\u0EBC',

    // ========
    // Tibetan — Astrological signs items: 2
    // ========

    // ༘  TIBETAN ASTROLOGICAL SIGN -KHYUD PA
    '\u0F18',
    // ༙  TIBETAN ASTROLOGICAL SIGN SDONG TSHUGS
    '\u0F19',

    // ========
    // Tibetan — Sign items: 4
    // ========

    // ༵  TIBETAN MARK NGAS BZUNG NYI ZLA
    '\u0F35',
    // ༷  TIBETAN MARK NGAS BZUNG SGOR RTAGS
    '\u0F37',
    // ྄  TIBETAN MARK HALANTA
    '\u0F84',
    // ࿆  TIBETAN SYMBOL PADMA GDAN
    '\u0FC6',

    // ========
    // Tibetan — Dependent vowel sign items: 3
    // ========

    // ཱ  TIBETAN VOWEL SIGN AA
    '\u0F71',
    // ུ  TIBETAN VOWEL SIGN U
    '\u0F74',
    // ཱུ  TIBETAN VOWEL SIGN UU
    '\u0F75',

    // ========
    // Tibetan — Transliteration subjoined signs items: 3
    // ========

    // ྍ  TIBETAN SUBJOINED SIGN LCE TSA CAN
    '\u0F8D',
    // ྎ  TIBETAN SUBJOINED SIGN MCHU CAN
    '\u0F8E',
    // ྏ  TIBETAN SUBJOINED SIGN INVERTED MCHU CAN
    '\u0F8F',

    // ========
    // Tibetan — Subjoined consonant items: 41
    // ========

    // ྐ  TIBETAN SUBJOINED LETTER KA
    '\u0F90',
    // ྑ  TIBETAN SUBJOINED LETTER KHA
    '\u0F91',
    // ྒ  TIBETAN SUBJOINED LETTER GA
    '\u0F92',
    // ྒྷ  TIBETAN SUBJOINED LETTER GHA
    '\u0F93',
    // ྔ  TIBETAN SUBJOINED LETTER NGA
    '\u0F94',
    // ྕ  TIBETAN SUBJOINED LETTER CA
    '\u0F95',
    // ྖ  TIBETAN SUBJOINED LETTER CHA
    '\u0F96',
    // ྗ  TIBETAN SUBJOINED LETTER JA
    '\u0F97',
    // ྙ  TIBETAN SUBJOINED LETTER NYA
    '\u0F99',
    // ྚ  TIBETAN SUBJOINED LETTER TTA
    '\u0F9A',
    // ྛ  TIBETAN SUBJOINED LETTER TTHA
    '\u0F9B',
    // ྜ  TIBETAN SUBJOINED LETTER DDA
    '\u0F9C',
    // ྜྷ  TIBETAN SUBJOINED LETTER DDHA
    '\u0F9D',
    // ྞ  TIBETAN SUBJOINED LETTER NNA
    '\u0F9E',
    // ྟ  TIBETAN SUBJOINED LETTER TA
    '\u0F9F',
    // ྠ  TIBETAN SUBJOINED LETTER THA
    '\u0FA0',
    // ྡ  TIBETAN SUBJOINED LETTER DA
    '\u0FA1',
    // ྡྷ  TIBETAN SUBJOINED LETTER DHA
    '\u0FA2',
    // ྣ  TIBETAN SUBJOINED LETTER NA
    '\u0FA3',
    // ྤ  TIBETAN SUBJOINED LETTER PA
    '\u0FA4',
    // ྥ  TIBETAN SUBJOINED LETTER PHA
    '\u0FA5',
    // ྦ  TIBETAN SUBJOINED LETTER BA
    '\u0FA6',
    // ྦྷ  TIBETAN SUBJOINED LETTER BHA
    '\u0FA7',
    // ྨ  TIBETAN SUBJOINED LETTER MA
    '\u0FA8',
    // ྩ  TIBETAN SUBJOINED LETTER TSA
    '\u0FA9',
    // ྪ  TIBETAN SUBJOINED LETTER TSHA
    '\u0FAA',
    // ྫ  TIBETAN SUBJOINED LETTER DZA
    '\u0FAB',
    // ྫྷ  TIBETAN SUBJOINED LETTER DZHA
    '\u0FAC',
    // ྭ  TIBETAN SUBJOINED LETTER WA
    '\u0FAD',
    // ྮ  TIBETAN SUBJOINED LETTER ZHA
    '\u0FAE',
    // ྯ  TIBETAN SUBJOINED LETTER ZA
    '\u0FAF',
    // ྰ  TIBETAN SUBJOINED LETTER -A
    '\u0FB0',
    // ྱ  TIBETAN SUBJOINED LETTER YA
    '\u0FB1',
    // ྲ  TIBETAN SUBJOINED LETTER RA
    '\u0FB2',
    // ླ  TIBETAN SUBJOINED LETTER LA
    '\u0FB3',
    // ྴ  TIBETAN SUBJOINED LETTER SHA
    '\u0FB4',
    // ྵ  TIBETAN SUBJOINED LETTER SSA
    '\u0FB5',
    // ྶ  TIBETAN SUBJOINED LETTER SA
    '\u0FB6',
    // ྷ  TIBETAN SUBJOINED LETTER HA
    '\u0FB7',
    // ྸ  TIBETAN SUBJOINED LETTER A
    '\u0FB8',
    // ྐྵ  TIBETAN SUBJOINED LETTER KSSA
    '\u0FB9',

    // ========
    // Tibetan — Fixed-form subjoined consonants items: 3
    // ========

    // ྺ  TIBETAN SUBJOINED LETTER FIXED-FORM WA
    '\u0FBA',
    // ྻ  TIBETAN SUBJOINED LETTER FIXED-FORM YA
    '\u0FBB',
    // ྼ  TIBETAN SUBJOINED LETTER FIXED-FORM RA
    '\u0FBC',

    // ========
    // Myanmar — Dependent vowel sign items: 2
    // ========

    // ု  MYANMAR VOWEL SIGN U
    '\u102F',
    // ူ  MYANMAR VOWEL SIGN UU
    '\u1030',

    // ========
    // Myanmar — Various signs items: 1
    // ========

    // ့  MYANMAR SIGN DOT BELOW
    '\u1037',

    // ========
    // Myanmar — Dependent consonant signs items: 2
    // ========

    // ွ  MYANMAR CONSONANT SIGN MEDIAL WA
    '\u103D',
    // ှ  MYANMAR CONSONANT SIGN MEDIAL HA
    '\u103E',

    // ========
    // Myanmar — Pali and Sanskrit extensions items: 2
    // ========

    // ၘ  MYANMAR VOWEL SIGN VOCALIC L
    '\u1058',
    // ၙ  MYANMAR VOWEL SIGN VOCALIC LL
    '\u1059',

    // ========
    // Myanmar — Extensions for Mon items: 3
    // ========

    // ၞ  MYANMAR CONSONANT SIGN MON MEDIAL NA
    '\u105E',
    // ၟ  MYANMAR CONSONANT SIGN MON MEDIAL MA
    '\u105F',
    // ၠ  MYANMAR CONSONANT SIGN MON MEDIAL LA
    '\u1060',

    // ========
    // Myanmar — Extensions for Shan items: 2
    // ========

    // ႂ  MYANMAR CONSONANT SIGN SHAN MEDIAL WA
    '\u1082',
    // ႍ  MYANMAR SIGN SHAN COUNCIL EMPHATIC TONE
    '\u108D',

    // ========
    // Tagalog — Dependent vowel sign items: 1
    // ========

    // ᜓ  TAGALOG VOWEL SIGN U
    '\u1713',

    // ========
    // Tagalog — Virama items: 1
    // ========

    // ᜔  TAGALOG SIGN VIRAMA
    '\u1714',

    // ========
    // Hanunoo — Dependent vowel sign items: 1
    // ========

    // ᜳ  HANUNOO VOWEL SIGN U
    '\u1733',

    // ========
    // Hanunoo — Virama items: 1
    // ========

    // ᜴  HANUNOO SIGN PAMUDPOD
    '\u1734',

    // ========
    // Buhid — Dependent vowel sign items: 1
    // ========

    // ᝓ  BUHID VOWEL SIGN U
    '\u1753',

    // ========
    // Tagbanwa — Dependent vowel sign items: 1
    // ========

    // ᝳ  TAGBANWA VOWEL SIGN U
    '\u1773',

    // ========
    // Khmer — Dependent vowel sign items: 3
    // ========

    // ុ  KHMER VOWEL SIGN U
    '\u17BB',
    // ូ  KHMER VOWEL SIGN UU
    '\u17BC',
    // ួ  KHMER VOWEL SIGN UA
    '\u17BD',

    // ========
    // Limbu — Dependent vowel sign items: 1
    // ========

    // ᤢ  LIMBU VOWEL SIGN U
    '\u1922',

    // ========
    // Limbu — Final consonants items: 1
    // ========

    // ᤲ  LIMBU SMALL LETTER ANUSVARA
    '\u1932',

    // ========
    // Limbu — Various signs items: 2
    // ========

    // ᤹  LIMBU SIGN MUKPHRENG
    '\u1939',
    // ᤻  LIMBU SIGN SA-I
    '\u193B',

    // ========
    // Buginese — Vowel items: 1
    // ========

    // ᨘ  BUGINESE VOWEL SIGN U
    '\u1A18',

    // ========
    // Tai Tham — Consonant signs items: 5
    // ========

    // ᩖ  TAI THAM CONSONANT SIGN MEDIAL LA
    '\u1A56',
    // ᩛ  TAI THAM CONSONANT SIGN HIGH RATHA OR LOW PA
    '\u1A5B',
    // ᩜ  TAI THAM CONSONANT SIGN MA
    '\u1A5C',
    // ᩝ  TAI THAM CONSONANT SIGN BA
    '\u1A5D',
    // ᩞ  TAI THAM CONSONANT SIGN SA
    '\u1A5E',

    // ========
    // Tai Tham — Dependent vowel sign items: 3
    // ========

    // ᩩ  TAI THAM VOWEL SIGN U
    '\u1A69',
    // ᩪ  TAI THAM VOWEL SIGN UU
    '\u1A6A',
    // ᩬ  TAI THAM VOWEL SIGN OA BELOW
    '\u1A6C',

    // ========
    // Tai Tham — Cryptogrammic mark items: 1
    // ========

    // ᩿  TAI THAM COMBINING CRYPTOGRAMMIC DOT
    '\u1A7F',

    // ========
    // Balinese — Dependent vowel sign items: 3
    // ========

    // ᬸ  BALINESE VOWEL SIGN SUKU
    '\u1B38',
    // ᬹ  BALINESE VOWEL SIGN SUKU ILUT
    '\u1B39',
    // ᬺ  BALINESE VOWEL SIGN RA REPA
    '\u1B3A',

    // ========
    // Balinese — Diacritical marks for musical symbols items: 1
    // ========

    // ᭬  BALINESE MUSICAL SYMBOL COMBINING ENDEP
    '\u1B6C',

    // ========
    // Sundanese — Consonant signs items: 4
    // ========

    // ᮢ  SUNDANESE CONSONANT SIGN PANYAKRA
    '\u1BA2',
    // ᮣ  SUNDANESE CONSONANT SIGN PANYIKU
    '\u1BA3',
    // ᮬ  SUNDANESE CONSONANT SIGN PASANGAN MA
    '\u1BAC',
    // ᮭ  SUNDANESE CONSONANT SIGN PASANGAN WA
    '\u1BAD',

    // ========
    // Sundanese — Vowel sign items: 1
    // ========

    // ᮥ  SUNDANESE VOWEL SIGN PANYUKU
    '\u1BA5',

    // ========
    // Lepcha — Dependent vowels items: 1
    // ========

    // ᰬ  LEPCHA VOWEL SIGN E
    '\u1C2C',

    // ========
    // Lepcha — Various signs items: 1
    // ========

    // ᰷  LEPCHA SIGN NUKTA
    '\u1C37',

    // ========
    // Vedic Extensions — Sign for Yajurvedic items: 7
    // ========

    // ᳕  VEDIC TONE YAJURVEDIC AGGRAVATED INDEPENDENT SVARITA
    '\u1CD5',
    // ᳖  VEDIC TONE YAJURVEDIC INDEPENDENT SVARITA
    '\u1CD6',
    // ᳗  VEDIC TONE YAJURVEDIC KATHAKA INDEPENDENT SVARITA
    '\u1CD7',
    // ᳘  VEDIC TONE CANDRA BELOW
    '\u1CD8',
    // ᳙  VEDIC TONE YAJURVEDIC KATHAKA INDEPENDENT SVARITA SCHROEDER
    '\u1CD9',
    // ᳜  VEDIC TONE KATHAKA ANUDATTA
    '\u1CDC',
    // ᳝  VEDIC TONE DOT BELOW
    '\u1CDD',

    // ========
    // Vedic Extensions — Tone marks for the Satapathabrahmana items: 2
    // ========

    // ᳞  VEDIC TONE TWO DOTS BELOW
    '\u1CDE',
    // ᳟  VEDIC TONE THREE DOTS BELOW
    '\u1CDF',

    // ========
    // Vedic Extensions — Nasalization mark items: 1
    // ========

    // ᳭  VEDIC SIGN TIRYAK
    '\u1CED',

    // ========
    // Syloti Nagri — Dependent vowel sign items: 1
    // ========

    // ꠥ  SYLOTI NAGRI VOWEL SIGN U
    '\uA825',

    // ========
    // Syloti Nagri — Sign items: 1
    // ========

    // ꠬  SYLOTI NAGRI SIGN ALTERNATE HASANTA
    '\uA82C',

    // ========
    // Saurashtra — Virama items: 1
    // ========

    // ꣄  SAURASHTRA SIGN VIRAMA
    '\uA8C4',

    // ========
    // Kayah Li — Tone marks items: 3
    // ========

    // ꤫  KAYAH LI TONE PLOPHU
    '\uA92B',
    // ꤬  KAYAH LI TONE CALYA
    '\uA92C',
    // ꤭  KAYAH LI TONE CALYA PLOPHU
    '\uA92D',

    // ========
    // Rejang — Vowel sign items: 7
    // ========

    // ꥇ  REJANG VOWEL SIGN I
    '\uA947',
    // ꥈ  REJANG VOWEL SIGN U
    '\uA948',
    // ꥉ  REJANG VOWEL SIGN E
    '\uA949',
    // ꥋ  REJANG VOWEL SIGN O
    '\uA94B',
    // ꥌ  REJANG VOWEL SIGN AU
    '\uA94C',
    // ꥍ  REJANG VOWEL SIGN EU
    '\uA94D',
    // ꥎ  REJANG VOWEL SIGN EA
    '\uA94E',

    // ========
    // Javanese — Dependent vowel sign items: 2
    // ========

    // ꦸ  JAVANESE VOWEL SIGN SUKU
    '\uA9B8',
    // ꦹ  JAVANESE VOWEL SIGN SUKU MENDUT
    '\uA9B9',

    // ========
    // Javanese — Dependent consonant signs items: 1
    // ========

    // ꦽ  JAVANESE CONSONANT SIGN KERET
    '\uA9BD',

    // ========
    // Cham — Dependent vowel sign items: 2
    // ========

    // ꨭ  CHAM VOWEL SIGN U
    '\uAA2D',
    // ꨲ  CHAM VOWEL SIGN UE
    '\uAA32',

    // ========
    // Cham — Consonant signs items: 2
    // ========

    // ꨵ  CHAM CONSONANT SIGN LA
    '\uAA35',
    // ꨶ  CHAM CONSONANT SIGN WA
    '\uAA36',

    // ========
    // Tai Viet — Vowels and finals items: 1
    // ========

    // ꪴ  TAI VIET VOWEL U
    '\uAAB4',

    // ========
    // Meetei Mayek Extensions — Dependent vowel sign items: 1
    // ========

    // ꫬ  MEETEI MAYEK VOWEL SIGN UU
    '\uAAEC',

    // ========
    // Meetei Mayek — Dependent vowel sign items: 1
    // ========

    // ꯨ  MEETEI MAYEK VOWEL SIGN UNAP
    '\uABE8',

    // ========
    // Meetei Mayek — Punctuation items: 1
    // ========

    // ꯭  MEETEI MAYEK APUN IYEK
    '\uABED',

    // ========
    // Kharoshthi — Vowel items: 2
    // ========

    // 𐨂  KHAROSHTHI VOWEL SIGN U
    '\u10A0',
    // 𐨃  KHAROSHTHI VOWEL SIGN VOCALIC R
    '\u10A0',

    // ========
    // Kharoshthi — Length mark items: 1
    // ========

    // 𐨌  KHAROSHTHI VOWEL LENGTH MARK
    '\u10A0',

    // ========
    // Kharoshthi — Various signs items: 4
    // ========

    // 𐨍  KHAROSHTHI SIGN DOUBLE RING BELOW
    '\u10A0',
    // 𐨎  KHAROSHTHI SIGN ANUSVARA
    '\u10A0',
    // 𐨹  KHAROSHTHI SIGN CAUDA
    '\u10A3',
    // 𐨺  KHAROSHTHI SIGN DOT BELOW
    '\u10A3',

    // ========
    // Brahmi — Dependent vowel sign items: 6
    // ========

    // 𑀼  BRAHMI VOWEL SIGN U
    '\u1103',
    // 𑀽  BRAHMI VOWEL SIGN UU
    '\u1103',
    // 𑀾  BRAHMI VOWEL SIGN VOCALIC R
    '\u1103',
    // 𑀿  BRAHMI VOWEL SIGN VOCALIC RR
    '\u1103',
    // 𑁀  BRAHMI VOWEL SIGN VOCALIC L
    '\u1104',
    // 𑁁  BRAHMI VOWEL SIGN VOCALIC LL
    '\u1104',

    // ========
    // Kaithi — Dependent vowel sign items: 2
    // ========

    // 𑂳  KAITHI VOWEL SIGN U
    '\u110B',
    // 𑂴  KAITHI VOWEL SIGN UU
    '\u110B',

    // ========
    // Kaithi — Virama items: 1
    // ========

    // 𑂹  KAITHI SIGN VIRAMA
    '\u110B',

    // ========
    // Kaithi — Various signs items: 1
    // ========

    // 𑂺  KAITHI SIGN NUKTA
    '\u110B',

    // ========
    // Chakma — Dependent vowel sign items: 4
    // ========

    // 𑄪  CHAKMA VOWEL SIGN U
    '\u1112',
    // 𑄫  CHAKMA VOWEL SIGN UU
    '\u1112',
    // 𑄱  CHAKMA O MARK
    '\u1113',
    // 𑄲  CHAKMA AU MARK
    '\u1113',

    // ========
    // Mahajani — Sign items: 1
    // ========

    // 𑅳  MAHAJANI SIGN NUKTA
    '\u1117',

    // ========
    // Sharada — Dependent vowel sign items: 6
    // ========

    // 𑆶  SHARADA VOWEL SIGN U
    '\u111B',
    // 𑆷  SHARADA VOWEL SIGN UU
    '\u111B',
    // 𑆸  SHARADA VOWEL SIGN VOCALIC R
    '\u111B',
    // 𑆹  SHARADA VOWEL SIGN VOCALIC RR
    '\u111B',
    // 𑆺  SHARADA VOWEL SIGN VOCALIC L
    '\u111B',
    // 𑆻  SHARADA VOWEL SIGN VOCALIC LL
    '\u111B',

    // ========
    // Sharada — Sign items: 1
    // ========

    // 𑇉  SHARADA SANDHI MARK
    '\u111C',

    // ========
    // Sharada — Signs for Kashmiri items: 2
    // ========

    // 𑇊  SHARADA SIGN NUKTA
    '\u111C',
    // 𑇌  SHARADA EXTRA SHORT VOWEL MARK
    '\u111C',

    // ========
    // Khojki — Dependent vowel sign items: 1
    // ========

    // 𑈯  KHOJKI VOWEL SIGN U
    '\u1122',

    // ========
    // Khudawadi — Dependent vowel sign items: 2
    // ========

    // 𑋣  KHUDAWADI VOWEL SIGN U
    '\u112E',
    // 𑋤  KHUDAWADI VOWEL SIGN UU
    '\u112E',

    // ========
    // Khudawadi — Various signs items: 2
    // ========

    // 𑋩  KHUDAWADI SIGN NUKTA
    '\u112E',
    // 𑋪  KHUDAWADI SIGN VIRAMA
    '\u112E',

    // ========
    // Grantha — Various signs items: 2
    // ========

    // 𑌻  COMBINING BINDU BELOW
    '\u1133',
    // 𑌼  GRANTHA SIGN NUKTA
    '\u1133',

    // ========
    // Newa — Dependent vowel sign items: 6
    // ========

    // 𑐸  NEWA VOWEL SIGN U
    '\u1143',
    // 𑐹  NEWA VOWEL SIGN UU
    '\u1143',
    // 𑐺  NEWA VOWEL SIGN VOCALIC R
    '\u1143',
    // 𑐻  NEWA VOWEL SIGN VOCALIC RR
    '\u1143',
    // 𑐼  NEWA VOWEL SIGN VOCALIC L
    '\u1143',
    // 𑐽  NEWA VOWEL SIGN VOCALIC LL
    '\u1143',

    // ========
    // Newa — Various signs items: 2
    // ========

    // 𑑂  NEWA SIGN VIRAMA
    '\u1144',
    // 𑑆  NEWA SIGN NUKTA
    '\u1144',

    // ========
    // Tirhuta — Dependent vowel sign items: 6
    // ========

    // 𑒳  TIRHUTA VOWEL SIGN U
    '\u114B',
    // 𑒴  TIRHUTA VOWEL SIGN UU
    '\u114B',
    // 𑒵  TIRHUTA VOWEL SIGN VOCALIC R
    '\u114B',
    // 𑒶  TIRHUTA VOWEL SIGN VOCALIC RR
    '\u114B',
    // 𑒷  TIRHUTA VOWEL SIGN VOCALIC L
    '\u114B',
    // 𑒸  TIRHUTA VOWEL SIGN VOCALIC LL
    '\u114B',

    // ========
    // Tirhuta — Various signs items: 2
    // ========

    // 𑓂  TIRHUTA SIGN VIRAMA
    '\u114C',
    // 𑓃  TIRHUTA SIGN NUKTA
    '\u114C',

    // ========
    // Siddham — Dependent vowel sign items: 4
    // ========

    // 𑖲  SIDDHAM VOWEL SIGN U
    '\u115B',
    // 𑖳  SIDDHAM VOWEL SIGN UU
    '\u115B',
    // 𑖴  SIDDHAM VOWEL SIGN VOCALIC R
    '\u115B',
    // 𑖵  SIDDHAM VOWEL SIGN VOCALIC RR
    '\u115B',

    // ========
    // Siddham — Various signs items: 2
    // ========

    // 𑖿  SIDDHAM SIGN VIRAMA
    '\u115B',
    // 𑗀  SIDDHAM SIGN NUKTA
    '\u115C',

    // ========
    // Siddham — Alternate vowel signs items: 2
    // ========

    // 𑗜  SIDDHAM VOWEL SIGN ALTERNATE U
    '\u115D',
    // 𑗝  SIDDHAM VOWEL SIGN ALTERNATE UU
    '\u115D',

    // ========
    // Modi — Dependent vowel sign items: 6
    // ========

    // 𑘳  MODI VOWEL SIGN U
    '\u1163',
    // 𑘴  MODI VOWEL SIGN UU
    '\u1163',
    // 𑘵  MODI VOWEL SIGN VOCALIC R
    '\u1163',
    // 𑘶  MODI VOWEL SIGN VOCALIC RR
    '\u1163',
    // 𑘷  MODI VOWEL SIGN VOCALIC L
    '\u1163',
    // 𑘸  MODI VOWEL SIGN VOCALIC LL
    '\u1163',

    // ========
    // Modi — Various signs items: 1
    // ========

    // 𑘿  MODI SIGN VIRAMA
    '\u1163',

    // ========
    // Takri — Dependent vowel sign items: 2
    // ========

    // 𑚰  TAKRI VOWEL SIGN U
    '\u116B',
    // 𑚱  TAKRI VOWEL SIGN UU
    '\u116B',

    // ========
    // Takri — Nukta items: 1
    // ========

    // 𑚷  TAKRI SIGN NUKTA
    '\u116B',

    // ========
    // Ahom — Medials items: 1
    // ========

    // 𑜝  AHOM CONSONANT SIGN MEDIAL LA
    '\u1171',

    // ========
    // Ahom — Vowel sign items: 3
    // ========

    // 𑜤  AHOM VOWEL SIGN U
    '\u1172',
    // 𑜥  AHOM VOWEL SIGN UU
    '\u1172',
    // 𑜨  AHOM VOWEL SIGN O
    '\u1172',

    // ========
    // Dogra — Dependent vowel sign items: 4
    // ========

    // 𑠯  DOGRA VOWEL SIGN U
    '\u1182',
    // 𑠰  DOGRA VOWEL SIGN UU
    '\u1183',
    // 𑠱  DOGRA VOWEL SIGN VOCALIC R
    '\u1183',
    // 𑠲  DOGRA VOWEL SIGN VOCALIC RR
    '\u1183',

    // ========
    // Dogra — Various signs items: 2
    // ========

    // 𑠹  DOGRA SIGN VIRAMA
    '\u1183',
    // 𑠺  DOGRA SIGN NUKTA
    '\u1183',

    // ========
    // Dives Akuru — Nukta items: 1
    // ========

    // 𑥃  DIVES AKURU SIGN NUKTA
    '\u1194',

    // ========
    // Nandinagari — Dependent vowel sign items: 4
    // ========

    // 𑧔  NANDINAGARI VOWEL SIGN U
    '\u119D',
    // 𑧕  NANDINAGARI VOWEL SIGN UU
    '\u119D',
    // 𑧖  NANDINAGARI VOWEL SIGN VOCALIC R
    '\u119D',
    // 𑧗  NANDINAGARI VOWEL SIGN VOCALIC RR
    '\u119D',

    // ========
    // Nandinagari — Various signs items: 1
    // ========

    // 𑧠  NANDINAGARI SIGN VIRAMA
    '\u119E',

    // ========
    // Zanabazar Square — Vowel sign items: 2
    // ========

    // 𑨂  ZANABAZAR SQUARE VOWEL SIGN UE
    '\u11A0',
    // 𑨃  ZANABAZAR SQUARE VOWEL SIGN U
    '\u11A0',

    // ========
    // Zanabazar Square — Vowel length mark items: 1
    // ========

    // 𑨊  ZANABAZAR SQUARE VOWEL LENGTH MARK
    '\u11A0',

    // ========
    // Zanabazar Square — Final consonant mark items: 1
    // ========

    // 𑨳  ZANABAZAR SQUARE FINAL CONSONANT MARK
    '\u11A3',

    // ========
    // Zanabazar Square — Virama items: 1
    // ========

    // 𑨴  ZANABAZAR SQUARE SIGN VIRAMA
    '\u11A3',

    // ========
    // Zanabazar Square — Cluster-final consonants items: 4
    // ========

    // 𑨻  ZANABAZAR SQUARE CLUSTER-FINAL LETTER YA
    '\u11A3',
    // 𑨼  ZANABAZAR SQUARE CLUSTER-FINAL LETTER RA
    '\u11A3',
    // 𑨽  ZANABAZAR SQUARE CLUSTER-FINAL LETTER LA
    '\u11A3',
    // 𑨾  ZANABAZAR SQUARE CLUSTER-FINAL LETTER VA
    '\u11A3',

    // ========
    // Soyombo — Vowel sign items: 4
    // ========

    // 𑩒  SOYOMBO VOWEL SIGN UE
    '\u11A5',
    // 𑩓  SOYOMBO VOWEL SIGN U
    '\u11A5',
    // 𑩙  SOYOMBO VOWEL SIGN VOCALIC R
    '\u11A5',
    // 𑩚  SOYOMBO VOWEL SIGN VOCALIC L
    '\u11A5',

    // ========
    // Soyombo — Vowel length mark items: 1
    // ========

    // 𑩛  SOYOMBO VOWEL LENGTH MARK
    '\u11A5',

    // ========
    // Soyombo — Final consonant mark items: 12
    // ========

    // 𑪊  SOYOMBO FINAL CONSONANT SIGN G
    '\u11A8',
    // 𑪋  SOYOMBO FINAL CONSONANT SIGN K
    '\u11A8',
    // 𑪌  SOYOMBO FINAL CONSONANT SIGN NG
    '\u11A8',
    // 𑪍  SOYOMBO FINAL CONSONANT SIGN D
    '\u11A8',
    // 𑪎  SOYOMBO FINAL CONSONANT SIGN N
    '\u11A8',
    // 𑪏  SOYOMBO FINAL CONSONANT SIGN B
    '\u11A8',
    // 𑪐  SOYOMBO FINAL CONSONANT SIGN M
    '\u11A9',
    // 𑪑  SOYOMBO FINAL CONSONANT SIGN R
    '\u11A9',
    // 𑪒  SOYOMBO FINAL CONSONANT SIGN L
    '\u11A9',
    // 𑪓  SOYOMBO FINAL CONSONANT SIGN SH
    '\u11A9',
    // 𑪔  SOYOMBO FINAL CONSONANT SIGN S
    '\u11A9',
    // 𑪕  SOYOMBO FINAL CONSONANT SIGN -A
    '\u11A9',

    // ========
    // Bhaiksuki — Dependent vowel sign items: 5
    // ========

    // 𑰲  BHAIKSUKI VOWEL SIGN U
    '\u11C3',
    // 𑰳  BHAIKSUKI VOWEL SIGN UU
    '\u11C3',
    // 𑰴  BHAIKSUKI VOWEL SIGN VOCALIC R
    '\u11C3',
    // 𑰵  BHAIKSUKI VOWEL SIGN VOCALIC RR
    '\u11C3',
    // 𑰶  BHAIKSUKI VOWEL SIGN VOCALIC L
    '\u11C3',

    // ========
    // Bhaiksuki — Various signs items: 1
    // ========

    // 𑰿  BHAIKSUKI SIGN VIRAMA
    '\u11C3',

    // ========
    // Marchen — Subjoined letters items: 28
    // ========

    // 𑲒  MARCHEN SUBJOINED LETTER KA
    '\u11C9',
    // 𑲓  MARCHEN SUBJOINED LETTER KHA
    '\u11C9',
    // 𑲔  MARCHEN SUBJOINED LETTER GA
    '\u11C9',
    // 𑲕  MARCHEN SUBJOINED LETTER NGA
    '\u11C9',
    // 𑲖  MARCHEN SUBJOINED LETTER CA
    '\u11C9',
    // 𑲗  MARCHEN SUBJOINED LETTER CHA
    '\u11C9',
    // 𑲘  MARCHEN SUBJOINED LETTER JA
    '\u11C9',
    // 𑲙  MARCHEN SUBJOINED LETTER NYA
    '\u11C9',
    // 𑲚  MARCHEN SUBJOINED LETTER TA
    '\u11C9',
    // 𑲛  MARCHEN SUBJOINED LETTER THA
    '\u11C9',
    // 𑲜  MARCHEN SUBJOINED LETTER DA
    '\u11C9',
    // 𑲝  MARCHEN SUBJOINED LETTER NA
    '\u11C9',
    // 𑲞  MARCHEN SUBJOINED LETTER PA
    '\u11C9',
    // 𑲟  MARCHEN SUBJOINED LETTER PHA
    '\u11C9',
    // 𑲠  MARCHEN SUBJOINED LETTER BA
    '\u11CA',
    // 𑲡  MARCHEN SUBJOINED LETTER MA
    '\u11CA',
    // 𑲢  MARCHEN SUBJOINED LETTER TSA
    '\u11CA',
    // 𑲣  MARCHEN SUBJOINED LETTER TSHA
    '\u11CA',
    // 𑲤  MARCHEN SUBJOINED LETTER DZA
    '\u11CA',
    // 𑲥  MARCHEN SUBJOINED LETTER WA
    '\u11CA',
    // 𑲦  MARCHEN SUBJOINED LETTER ZHA
    '\u11CA',
    // 𑲧  MARCHEN SUBJOINED LETTER ZA
    '\u11CA',
    // 𑲪  MARCHEN SUBJOINED LETTER RA
    '\u11CA',
    // 𑲫  MARCHEN SUBJOINED LETTER LA
    '\u11CA',
    // 𑲬  MARCHEN SUBJOINED LETTER SHA
    '\u11CA',
    // 𑲭  MARCHEN SUBJOINED LETTER SA
    '\u11CA',
    // 𑲮  MARCHEN SUBJOINED LETTER HA
    '\u11CA',
    // 𑲯  MARCHEN SUBJOINED LETTER A
    '\u11CA',

    // ========
    // Marchen — Dependent vowel sign items: 2
    // ========

    // 𑲰  MARCHEN VOWEL SIGN AA
    '\u11CB',
    // 𑲲  MARCHEN VOWEL SIGN U
    '\u11CB',

    // ========
    // Masaram Gondi — Dependent vowel sign items: 1
    // ========

    // 𑴶  MASARAM GONDI VOWEL SIGN VOCALIC R
    '\u11D3',

    // ========
    // Masaram Gondi — Various signs items: 2
    // ========

    // 𑵂  MASARAM GONDI SIGN NUKTA
    '\u11D4',
    // 𑵄  MASARAM GONDI SIGN HALANTA
    '\u11D4',

    // ========
    // Masaram Gondi — Cluster-specific consonant forms items: 1
    // ========

    // 𑵇  MASARAM GONDI RA-KARA
    '\u11D4',

    // ========
    // Makasar — Vowel sign items: 1
    // ========

    // 𑻴  MAKASAR VOWEL SIGN U
    '\u11EF',
  ],
  right: [
    // ========
    //     Devanagari — Various signs items: 1
    // ========

    //  ः 	 DEVANAGARI SIGN VISARGA
    '\u0903',

    // ========
    //  Devanagari — Dependent vowel sign items: 8
    // ========

    //  ऻ 	 DEVANAGARI VOWEL SIGN OOE
    '\u093B',
    //  ा 	 DEVANAGARI VOWEL SIGN AA
    '\u093E',
    //  ी 	 DEVANAGARI VOWEL SIGN II
    '\u0940',
    //  ॉ 	 DEVANAGARI VOWEL SIGN CANDRA O
    '\u0949',
    //  ॊ 	 DEVANAGARI VOWEL SIGN SHORT O
    '\u094A',
    //  ो 	 DEVANAGARI VOWEL SIGN O
    '\u094B',
    //  ौ 	 DEVANAGARI VOWEL SIGN AU
    '\u094C',
    //  ॏ 	 DEVANAGARI VOWEL SIGN AW
    '\u094F',

    // ========
    //  Bengali — Various signs items: 2
    // ========

    //  ং 	 BENGALI SIGN ANUSVARA
    '\u0982',
    //  ঃ 	 BENGALI SIGN VISARGA
    '\u0983',

    // ========
    //  Bengali — Dependent vowel sign items: 2
    // ========

    //  া 	 BENGALI VOWEL SIGN AA
    '\u09BE',
    //  ী 	 BENGALI VOWEL SIGN II
    '\u09C0',

    // ========
    //  Bengali — Sign items: 1
    // ========

    //  ৗ 	 BENGALI AU LENGTH MARK
    '\u09D7',

    // ========
    //  Gurmukhi — Various signs items: 1
    // ========

    //  ਃ 	 GURMUKHI SIGN VISARGA
    '\u0A03',

    // ========
    //  Gurmukhi — Dependent vowel sign items: 2
    // ========

    //  ਾ 	 GURMUKHI VOWEL SIGN AA
    '\u0A3E',
    //  ੀ 	 GURMUKHI VOWEL SIGN II
    '\u0A40',

    // ========
    //  Gujarati — Various signs items: 1
    // ========

    //  ઃ 	 GUJARATI SIGN VISARGA
    '\u0A83',

    // ========
    //  Gujarati — Dependent vowel sign items: 4
    // ========

    //  ા 	 GUJARATI VOWEL SIGN AA
    '\u0ABE',
    //  ી 	 GUJARATI VOWEL SIGN II
    '\u0AC0',
    //  ો 	 GUJARATI VOWEL SIGN O
    '\u0ACB',
    //  ૌ 	 GUJARATI VOWEL SIGN AU
    '\u0ACC',

    // ========
    //  Oriya — Various signs items: 2
    // ========

    //  ଂ 	 ORIYA SIGN ANUSVARA
    '\u0B02',
    //  ଃ 	 ORIYA SIGN VISARGA
    '\u0B03',

    // ========
    //  Oriya — Dependent vowel sign items: 2
    // ========

    //  ା 	 ORIYA VOWEL SIGN AA
    '\u0B3E',
    //  ୀ 	 ORIYA VOWEL SIGN II
    '\u0B40',

    // ========
    //  Tamil — Dependent vowel sign items: 4
    // ========

    //  ா 	 TAMIL VOWEL SIGN AA
    '\u0BBE',
    //  ி 	 TAMIL VOWEL SIGN I
    '\u0BBF',
    //  ு 	 TAMIL VOWEL SIGN U
    '\u0BC1',
    //  ூ 	 TAMIL VOWEL SIGN UU
    '\u0BC2',

    // ========
    //  Tamil — Various signs items: 1
    // ========

    //  ௗ 	 TAMIL AU LENGTH MARK
    '\u0BD7',

    // ========
    //  Telugu — Various signs items: 3
    // ========

    //  ఁ 	 TELUGU SIGN CANDRABINDU
    '\u0C01',
    //  ం 	 TELUGU SIGN ANUSVARA
    '\u0C02',
    //  ః 	 TELUGU SIGN VISARGA
    '\u0C03',

    // ========
    //  Telugu — Dependent vowel sign items: 4
    // ========

    //  ు 	 TELUGU VOWEL SIGN U
    '\u0C41',
    //  ూ 	 TELUGU VOWEL SIGN UU
    '\u0C42',
    //  ృ 	 TELUGU VOWEL SIGN VOCALIC R
    '\u0C43',
    //  ౄ 	 TELUGU VOWEL SIGN VOCALIC RR
    '\u0C44',

    // ========
    //  Kannada — Various signs items: 4
    // ========

    //  ಂ 	 KANNADA SIGN ANUSVARA
    '\u0C82',
    //  ಃ 	 KANNADA SIGN VISARGA
    '\u0C83',
    //  ೕ 	 KANNADA LENGTH MARK
    '\u0CD5',
    //  ೖ 	 KANNADA AI LENGTH MARK
    '\u0CD6',

    // ========
    //  Kannada — Dependent vowel sign items: 5
    // ========

    //  ಾ 	 KANNADA VOWEL SIGN AA
    '\u0CBE',
    //  ು 	 KANNADA VOWEL SIGN U
    '\u0CC1',
    //  ೂ 	 KANNADA VOWEL SIGN UU
    '\u0CC2',
    //  ೃ 	 KANNADA VOWEL SIGN VOCALIC R
    '\u0CC3',
    //  ೄ 	 KANNADA VOWEL SIGN VOCALIC RR
    '\u0CC4',

    // ========
    //  Malayalam — Various signs items: 2
    // ========

    //  ം 	 MALAYALAM SIGN ANUSVARA
    '\u0D02',
    //  ഃ 	 MALAYALAM SIGN VISARGA
    '\u0D03',

    // ========
    //  Malayalam — Dependent vowel sign items: 6
    // ========

    //  ാ 	 MALAYALAM VOWEL SIGN AA
    '\u0D3E',
    //  ി 	 MALAYALAM VOWEL SIGN I
    '\u0D3F',
    //  ീ 	 MALAYALAM VOWEL SIGN II
    '\u0D40',
    //  ു 	 MALAYALAM VOWEL SIGN U
    '\u0D41',
    //  ൂ 	 MALAYALAM VOWEL SIGN UU
    '\u0D42',
    //  ൗ 	 MALAYALAM AU LENGTH MARK
    '\u0D57',

    // ========
    //  Sinhala — Various signs items: 2
    // ========

    //  ං 	 SINHALA SIGN ANUSVARAYA
    '\u0D82',
    //  ඃ 	 SINHALA SIGN VISARGAYA
    '\u0D83',

    // ========
    //  Sinhala — Dependent vowel sign items: 7
    // ========

    //  ා 	 SINHALA VOWEL SIGN AELA-PILLA
    '\u0DCF',
    //  ැ 	 SINHALA VOWEL SIGN KETTI AEDA-PILLA
    '\u0DD0',
    //  ෑ 	 SINHALA VOWEL SIGN DIGA AEDA-PILLA
    '\u0DD1',
    //  ෘ 	 SINHALA VOWEL SIGN GAETTA-PILLA
    '\u0DD8',
    //  ෟ 	 SINHALA VOWEL SIGN GAYANUKITTA
    '\u0DDF',
    //  ෲ 	 SINHALA VOWEL SIGN DIGA GAETTA-PILLA
    '\u0DF2',
    //  ෳ 	 SINHALA VOWEL SIGN DIGA GAYANUKITTA
    '\u0DF3',

    // ========
    //  Thai — Vowel items: 3
    // ========

    //  ะ 	 THAI CHARACTER SARA A
    '\u0E30',
    //  า 	 THAI CHARACTER SARA AA
    '\u0E32',
    //  ำ 	 THAI CHARACTER SARA AM
    '\u0E33',

    // ========
    //  Thai — Vowel length mark items: 1
    // ========

    //  ๅ 	 THAI CHARACTER LAKKHANGYAO
    '\u0E45',

    // ========
    //  Lao — Vowel items: 3
    // ========

    //  ະ 	 LAO VOWEL SIGN A
    '\u0EB0',
    //  າ 	 LAO VOWEL SIGN AA
    '\u0EB2',
    //  ຳ 	 LAO VOWEL SIGN AM
    '\u0EB3',

    // ========
    //  Tibetan — Astrological signs items: 1
    // ========

    //  ༾ 	 TIBETAN SIGN YAR TSHES
    '\u0F3E',

    // ========
    //  Tibetan — Vocalic modification items: 1
    // ========

    //  ཿ 	 TIBETAN SIGN RNAM BCAD
    '\u0F7F',

    // ========
    //  Myanmar — Dependent vowel sign items: 2
    // ========

    //  ါ 	 MYANMAR VOWEL SIGN TALL AA
    '\u102B',
    //  ာ 	 MYANMAR VOWEL SIGN AA
    '\u102C',

    // ========
    //  Myanmar — Various signs items: 1
    // ========

    //  း 	 MYANMAR SIGN VISARGA
    '\u1038',

    // ========
    //  Myanmar — Dependent consonant signs items: 1
    // ========

    //  ျ 	 MYANMAR CONSONANT SIGN MEDIAL YA
    '\u103B',

    // ========
    //  Myanmar — Pali and Sanskrit extensions items: 2
    // ========

    //  ၖ 	 MYANMAR VOWEL SIGN VOCALIC R
    '\u1056',
    //  ၗ 	 MYANMAR VOWEL SIGN VOCALIC RR
    '\u1057',

    // ========
    //  Myanmar — Extensions for S'gaw Karen items: 3
    // ========

    //  ၢ 	 MYANMAR VOWEL SIGN SGAW KAREN EU
    '\u1062',
    //  ၣ 	 MYANMAR TONE MARK SGAW KAREN HATHI
    '\u1063',
    //  ၤ 	 MYANMAR TONE MARK SGAW KAREN KE PHO
    '\u1064',

    // ========
    //  Myanmar — Extensions for Western Pwo Karen items: 7
    // ========

    //  ၧ 	 MYANMAR VOWEL SIGN WESTERN PWO KAREN EU
    '\u1067',
    //  ၨ 	 MYANMAR VOWEL SIGN WESTERN PWO KAREN UE
    '\u1068',
    //  ၩ 	 MYANMAR SIGN WESTERN PWO KAREN TONE-1
    '\u1069',
    //  ၪ 	 MYANMAR SIGN WESTERN PWO KAREN TONE-2
    '\u106A',
    //  ၫ 	 MYANMAR SIGN WESTERN PWO KAREN TONE-3
    '\u106B',
    //  ၬ 	 MYANMAR SIGN WESTERN PWO KAREN TONE-4
    '\u106C',
    //  ၭ 	 MYANMAR SIGN WESTERN PWO KAREN TONE-5
    '\u106D',

    // ========
    //  Myanmar — Extensions for Shan items: 7
    // ========

    //  ႃ 	 MYANMAR VOWEL SIGN SHAN AA
    '\u1083',
    //  ႇ 	 MYANMAR SIGN SHAN TONE-2
    '\u1087',
    //  ႈ 	 MYANMAR SIGN SHAN TONE-3
    '\u1088',
    //  ႉ 	 MYANMAR SIGN SHAN TONE-5
    '\u1089',
    //  ႊ 	 MYANMAR SIGN SHAN TONE-6
    '\u108A',
    //  ႋ 	 MYANMAR SIGN SHAN COUNCIL TONE-2
    '\u108B',
    //  ႌ 	 MYANMAR SIGN SHAN COUNCIL TONE-3
    '\u108C',

    // ========
    //  Myanmar — Extensions for Rumai Palaung items: 1
    // ========

    //  ႏ 	 MYANMAR SIGN RUMAI PALAUNG TONE-5
    '\u108F',

    // ========
    //  Myanmar — Extensions for Khamti Shan items: 2
    // ========

    //  ႚ 	 MYANMAR SIGN KHAMTI TONE-1
    '\u109A',
    //  ႛ 	 MYANMAR SIGN KHAMTI TONE-3
    '\u109B',

    // ========
    //  Myanmar — Extensions for Aiton and Phake items: 1
    // ========

    //  ႜ 	 MYANMAR VOWEL SIGN AITON A
    '\u109C',

    // ========
    //  Khmer — Dependent vowel sign items: 1
    // ========

    //  ា 	 KHMER VOWEL SIGN AA
    '\u17B6',

    // ========
    //  Khmer — Various signs items: 2
    // ========

    //  ះ 	 KHMER SIGN REAHMUK
    '\u17C7',
    //  ៈ 	 KHMER SIGN YUUKALEAPINTU
    '\u17C8',

    // ========
    //  Limbu — Dependent vowel sign items: 2
    // ========

    //  ᤣ 	 LIMBU VOWEL SIGN EE
    '\u1923',
    //  ᤤ 	 LIMBU VOWEL SIGN AI
    '\u1924',

    // ========
    //  Limbu — Subjoined consonant items: 3
    // ========

    //  ᤩ 	 LIMBU SUBJOINED LETTER YA
    '\u1929',
    //  ᤪ 	 LIMBU SUBJOINED LETTER RA
    '\u192A',
    //  ᤫ 	 LIMBU SUBJOINED LETTER WA
    '\u192B',

    // ========
    //  Limbu — Final consonants items: 8
    // ========

    //  ᤰ 	 LIMBU SMALL LETTER KA
    '\u1930',
    //  ᤱ 	 LIMBU SMALL LETTER NGA
    '\u1931',
    //  ᤳ 	 LIMBU SMALL LETTER TA
    '\u1933',
    //  ᤴ 	 LIMBU SMALL LETTER NA
    '\u1934',
    //  ᤵ 	 LIMBU SMALL LETTER PA
    '\u1935',
    //  ᤶ 	 LIMBU SMALL LETTER MA
    '\u1936',
    //  ᤷ 	 LIMBU SMALL LETTER RA
    '\u1937',
    //  ᤸ 	 LIMBU SMALL LETTER LA
    '\u1938',

    // ========
    //  New Tai Lue — Vowel sign items: 13
    // ========

    //  ᦰ 	 NEW TAI LUE VOWEL SIGN VOWEL SHORTENER
    '\u19B0',
    //  ᦱ 	 NEW TAI LUE VOWEL SIGN AA
    '\u19B1',
    //  ᦲ 	 NEW TAI LUE VOWEL SIGN II
    '\u19B2',
    //  ᦳ 	 NEW TAI LUE VOWEL SIGN U
    '\u19B3',
    //  ᦴ 	 NEW TAI LUE VOWEL SIGN UU
    '\u19B4',
    //  ᦸ 	 NEW TAI LUE VOWEL SIGN OA
    '\u19B8',
    //  ᦹ 	 NEW TAI LUE VOWEL SIGN UE
    '\u19B9',
    //  ᦻ 	 NEW TAI LUE VOWEL SIGN AAY
    '\u19BB',
    //  ᦼ 	 NEW TAI LUE VOWEL SIGN UY
    '\u19BC',
    //  ᦽ 	 NEW TAI LUE VOWEL SIGN OY
    '\u19BD',
    //  ᦾ 	 NEW TAI LUE VOWEL SIGN OAY
    '\u19BE',
    //  ᦿ 	 NEW TAI LUE VOWEL SIGN UEY
    '\u19BF',
    //  ᧀ 	 NEW TAI LUE VOWEL SIGN IY
    '\u19C0',

    // ========
    //  New Tai Lue — Tone marks items: 2
    // ========

    //  ᧈ 	 NEW TAI LUE TONE MARK-1
    '\u19C8',
    //  ᧉ 	 NEW TAI LUE TONE MARK-2
    '\u19C9',

    // ========
    //  Buginese — Vowel items: 1
    // ========

    //  ᨚ 	 BUGINESE VOWEL SIGN O
    '\u1A1A',

    // ========
    //  Tai Tham — Consonant signs items: 1
    // ========

    //  ᩗ 	 TAI THAM CONSONANT SIGN LA TANG LAI
    '\u1A57',

    // ========
    //  Tai Tham — Dependent vowel sign items: 4
    // ========

    //  ᩡ 	 TAI THAM VOWEL SIGN A
    '\u1A61',
    //  ᩣ 	 TAI THAM VOWEL SIGN AA
    '\u1A63',
    //  ᩤ 	 TAI THAM VOWEL SIGN TALL AA
    '\u1A64',
    //  ᩭ 	 TAI THAM VOWEL SIGN OY
    '\u1A6D',

    // ========
    //  Balinese — Various signs items: 1
    // ========

    //  ᬄ 	 BALINESE SIGN BISAH
    '\u1B04',

    // ========
    //  Balinese — Dependent vowel sign items: 1
    // ========

    //  ᬵ 	 BALINESE VOWEL SIGN TEDUNG
    '\u1B35',

    // ========
    //  Balinese — Sign items: 1
    // ========

    //  ᭄ 	 BALINESE ADEG ADEG
    '\u1B44',

    // ========
    //  Sundanese — Various signs items: 1
    // ========

    //  ᮂ 	 SUNDANESE SIGN PANGWISAD
    '\u1B82',

    // ========
    //  Sundanese — Consonant signs items: 1
    // ========

    //  ᮡ 	 SUNDANESE CONSONANT SIGN PAMINGKAL
    '\u1BA1',

    // ========
    //  Sundanese — Vowel sign items: 1
    // ========

    //  ᮧ 	 SUNDANESE VOWEL SIGN PANOLONG
    '\u1BA7',

    // ========
    //  Sundanese — Virama items: 1
    // ========

    //  ᮪ 	 SUNDANESE SIGN PAMAAEH
    '\u1BAA',

    // ========
    //  Batak — Dependent vowel sign items: 5
    // ========

    //  ᯧ 	 BATAK VOWEL SIGN E
    '\u1BE7',
    //  ᯪ 	 BATAK VOWEL SIGN I
    '\u1BEA',
    //  ᯫ 	 BATAK VOWEL SIGN KARO I
    '\u1BEB',
    //  ᯬ 	 BATAK VOWEL SIGN O
    '\u1BEC',
    //  ᯮ 	 BATAK VOWEL SIGN U
    '\u1BEE',

    // ========
    //  Batak — Sign items: 2
    // ========

    //  ᯲ 	 BATAK PANGOLAT
    '\u1BF2',
    //  ᯳ 	 BATAK PANONGONAN
    '\u1BF3',

    // ========
    //  Lepcha — Subjoined consonant items: 2
    // ========

    //  ᰤ 	 LEPCHA SUBJOINED LETTER YA
    '\u1C24',
    //  ᰥ 	 LEPCHA SUBJOINED LETTER RA
    '\u1C25',

    // ========
    //  Lepcha — Dependent vowels items: 3
    // ========

    //  ᰦ 	 LEPCHA VOWEL SIGN AA
    '\u1C26',
    //  ᰪ 	 LEPCHA VOWEL SIGN U
    '\u1C2A',
    //  ᰫ 	 LEPCHA VOWEL SIGN UU
    '\u1C2B',

    // ========
    //  Vedic Extensions — Tone mark for the Atharvaveda items: 1
    // ========

    //  ᳡ 	 VEDIC TONE ATHARVAVEDIC INDEPENDENT SVARITA
    '\u1CE1',

    // ========
    //  Vedic Extensions — Sign items: 1
    // ========

    //  ᳷ 	 VEDIC SIGN ATIKRAMA
    '\u1CF7',

    // ========
    //  Syloti Nagri — Dependent vowel sign items: 3
    // ========

    //  ꠣ 	 SYLOTI NAGRI VOWEL SIGN A
    '\uA823',
    //  ꠤ 	 SYLOTI NAGRI VOWEL SIGN I
    '\uA824',
    //  ꠧ 	 SYLOTI NAGRI VOWEL SIGN OO
    '\uA827',

    // ========
    //  Saurashtra — Various signs items: 2
    // ========

    //  ꢀ 	 SAURASHTRA SIGN ANUSVARA
    '\uA880',
    //  ꢁ 	 SAURASHTRA SIGN VISARGA
    '\uA881',

    // ========
    //  Saurashtra — Consonant items: 1
    // ========

    //  ꢴ 	 SAURASHTRA CONSONANT SIGN HAARU
    '\uA8B4',

    // ========
    //  Saurashtra — Dependent vowel sign items: 15
    // ========

    //  ꢵ 	 SAURASHTRA VOWEL SIGN AA
    '\uA8B5',
    //  ꢶ 	 SAURASHTRA VOWEL SIGN I
    '\uA8B6',
    //  ꢷ 	 SAURASHTRA VOWEL SIGN II
    '\uA8B7',
    //  ꢸ 	 SAURASHTRA VOWEL SIGN U
    '\uA8B8',
    //  ꢹ 	 SAURASHTRA VOWEL SIGN UU
    '\uA8B9',
    //  ꢺ 	 SAURASHTRA VOWEL SIGN VOCALIC R
    '\uA8BA',
    //  ꢻ 	 SAURASHTRA VOWEL SIGN VOCALIC RR
    '\uA8BB',
    //  ꢼ 	 SAURASHTRA VOWEL SIGN VOCALIC L
    '\uA8BC',
    //  ꢽ 	 SAURASHTRA VOWEL SIGN VOCALIC LL
    '\uA8BD',
    //  ꢾ 	 SAURASHTRA VOWEL SIGN E
    '\uA8BE',
    //  ꢿ 	 SAURASHTRA VOWEL SIGN EE
    '\uA8BF',
    //  ꣀ 	 SAURASHTRA VOWEL SIGN AI
    '\uA8C0',
    //  ꣁ 	 SAURASHTRA VOWEL SIGN O
    '\uA8C1',
    //  ꣂ 	 SAURASHTRA VOWEL SIGN OO
    '\uA8C2',
    //  ꣃ 	 SAURASHTRA VOWEL SIGN AU
    '\uA8C3',

    // ========
    //  Rejang — Consonant signs items: 1
    // ========

    //  ꥒ 	 REJANG CONSONANT SIGN H
    '\uA952',

    // ========
    //  Rejang — Virama items: 1
    // ========

    //  ꥓ 	 REJANG VIRAMA
    '\uA953',

    // ========
    //  Javanese — Various signs items: 1
    // ========

    //  ꦃ 	 JAVANESE SIGN WIGNYAN
    '\uA983',

    // ========
    //  Javanese — Dependent vowel sign items: 2
    // ========

    //  ꦴ 	 JAVANESE VOWEL SIGN TARUNG
    '\uA9B4',
    //  ꦵ 	 JAVANESE VOWEL SIGN TOLONG
    '\uA9B5',

    // ========
    //  Cham — Consonant signs items: 1
    // ========

    //  ꨳ 	 CHAM CONSONANT SIGN YA
    '\uAA33',

    // ========
    //  Cham — Final consonants items: 1
    // ========

    //  ꩍ 	 CHAM CONSONANT SIGN FINAL H
    '\uAA4D',

    // ========
    //  Myanmar Extended A — Pa'o Karen tone mark items: 1
    // ========

    //  ꩻ 	 MYANMAR SIGN PAO KAREN TONE
    '\uAA7B',

    // ========
    //  Myanmar Extended A — Tai Laing tone marks items: 1
    // ========

    //  ꩽ 	 MYANMAR SIGN TAI LAING TONE-5
    '\uAA7D',

    // ========
    //  Tai Viet — Vowels and finals items: 3
    // ========

    //  ꪱ 	 TAI VIET VOWEL AA
    '\uAAB1',
    //  ꪺ 	 TAI VIET VOWEL UA
    '\uAABA',
    //  ꪽ 	 TAI VIET VOWEL AN
    '\uAABD',

    // ========
    //  Meetei Mayek Extensions — Dependent vowel sign items: 1
    // ========

    //  ꫯ 	 MEETEI MAYEK VOWEL SIGN AAU
    '\uAAEF',

    // ========
    //  Meetei Mayek Extensions — Sign items: 1
    // ========

    //  ꫵ 	 MEETEI MAYEK VOWEL SIGN VISARGA
    '\uAAF5',

    // ========
    //  Meetei Mayek — Dependent vowel sign items: 6
    // ========

    //  ꯣ 	 MEETEI MAYEK VOWEL SIGN ONAP
    '\uABE3',
    //  ꯤ 	 MEETEI MAYEK VOWEL SIGN INAP
    '\uABE4',
    //  ꯦ 	 MEETEI MAYEK VOWEL SIGN YENAP
    '\uABE6',
    //  ꯧ 	 MEETEI MAYEK VOWEL SIGN SOUNAP
    '\uABE7',
    //  ꯩ 	 MEETEI MAYEK VOWEL SIGN CHEINAP
    '\uABE9',
    //  ꯪ 	 MEETEI MAYEK VOWEL SIGN NUNG
    '\uABEA',

    // ========
    //  Meetei Mayek — Punctuation items: 1
    // ========

    //  ꯬ 	 MEETEI MAYEK LUM IYEK
    '\uABEC',

    // ========
    //  Brahmi — Various signs items: 2
    // ========

    //  𑀀 	 BRAHMI SIGN CANDRABINDU
    '\u1100',
    //  𑀂 	 BRAHMI SIGN VISARGA
    '\u1100',

    // ========
    //  Kaithi — Various signs items: 1
    // ========

    //  𑂂 	 KAITHI SIGN VISARGA
    '\u1108',

    // ========
    //  Kaithi — Dependent vowel sign items: 4
    // ========

    //  𑂰 	 KAITHI VOWEL SIGN AA
    '\u110B',
    //  𑂲 	 KAITHI VOWEL SIGN II
    '\u110B',
    //  𑂷 	 KAITHI VOWEL SIGN O
    '\u110B',
    //  𑂸 	 KAITHI VOWEL SIGN AU
    '\u110B',

    // ========
    //  Chakma — Dependent vowel sign items: 2
    // ========

    //  𑅅 	 CHAKMA VOWEL SIGN AA
    '\u1114',
    //  𑅆 	 CHAKMA VOWEL SIGN EI
    '\u1114',

    // ========
    //  Sharada — Various signs items: 1
    // ========

    //  𑆂 	 SHARADA SIGN VISARGA
    '\u1118',

    // ========
    //  Sharada — Dependent vowel sign items: 2
    // ========

    //  𑆳 	 SHARADA VOWEL SIGN AA
    '\u111B',
    //  𑆵 	 SHARADA VOWEL SIGN II
    '\u111B',

    // ========
    //  Sharada — Virama items: 1
    // ========

    //  𑇀 	 SHARADA SIGN VIRAMA
    '\u111C',

    // ========
    //  Khojki — Dependent vowel sign items: 3
    // ========

    //  𑈬 	 KHOJKI VOWEL SIGN AA
    '\u1122',
    //  𑈭 	 KHOJKI VOWEL SIGN I
    '\u1122',
    //  𑈮 	 KHOJKI VOWEL SIGN II
    '\u1122',

    // ========
    //  Khojki — Various signs items: 1
    // ========

    //  𑈵 	 KHOJKI SIGN VIRAMA
    '\u1123',

    // ========
    //  Khudawadi — Dependent vowel sign items: 2
    // ========

    //  𑋠 	 KHUDAWADI VOWEL SIGN AA
    '\u112E',
    //  𑋢 	 KHUDAWADI VOWEL SIGN II
    '\u112E',

    // ========
    //  Grantha — Various signs items: 2
    // ========

    //  𑌂 	 GRANTHA SIGN ANUSVARA
    '\u1130',
    //  𑌃 	 GRANTHA SIGN VISARGA
    '\u1130',

    // ========
    //  Grantha — Dependent vowel sign items: 9
    // ========

    //  𑌾 	 GRANTHA VOWEL SIGN AA
    '\u1133',
    //  𑌿 	 GRANTHA VOWEL SIGN I
    '\u1133',
    //  𑍁 	 GRANTHA VOWEL SIGN U
    '\u1134',
    //  𑍂 	 GRANTHA VOWEL SIGN UU
    '\u1134',
    //  𑍃 	 GRANTHA VOWEL SIGN VOCALIC R
    '\u1134',
    //  𑍄 	 GRANTHA VOWEL SIGN VOCALIC RR
    '\u1134',
    //  𑍗 	 GRANTHA AU LENGTH MARK
    '\u1135',
    //  𑍢 	 GRANTHA VOWEL SIGN VOCALIC L
    '\u1136',
    //  𑍣 	 GRANTHA VOWEL SIGN VOCALIC LL
    '\u1136',

    // ========
    //  Grantha — Virama items: 1
    // ========

    //  𑍍 	 GRANTHA SIGN VIRAMA
    '\u1134',

    // ========
    //  Newa — Dependent vowel sign items: 4
    // ========

    //  𑐵 	 NEWA VOWEL SIGN AA
    '\u1143',
    //  𑐷 	 NEWA VOWEL SIGN II
    '\u1143',
    //  𑑀 	 NEWA VOWEL SIGN O
    '\u1144',
    //  𑑁 	 NEWA VOWEL SIGN AU
    '\u1144',

    // ========
    //  Newa — Various signs items: 1
    // ========

    //  𑑅 	 NEWA SIGN VISARGA
    '\u1144',

    // ========
    //  Tirhuta — Dependent vowel sign items: 3
    // ========

    //  𑒰 	 TIRHUTA VOWEL SIGN AA
    '\u114B',
    //  𑒲 	 TIRHUTA VOWEL SIGN II
    '\u114B',
    //  𑒽 	 TIRHUTA VOWEL SIGN SHORT O
    '\u114B',

    // ========
    //  Tirhuta — Various signs items: 1
    // ========

    //  𑓁 	 TIRHUTA SIGN VISARGA
    '\u114C',

    // ========
    //  Siddham — Dependent vowel sign items: 2
    // ========

    //  𑖯 	 SIDDHAM VOWEL SIGN AA
    '\u115A',
    //  𑖱 	 SIDDHAM VOWEL SIGN II
    '\u115B',

    // ========
    //  Siddham — Various signs items: 1
    // ========

    //  𑖾 	 SIDDHAM SIGN VISARGA
    '\u115B',

    // ========
    //  Modi — Dependent vowel sign items: 5
    // ========

    //  𑘰 	 MODI VOWEL SIGN AA
    '\u1163',
    //  𑘱 	 MODI VOWEL SIGN I
    '\u1163',
    //  𑘲 	 MODI VOWEL SIGN II
    '\u1163',
    //  𑘻 	 MODI VOWEL SIGN O
    '\u1163',
    //  𑘼 	 MODI VOWEL SIGN AU
    '\u1163',

    // ========
    //  Modi — Various signs items: 1
    // ========

    //  𑘾 	 MODI SIGN VISARGA
    '\u1163',

    // ========
    //  Takri — Various signs items: 1
    // ========

    //  𑚬 	 TAKRI SIGN VISARGA
    '\u116A',

    // ========
    //  Takri — Dependent vowel sign items: 1
    // ========

    //  𑚯 	 TAKRI VOWEL SIGN II
    '\u116A',

    // ========
    //  Takri — Virama items: 1
    // ========

    //  𑚶 	 TAKRI SIGN VIRAMA
    '\u116B',

    // ========
    //  Ahom — Vowel sign items: 2
    // ========

    //  𑜠 	 AHOM VOWEL SIGN A
    '\u1172',
    //  𑜡 	 AHOM VOWEL SIGN AA
    '\u1172',

    // ========
    //  Dogra — Dependent vowel sign items: 2
    // ========

    //  𑠬 	 DOGRA VOWEL SIGN AA
    '\u1182',
    //  𑠮 	 DOGRA VOWEL SIGN II
    '\u1182',

    // ========
    //  Dogra — Various signs items: 1
    // ========

    //  𑠸 	 DOGRA SIGN VISARGA
    '\u1183',

    // ========
    //  Dives Akuru — Dependent vowel sign items: 5
    // ========

    //  𑤰 	 DIVES AKURU VOWEL SIGN AA
    '\u1193',
    //  𑤱 	 DIVES AKURU VOWEL SIGN I
    '\u1193',
    //  𑤲 	 DIVES AKURU VOWEL SIGN II
    '\u1193',
    //  𑤳 	 DIVES AKURU VOWEL SIGN U
    '\u1193',
    //  𑤴 	 DIVES AKURU VOWEL SIGN UU
    '\u1193',

    // ========
    //  Dives Akuru — Sign items: 1
    // ========

    //  𑤽 	 DIVES AKURU SIGN HALANTA
    '\u1193',

    // ========
    //  Dives Akuru — Conjunct-specific letters items: 1
    // ========

    //  𑥀 	 DIVES AKURU MEDIAL YA
    '\u1194',

    // ========
    //  Nandinagari — Dependent vowel sign items: 4
    // ========

    //  𑧑 	 NANDINAGARI VOWEL SIGN AA
    '\u119D',
    //  𑧓 	 NANDINAGARI VOWEL SIGN II
    '\u119D',
    //  𑧜 	 NANDINAGARI VOWEL SIGN O
    '\u119D',
    //  𑧝 	 NANDINAGARI VOWEL SIGN AU
    '\u119D',

    // ========
    //  Nandinagari — Various signs items: 2
    // ========

    //  𑧞 	 NANDINAGARI SIGN ANUSVARA
    '\u119D',
    //  𑧟 	 NANDINAGARI SIGN VISARGA
    '\u119D',

    // ========
    //  Zanabazar Square — Signs for Sanskrit items: 1
    // ========

    //  𑨹 	 ZANABAZAR SQUARE SIGN VISARGA
    '\u11A3',

    // ========
    //  Soyombo — Vowel sign items: 2
    // ========

    //  𑩗 	 SOYOMBO VOWEL SIGN AI
    '\u11A5',
    //  𑩘 	 SOYOMBO VOWEL SIGN AU
    '\u11A5',

    // ========
    //  Soyombo — Various signs items: 1
    // ========

    //  𑪗 	 SOYOMBO SIGN VISARGA
    '\u11A9',

    // ========
    //  Bhaiksuki — Dependent vowel sign items: 1
    // ========

    //  𑰯 	 BHAIKSUKI VOWEL SIGN AA
    '\u11C2',

    // ========
    //  Bhaiksuki — Various signs items: 1
    // ========

    //  𑰾 	 BHAIKSUKI SIGN VISARGA
    '\u11C3',

    // ========
    //  Marchen — Subjoined letters items: 1
    // ========

    //  𑲩 	 MARCHEN SUBJOINED LETTER YA
    '\u11CA',

    // ========
    //  Marchen — Dependent vowel sign items: 1
    // ========

    //  𑲴 	 MARCHEN VOWEL SIGN O
    '\u11CB',

    // ========
    //  Masaram Gondi — Cluster-specific consonant forms items: 1
    // ========

    //  𑵆 	 MASARAM GONDI REPHA
    '\u11D4',

    // ========
    //  Gunjala Gondi — Dependent vowel sign items: 7
    // ========

    //  𑶊 	 GUNJALA GONDI VOWEL SIGN AA
    '\u11D8',
    //  𑶋 	 GUNJALA GONDI VOWEL SIGN I
    '\u11D8',
    //  𑶌 	 GUNJALA GONDI VOWEL SIGN II
    '\u11D8',
    //  𑶍 	 GUNJALA GONDI VOWEL SIGN U
    '\u11D8',
    //  𑶎 	 GUNJALA GONDI VOWEL SIGN UU
    '\u11D8',
    //  𑶓 	 GUNJALA GONDI VOWEL SIGN OO
    '\u11D9',
    //  𑶔 	 GUNJALA GONDI VOWEL SIGN AU
    '\u11D9',

    // ========
    //  Gunjala Gondi — Various signs items: 1
    // ========

    //  𑶖 	 GUNJALA GONDI SIGN VISARGA
    '\u11D9',

    // ========
    //  Makasar — Vowel sign items: 1
    // ========

    //  𑻶 	 MAKASAR VOWEL SIGN O
    '\u11EF',
  ],
};

/**
 * Returns the indic positional category of a character
 *
 * @param {string} character A character to check
 * @returns {string|null} The name of the associated indic poisitional category
 */
export const getIndicPositionalCategory = (character) => {
  const categories = Object.keys(IndicPositionalCategories);
  for (let index = 0; index < categories.length; index += 1) {
    const category = categories[index];
    if (IndicPositionalCategories[category].includes(character)) {
      return category;
    }
  }

  return null;
};
