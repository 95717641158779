/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { createSelector, createSlice } from 'redux-starter-kit';

import {
  createTemplate,
  fetchTemplate,
  fetchVersion,
  getAfterEffectsProject,
  getAfterEffectsProjects,
  getOrCreateUnpublishedVersion,
  uploadAfterEffectsProject,
  updateTemplate,
  updateVersion,
} from '../api/index.js';
import { importStatuses, templateImportStatus, templateQueueStatus } from '../constants/index.js';
import templatesSlice from './templates.js';

/**
 * Get the template import status from an API After Effects project import status.
 *
 * @param   {number}  status  After Effects import API status
 * @return  {string}          Template import status
 */
const getTemplateImportStatusForAPIStatus = (status) => {
  switch (status) {
    case importStatuses.waiting:
      return templateImportStatus.queued;
    case importStatuses.inProgress:
      return templateImportStatus.creatingTemplate;
    case importStatuses.complete:
      return templateImportStatus.complete;
    default:
      return templateImportStatus.failed;
  }
}

/**
 * `templateImport` slice - small slice for managing the after effects templateImport process.
 *
 * SLICE DEPENDENCIES:
 *  - `templates`
 */
const templateImport = createSlice({
  slice: 'templateImport',
  initialState: {
    status: templateImportStatus.none,
    error: null,
  },
  reducers: {
    setQueueStatus: (state, action) => {
      state.queueStatus = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    importFailed: (state, action) => {
      state.error = action.payload;
      state.status = templateImportStatus.failed;
    },
    resetImport: (state) => {
      state.status = templateImportStatus.none;
      state.error = null;
    },
  },
});

templateImport.selectors.getImportStatus = createSelector(['templateImport.status']);
templateImport.selectors.getImportError = createSelector(['templateImport.error']);
templateImport.selectors.getQueueStatus = createSelector(['templateImport.queueStatus']);

const TEMPLATE_POLLING_INTERVAL_MS = 2500;

templateImport.operations = {
  watchQueue() {
    return async (dispatch) => {
      let projects = await getAfterEffectsProjects();

      let waitingProject = projects.data.find(({ status }) => status === importStatuses.waiting);
      if (waitingProject) {
        dispatch(templateImport.actions.setQueueStatus(
          templateQueueStatus.queued,
        ));
        while (waitingProject) {
          /* eslint-disable-next-line no-await-in-loop */
          await new Promise((res) => { setTimeout(res, TEMPLATE_POLLING_INTERVAL_MS); });
          /* eslint-disable-next-line no-await-in-loop */
          projects = await getAfterEffectsProjects();
          waitingProject = projects.data.find(({ status }) => status === importStatuses.waiting);
        }
      }

      let inProgressProject = projects.data.find(
        ({ status }) => status === importStatuses.inProgress,
      );
      if (inProgressProject) {
        dispatch(templateImport.actions.setQueueStatus(templateQueueStatus.creatingTemplate));
        while (inProgressProject) {
          /* eslint-disable-next-line no-await-in-loop */
          await new Promise((res) => { setTimeout(res, TEMPLATE_POLLING_INTERVAL_MS); });
          /* eslint-disable-next-line no-await-in-loop */
          projects = await getAfterEffectsProjects();
          inProgressProject = projects.data.find(
            ({ status }) => status === importStatuses.inProgress,
          );
        }
      }

      dispatch(templateImport.actions.setQueueStatus(templateQueueStatus.none));
    }
  },
  createTemplateFromConfirmationCode(confirmationCode) {
    return async (dispatch) => {
      try {
        dispatch(templateImport.actions.setStatus(templateImportStatus.uploading));
        let aeImportRes = await uploadAfterEffectsProject(confirmationCode);

        // Don't retrieve the status from the uploadAfterEffectsProject response or immediately set
        // it as the status--it is expected to be 0 (waiting) regardless of whether or not there is
        // another upload with higher priority in the queue because the post-upload import process
        // has not begun yet.
        let lastStatus = null;

        while (aeImportRes.data.status !== importStatuses.complete) {
          if (aeImportRes.data.status === importStatuses.failed) {
            throw new Error(`Import failed with error message ${aeImportRes.data.error}`);
          }

          /* eslint-disable-next-line no-await-in-loop */
          await new Promise((res) => { setTimeout(res, TEMPLATE_POLLING_INTERVAL_MS); });
          /* eslint-disable-next-line no-await-in-loop */
          aeImportRes = await getAfterEffectsProject(aeImportRes.data.id);

          if (lastStatus !== aeImportRes.data.status) {
            lastStatus = aeImportRes.data.status;
            dispatch(
              templateImport.actions.setStatus(getTemplateImportStatusForAPIStatus(lastStatus)),
            );
          }
        }

        dispatch(templateImport.actions.setStatus(templateImportStatus.queued));

        dispatch(templateImport.actions.setStatus(templateImportStatus.creatingTemplate));
        const newTemplateRes = await createTemplate();
        const newTemplate = newTemplateRes.data;

        dispatch(templateImport.actions.setStatus(templateImportStatus.creatingVersion));
        const unpublishedVersionRes = await getOrCreateUnpublishedVersion(newTemplate.id);

        dispatch(templateImport.actions.setStatus(templateImportStatus.addingImportToVersion));
        await updateVersion(newTemplate.id, unpublishedVersionRes.data.version, {
          afterEffectsImport: aeImportRes.data.id,
        });

        // Re-fetching template to make sure it's fully serialized with the new version data
        const fullTemplateResponse = await fetchTemplate(newTemplate.id);
        const fullTemplate = fullTemplateResponse.data;
        dispatch(templatesSlice.actions.receivedTemplate({ template: fullTemplate }));

        dispatch(templateImport.actions.setStatus(templateImportStatus.complete));

        return fullTemplate;
      } catch (e) {
        console.error(e);
        const errorBody = _.get(e, 'response.data', '');
        dispatch(templateImport.actions.importFailed(`${e.message}. ${errorBody.message || errorBody}`));
        return null;
      }
    };
  },
  deleteTemplate(template) {
    return async (dispatch) => {
      const { data: updatedTemplate } = await updateTemplate(template.id, { isHidden: true });
      dispatch(templatesSlice.actions.receivedTemplate({ template: updatedTemplate }));
    };
  },
  duplicateTemplate(template) {
    return async (dispatch, getState) => {
      const storeState = getState();
      const latestVersionNumber =
        templatesSlice.selectors.getLatestVersionByTemplateId(storeState, template.id);

      const latestVersionResponse =
        await fetchVersion(template.id, latestVersionNumber, ['templateManifest']);

      const {
        mostRecentImport: sourceAfterEffectsImportID,
        templateManifest,
      } = latestVersionResponse.data;

      try {
        dispatch(templateImport.actions.setStatus(templateImportStatus.queued));
        dispatch(templateImport.actions.setStatus(templateImportStatus.creatingTemplate));
        const newTemplateRes = await createTemplate({
          name: `${template.name} (copy)`,
        });
        const newTemplate = newTemplateRes.data;

        dispatch(templateImport.actions.setStatus(templateImportStatus.creatingVersion));
        const unpublishedVersionRes = await getOrCreateUnpublishedVersion(newTemplate.id);

        dispatch(templateImport.actions.setStatus(templateImportStatus.addingImportToVersion));
        const versionOptions = {
          afterEffectsImport: sourceAfterEffectsImportID,
          templateManifest,
        };

        await updateVersion(newTemplate.id, unpublishedVersionRes.data.version, versionOptions);

        // Re-fetching template to make sure it's fully serialized with the new version data
        const fullTemplateResponse = await fetchTemplate(newTemplate.id);
        const fullTemplate = fullTemplateResponse.data;

        dispatch(templatesSlice.actions.receivedTemplate({ template: fullTemplate }));
        dispatch(templatesSlice.operations.fetchTemplateById(fullTemplate.id));
        dispatch(templateImport.actions.setStatus(templateImportStatus.complete));

        return fullTemplate;
      } catch (e) {
        console.error(e);
        const errorBody = _.get(e, 'response.data', '');
        dispatch(templateImport.actions.importFailed(
          `${e.message}. ${errorBody.message || errorBody}`,
        ));
        return null;
      }
    };
  },
};

export default templateImport;
