import cxs from 'cxs';
import cxsComponent from 'cxs/component.js';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { templateSettingTypes } from '../constants/index.js';
import { darkBackground, defaultText, selectedTextBackground, selectedText } from './lib/colors.js';
import CollapsibleMenu from './CollapsibleMenu.js';
import { actions, selectors } from '../store/index.js';

const ListItem = cxsComponent('li')((props) => ({
  backgroundColor: props.isSelected ? selectedTextBackground : 'inherit',
  color: props.isSelected ? selectedText : defaultText,
  padding: '10px 6px 10px 13px',
  borderTop: `solid 1px ${darkBackground}`,
  'last-child': {
    borderBottom: `solid 1px ${darkBackground}`,
  },
  ':hover': {
    cursor: 'pointer',
  },
}));

ListItem.propTypes = {
  isSelected: PropTypes.bool.isRequired,
};

const nameProperties = {
  fontSize: '12px',
};

const selectedNameStyles = cxs({
  ...nameProperties,
  color: selectedText,
});

const unselectedNameStyles = cxs({
  ...nameProperties,
  color: defaultText,
});

const descriptionStyles = cxs({
  fontSize: '10px',
  color: '#646464',
});

/**
 * Selectable setting menu item
 * @param  {string}   name          Name of the setting category
 * @param  {string}   description   Description of what this controls
 * @param  {string}   settingType   The type of setting this item corresponds to
 */
const TemplateSettingItem = ({ name, description, settingType }) => {
  const selectedSettingType = useSelector(selectors.getSelectedTemplateSetting);
  const isSelected = settingType === selectedSettingType;
  const dispatch = useDispatch();

  const onClickSetting = () => {
    dispatch(actions.selectTemplateSetting(settingType));
  };

  return (
    <ListItem isSelected={isSelected} onClick={onClickSetting}>
      <div className={isSelected ? selectedNameStyles : unselectedNameStyles}>{name}</div>
      <div className={descriptionStyles}>{description}</div>
    </ListItem>
  );
};

TemplateSettingItem.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  settingType: PropTypes.string.isRequired,
};

const settingsListStyle = cxs({
  listStyle: 'none',
  margin: 0,
  paddingLeft: 0,
});

/**
 * Displays the collapsable list of "settings" for the templats:
 */
const TemplateSettingsList = () => (
  <CollapsibleMenu title="Template Settings">
    <ul className={settingsListStyle}>
      <TemplateSettingItem
        name="Basic settings"
        description="Template name, aspect ratio, and license"
        settingType={templateSettingTypes.basic}
      />
      <TemplateSettingItem
        name="Overrides"
        description="Bulk change text, images, and colors"
        settingType={templateSettingTypes.overrides}
      />
      <TemplateSettingItem
        name="Groups"
        description="Group layers together and share frame numbers"
        settingType={templateSettingTypes.groups}
      />
      <TemplateSettingItem
        name="Switches"
        description="Enable switching between groups"
        settingType={templateSettingTypes.switches}
      />
      <TemplateSettingItem
        name="Music tracks"
        description="Add and modify music tracks"
        settingType={templateSettingTypes.music}
      />
    </ul>
  </CollapsibleMenu>
);

export default TemplateSettingsList;
