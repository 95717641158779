/* global API_SERVER */
import axios from 'axios';

/**
 * Create a new videoTemplateAudio record
 * @param  {object} formData  Multipart form data
 */
export const createVideoTemplateAudio = (formData) =>
  axios.post(`${API_SERVER}/api/video-template-audio/`, formData);

/**
 * Retrieves the audio resource
 * @param  {string} publicID  Id
 */
export const getVideoTemplateAudioFile = (publicId) =>
  axios.get(`${API_SERVER}/api/video-template-audio/${publicId}/`);
