import cxs from 'cxs';
import cxsComponent from 'cxs/component.js';

export const desktopBreakpoint = 768;
export const classnamePrefix = 'wawr-controls-';
export const desktopMediaQuery = `@media screen and (min-width: ${desktopBreakpoint}px)`;

cxs.prefix(classnamePrefix);

export const BaseButton = cxsComponent('button')({
  background: 'none',
  color: 'inherit',
  border: 'none',
  padding: '0',
  font: 'inherit',
  cursor: 'pointer',
});
