import React from 'react';
import PropTypes from 'prop-types';

import { darkIcon, lightIcon } from './colors.js';

export const wtsIconTypes = {
  audio: 'audio',
  color: 'color',
  composition: 'composition',
  image: 'image',
  override: 'override',
  preComp: 'preComp',
  null: 'null',
  shape: 'shape',
  solid: 'solid',
  text: 'text',
  video: 'video',
  waymarkAudio: 'waymarkAudio',
  waymarkVideo: 'waymarkVideo',
  font: 'font',
};

const wtsIconColors = {
  dark: darkIcon,
  light: lightIcon,
};

const ColorIcon = ({ className, fillColor }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="15"
    viewBox="0 0 16 15"
  >
    <title>Color</title>
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M3.5 9.16C3.5 6.763 8 1.5 8 1.5s4.5 5.263 4.5 7.66c0 2.397-2.015 4.34-4.5 4.34s-4.5-1.943-4.5-4.34zM8 11.5c-1.385 0-2.5-1.076-2.5-2.393a.5.5 0 1 0-1 0C4.5 10.985 6.071 12.5 8 12.5a.5.5 0 1 0 0-1z"
    />
  </svg>
);

ColorIcon.propTypes = {
  className: PropTypes.string.isRequired,
  fillColor: PropTypes.string.isRequired,
};

const CompositionIcon = ({ className, fillColor }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="15"
    viewBox="0 0 16 15"
  >
    <title>Composition</title>
    <g fill="none" fillRule="evenodd">
      <path
        fill={fillColor}
        d="M.5 1h15a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-12A.5.5 0 0 1 .5 1zm4.962 7.485L4.436 9.922a1 1 0 0 0 .814 1.581h5A1 1 0 0 0 11.115 10h.385a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1h-3c-.034 0-.069.002-.102.005a2.751 2.751 0 1 0-2.936 3.48zM14 2v2h1V2h-1zm0 3v2h1V5h-1zm0 3v2h1V8h-1zm0 3v2h1v-2h-1zM1 2v2h1V2H1zm0 3v2h1V5H1zm0 3v2h1V8H1zm0 3v2h1v-2H1z"
      />
      <path
        fill="#2D8BEB"
        fillRule="nonzero"
        d="M7.476 6.038a.99.99 0 0 0-.54.381l-.728 1.02A1.752 1.752 0 0 1 4 5.75a1.75 1.75 0 1 1 3.476.288z"
      />
      <path
        fill="#F53C3C"
        fillRule="nonzero"
        d="M10.406 9L8.564 6.42a1.028 1.028 0 0 0-.064-.082V6h3v3h-1.094z"
      />
      <path fill="#139B15" fillRule="nonzero" d="M7.75 7l-2.5 3.503h5z" />
    </g>
  </svg>
);

CompositionIcon.propTypes = {
  className: PropTypes.string.isRequired,
  fillColor: PropTypes.string.isRequired,
};

const ImageIcon = ({ className, fillColor }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16"
    height="15"
    viewBox="0 0 16 15"
  >
    <title>Image</title>
    <defs>
      <path id="b" d="M9 1l4 4H9.25A.25.25 0 0 1 9 4.75V1z" />
      <filter
        id="a"
        width="187.5%"
        height="187.5%"
        x="-56.2%"
        y="-31.2%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dx="-.5" dy=".5" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation=".5" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill={fillColor}
        d="M3.25 1h5.646a.25.25 0 0 1 .177.073l3.854 3.854a.25.25 0 0 1 .073.177v8.646a.25.25 0 0 1-.25.25h-9.5a.25.25 0 0 1-.25-.25V1.25A.25.25 0 0 1 3.25 1z"
      />
      <g transform="translate(5 3)">
        <rect width="5" height="4" fill="#509DF7" rx=".6" />
        <rect width="5" height="4" x="1" y="1" fill="#E8920D" rx=".6" />
        <path
          fill="#B53938"
          fillOpacity=".377"
          d="M1 4h5v.4a.6.6 0 0 1-.6.6H1.6a.6.6 0 0 1-.6-.6V4z"
        />
      </g>
      <use fill="#000" filter="url(#a)" xlinkHref="#b" />
      <use fill={fillColor} xlinkHref="#b" />
      <text
        fill="#303030"
        fontFamily="SFCompactText-Medium, SF Compact Text"
        fontSize="4"
        fontWeight="400"
      >
        <tspan x="4.472" y="12">
          img
        </tspan>
      </text>
    </g>
  </svg>
);

ImageIcon.propTypes = {
  className: PropTypes.string.isRequired,
  fillColor: PropTypes.string.isRequired,
};

/* eslint-disable-next-line no-unused-vars */
const OverrideIcon = ({ className, fillColor }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15">
    <title>Override</title>
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M6.997 8.06l1.469 1.47a.75.75 0 0 0 1.06 0l1.472-1.47 1.472 1.47a.75.75 0 0 0 1.06-1.06l-2.002-2a.75.75 0 0 0-1.06 0L8.996 7.94 7.527 6.47a.75.75 0 0 0-1.06 0L4.998 7.939l-1.467-1.47A.75.75 0 0 0 2.469 7.53l1.998 2a.75.75 0 0 0 1.061 0l1.469-1.47z"
    />
  </svg>
);

OverrideIcon.propTypes = {
  className: PropTypes.string.isRequired,
  fillColor: PropTypes.string.isRequired,
};

const SolidIcon = ({ className, fillColor }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="15"
    viewBox="0 0 16 15"
  >
    <title>Solid</title>
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M8 10.52l-3.192 2.113a.15.15 0 0 1-.228-.165l1.023-3.69-2.995-2.382a.15.15 0 0 1 .086-.268l3.825-.167 1.34-3.585a.15.15 0 0 1 .281 0L9.481 5.96l3.825.167a.15.15 0 0 1 .086.268l-2.995 2.383 1.023 3.689a.15.15 0 0 1-.228.165L8 10.52z"
    />
  </svg>
);

SolidIcon.propTypes = {
  className: PropTypes.string.isRequired,
  fillColor: PropTypes.string.isRequired,
};

const TextIcon = ({ className, fillColor }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="15"
    viewBox="0 0 16 15"
  >
    <title>Text</title>
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M9 4v7h.75a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-3.5a.25.25 0 0 1-.25-.25v-.5a.25.25 0 0 1 .25-.25H7V4H5v.75a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 4 4.75v-1.5A.25.25 0 0 1 4.25 3h7.5a.25.25 0 0 1 .25.25v1.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25V4H9z"
    />
  </svg>
);

TextIcon.propTypes = {
  className: PropTypes.string.isRequired,
  fillColor: PropTypes.string.isRequired,
};

const PreCompIcon = ({ className, fillColor }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="15"
    viewBox="0 0 16 15"
  >
    <title>Precomp</title>
    <g id="intersection_1_">
      <g>
        <path
          fill={fillColor}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10,3C9.08,3,8.24,3.26,7.5,3.69C6.76,3.26,5.92,3,5,3C2.24,3,0,5.24,0,8
          s2.24,5,5,5c0.92,0,1.76-0.26,2.5-0.69C8.24,12.74,9.08,13,10,13c2.76,0,5-2.24,5-5S12.76,3,10,3z M5.9,10.85
          C5.61,10.94,5.31,11,5,11c-1.66,0-3-1.34-3-3s1.34-3,3-3c0.31,0,0.61,0.06,0.9,0.15C5.33,5.96,5,6.94,5,8
          C5,9.06,5.33,10.04,5.9,10.85z M10,11c-0.31,0-0.61-0.06-0.9-0.15C9.67,10.04,10,9.06,10,8c0-1.06-0.33-2.04-0.9-2.85
          C9.39,5.06,9.69,5,10,5c1.66,0,3,1.34,3,3S11.66,11,10,11z"
        />
      </g>
    </g>
  </svg>
)

PreCompIcon.propTypes = {
  className: PropTypes.string.isRequired,
  fillColor: PropTypes.string.isRequired,
};

const NullIcon = ({ className, fillColor }) => (
  <svg
    className={className}
    width="12"
    height="10"
    viewBox="0 0 16 15"
  >
    <title>Null</title>
    <g id="cross_mark_5_">
      <g>
        <path
          fill={fillColor}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.41,8l3.29-3.29C12.89,4.53,13,4.28,13,4c0-0.55-0.45-1-1-1
          c-0.28,0-0.53,0.11-0.71,0.29L8,6.59L4.71,3.29C4.53,3.11,4.28,3,4,3C3.45,3,3,3.45,3,4c0,0.28,0.11,0.53,0.29,0.71L6.59,8
          l-3.29,3.29C3.11,11.47,3,11.72,3,12c0,0.55,0.45,1,1,1c0.28,0,0.53-0.11,0.71-0.29L8,9.41l3.29,3.29C11.47,12.89,11.72,13,12,13
          c0.55,0,1-0.45,1-1c0-0.28-0.11-0.53-0.29-0.71L9.41,8z"
        />
      </g>
    </g>
  </svg>
)

NullIcon.propTypes = {
  className: PropTypes.string.isRequired,
  fillColor: PropTypes.string.isRequired,
};

const WaymarkVideoIcon = ({ className, fillColor }) => (
  <svg
    className={className}
    width="16"
    height="15"
    viewBox="0 0 16 15"
  >
    <title>Video</title>
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M8 13.5a6 6 0 1 1 0-12 6 6 0 0 1 0 12zm2.643-5.786a.25.25 0 0 0 0-.428L6.379 4.727A.25.25 0 0 0 6 4.942v5.116a.25.25 0 0 0 .379.215l4.264-2.559z"
    />
  </svg>
)

WaymarkVideoIcon.propTypes = {
  className: PropTypes.string.isRequired,
  fillColor: PropTypes.string.isRequired,
};

const FontIcon = ({ className, fillColor }) => (
  <svg
    className={className}
    x="0px"
    y="0px"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <title>Font</title>
    <g>
      <g>
        <path
          d="M9.8,4H11c0.5,0,1-0.4,1-1c0-0.6-0.4-1-1-1H7C6.5,2,6,2.4,6,3c0,0.6,0.4,1,1,1h0.8l-1.6,8H5 c-0.5,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4c0.5,0,1-0.4,1-1c0-0.6-0.4-1-1-1H8.2L9.8,4z"
          fill={fillColor}
        />
      </g>
    </g>
  </svg>
)

FontIcon.propTypes = {
  className: PropTypes.string.isRequired,
  fillColor: PropTypes.string.isRequired,
};

/**
 * Renders Waymark Template Stuido SVG Icons.
 * All icons have a light and a dark theme.
 * All icons accept a className for additional styling.
 */
const WTSIcon = ({ className, iconType, theme }) => {
  const fillColor = wtsIconColors[theme];

  switch (iconType) {
    case wtsIconTypes.image:
      return <ImageIcon fillColor={fillColor} className={className} />;

    case wtsIconTypes.override:
      return <OverrideIcon fillColor={fillColor} className={className} />;

    case wtsIconTypes.shape:
      return <SolidIcon fillColor={fillColor} className={className} />;

    case wtsIconTypes.solid:
      return <SolidIcon fillColor={fillColor} className={className} />;

    case wtsIconTypes.text:
      return <TextIcon fillColor={fillColor} className={className} />;

    case wtsIconTypes.color:
      return <ColorIcon fillColor={fillColor} className={className} />;

    case wtsIconTypes.preComp:
      return <PreCompIcon fillColor={fillColor} className={className} />;

    case wtsIconTypes.null:
      return <NullIcon fillColor={fillColor} className={className} />;

    case wtsIconTypes.waymarkVideo:
      return <WaymarkVideoIcon fillColor={fillColor} className={className} />;

    case wtsIconTypes.font:
      return <FontIcon fillColor={fillColor} className={className} />;

    default:
      // SS TODO: We do not have icons for each layer type yet, specifically
      // will need shape and audio.
      return <CompositionIcon fillColor={fillColor} className={className} />;
  }
};

WTSIcon.propTypes = {
  className: PropTypes.string,
  iconType: PropTypes.oneOf([
    wtsIconTypes.audio,
    wtsIconTypes.color,
    wtsIconTypes.composition,
    wtsIconTypes.preComp,
    wtsIconTypes.image,
    wtsIconTypes.null,
    wtsIconTypes.override,
    wtsIconTypes.shape,
    wtsIconTypes.solid,
    wtsIconTypes.text,
    wtsIconTypes.video,
    wtsIconTypes.waymarkAudio,
    wtsIconTypes.waymarkVideo,
    wtsIconTypes.font,
  ]).isRequired,
  theme: PropTypes.oneOf(['dark', 'light']),
};

WTSIcon.defaultProps = {
  className: '',
  theme: 'light',
};

export default WTSIcon;
