/**
 * The base of a configuration schema. All dynamic properties live
 * in the "properties" object.
 */
export const configurationSchemaBase = {
  definitions: {},
  $schema: 'http://json-schema.org/draft-07/schema#',
  $id: 'configurationSchema',
  type: 'object',
  properties: {},
};

/**
 * Types of layers that can be present in a template manifest.
 *
 * The keys should be identical to WaymarkAuthorWebRenderer.layerTypes, with the addition of the
 * custom 'composition' layer type that represents the composition data read from a composition
 * asset (the layer that references the comp asset will still have preComp type).
 */
export const layerTypes = {
  audio: 'audio',
  composition: 'composition',
  image: 'image',
  null: 'null',
  preComp: 'preComp',
  shape: 'shape',
  solid: 'solid',
  text: 'text',
  video: 'video',
  waymarkAudio: 'waymarkAudio',
  waymarkVideo: 'waymarkVideo',
};
