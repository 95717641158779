import _ from 'lodash';
import {
  assetTypes,
  getAssetDataType,
  layerTypes as manifestLayerTypes,
} from '@stikdev/waymark-author-web-renderer/manifest.js';

import {
  parseImageAttributes,
  parseVideoAttributes,
  parsePreCompAttributes,
  parseShapeAttributes,
  parseSolidAttributes,
  parseTextLayerAttributes,
} from './projectManifestLayerAttributes.js';
import { layerTypes } from '../constants/TemplateManifest.js';

/**
 * Convert manifestLayerType to human-readable layer type.
 * @param  {int} layerTypeCode    Project manifest layer code.
 */
export const parseLayerType = (layerTypeCode) => _.invert(manifestLayerTypes)[layerTypeCode];

/**
 * Parse a project manifest layer with data specific to the layer type.
 * @param  {object} layer             A project manifest layer.
 * @param  {array} assets             The assets from a project manifest containing
 *                                    the above layer.
 * @param  {object} projectManifest   The originating project manifest.
 * @param  {object} composition       The parent composition
 * @return {object}                   Parsed layer data.
 */
export const parseLayer = (layer, assets, projectManifest, composition) => {
  let layerData = {};
  layerData.name = layer.nm;
  layerData.index = layer.ind;
  layerData.parentCompositionId = composition.compId
  layerData.type = parseLayerType(layer.ty);
  layerData.uuid = layer.meta.uuid;
  layerData.startFrame = Math.round(layer.ip);
  layerData.endFrame = Math.round(layer.op);
  // There is a After Effects/Bodymovin property called 'isHidden' which is translated by the renderer to determine if the layer
  // in question will be rendered. This reflects the current waymark-author-web-renderer logic behind that.
  // If it changes at all in the future, this will have to be altered as it is uncoupled.
  layerData.isRenderable = !layer.hd && ![layerTypes.null, layerTypes.waymarkAudio, layerTypes.audio].includes(layerData.type);
  // This is a best-guess as to when the object is most visible. (e.g. Used when jumping to a frame while editing.)
  // This frame can be overridden by the author in the templateManifest
  layerData.frameNumber = Math.round(((layerData.endFrame - layerData.startFrame) / 2) + layerData.startFrame)

  switch (layer.ty) {
    case manifestLayerTypes.text: {
      const textInfo = parseTextLayerAttributes(layer, projectManifest.fonts.rendererFonts);
      layerData = {
        ...layerData,
        ...textInfo,
      };

      break;
    }

    case manifestLayerTypes.image: {
      const imageInfo = parseImageAttributes(layer, assets);
      layerData = {
        ...layerData,
        ...imageInfo,
      };
      break;
    }

    case manifestLayerTypes.waymarkVideo: {
      const videoInfo = parseVideoAttributes(layer, assets);
      layerData = {
        ...layerData,
        ...videoInfo,
      };
      break;
    }

    case manifestLayerTypes.shape: {
      const shapeInfo = parseShapeAttributes(layer);
      layerData = {
        ...layerData,
        ...shapeInfo,
      };
      break;
    }

    case manifestLayerTypes.solid: {
      const solidInfo = parseSolidAttributes(layer);
      layerData = {
        ...layerData,
        ...solidInfo,
      };
      break;
    }

    case manifestLayerTypes.preComp: {
      const preCompInfo = parsePreCompAttributes(layer)
      layerData = {
        ...layerData,
        ...preCompInfo,
      };
      break;
    }

    // TODO: We will need to parse more data types in the future!
    // audio
    // video
    default: {
      break;
    }
  }

  return layerData;
};

/**
 * Parse template manifest assets array. The assets array currently contains
 * assets (image, audio, video assets), and compositions. Although layers within
 * compositions often reference assets, they need to parsed differently. This
 * function separates the two so they can be used later on.
 * @param  {array} assets
 * Project manifest assets array.
 * @param  {object} projectManifest
 * The originating project manifest. (Currently unused.)
 * @return {object}         An object with parsed assets and parsed compositions.
 */
const parseAssets = (assets = []) =>
  _.reduce(assets, (accum, asset) => {
    const assetType = getAssetDataType(asset);
    if (assetType === assetTypes.composition) {
      accum.compositions.push(asset)
    } else if (assetType === assetTypes.video) {
      accum.assets.push({
        height: asset.h,
        location: asset.location,
        modifications: asset.modifications,
        refId: asset.id,
        type: asset.type,
        width: asset.w,
        name: asset.name,
        footageCompositionFor: asset.footageCompositionFor,
        sourceCompositionId: asset.sourceCompositionId,
      });
    } else {
      accum.assets.push({
        height: asset.h,
        location: asset.location,
        modifications: asset.modifications,
        refId: asset.id,
        type: asset.type,
        width: asset.w,
        name: asset.name,
      });
    }

    return accum;
  }, {assets: [], compositions: []})

/**
 * Parse compositions and their child layers.
 * @param  {array} compositions
 * A project manifest's compositions and child layers.
 * @param  {array} assets
 * Parsed assets that may be referenced by a composition.
 * @param  {object} projectManifest
 * The originating project manifest.
 * @return {array}              [description]
 */
const parseCompositions = (compositions, assets, projectManifest) =>
  compositions.map((composition) => {
    const childLayers = _.reduce(composition.layers, (accum, layer) => {
      accum.push(parseLayer(layer, assets, projectManifest, composition))
      return accum;
    }, [])

    return {
      id: composition.id,
      name: composition.nm || composition.id,
      type: layerTypes.composition,
      childLayers,
    }
  })


// Because the root composition doesn't have an id, we're assigning one for use in the UI
export const primaryCompositionID = 'primary_composition'

/**
 * Parses a project manifest's assets, compositions, and root layers.
 * @param  {object}   projectManifest     JSON file representing a
 *                                        Waymark Author template.
 * @return {array}                        All layers from the project manifest and
 *                                        associated data.
 */
export const parseProjectManifest = (projectManifest) => {
  const { assets, compositions } = parseAssets(projectManifest.assets, projectManifest);
  const parsedCompositions = parseCompositions(_.cloneDeep(compositions), assets, projectManifest);
  // returns an array, that we'll concat
  const rootComposition = parseCompositions([_.cloneDeep(projectManifest)], assets, projectManifest);
  // the root composition doesn't have an id, so we'll assign one for use in the UI
  rootComposition[0].id = primaryCompositionID
  return rootComposition.concat(parsedCompositions)
};


/**
 * Loops through a project manifest and getches layers by the specified type.
 *
 * @param      {Object[]}  layers       The layers
 * @param      {String}   layerType    The layer type
 * @param      {Object[]}  accumulator  The accumulator
 * @return     {Object[]}  The layers filtered by type.
 */
export const getLayersByType = (layers, layerType) => {
  const matchingLayers = []
  _.reduce(
    layers,
    (accum, layer) => {
      if (layer.childLayers) {
        accum.push(...getLayersByType(layer.childLayers, layerType))
      }

      if (layer.type === layerType) {
        accum.push(layer);
      }
      return accum;
    },
    matchingLayers,
  )

  return matchingLayers
}

/**
 * Serialize video template audio as a `waymark-template-studio` plugin asset.
 *
 * @param   {object}  videoTemplateAudio  Video template audio object
 * @return  {asset}                       `waymark-template-studio` plugin asset
 */
export const createAssetFromVideoTemplateAudio = (videoTemplateAudio) => ({
  type: 'audio',
  name: videoTemplateAudio.name,
  id: videoTemplateAudio.id,
  location: {
    plugin: 'waymark-template-studio',
    id: videoTemplateAudio.id,
  }
});

/**
 * Recursively flatten an array of layers which may have childLayers.
 *
 * @param  {[object]} layers Layers
 * @return {[string]}        UUIDs
 */
export const flattenLayers = (layers) => {
  let flattenedLayers = [];
  layers.forEach((layer) => {
    flattenedLayers.push(layer);
    if (layer.childLayers) {
      flattenedLayers = [
        ...flattenedLayers,
        ...flattenLayers(layer.childLayers),
      ];
    }
  });

  return flattenedLayers;
};
