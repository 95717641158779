/* eslint-env browser */
/* global API_SERVER */
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Intent } from '@blueprintjs/core';

import WaymarkAuthorWebRenderer, {
  RendererPlayer,
} from '@stikdev/waymark-author-web-renderer/player';

import store, { actions, selectors } from '../store/index.js';
import {
  createOutlineObject,
  getDisplayObjects,
  getLayerUUIDsUnderInteraction,
  registerInteractiveEvents,
  removeOutlineObject,
  unregisterInteractiveEvents,
} from '../utils/pixiDisplayObject.js';
import VideoPlaybackContextMenu from './VideoPlaybackContextMenu.js';
import { VideoPlaybackContextMenuContext } from './contextProviders/VideoPlaybackContextMenuProvider.js';
import PlayerSizeWrapper from './PlayerSizeWrapper.js';
import ErrorBoundary from './ErrorBoundary.js';
import useSharedRenderer from './useSharedRenderer.js';

WaymarkAuthorWebRenderer.settings.WAYMARK_TEMPLATE_STUDIO_PLUGIN_HOST = API_SERVER;
WaymarkAuthorWebRenderer.settings.ASSET_QUALITY = 'medium';
WaymarkAuthorWebRenderer.settings.EFFECT_QUALITY = 'med';

// Not required for any functionality, but this will silence asset plugin warnings.
WaymarkAuthorWebRenderer.settings.WAYMARK_ENVIRONMENT = 'prod';

window.WaymarkAuthorWebRenderer = WaymarkAuthorWebRenderer;

const VideoPlayback = () => {
  const [clickEventCoordinates, setClickEventCoordinates] = useState({ x: 0, y: 0 });
  const [displayObjectOutlines, setDisplayObjectOutlines] = useState([]);
  const { renderer, setRenderer, setIsRendererSetup } = useSharedRenderer();
  const [canvas, setCanvas] = useState(null);
  const [rendererCurrentFrame, setRendererCurrentFrame] = useState(0);
  const [shouldShowContextMenu, setShouldShowContextMenu] = useContext(
    VideoPlaybackContextMenuContext,
  );
  const [visibleLayers, setVisibleLayers] = useState([]);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const dispatch = useDispatch();
  const allCompositions = useSelector(selectors.getAllCompositions);
  const selectedLayers = useSelector(selectors.getSelectedLayers);
  const version = useSelector(selectors.getActiveVersion);
  const changeId = useSelector(selectors.getManifestChangeId);
  const isWorkspaceSetup = useSelector(selectors.getIsWorkspaceSetup);
  const shouldAllowScrubToSelectedLayer = useSelector(selectors.getShouldAllowScrubToSelectedLayer);
  const projectManifest = version ? version.projectManifest : null;

  // Check for duplicate UUIDs in the project manfiest and show an alert if there are.
  useEffect(() => {
    if (projectManifest) {
      const allUUIDs = [];
      const findUUIDs = (currentProjectManifest) => {
        Object.entries(currentProjectManifest).forEach((projectManifestObject) => {
          const key = projectManifestObject[0];
          const value = projectManifestObject[1];
          if (value instanceof Object || value instanceof Array) {
            findUUIDs(value);
          }

          if (key === 'uuid') {
            allUUIDs.push(value);
          }
        });
      };

      findUUIDs(projectManifest);
      const duplicateUUIDs = _.filter(allUUIDs, (uuid, i, uuids) => _.includes(uuids, uuid, i + 1));
      if (duplicateUUIDs.length) {
        setIsAlertOpen(true);
      }
    }
  }, [projectManifest]);

  // On mount, setup the right click event listener. The event listener
  // also caches the left and right coordinates for the context menu.
  useEffect(() => {
    const registerRightClickEvent = (event) => {
      event.preventDefault();
    };

    if (canvas && canvas.current) {
      // Disable browser context windows on right click.
      /* eslint-disable-next-line no-undef */
      canvas.current.addEventListener('contextmenu', registerRightClickEvent);
    }

    return () => {
      if (canvas && canvas.current) {
        canvas.current.removeEventListener('contextmenu', registerRightClickEvent);
      }
    };
  }, [canvas]);

  // Listens to the renderer and sets up listeners on renderer setUp and renderer frameRender.
  // On setup, enables interactivity on Pixi DisplayObjects and registers click events.
  // On teardown, disables interactivity on Pixi DisplayObjects and unregisters click events.
  useEffect(() => {
    const onFrameRender = ({ frameNumber }) => {
      setRendererCurrentFrame(Math.round(frameNumber));
    };

    const openDisplayObjectContextMenu = (event) => {
      const { clientX, clientY } = event.data.originalEvent;
      setClickEventCoordinates({
        x: clientX,
        y: clientY,
      });

      const clickedLayerUUIDs = getLayerUUIDsUnderInteraction(event, renderer);
      const storeState = store.getState();
      const clickedLayers = clickedLayerUUIDs.map((uuid) =>
        selectors.getLayerByUUID(storeState, uuid),
      );
      setVisibleLayers(clickedLayers);
      setShouldShowContextMenu(true);
    };

    const onClickVideoPlayer = (event) => {
      if (event.data.originalEvent.ctrlKey) {
        openDisplayObjectContextMenu(event);
      } else {
        setShouldShowContextMenu(false);
      }
    };

    const displayObjectMouseEvents = [
      {
        type: 'rightclick',
        loggingCallback: openDisplayObjectContextMenu,
      },
      {
        type: 'click',
        loggingCallback: onClickVideoPlayer,
      },
    ];

    if (renderer) {
      renderer.on('frameRender:end', onFrameRender);
      renderer.on('setup:end', () => {
        /* Resize the renderer to .7 scale for efficiency. Why .7? It was
        found to be the lowest scale at which all text was still legible in
        our inagural 4 Pixi templates. */
        renderer.setScale(0.7);
        // The first item is the primary composition, register all layers below that with interactive events
        allCompositions[0].childLayers.forEach((layer) => {
          registerInteractiveEvents(displayObjectMouseEvents, layer, VideoPlayback, renderer);
        });
        setIsRendererSetup(true);
      });
    }

    return () => {
      if (renderer) {
        renderer.off('frameRender:end', onFrameRender);
        renderer.off('setup:end', () => {
          // The first item is the primary composition, unregister all layers below from interactive events
          allCompositions[0].childLayers.forEach((layer) => {
            unregisterInteractiveEvents(displayObjectMouseEvents, layer, VideoPlayback, renderer);
          });
        });
        // Destroy the current renderer
        renderer.destroy();
        setRenderer(null);
        setIsRendererSetup(false);
      }
    };
  }, [renderer, allCompositions, setClickEventCoordinates, setShouldShowContextMenu]);

  // Listens to selectedLayers, updates object outlines in the video player, and
  // scrubs video player to most recently selected display object.
  useDeepCompareEffect(() => {
    // If our renderer isn't created yet, nothing to do.
    if (!renderer) return;

    const oldOutlines = _.cloneDeep(displayObjectOutlines);
    selectedLayers.forEach((layer) => {
      if (_.includes(oldOutlines, layer)) {
        // remove the layer from oldOutlines so the outline does not get removed.
        oldOutlines.splice(oldOutlines.indexOf(layer), 1);
      } else {
        // The layer does not have an outline, so we can draw it now.
        const foundObjects = getDisplayObjects(layer, renderer);
        try {
          foundObjects.forEach((foundObject) => {
            createOutlineObject(foundObject, renderer);
          });
        } catch (e) {
          console.warn('Error during createOutlineObject: ', e);
        }
      }
    });

    // Remove outlines from any object that is no longer selected.
    oldOutlines.forEach((displayObject) => {
      const foundObjects = getDisplayObjects(displayObject, renderer);
      try {
        foundObjects.forEach((foundObject) => {
          removeOutlineObject(foundObject, renderer);
        });
      } catch (e) {
        console.warn('Error during removeOutlineObject: ', e);
      }
    });

    // Update the current display objects with outlines.
    setDisplayObjectOutlines(selectedLayers);

    // Scrub video to most recently selected layer.
    if (selectedLayers.length && shouldAllowScrubToSelectedLayer) {
      const foundLayer = selectors.getLayerByUUID(
        store.getState(),
        selectedLayers[selectedLayers.length - 1],
      );

      const duration = foundLayer.endFrame - foundLayer.startFrame;
      const startFrameBuffer = foundLayer.startFrame + duration * 0.1;
      const endFrameBuffer = foundLayer.endFrame - duration * 0.1;
      const shouldScrubToFrame =
        rendererCurrentFrame < startFrameBuffer || rendererCurrentFrame > endFrameBuffer;

      if (shouldScrubToFrame) {
        // SS TODO: Scrubbing to the start frame often results in the object
        // not yet being visible on the canvas, so here we're scrubbing to 10%
        // past a layer's start frame. It's better, but not great. This could
        // probably use some tightening up as we build out the studio and have
        // more templates running through it.
        renderer.goToFrame(startFrameBuffer);
      }
    }
  }, [selectedLayers]);

  const onSelectLayer = (layer) => {
    dispatch(actions.deselectAllLayers());
    dispatch(actions.selectLayers({ selectedLayers: [layer.uuid], shouldScrubToLayer: false }));

    // Find the parent composition of the layer selected
    const { id: compositionId } = allCompositions.find(({ childLayers }) =>
      childLayers.find(({ uuid }) => uuid === layer.uuid),
    );
    // Open the composition in the sidebar panel
    dispatch(actions.expandCompositions({ compositions: [compositionId] }));

    setShouldShowContextMenu(false);
  };

  return (
    <>
      {projectManifest && (
        <>
          <Alert
            isOpen={isAlertOpen}
            confirmButtonText="Okay"
            onClose={() => setIsAlertOpen(false)}
            intent={Intent.DANGER}
          >
            DANGER! The project manifest you just uploaded has duplicate UUIDs. Do not proceed until
            duplicate UUIDs have been removed.
          </Alert>
          {isWorkspaceSetup && (
            <PlayerSizeWrapper playerWidth={projectManifest.w} playerHeight={projectManifest.h}>
              <ErrorBoundary>
                <RendererPlayer
                  key={changeId}
                  hasGlobalKeyboardListeners={false}
                  onRendererCreated={setRenderer}
                  onCanvasElementCreated={setCanvas}
                  projectManifest={projectManifest}
                  rendererOptions={{
                    additionalPixiOptions: {
                      backgroundColor: 0x00ff00,
                    },
                  }}
                />
              </ErrorBoundary>
            </PlayerSizeWrapper>
          )}
        </>
      )}
      {shouldShowContextMenu && (
        <VideoPlaybackContextMenu
          contextMenuCoordinates={{ x: clickEventCoordinates.x, y: clickEventCoordinates.y }}
          menuItems={visibleLayers}
          onSelectMenuItem={onSelectLayer}
        />
      )}
    </>
  );
};

export default VideoPlayback;
