/* eslint-env browser */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import cxs from 'cxs';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Flex, Box } from 'reflexbox';
import {
  Button,
  Callout,
  Card,
  Spinner,
  InputGroup,
  Intent,
  Menu,
  MenuItem,
  Popover,
  Position,
} from '@blueprintjs/core';

import { actions, selectors, operations } from '../store/index.js';
import { makeImportant } from '../utils/styles.js';
import {
  historyPropType,
  templatePropType,
  templateGroups,
  templateImportStatus,
  templateQueueStatus,
} from '../constants/index.js';
import { Paragraph, SectionTitle, WTSButton } from './lib/index.js';
import { darkBackground, defaultBackground, defaultText, mutedText } from './lib/colors.js';
import PromptForContinueDialog from './PromptForContinueDialog.js';
import TemplateTag from './TemplateTag.js';

const sharedCardStyleProperties = {
  color: defaultText,
  fontSize: '12px',
};

const templateCardStyle = cxs({
  ...sharedCardStyleProperties,
  backgroundColor: makeImportant(defaultBackground),
  ':hover': {
    cursor: 'pointer',
  },
});

const metaDataStyle = cxs({
  color: mutedText,
  marginTop: '6px',
});

const tagWrapperStyle = cxs({
  marginTop: '3px',
  display: 'inline-block',
});

/**
 * Template display component
 *
 * @param  {Object}       history                     React router history
 * @param  {Boolean}      isMakeACopyEnabled          Is "Make a copy" feature enabled
 * @param  {Method}       onClickDelete               On click delete callback
 * @param  {Method}       onClickMakeACopy            On click make a copy callback
 * @param  {WTSTemplate}  template                    Template object
 * @param  {Date}         template.createdAt          Date the template was created
 * @param  {Date}         template.modifiedAt         Date the template was last updated
 * @param  {string}       template.name               Template name
 * @param  {string}       template.publishedVersion   Published version number
 */
const TemplateCard = ({
  history,
  isMakeACopyEnabled,
  onClickDelete,
  onClickMakeACopy,
  template,
}) => {
  const { name, createdAt, modifiedAt, publishedVersion } = template;
  let isMenuClick = false;

  const navigateToTemplateWorkspace = () => {
    // Don't push the template path to the history if the menu was clicked.
    // The dropdown menu is a subelement of the template card, so the template card will
    // inherit menu clicks.
    //
    // Click propogation can't be prevented with event.stopPropagation or event.preventDefault.
    // In addition, setting disabled={true} on a menu item won't prevent the click from propgating
    // to the parent card even though the menu item itself won't be clickable.
    if (isMenuClick) {
      isMenuClick = false;
      return;
    }

    history.push(`/templates/${template.id}`);
  };

  const onClickMore = () => {
    isMenuClick = true;
  };

  const onClickMakeACopyMenuItem = () => {
    isMenuClick = true;
    if (isMakeACopyEnabled) {
      onClickMakeACopy(template);
    }
  };

  const onClickDeleteMenuItem = () => {
    isMenuClick = true;
    onClickDelete(template);
  };

  return (
    <Card className={templateCardStyle} onClick={navigateToTemplateWorkspace}>
      <div>{name}</div>
      <div className={metaDataStyle}>
        <div>Published: {publishedVersion || '-'}</div>
        <div>Last updated: {modifiedAt.toLocaleDateString()}</div>
        <div>Created: {createdAt.toLocaleDateString()}</div>
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <TemplateTag template={template} wrapperClass={tagWrapperStyle} />
          <Popover
            content={
              <Menu>
                <MenuItem
                  className={isMakeACopyEnabled ? null : 'bp3-disabled'}
                  text="Make a copy"
                  onClick={onClickMakeACopyMenuItem}
                />
                <MenuItem text="Delete" onClick={onClickDeleteMenuItem} />
              </Menu>
            }
            position={Position.BOTTOM_LEFT}
          >
            <Button icon="more" onClick={onClickMore} />
          </Popover>
        </div>
      </div>
    </Card>
  );
};

TemplateCard.propTypes = {
  history: historyPropType.isRequired,
  isMakeACopyEnabled: PropTypes.bool,
  onClickDelete: PropTypes.func.isRequired,
  onClickMakeACopy: PropTypes.func.isRequired,
  template: templatePropType.isRequired,
};

TemplateCard.defaultProps = {
  isMakeACopyEnabled: true,
};

// Give the TemplateCard access to our browser router history object.
const RoutedTemplateCard = withRouter(TemplateCard);

/**
 * Utility reflexbox `Flex` container component to allow sharing of template list flex box structure.
 * (Used for consistency between `TemplateList` and `RecentTemplatesSection`)
 * @param  {node} children
 */
const TemplateListFlex = ({ children, ...props }) => (
  /* Negative margin here to account for the padding on underlying `TemplateListBox` components. */
  <Flex m="-6px -12px" wrap {...props}>
    {children}
  </Flex>
);

TemplateListFlex.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * Utility reflexbox `Box` flex item component to allow sharing of template list flex box structure.
 * (Used for consistency between `TemplateList` and `RecentTemplatesSection`)
 * @param  {node} children
 */
const TemplateListBox = ({ children, ...props }) => (
  <Box w={[1, 1 / 2, 1 / 3, 1 / 4]} p="6px 12px" {...props}>
    {children}
  </Box>
);

TemplateListBox.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * Canonical component for rendering a list of clickable template cards.
 * @param  {Boolean}       isMakeACopyEnabled  Is "Make a copy" feature enabled
 * @param  {Method}        onClickDelete       On click delete callback
 * @param  {Method}        onClickMakeACopy    On click make a copy callback
 * @param  {WTSTemplate[]} templates           Array of serialized template objects.
 */
const TemplateList = ({ isMakeACopyEnabled, onClickDelete, onClickMakeACopy, templates }) => (
  <TemplateListFlex>
    {templates.map((template) => (
      <TemplateListBox key={template.name}>
        <RoutedTemplateCard
          isMakeACopyEnabled={isMakeACopyEnabled}
          onClickDelete={onClickDelete}
          onClickMakeACopy={onClickMakeACopy}
          template={template}
        />
      </TemplateListBox>
    ))}
  </TemplateListFlex>
);

TemplateList.propTypes = {
  isMakeACopyEnabled: PropTypes.bool,
  onClickDelete: PropTypes.func.isRequired,
  onClickMakeACopy: PropTypes.func.isRequired,
  templates: PropTypes.arrayOf(templatePropType).isRequired,
};

TemplateList.defaultProps = {
  isMakeACopyEnabled: true,
};

const newTemplateCardStyle = cxs({
  ...sharedCardStyleProperties,
  backgroundColor: makeImportant(darkBackground),
  minHeight: '125px',
  position: 'relative'
});

const uploadWrapperStyle = cxs({
  marginTop: '24px',
});

const calloutStyle = cxs({
  marginBottom: '12px',
});

const retryWrapperStyle = cxs({
  marginTop: '24px',
});

/**
 * Card component exposing the option to start a new template.
 */
const NewTemplateCard = ({ history }) => {
  const confirmationCodeInput = React.createRef();
  const dispatch = useDispatch();
  const queueState = useSelector(selectors.getQueueStatus);
  const uploadState = useSelector(selectors.getImportStatus);
  const error = useSelector(selectors.getImportError);

  const onSubmitImport = async (event) => {
    event.preventDefault()
    const confirmationCode = confirmationCodeInput.current.value

    if(!confirmationCode) {
      return
    }

    try {
      const template = await dispatch(operations.createTemplateFromConfirmationCode(confirmationCode));
      history.push(`/templates/${template.id}`);
    } catch (err) {
      console.error(err);
      // eslint-disable-next-line no-alert
      alert('Sorry, something went wrong.');
    }
  };

  const onClickRetry = () => {
    dispatch(actions.resetImport());
  };

  const getButtonContent = () => {
    if (
      ([
        templateImportStatus.uploading,
        templateImportStatus.creatingTemplate,
        templateImportStatus.creatingVersion,
        templateImportStatus.addingImportToVersion,
      ]).includes(uploadState)
    ) {
      return (
        <Spinner
          css={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        />
      )
    }

    if (uploadState === templateImportStatus.queued) {
      return (
        <>
          <Spinner />
          <Callout intent={Intent.WARNING} className={calloutStyle}>
            <div>Another import is in progress. Waiting for other import to finish...</div>
          </Callout>
        </>
      );
    }

    if ([
      templateQueueStatus.queued,
      templateQueueStatus.creatingTemplate,
    ].includes(queueState)) {
      return (
        <>
          <Spinner />
          <Callout intent={Intent.WARNING} className={calloutStyle}>
            <div>An import is currently in progress. Waiting for import to finish...</div>
          </Callout>
        </>
      );
    }

    if (error) {
      return (
        <>
          <Callout intent={Intent.DANGER} className={calloutStyle}>
            <div>Unable to import</div>
          </Callout>
          <div className={metaDataStyle}>{error}</div>
          <div className={retryWrapperStyle}>
            <WTSButton wtsIntent="default" onClick={onClickRetry} fullWidth>
              Retry
            </WTSButton>
          </div>
        </>
      );
    }

    return (
      <>
        <div>New Template</div>
        <div className={metaDataStyle}>
          Once you’ve previewed and exported your template, you can import the template using the
          confirmation code to begin to configure it’s edatibility.
        </div>
        <form className={uploadWrapperStyle} onSubmit={onSubmitImport}>
          <InputGroup
            placeholder='ex. DNmXXG2kiP'
            inputRef={confirmationCodeInput}
            css={{
              backgroundColor: defaultBackground,
              marginBottom: 5,
            }}
          />
          <WTSButton
            wtsIntent="primary"
            type="submit"

            fullWidth
          >
            Import
          </WTSButton>
        </form>
      </>
    );
  }

  return (
    <TemplateListBox>
      <Card className={newTemplateCardStyle}>
        {getButtonContent()}
      </Card>
    </TemplateListBox>
  );
};

NewTemplateCard.propTypes = {
  history: historyPropType.isRequired,
};

const RoutedNewTemplateCard = withRouter(NewTemplateCard);

/**
 * Display component for our "recent templates" section.
 * Similar to our standard `TemplateList` except that the first element is not
 * an existing template, but a card that allows the creation of a new template via
 * file import.
 * @param  {Boolean}      isMakeACopyEnabled  Is "Make a copy" feature enabled
 * @param  {Method}       onClickDelete       On click delete callback
 * @param  {Method}       onClickMakeACopy    On click make a copy callback
 * @param  {WTSTempate[]} recentTemplates
 *    List of waymark template objects to display after the New Template card.
 */
const RecentTemplatesSection = ({
  isMakeACopyEnabled,
  onClickDelete,
  onClickMakeACopy,
  recentTemplates,
}) => (
  <>
    <SectionTitle>Recent</SectionTitle>
    <TemplateListFlex align="start">
      {/* First item is the new template option. */}
      <RoutedNewTemplateCard />
      {recentTemplates.map((template) => (
        <TemplateListBox key={template.name}>
          <RoutedTemplateCard
            isMakeACopyEnabled={isMakeACopyEnabled}
            onClickDelete={onClickDelete}
            onClickMakeACopy={onClickMakeACopy}
            template={template}
          />
        </TemplateListBox>
      ))}
    </TemplateListFlex>
  </>
);

RecentTemplatesSection.propTypes = {
  isMakeACopyEnabled: PropTypes.bool,
  onClickDelete: PropTypes.func.isRequired,
  onClickMakeACopy: PropTypes.func.isRequired,
  recentTemplates: PropTypes.arrayOf(templatePropType).isRequired,
};

RecentTemplatesSection.defaultProps = {
  isMakeACopyEnabled: true,
};

/**
 * Filter function that determines whether a template should show up for a particular search query.
 * @param   {WTSTemplate}  template    Template object
 * @param   {string}       query       Search string
 * @return  {bool}  Whether or not the template should show up for the given query.
 */
const shouldShowTemplate = (template, query) => {
  const normalizeName = template.name.toLowerCase();
  const normalizeQuery = query.toLowerCase();
  // For now, just name comparison. Anything else we want?
  return !normalizeQuery || normalizeName.indexOf(normalizeQuery) !== -1;
};

const MILLISECONDS_IN_A_WEEK = 1000 * 60 * 60 * 24 * 7;

const templateGroupSectionStyle = cxs({
  margin: '44px 0',
});

/**
 * The main content area of the template selection screen, where templates are shown
 * as well as the ability to start a new template.
 * @param  {string}   templateQuery         Search query for filtering templates.
 * @param  {string}   activeTemplateGroup  Selected template group to display.
 */
const TemplateResultsGrid = ({ templateQuery, activeTemplateGroup }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isCopyAlertOpen, setIsCopyAlertOpen] = useState(false);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);

  // Fetch me some templates, but only when we need them!
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectors.getIsUserAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(operations.getTemplates());
      dispatch(operations.watchQueue());
    }
  }, [isAuthenticated]);

  const allTemplates = useSelector(selectors.getTemplates);
  const filteredTemplates = allTemplates.filter(
    (template) => shouldShowTemplate(template, templateQuery) && !template.isHidden,
  );

  const now = new Date();
  const filteredRecentTemplates = filteredTemplates
    // Only include templates modified within the last week.
    .filter((template) => now - template.modifiedAt < MILLISECONDS_IN_A_WEEK)
    // Sort by most recently updated.
    .sort((a, b) => (a.modifiedAt > b.modifiedAt ? -1 : 1))
    // Only include the 3 most recent.
    .slice(0, 3);

  const filteredPublishedTemplates = [];
  const filteredUnpublishedTemplates = [];

  // I could call filter twice, but then I'm iterating the full array twice.
  // This gets the two arrays populated in one iteration.
  filteredTemplates.forEach((template) => {
    if (template.publishedVersion) {
      filteredPublishedTemplates.push(template);
    } else {
      filteredUnpublishedTemplates.push(template);
    }
  });

  const queueState = useSelector(selectors.getQueueStatus);
  const uploadState = useSelector(selectors.getImportStatus);

  const isMakeACopyEnabled =
    // Disable "Make a copy" when another template is queued to import
    ![templateQueueStatus.queued, templateQueueStatus.creatingTemplate].includes(queueState) &&
    // Disable "Make a copy" when another template is importing
    ![
      templateImportStatus.queued,
      templateImportStatus.uploading,
      templateImportStatus.creatingTemplate,
      templateImportStatus.creatingVersion,
      templateImportStatus.addingImportToVersion,
    ].includes(uploadState);

  const onClickMakeACopy = async (template) => {
    setSelectedTemplate(template);
    setIsCopyAlertOpen(true);
  };

  const onClickDelete = (template) => {
    setSelectedTemplate(template);
    setIsDeleteAlertOpen(true);
  };

  const onCloseCopyAlert = async (didConfirmCopy) => {
    setIsCopyAlertOpen(false);
    try {
      if (didConfirmCopy) {
        await dispatch(operations.duplicateTemplate(selectedTemplate));
      }
    } finally {
      setSelectedTemplate(null);
    }
  };

  const onCloseDeleteAlert = (didConfirmDelete) => {
    setIsDeleteAlertOpen(false);
    try {
      if (didConfirmDelete) {
        dispatch(operations.deleteTemplate(selectedTemplate));
      }
    } finally {
      setSelectedTemplate(null);
    }
  };

  return (
    <>
      <PromptForContinueDialog
        continueButtonMessage="Copy"
        isOpen={isCopyAlertOpen}
        onClose={onCloseCopyAlert}
        requiredInputText="copy"
      >
        Are you sure you want to make a copy of {selectedTemplate ? selectedTemplate.name : ''}?
      </PromptForContinueDialog>

      <PromptForContinueDialog
        continueButtonMessage="Delete"
        intent={Intent.DANGER}
        isOpen={isDeleteAlertOpen}
        onClose={onCloseDeleteAlert}
        requiredInputText="delete"
      >
        Are you sure you want to delete {selectedTemplate ? selectedTemplate.name : ''}?
      </PromptForContinueDialog>

      {[templateGroups.all, templateGroups.recent].includes(activeTemplateGroup) && (
        <section className={templateGroupSectionStyle}>
          <RecentTemplatesSection
            isMakeACopyEnabled={isMakeACopyEnabled}
            onClickDelete={onClickDelete}
            onClickMakeACopy={onClickMakeACopy}
            recentTemplates={filteredRecentTemplates}
          />
        </section>
      )}
      {[templateGroups.all, templateGroups.unpublished].includes(activeTemplateGroup) &&
        !!filteredUnpublishedTemplates.length && (
          <section className={templateGroupSectionStyle}>
            <SectionTitle>Unpublished</SectionTitle>
            <Paragraph>Templates that have never been published.</Paragraph>
            <TemplateList
              isMakeACopyEnabled={isMakeACopyEnabled}
              onClickDelete={onClickDelete}
              onClickMakeACopy={onClickMakeACopy}
              templates={filteredUnpublishedTemplates}
            />
          </section>
        )}
      {[templateGroups.all, templateGroups.published].includes(activeTemplateGroup) &&
        !!filteredPublishedTemplates.length && (
          <section className={templateGroupSectionStyle}>
            <SectionTitle>Published</SectionTitle>
            <Paragraph>
              Templates tagged as
              <strong> DRAFT </strong>
              indicate that modifications have been made to this template that have not yet been
              published.
            </Paragraph>
            <TemplateList
              isMakeACopyEnabled={isMakeACopyEnabled}
              onClickDelete={onClickDelete}
              onClickMakeACopy={onClickMakeACopy}
              templates={filteredPublishedTemplates}
            />
          </section>
        )}
    </>
  );
};

TemplateResultsGrid.propTypes = {
  templateQuery: PropTypes.string.isRequired,
  activeTemplateGroup: PropTypes.string.isRequired,
};

export default TemplateResultsGrid;
