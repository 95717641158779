import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import cxs from 'cxs';

import { BaseButton, desktopMediaQuery } from './style.js';
import {
  PlayIcon,
  PauseIcon,
  RestartIcon,
  ExitFullscreenIcon,
  EnterFullscreenIcon,
  MuteIcon,
  UnmuteIcon,
} from './Icons.js';

import PlayerScrubber from './PlayerScrubber.js';
import { keyCodes } from '../utils/index.js';

const rendererControlsClassName = cxs({
  backgroundColor: 'rgba(0,0,0, .4)',
  borderRadius: '5px',
  color: '#FFFFFF',
  display: 'grid',
  fontFamily:
    '"HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif',
  fontWeight: '300',
  gridGap: '5px',
  margin: '30px auto 0',
  maxWidth: '1000px',
  padding: '5px',
  textAlign: 'center',
  gridTemplateColumns:
    'minmax(28px, 1fr) minmax(28px, 1fr) minmax(28px, 1fr) minmax(28px, 1fr) minmax(35px, 1fr) minmax(50px, 2fr) minmax(28px, 1fr)',
  fontSize: '.9em',
  [desktopMediaQuery]: {
    gridTemplateColumns:
      'minmax(28px, 1fr) minmax(28px, 1fr) minmax(28px, 1fr) minmax(28px, 1fr) minmax(35px, 1fr) minmax(50px, 6fr) minmax(28px, 1fr)',
    fontSize: '1em',
  },
});

const goToFrameInput = cxs({
  textAlign: 'center',
});

const frameScrubberWrapper = cxs({
  position: 'relative',
  padding: '0 10px',
});

const frameScrubber = cxs({
  position: 'absolute',
  transform: 'translate(0, -51%)',
  top: '50%',
  width: 'calc(100% - 20px)',
});

const totalFramesCount = cxs({
  lineHeight: '28px',
});

const totalFramesContent = cxs({
  verticalAlign: 'middle',
});

export default function RendererControls({
  onPlay,
  onStop,
  onMute,
  onUnmute,
  isEnabled,
  isPlaying,
  isMuted,
  onGoToFrame,
  currentFrame,
  totalFrames,
  onToggleFullscreen,
  isFullscreen,
  hasGlobalKeyboardListeners,
  scrubberChildren,
  scrubberClassName,
  trackClassName,
  trackCompleteClassName,
}) {
  const [isGoingToFrame, setIsGoingToFrame] = useState(false);

  const onTogglePlayPause = useCallback(() => {
    if (isPlaying) {
      onStop();
    } else {
      onPlay();
    }
  }, [isPlaying]);

  const onToggleMute = useCallback(() => {
    if (isMuted) {
      onUnmute();
    } else {
      onMute();
    }
  }, [isMuted]);

  const onFocusGoToFrame = () => {
    setIsGoingToFrame(true);
  };

  const onChangeGoToFrame = (event) => {
    if (isGoingToFrame) {
      onGoToFrame(Math.min(event.target.value, totalFrames));
    }
  };

  const onRestartVideo = () => {
    onGoToFrame(0);
  };

  const onBlurGoToFrame = async (event) => {
    await onGoToFrame(Math.min(event.target.value, totalFrames));
    setIsGoingToFrame(false);
  };

  const onKeyPress = useCallback(
    (event) => {
      if ([keyCodes.SPACE, keyCodes.ENTER].includes(event.which)) {
        event.preventDefault();
        if (isPlaying) {
          onStop();
        } else {
          onPlay();
        }
      }
    },
    [isPlaying],
  );

  useEffect(() => {
    if (hasGlobalKeyboardListeners) {
      window.addEventListener('keydown', onKeyPress);
    } else {
      window.removeEventListener('keydown', onKeyPress);
    }
    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [onKeyPress]);

  const iconColor = !isEnabled ? '#CCC' : '#FFF';

  return (
    <div className={rendererControlsClassName}>
      <BaseButton onClick={onTogglePlayPause} type="button" disabled={!isEnabled} tabIndex={0}>
        {isPlaying ? <PauseIcon fill={iconColor} /> : <PlayIcon fill={iconColor} />}
      </BaseButton>

      <BaseButton onClick={onRestartVideo} type="button" disabled={!isEnabled} tabIndex={0}>
        <RestartIcon stroke={iconColor} />
      </BaseButton>

      <BaseButton onClick={onToggleMute} type="button" disabled={!isEnabled} tabIndex={0}>
        {isMuted ? <UnmuteIcon color={iconColor} /> : <MuteIcon color={iconColor} />}
      </BaseButton>

      {isGoingToFrame ? (
        <input
          className={goToFrameInput}
          defaultValue={currentFrame}
          disabled={!isEnabled}
          type="number"
          onBlur={onBlurGoToFrame}
          onChange={onChangeGoToFrame}
          min={0}
          max={totalFrames}
        />
      ) : (
        <BaseButton
          type="button"
          disabled={!isEnabled}
          onClick={onFocusGoToFrame}
          onFocus={onFocusGoToFrame}
        >
          {currentFrame}
        </BaseButton>
      )}

      <div className={totalFramesCount}>
        <span className={totalFramesContent}>{`/ ${totalFrames}`}</span>
      </div>

      <div className={frameScrubberWrapper}>
        <PlayerScrubber
          className={`${frameScrubber} ${scrubberClassName}`}
          duration={totalFrames}
          isEnabled={isEnabled}
          trackClassName={trackClassName}
          trackCompleteClassName={trackCompleteClassName}
          value={currentFrame}
          onChange={onGoToFrame}
        >
          {scrubberChildren}
        </PlayerScrubber>
      </div>
      <BaseButton type="button" disabled={!isEnabled} onClick={onToggleFullscreen}>
        {isFullscreen ? (
          <ExitFullscreenIcon stroke={iconColor} />
        ) : (
          <EnterFullscreenIcon stroke={iconColor} />
        )}
      </BaseButton>
    </div>
  );
}

RendererControls.propTypes = {
  onPlay: PropTypes.func.isRequired,
  onStop: PropTypes.func.isRequired,
  onMute: PropTypes.func.isRequired,
  onUnmute: PropTypes.func.isRequired,
  isEnabled: PropTypes.bool,
  isPlaying: PropTypes.bool.isRequired,
  isMuted: PropTypes.bool.isRequired,
  onGoToFrame: PropTypes.func.isRequired,
  currentFrame: PropTypes.number.isRequired,
  totalFrames: PropTypes.number.isRequired,
  onToggleFullscreen: PropTypes.func.isRequired,
  isFullscreen: PropTypes.bool.isRequired,
  hasGlobalKeyboardListeners: PropTypes.bool,
  scrubberChildren: PropTypes.node,
  scrubberClassName: PropTypes.string,
  trackClassName: PropTypes.string,
  trackCompleteClassName: PropTypes.string,
};

RendererControls.defaultProps = {
  hasGlobalKeyboardListeners: true,
  isEnabled: true,
  scrubberChildren: null,
  scrubberClassName: '',
  trackClassName: '',
  trackCompleteClassName: '',
};
