/* eslint-disable func-names, no-underscore-dangle */
export default function enableSpriteProperties(pixiNamespace) {
  /**
   * Gets the local bounds of the sprite object.
   *
   * @param {PIXI.Rectangle} [rect] - The output rectangle.
   * @return {PIXI.Rectangle} The bounds.
   */
  // eslint-disable-next-line no-param-reassign
  pixiNamespace.Sprite.prototype.getLocalBounds = function getLocalBounds(rect) {
    // we can do a fast local bounds if the sprite has no children!
    // NOTE: This is the only line changed from the original function.
    //       We want to be able to use our redraw layer and background fill without affecting the bounds of the image
    // TODO: Find a better definition for this that doesn't use our waymark-specific properties AND correctly calculates bounds
    if (
      this.children.length === 0 ||
      (this.children.length === 2 && this.mediaRedraw && this.backgroundFill)
    ) {
      this._bounds.minX = this._texture.orig.width * -this._anchor._x;
      this._bounds.minY = this._texture.orig.height * -this._anchor._y;
      this._bounds.maxX = this._texture.orig.width * (1 - this._anchor._x);
      this._bounds.maxY = this._texture.orig.height * (1 - this._anchor._y);

      if (!rect) {
        if (!this._localBoundsRect) {
          this._localBoundsRect = new pixiNamespace.Rectangle();
        }

        // eslint-disable-next-line no-param-reassign
        rect = this._localBoundsRect;
      }

      return this._bounds.getRectangle(rect);
    }

    return pixiNamespace.Container.prototype.getLocalBounds.call(this, rect);
  };

  /**
   * The the bound of an object without any transform applied.
   * Ex: A 100px wide square with a scale of .5 is reported to be 50px wide by getLocalBounds.
   *     This would report a width of 100px.
   * NOTE: This has to go AFTER the getLocalBounds definition because it will be referencing the old one.
   *
   * @param      {PIXI.Rectangle}  rectangle  An optional rectangle to copy the bounds to
   * @return     {PIXI.Rectangle}  The true bounds.
   */
  // eslint-disable-next-line no-param-reassign
  pixiNamespace.Sprite.prototype.getTrueBounds = pixiNamespace.Sprite.prototype.getLocalBounds;
}
