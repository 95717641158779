import _ from 'lodash';

import { templateManifestOverrideKeys } from '../constants/index.js';

/**
 * Utility class for managing a translation layer between attributes stored within
 * our template manifest and external needs, most notably how attributes translate
 * into video template configurations.
 */
class StandardTemplateManifestAttribute {
  /**
   * Instantiated with the top-level key that stores the attribute within the template manifest,
   * and the manifest value.
   * @param  {string}                   topLevelKey
   * @param  {string | object | array}  templateManifestValue
   */
  constructor(topLevelKey, templateManifestValue) {
    this.topLevelKey = topLevelKey;
    this.templateManifestValue = templateManifestValue;
  }

  /**
   * Getter method for determining which override UUIDs are contained within the attribute's value.
   * @returns {string[]}  Array of override UUID strings.
   *
   * E.g.:
   *
   *    const contentAttribute = new StandardTemplateManifestAttribute('content', { override: 'override-id-123' });
   *    contentAttribute.overrideUUIDs
   *      // ['override-id-123']
   */
  get overrideUUIDs() {
    const overrides = Object.entries(this.getConfigurationValues()).map(([, value]) => {
      const overrideUUID = _.get(value, 'override');
      if (overrideUUID) {
        return overrideUUID;
      }
      return null;
    });

    return overrides.filter(_.identity);
  }

  /**
   * Instance method that translates the template manifest value into a video template
   * configuration key/value pairs.
   * @param  {string} [keyPrefix]   Optional prefix to include in the configuration key
   * @returns {Object}  Video template configuration values
   *
   * E.g.:
   *
   *     const colorAttribute = new StandardTemplateManifestAttribute('fillColor', '#ccc');
   *     colorAttribute.getConfigurationValues('configuration-path--')
   *       // { 'configuration-path--fillColor': '#ccc' }
   */
  getConfigurationValues(keyPrefix = '') {
    if (this.templateManifestValue === null) {
      return null;
    }

    return { [`${keyPrefix}${this.topLevelKey}`]: this.templateManifestValue };
  }
}

/**
 * Modified Attribute class specifically for the gradient fill attribute.
 * Utilizes the same constructor and `overrideUUIDs` getter.
 */
class GradientFillTemplateManifestAttribute extends StandardTemplateManifestAttribute {
  /**
   * Modified logic to translate each step in the gradient fill attribute into a distinct
   * manifest value.
   * @param  {string} [keyPrefix]   Optional prefix to include in the configuration key
   * @returns {Object}  Video template configuration values
   *
   * E.g.
   *
   *     const gradientFillAttribute = new GradientFillTemplateManifestAttribute('gradientFill', {
   *       1: { override: '123-123' },
   *       2: { override: '456-456' },
   *     });
   *     gradientFillAttribute.getConfigurationValues()
   *       {
   *         'gradientFill.1': { override: '123-123' },
   *         'gradientFill.2': { override: '456-456' },
   *       }
   */
  getConfigurationValues(keyPrefix = '') {
    if (this.templateManifestValue === null) {
      return null;
    }

    const configurationValues = {};
    Object.entries(this.templateManifestValue).forEach(([key, value]) => {
      configurationValues[`${keyPrefix}${this.topLevelKey}.${key}`] = value;
    });

    return configurationValues;
  }
}

// Mapping between attribute keys and helper classes
export const templateManifestAttributes = {
  [templateManifestOverrideKeys.content]: StandardTemplateManifestAttribute,
  [templateManifestOverrideKeys.color.fillColor]: StandardTemplateManifestAttribute,
  [templateManifestOverrideKeys.color.fillEffect]: StandardTemplateManifestAttribute,
  [templateManifestOverrideKeys.color.strokeColor]: StandardTemplateManifestAttribute,
  [templateManifestOverrideKeys.font]: StandardTemplateManifestAttribute,
  [templateManifestOverrideKeys.color.gradientFill]: GradientFillTemplateManifestAttribute,
};

/**
 * Provided a object of layersExtendedAttributes, translates each attribute into configuration
 * values and returns the merged object of all configurtion values.
 * @param   {Object}   extendedAttributes  `layersExtendedAttributes` entry for a specific layer
 * @returns {Object}   All video tempalte configuration values derived from those attributes.
 *
 * E.g.
 *
 *     getConfigurationValuesFromExtendedAttributes({
 *       fillColor: '#123',
 *       gradientFill: {
 *         1: { override: '123-123' }
 *         2: { override: '456-456' }
 *       }
 *     })
 *
 *    ==
 *
 *    {
 *       'fillColor': '#123',
 *       'gradientFill.1`: { override: '123-123' },
 *       'gradientFill.2`: { override: '456-456' },
 *    }
 */
export const getConfigurationValuesFromExtendedAttributes = (
  extendedAttributes,
  keyPrefix = '',
) => {
  const attributes = Object.entries(extendedAttributes).map(
    ([key, value]) => new templateManifestAttributes[key](key, value),
  );

  const configurationValues = attributes.reduce(
    (accum, attribute) => ({
      ...accum,
      ...attribute.getConfigurationValues(keyPrefix),
    }),
    {},
  );

  return configurationValues;
};
