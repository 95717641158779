/* eslint-disable */

/**
 * Filter System overridden to fix bug in `push` as well as the desire to add additional
 * padding around a filter when fit to the inside of the canvas
 *
 * The ultimate goal is to eventually remove this by some combination of
 * (a) contributing a bug fix to PIXI.js (the auto fit fix) or
 * (b) finding a more localized workaround (the frame padding workaround).
 */

export default function enableDisplayObjectProperties(pixiNamespace) {
  /**
   * System plugin to the renderer to manage filter states.
   * THIS IS COPIED DIRECTLY FROM PIXI.JS BECAUSE IT IS A PRIVATE FUNCTION
   *
   * @class
   * @private
   */
  const FilterState = function FilterState() {
    this.renderTexture = null;

    /**
     * Target of the filters
     * We store for case when custom filter wants to know the element it was applied on
     * @member {PIXI.DisplayObject}
     * @private
     */
    this.target = null;

    /**
     * Compatibility with PixiJS v4 filters
     * @member {boolean}
     * @default false
     * @private
     */
    this.legacy = false;

    /**
     * Resolution of filters
     * @member {number}
     * @default 1
     * @private
     */
    this.resolution = 1;

    // next three fields are created only for root
    // re-assigned for everything else

    /**
     * Source frame
     * @member {PIXI.Rectangle}
     * @private
     */
    this.sourceFrame = new pixiNamespace.Rectangle();

    /**
     * Destination frame
     * @member {PIXI.Rectangle}
     * @private
     */
    this.destinationFrame = new pixiNamespace.Rectangle();

    /**
     * Collection of filters
     * @member {PIXI.Filter[]}
     * @private
     */
    this.filters = [];
  };

  /**
   * clears the state
   * @private
   */
  FilterState.prototype.clear = function clear() {
    this.target = null;
    this.filters = null;
    this.renderTexture = null;
  };

  pixiNamespace.systems.FilterSystem.AUTO_FIT_BOUNDS_PADDING = 50;

  pixiNamespace.systems.FilterSystem.prototype.push = function push(target, filters) {
    const renderer = this.renderer;
    const filterStack = this.defaultFilterStack;
    const state = this.statePool.pop() || new FilterState();

    let resolution = filters[0].resolution;
    let padding = filters[0].padding;
    let autoFit = filters[0].autoFit;
    let legacy = filters[0].legacy;

    for (let i = 1; i < filters.length; i++) {
      const filter = filters[i];

      // lets use the lowest resolution..
      resolution = Math.min(resolution, filter.resolution);
      // and the largest amount of padding!
      padding = Math.max(padding, filter.padding);
      // only auto fit if all filters are autofit
      // BEGIN FIX
      autoFit = autoFit && filter.autoFit;
      // END FIX
      legacy = legacy || filter.legacy;
    }

    if (filterStack.length === 1) {
      this.defaultFilterStack[0].renderTexture = renderer.renderTexture.current;
    }

    filterStack.push(state);

    state.resolution = resolution;

    state.legacy = legacy;

    state.target = target;

    state.sourceFrame.copyFrom(target.filterArea || target.getBounds(true));

    state.sourceFrame.pad(padding);
    if (autoFit) {
      // BEGIN FIX
      // Add a small padding around the frame so pixels near the edge, but outside of
      // the canvas can be used for interpretation in the shader. Mostly used by MotionBlur3DFilter
      const frame = this.renderer.renderTexture.sourceFrame.clone();
      frame.pad(pixiNamespace.systems.FilterSystem.AUTO_FIT_BOUNDS_PADDING);
      state.sourceFrame.fit(frame);
      // END FIX
    }

    // round to whole number based on resolution
    state.sourceFrame.ceil(resolution);

    state.renderTexture = this.getOptimalFilterTexture(
      state.sourceFrame.width,
      state.sourceFrame.height,
      resolution,
    );
    state.filters = filters;

    state.destinationFrame.width = state.renderTexture.width;
    state.destinationFrame.height = state.renderTexture.height;

    state.renderTexture.filterFrame = state.sourceFrame;

    renderer.renderTexture.bind(state.renderTexture, state.sourceFrame); // /, state.destinationFrame);
    renderer.renderTexture.clear();
  };
}

/* eslint-enable */
