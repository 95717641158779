/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { createSelector, createSlice } from 'redux-starter-kit';

import { createVideoTemplateAudio, updateVersion } from '../api/index.js';
import { createAssetFromVideoTemplateAudio } from '../utils/projectManifest.js';
import { getOverrideMembersFromLayersExtendedAttributes } from '../utils/templateManifest.js';
import { isValidUUID, uuid } from '../utils/uuid.js';
import { createDynamicLayer } from './utils.js';
import workspaceVersions from './workspaceVersions.js';
import { fitFillAlignments } from '@stikdev/waymark-author-web-renderer';

/**
 * `workspaceTemplateManifest` slice for managing templateManifest updates within the studio.
 * A dependency of our main `workspace` slice.
 *
 * SLICE DEPENDENCIES:
 *   - `workspaceVersions`
 */
const defaultState = {
  isWorkspaceSetup: false,
  templateManifestLastSyncedAt: null,
  templateManifest: {
    backgroundAudio: {},
    overrides: [],
    layersExtendedAttributes: {},
    sceneGroups: [],
    sceneSwitches: [],
  },
};

/**
 * Return a full override from a partial override.
 * Default values will be populated where missing.
 */
const populateOverrideWithDefaults = (override) => {
  let newOverride = {
    // Required
    type: override.type,
    id: override.id || uuid(),
    name: override.name || 'Untitled override',
    placeholder: override.placeholder || '',
    frameNumber: override.frameNumber || 0,
  };

  if (override.type && override.type === 'image') {
    // if the override is an image, we need to add the `freeformCropping` attribute, defaulting to false
    // as well as the `fitFillAlignment` attribute, defaulting to `centerCenter`
    newOverride = {
      ...newOverride,
      freeformCropping: false,
      fitFillAlignment: fitFillAlignments.centerCenter,
    };
  }

  return newOverride;
};

const workspaceTemplateManifest = createSlice({
  slice: 'workspaceTemplateManifest',
  initialState: defaultState,
  extraReducers: {
    // When we exit the workspace, reset state to the default.
    'workspace/leavingWorkspace': (state) => {
      state.templateManifestLastSyncedAt = null;
      state.templateManifest = defaultState.templateManifest;
    },
  },
  reducers: {
    workspaceSetupCompleted: (state) => {
      state.isWorkspaceSetup = true;
    },
    setTemplateManifest: (state, action) => {
      const newManifest = action.payload;

      state.templateManifest = {
        ...defaultState.templateManifest,
        ...newManifest,
      };
    },
    manifestSyncSuccessful: (state) => {
      state.templateManifestLastSyncedAt = JSON.stringify(new Date());
    },
    /**
     * Programmatically set up the font overrides based on the provided font data from the projectManifest.
     * @param  {Object} state
     * @param  {Object} fontData
     * @param  {Object} fontData.fontOverrides            Object of font overrides, keyed on unique fontKey
     */
    setNewFontOverideData: (state, action) => {
      const { newFontOverrides } = action.payload;

      // Add in new overrides
      state.templateManifest.overrides.push(...Object.values(newFontOverrides));
      const allFontOverrideIds = state.templateManifest.overrides
        .filter((override) => override.type === 'font')
        .map((override) => override.id);

      // Remove stale references from layers extended attributes
      Object.entries(state.templateManifest.layersExtendedAttributes).forEach(
        (layerUUID, attributes) => {
          const fontOverrideId = _.get(attributes, 'font.override');
          if (fontOverrideId && !allFontOverrideIds.indluces(fontOverrideId)) {
            /* Note: This protects against an entry in `layersExtendedAttributes` that has a stale fontOverride reference. */
            attributes.font = null;
          }
        },
      );
    },
    /**
     * LAYERS EXTENDED ATTRIBUTES
     */
    createLayersExtendedAttributes: (state, action) => {
      const formattedExtendedAttributes = createDynamicLayer(
        action.payload.layerType,
        action.payload.updateValue,
      );
      state.templateManifest.layersExtendedAttributes[
        action.payload.layerUUID
      ] = formattedExtendedAttributes;
    },
    deleteLayersExtendedAttributes: (state, action) => {
      delete state.templateManifest.layersExtendedAttributes[action.payload];
    },
    // MB TODO: Dry this up with the validation in `createDynamicLayer`
    updateDynamicProperties: (state, action) => {
      const updateDotPath = Object.keys(action.payload.updateValue)[0];
      let updateValue = Object.values(action.payload.updateValue)[0];

      if (isValidUUID(updateValue)) {
        updateValue = {
          override: updateValue,
        };
      }

      action.payload.layerUUIDS.forEach((layerUUID) => {
        _.set(
          state.templateManifest.layersExtendedAttributes,
          `${layerUUID}.${updateDotPath}`,
          updateValue,
        );
      });
    },
    /**
     * OVERRIDES
     */
    updateOverride: (state, action) => {
      const newOverride = action.payload;
      state.templateManifest.overrides = state.templateManifest.overrides.map((override) =>
        override.id === newOverride.id ? newOverride : override,
      );
    },
    createOverride: (state, action) => {
      const override = populateOverrideWithDefaults(action.payload);
      state.templateManifest.overrides.push(override);
    },
    createOverrides: (state, action) => {
      action.payload.forEach((providedOverride) => {
        const override = populateOverrideWithDefaults(providedOverride);
        state.templateManifest.overrides.push(override);
      });
    },
    deleteOverride: (state, action) => {
      const removedOverride = action.payload;
      state.templateManifest.overrides = state.templateManifest.overrides.filter(
        (override) => override.id !== removedOverride,
      );
    },
    /**
     * SCENE GROUPS
     */
    updateSceneGroup: (state, action) => {
      const newGroup = action.payload;
      state.templateManifest.sceneGroups = state.templateManifest.sceneGroups.map((group) =>
        group.id === newGroup.id ? newGroup : group,
      );
    },
    deleteSceneGroup: (state, action) => {
      const removedGroupId = action.payload;
      state.templateManifest.sceneGroups = state.templateManifest.sceneGroups.filter(
        (group) => group.id !== removedGroupId,
      );
      state.templateManifest.sceneSwitches = state.templateManifest.sceneSwitches.map(
        (sceneSwitch) => ({
          ...sceneSwitch,
          groups: _.without(sceneSwitch.groups, removedGroupId),
        }),
      );
    },
    createSceneGroup: (state, action) => {
      const { name, layers } = action.payload;
      state.templateManifest.sceneGroups.push({
        name,
        layers,
        id: uuid(),
      });
    },
    /**
     * SCENE SWITCHES
     */
    updateSceneSwitch: (state, action) => {
      const newSwitch = action.payload;
      state.templateManifest.sceneSwitches = state.templateManifest.sceneSwitches.map(
        (sceneSwitch) => (sceneSwitch.id === newSwitch.id ? newSwitch : sceneSwitch),
      );
    },
    deleteSceneSwitch: (state, action) => {
      const removedSwitchId = action.payload;
      state.templateManifest.sceneSwitches = state.templateManifest.sceneSwitches.filter(
        (group) => group.id !== removedSwitchId,
      );
    },
    createSceneSwitch: (state, action) => {
      const newId = action.payload;
      state.templateManifest.sceneSwitches.push({
        name: 'Untitled Switch',
        // Prefer the provided UUID if there is one.
        id: newId || uuid(),
        groups: [],
        defaultSelection: null,
        frameNumber: '',
      });
    },
    /**
     * AUDIO
     */
    updateAudioOption: (state, action) => {
      const newAudioTrack = action.payload;
      state.templateManifest.backgroundAudio.options = state.templateManifest.backgroundAudio.options.map(
        (audioOption) => (audioOption.id === newAudioTrack.id ? newAudioTrack : audioOption),
      );
    },
    deleteAudioOption: (state, action) => {
      const removedOptionId = action.payload;
      state.templateManifest.backgroundAudio.options = state.templateManifest.backgroundAudio.options.filter(
        (audioOption) => audioOption.id !== removedOptionId,
      );
      if (state.templateManifest.backgroundAudio.defaultSelection === removedOptionId) {
        state.templateManifest.backgroundAudio.defaultSelection = null;
      }
    },
    createdAudioOption: (state, action) => {
      if (Array.isArray(state.templateManifest.backgroundAudio.options)) {
        state.templateManifest.backgroundAudio.options.push(action.payload);
      } else {
        state.templateManifest.backgroundAudio.options = [action.payload];
      }
    },
    updateBackgroundAudioDefaultSelection: (state, action) => {
      state.templateManifest.backgroundAudio.defaultSelection = action.payload;
    },
  },
});

workspaceTemplateManifest.selectors.getIsWorkspaceSetup = createSelector([
  'workspaceTemplateManifest.isWorkspaceSetup',
]);
workspaceTemplateManifest.selectors.gettemplateManifestLastSyncedAt = createSelector(
  ['workspaceTemplateManifest.templateManifestLastSyncedAt'],
  (templateManifestLastSyncedAt) => new Date(JSON.parse(templateManifestLastSyncedAt)),
);

/**
 * Start Template Manifest
 */
workspaceTemplateManifest.selectors.getTemplateManifest = createSelector([
  'workspaceTemplateManifest.templateManifest',
]);
workspaceTemplateManifest.selectors.getTemplateManifestGroups = createSelector([
  'workspaceTemplateManifest.templateManifest.sceneGroups',
]);
workspaceTemplateManifest.selectors.getTemplateManifestOverrides = createSelector([
  'workspaceTemplateManifest.templateManifest.overrides',
]);
workspaceTemplateManifest.selectors.getVisibleTemplateManifestOverrides = createSelector(
  [workspaceTemplateManifest.selectors.getTemplateManifestOverrides],
  (overrides) => _.reject(overrides, { type: 'font' }),
);
workspaceTemplateManifest.selectors.getTemplateManifestFontOverrides = createSelector(
  [workspaceTemplateManifest.selectors.getTemplateManifestOverrides],
  (overrides) => _.filter(overrides, { type: 'font' }),
);
workspaceTemplateManifest.selectors.getTemplateManifestSwitches = createSelector([
  'workspaceTemplateManifest.templateManifest.sceneSwitches',
]);
workspaceTemplateManifest.selectors.getTemplateManifestBackgroundAudio = createSelector([
  'workspaceTemplateManifest.templateManifest.backgroundAudio',
]);
workspaceTemplateManifest.selectors.getTemplateManifestBackgroundAudioOptions = createSelector(
  [workspaceTemplateManifest.selectors.getTemplateManifestBackgroundAudio],
  (backgroundAudio) => backgroundAudio.options || [],
);
workspaceTemplateManifest.selectors.getTemplateManifestBackgroundAudioDefaultSelection = createSelector(
  [workspaceTemplateManifest.selectors.getTemplateManifestBackgroundAudio],
  (backgroundAudio) => backgroundAudio.defaultSelection,
);
workspaceTemplateManifest.selectors.getSceneGroupByUUID = (state, groupUUID) =>
  createSelector([workspaceTemplateManifest.selectors.getTemplateManifestGroups], (sceneGroups) =>
    _.find(sceneGroups, { id: groupUUID }),
  )(state);
workspaceTemplateManifest.selectors.getOverrideByUUID = (state, overrideUUID) =>
  createSelector([workspaceTemplateManifest.selectors.getTemplateManifestOverrides], (overrides) =>
    _.find(overrides, { id: overrideUUID }),
  )(state);

workspaceTemplateManifest.selectors.getOverrideMembers = (state, overrideUUID) => {
  const { layersExtendedAttributes } = _.cloneDeep(
    workspaceTemplateManifest.selectors.getTemplateManifest(state),
  );

  return getOverrideMembersFromLayersExtendedAttributes(layersExtendedAttributes, overrideUUID);
};

workspaceTemplateManifest.selectors.getTemplateManifestData = (state, layerUUID) =>
  createSelector(
    [workspaceTemplateManifest.selectors.getTemplateManifest],
    (templateManifest) => templateManifest.layersExtendedAttributes[layerUUID] || {},
  )(state);

/**
 * End Template Manifest
 */

/**
 * Start Display Layers
 */

/**
 * Returns list of edited layers
 */
workspaceTemplateManifest.selectors.getEditableLayerUUIDS = (state) => {
  const templateManifest = workspaceTemplateManifest.selectors.getTemplateManifest(state);
  // If there are no entries in templateManifest.layersExtendedAttributes
  // then there are no editable layers.
  if (!templateManifest.layersExtendedAttributes) return [];
  return Object.keys(templateManifest.layersExtendedAttributes);
};

/**
 * End Display Layers
 */

workspaceTemplateManifest.operations = {
  createVideoTemplateAudio(formData) {
    return async (dispatch, getState) => {
      const response = await createVideoTemplateAudio(formData);
      const { data: videoTemplateAudios } = response;

      const storeState = getState();
      const projectManifest = _.cloneDeep(
        workspaceVersions.selectors.getActiveProjectManifest(storeState),
      );
      const assets = [];
      for (let i = 0; i < videoTemplateAudios.length; i += 1) {
        const asset = createAssetFromVideoTemplateAudio(videoTemplateAudios[i]);
        // Add the new audio asset to the projectManifest array
        projectManifest.assets.push(asset);
        assets.push(asset);
      }

      await dispatch(workspaceVersions.operations.updateActiveProjectManifest(projectManifest));

      return assets;
    };
  },
  removeVideoTemplateAudio(removedAudioId) {
    return async (dispatch, getState) => {
      const storeState = getState();
      const projectManifest = _.cloneDeep(
        workspaceVersions.selectors.getActiveProjectManifest(storeState),
      );

      // Remove the asset from the projectManifest's assets array
      projectManifest.assets = projectManifest.assets.filter(
        (asset) => asset.id !== removedAudioId,
      );

      // If the deleted audio option was also the default selected option, we need to remove
      // the reference to the deleted asset id on the waymarkAudioLayer (type === 101);
      const audioLayer = _.find(projectManifest.layers, { ty: 101 });
      if (audioLayer && audioLayer.refId === removedAudioId) {
        audioLayer.refId = '';
      }

      await dispatch(workspaceVersions.operations.updateActiveProjectManifest(projectManifest));
      await dispatch(workspaceTemplateManifest.actions.deleteAudioOption(removedAudioId));
    };
  },
  updateBackgroundAudioDefaultSelection(audioId) {
    return async (dispatch, getState) => {
      // Update the manifest in the store state.
      await dispatch(
        workspaceTemplateManifest.actions.updateBackgroundAudioDefaultSelection(audioId),
      );

      const storeState = getState();
      const projectManifest = _.cloneDeep(
        workspaceVersions.selectors.getActiveProjectManifest(storeState),
      );
      const audioLayer = _.find(projectManifest.layers, { ty: 101 });

      if (!audioLayer) {
        console.error('No audio layer found in the project manifest.');
        return;
      }

      audioLayer.refId = audioId;

      await dispatch(workspaceVersions.operations.updateActiveProjectManifest(projectManifest));
    };
  },
  updateTemplateManifest(updateAction, actionPayload) {
    return async (dispatch, getState) => {
      // Dispatch the provided action before syncing
      // MB TODO: Maybe this should be optional?
      await dispatch(updateAction(actionPayload));

      const newState = getState();
      if (!workspaceTemplateManifest.selectors.getIsWorkspaceSetup(newState)) {
        console.warn(
          'WARNING: Attempted to sync templateManifest before the workspace is setup. This is a noop.',
        );
        return;
      }

      const newManifest = workspaceTemplateManifest.selectors.getTemplateManifest(newState);

      const templateId = workspaceVersions.selectors.getActiveTemplateId(newState);
      const versionNumber = workspaceVersions.selectors.getActiveVersionNumber(newState);

      try {
        await updateVersion(templateId, versionNumber, { templateManifest: newManifest });
        dispatch(workspaceTemplateManifest.actions.manifestSyncSuccessful());
      } catch (e) {
        console.error('MB TODO: Error handling for edit syncing');
      }
    };
  },
};

export default workspaceTemplateManifest;
