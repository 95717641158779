/* eslint-disable no-undef */

/**
 * Helper method to attach an event listener for the fullscreen api across different browsers
 * With help from: https://davidwalsh.name/fullscreen
 * @method requestFullscreen
 * @param  {Function}          eventListener Event Listener for the fullscreenchange event
 */
export function addFullscreenEventListener(eventListener) {
  document.addEventListener('fullscreenchange', eventListener);
  document.addEventListener('mozfullscreenchange', eventListener);
  document.addEventListener('MSFullscreenChange', eventListener);
  return document.addEventListener('webkitfullscreenchange', eventListener);
}

/**
 * Helper method to remove an event listener for the fullscreen api across different browsers
 * With help from: https://davidwalsh.name/fullscreen
 * @method requestFullscreen
 * @param  {Function}          eventListener Event Listener for the fullscreenchange event
 */
export function removeFullscreenEventListener(eventListener) {
  document.removeEventListener('fullscreenchange', eventListener);
  document.removeEventListener('mozfullscreenchange', eventListener);
  document.removeEventListener('MSFullscreenChange', eventListener);
  return document.removeEventListener('webkitfullscreenchange', eventListener);
}

/**
 * Helper Method to request the fullscreen api across different browsers
 * With help from: https://davidwalsh.name/fullscreen
 * @method requestFullscreen
 * @param  {DOM Element}          element DOM Element to call the fullscreen request on
 * @return {Promise|null}                 Callback for when the fullscreen request has been accepted/declined
 *                                        returns null if unable to request fullscreen mode on this DOM element
 */
export function requestFullscreen(element) {
  if (element.requestFullscreen) {
    return element.requestFullscreen();
  }
  if (element.mozRequestFullScreen) {
    return element.mozRequestFullScreen();
  }
  if (element.msRequestFullscreen) {
    return element.msRequestFullscreen();
  }
  if (element.webkitRequestFullscreen) {
    return element.webkitRequestFullscreen();
  }
  return null;
}

/**
 * Helper Method to exit the fullscreen api across different browsers With help
 * from: https://davidwalsh.name/fullscreen
 * @method     requestFullscreen
 */
export function exitFullscreen() {
  if (document.exitFullscreen) {
    return document.exitFullscreen();
  }
  if (document.mozCancelFullScreen) {
    return document.mozCancelFullScreen();
  }
  if (document.msExitFullscreen) {
    return document.msExitFullscreen();
  }
  if (document.webkitExitFullscreen) {
    return document.webkitExitFullscreen();
  }
  return null;
}

/**
 * Helper Method to determine the state of the fullscreen mode across different
 * browsers With help from: https://davidwalsh.name/fullscreen
 * @method     requestFullscreen
 *
 * @return     {boolean}  True if fullscreen enabled, False otherwise.
 */
export function isFullscreenEnabled() {
  return (
    document.currentFullScreenElement != null ||
    document.mozCurrentFullScreenElement != null ||
    document.mozFullScreenEnabled != null ||
    document.msFullscreenEnabled != null ||
    document.webkitCurrentFullScreenElement != null
  );
}
