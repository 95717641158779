/* eslint-env browser */
/* eslint-disable no-param-reassign */
import { createSelector, createSlice } from 'redux-starter-kit';

import { waymarkAuthKey } from '../api/constants.js';
import { fetchAccessToken } from '../api/index.js';

/**
 * `authUser` slice
 * Small reducer and related redux code intended to be consumable by other reducers as needed for api calls, etc.
 * 
 * SLICE DEPENDENCIES:
 *  [ NONE ]
 */
const authUser = createSlice({
  slice: 'authUser',
  initialState: {
    username: null,
    accessToken: null,
    hasBeenLoggedOut: false,
  },
  reducers: {
    forcedLogout: (state) => {
      state.hasBeenLoggedOut = true;
    },
    setUser: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.username = action.payload.username;
      state.hasBeenLoggedOut = false;
    },
    removeUser: (state) => {
      state.username = null;
      state.accessToken = null;
    },
  },
});

authUser.selectors.getAccessToken = createSelector(['authUser.accessToken']);
authUser.selectors.getHasBeenLoggedOut = createSelector(['authUser.hasBeenLoggedOut']);
authUser.selectors.getUsername = createSelector(['authUser.username']);
authUser.selectors.getIsUserAuthenticated = createSelector(
  [authUser.selectors.getAccessToken],
  (accessToken) => !!accessToken,
);

authUser.operations = {
  // Handles fetching an access token for a user. Error handling should be handled by the calling consumer.
  getAccessToken(userCredentials) {
    return async (dispatch) => {
      const token = await fetchAccessToken(userCredentials);
      dispatch(
        authUser.actions.setUser({
          username: userCredentials.username,
          accessToken: token,
        }),
      );
    };
  },
  logoutUser() {
    return async (dispatch) => {
      // Clear the stored token.
      localStorage.removeItem(waymarkAuthKey);

      // Clear the user from our store state.
      await dispatch(authUser.actions.removeUser());
    };
  },
  apiRequestUnauthorized() {
    return async (dispatch) => {
      await dispatch(authUser.actions.forcedLogout());
      await dispatch(authUser.operations.logoutUser());
    }
  }
};

export default authUser;
