/* eslint-env browser */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, PanelStack } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { actions, selectors } from '../store/index.js';
import {
  projectManifestLayerPropType,
  projectManifestCompositionPropType,
} from '../constants/propTypes.js';
import CompositionListPanel from './CompositionListPanel.js';
import { darkBackground, defaultBackground, defaultText } from './lib/colors.js';
import LayerListFilter from './LayerListFilter.js';

const PANEL_HEADER_HEIGHT = 35;
const ALL_COMPOSITIONS_PANEL_ID = 'all_compositions';

/**
 * Component that displays compositions from a project manifest. All compositions are
 * displayed by default and can be expanded, collapsed, and investigated in
 * detail
 *
 * @class      CompositionListPanelStack
 * @param      {Object}    props                        The props
 * @param      {Object[]}  props.compositions                 The compositions, organized by compositions
 * @param      {number}    props.scrollPosition         The current scroll position of the container
 * @param      {Function}  props.onResetScrollPosition
 * When a panel changes, this callback function resets the scroll position of the container back to the previous position
 */
const CompositionListPanelStack = ({ compositions, scrollPosition, onResetScrollPosition }) => {
  const dispatch = useDispatch();

  const [isSectionExpanded, setIsSectionExpanded] = useState(true);
  const [panelHeight, setPanelHeight] = useState(400);

  /**
   * NOTE: Because of the intracacies of the BlueprintJS PanelStack api, props passed into the initialPanel prop
   * are not updated. This means our onPanelResize callback that we pass down to every subsequently created panel
   * is the same function created here on our first invocation of this functional component. Because of this, the
   * scope of onPanelResize is also frozen. We use this ref instead of the activePanelStack state so we can use this
   * ref as a pointer to the current state's value instead of the stale state from the creation of the function
   */
  const activePanelStackDueToFrozenFunctionScope = useRef(null);
  const [activePanelStack, setActivePanelStack] = useState([
    { compositionId: ALL_COMPOSITIONS_PANEL_ID, scrollPosition },
  ]);
  activePanelStackDueToFrozenFunctionScope.current = activePanelStack;

  const [activePanelRef, setActivePanelRef] = useState(null);
  const expandedCompositions = useSelector(selectors.getExpandedCompositions);

  const areAllCompositionsExpanded = expandedCompositions.length === compositions.length;

  /**
   * Expands all compositions
   */
  const onClickExpandAll = () => {
    // When we have expanded all of the compositions, the button instead collapses all of the compositions
    if (areAllCompositionsExpanded) {
      dispatch(actions.collapseAllCompositions());
    } else {
      dispatch(
        actions.expandCompositions({
          compositions: _.map(compositions, 'id'),
        }),
      );
    }
  };

  /**
   * Controls the visibility of this entire section (minus the header)
   */
  const onClickExpandSection = () => {
    setIsSectionExpanded(!isSectionExpanded);
  };

  /**
   * Called on the resize of a panel. We need to check and maintain a measure of the
   * height of the current panel, as the PanelStack is an absolutely-positioned element
   * and won't expand to the height of its children.
   *
   * NOTE: This function is passed down to the initial panel and all subsequently-created panels
   * Because of the intracacies of the BlueprintJS PanelStack API, the props of the panel are not updated,
   * meaning this function's scope is frozen in time at the moment of its creation, hence the use of the
   * activePanelStackDueToFrozenFunctionScope ref for the variable.
   *
   * @param      {Object}  props                     The props
   * @param      {Number}  props.height              The height of the panel
   * @param      {String}  props.panelCompositionId  The id of the composition, used to id what panel is resizing
   * @param      {Ref}     props.panelRef            A react ref object to the panel
   */
  const onPanelResize = ({ height, panelCompositionId, panelRef }) => {
    if (
      panelCompositionId ===
      activePanelStackDueToFrozenFunctionScope.current[
        activePanelStackDueToFrozenFunctionScope.current.length - 1
      ].compositionId
    ) {
      setPanelHeight(height);
      setActivePanelRef(panelRef);
    }
  };

  /**
   * A callback for when a new composition panel is opened
   *
   * @param      {object}  panel         The panel
   * @param      {object}  panel.props   The panel's props
   * @param      {string}  panel.props.panelCompositionId
   * The panel's composition id, used for identifying a panel
   */
  const onOpenPanel = (panel) => {
    setActivePanelStack([
      ...activePanelStack,
      {
        // Record the scroll position so we can scroll the panel back to its previous spot
        scrollPosition,
        // We need to keep track of the current panel stack, so we record the panels' id as they come in
        compositionId: panel.props.panelCompositionId,
      },
    ]);
  };

  const onClosePanel = () => {
    const { scrollPosition: previousScrollPosition } = activePanelStack[
      activePanelStack.length - 1
    ];
    setActivePanelStack(activePanelStack.slice(0, activePanelStack.length - 1));

    // The closePanel callback gets called right before the panels actually switch (and there isn't any other event to hook into),
    // so set a timeout and scroll the position once the panels switch out
    setTimeout(() => {
      onResetScrollPosition(previousScrollPosition);
    });
  };

  useEffect(() => {
    const onWindowResize = () => {
      if (activePanelRef && activePanelRef.current) {
        // When our window resizes, update the height based on the active panel's current height
        const { height } = activePanelRef.current.getBoundingClientRect();
        setPanelHeight(height);
      }
    };

    // Get the original state of the panel height
    onWindowResize();
    window.addEventListener('resize', onWindowResize);
    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [setPanelHeight, activePanelRef]);

  const isRootComposition = activePanelStack && activePanelStack.length === 1;

  return (
    <div
      css={{
        position: 'relative',
        flex: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div css={{ position: 'absolute', right: 0, top: 3, zIndex: 2 }}>
        {isRootComposition && (
          <Button
            icon={areAllCompositionsExpanded ? IconNames.COLLAPSE_ALL : IconNames.EXPAND_ALL}
            title={
              areAllCompositionsExpanded ? 'Collapse all compositions' : 'Expand all compositions'
            }
            onClick={onClickExpandAll}
            minimal
          />
        )}
        <Button
          icon={isSectionExpanded ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN}
          title={isSectionExpanded ? 'Collapse section' : 'Expand section'}
          onClick={onClickExpandSection}
          minimal
        />
      </div>
      <PanelStack
        initialPanel={{
          component: CompositionListPanel,
          title: 'Layers',
          props: {
            compositions,
            onPanelResize,
            panelCompositionId: ALL_COMPOSITIONS_PANEL_ID,
          },
        }}
        onOpen={onOpenPanel}
        onClose={onClosePanel}
        css={{
          minHeight: isSectionExpanded ? panelHeight + PANEL_HEADER_HEIGHT : PANEL_HEADER_HEIGHT,
          flex: isSectionExpanded ? 'auto' : 0,
          ' .bp3-panel-stack-view': {
            backgroundColor: 'transparent',
            overflow: isSectionExpanded ? 'auto' : 'hidden',
          },
          ' .bp3-panel-stack-header': {
            backgroundColor: defaultBackground,
            color: defaultText,
            fontSize: 12,
            border: `1px solid ${darkBackground}`,
            paddingRight: isRootComposition ? 60 : 30,
          },
          ' .bp3-panel-stack-header > span': {
            flex: 0,
          },
          ' .bp3-panel-stack-header > span:first-of-type': {
            maxWidth: '33%',
          },
          ' .bp3-heading': {
            marginLeft: 13,
          },
          ' .bp3-panel-stack-header button': {
            marginRight: -8,
          },
          ' .bp3-panel-stack-header .bp3-button-text': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            wordWrap: 'normal',
            flex: 'auto',
          },
        }}
      />
      {isSectionExpanded && (
        <LayerListFilter
          css={{
            width: '100%',
            position: 'sticky',
            bottom: 0,
          }}
        />
      )}
    </div>
  );
};

CompositionListPanelStack.propTypes = {
  compositions: PropTypes.arrayOf(
    PropTypes.oneOfType([projectManifestLayerPropType, projectManifestCompositionPropType]),
  ).isRequired,
  scrollPosition: PropTypes.number.isRequired,
  onResetScrollPosition: PropTypes.func.isRequired,
};

export default CompositionListPanelStack;
