import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Flex, Box } from 'reflexbox';
import PropTypes from 'prop-types';
import cxs from 'cxs';
import { Alert, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { uuid } from '../utils/uuid.js';
import { wtsIntent } from '../constants/index.js';
import { WTSButton, WTSIcon } from './lib/index.js';
import { defaultText, darkBackground, selectedText, selectedTextBackground } from './lib/colors.js';

const itemProperties = {
  color: defaultText,
  fontSize: '12px',
  borderBottom: `solid 1px ${darkBackground}`,
  padding: '4px 12px',
  ':hover': {
    cursor: 'pointer',
  },
};

const itemStyle = cxs(itemProperties);
const selectedItemStyle = cxs({
  ...itemProperties,
  backgroundColor: selectedTextBackground,
  color: selectedText,
});

const itemNameStyles = cxs({
  display: 'inline-block',
});

const iconStyles = cxs({
  display: 'inline-block',
  marginRight: '6px',
  verticalAlign: 'bottom',
});

const ListItem = ({ selectedItemId, item, onClick }) => {
  const onClickGroup = () => {
    onClick(item.id);
  };

  const isSelected = item.id === selectedItemId;

  return (
    /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
    <div className={isSelected ? selectedItemStyle : itemStyle} onClick={onClickGroup}>
      {item.wtsIconType ? (
        <WTSIcon
          className={iconStyles}
          iconType={item.wtsIconType}
          theme={isSelected ? 'dark' : 'light'}
        />
      ) : null}
      <div className={itemNameStyles}>
        {item.name +
          (typeof item.displayName === 'string'
            ? ` (${item.displayName || '**empty string**'})`
            : '')}
      </div>
    </div>
  );
};

const listItemPropType = PropTypes.shape({
  name: PropTypes.string,
  id: PropTypes.string,
  icon: PropTypes.string,
});

ListItem.propTypes = {
  item: listItemPropType.isRequired,
  selectedItemId: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

ListItem.defaultProps = {
  selectedItemId: null,
};

const buttonContainerStyles = cxs({
  margin: '12px 18px',
});

/**
 * A standardized UI component for managing lists of similar items within the Waymark Template Studio.
 * Supports adding, selecting, and deleting items.
 * @param  {React.Component} [CreateButtonComponent]
 *    Optional component that will take the place of the standard "Create" button UI for handling item creation.
 *    Component should take a required `onCreate` callback prop. The ListManager will forward along any args provided
 *    to the callback when calling the consumer's `onCreate` callback.
 * @param  {Object[]} items
 *    Array of items of the form { id, name, wtsIconType }. `wtsIconType` is optional, and if present will render
 *    the corresponding icon inline on the left side of the item name.
 * @param  {string} [listItemName]  The generic name to use for an item in this list. E.g., 'layer' or 'group'
 * @param  {func} [onCreate]  Callback hit when the user requests a new item be created.
 *      NOTE: this does not create a new item internally, the consumer must handle item creation.
 * @param  {func} onDelete  Callback hit when the user requests an item be removed.
 *      NOTE: this does not delete the item internally, the consumer must handle item deletion.
 * @param  {func} onItemSelected  Callback informing the consumer that a new item has been selected.
 * @param  {bool} [shouldAllowCreate] Allows the user to specify whether or not to show an item creation UI.
 * @param  {string} [selectedItemIdOverride]
 *      Optional way for the consumer to force-override the selected item at a particular moment in time.
 *      NOTE: This is a "moment-in-time" override -- we only react to this override when the value changes.
 *      If, for example, the comsumer provides a `selectedItemIdOverride`, then the user subsequently selects
 *      a new item, that selection will be respected. The override only "wins" in the moment it is initially set.
 *      Otherwise the component behaves internally as thought the override didn't exist
 */
const ListManager = ({
  CreateButtonComponent,
  children,
  items,
  listItemName,
  onCreate,
  onDelete,
  onItemSelected,
  shouldAllowCreate,
  shouldAllowDelete,
  selectedItemIdOverride,
}) => {
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [shouldDisplayCreateSuccess, setShouldDisplayCreateSuccess] = useState(false);
  const selectedItem = _.find(items, { id: selectedItemId });

  /* If the consumer would like to "force select" / programatically select an item in the
  list, we allow passing a `selectedItemIdOverride`, which will update the selected item
  once whenever the value changes. Subsequent selections will updated the selected item as usual.  */
  useEffect(() => {
    if (selectedItemIdOverride) {
      setSelectedItemId(selectedItemIdOverride);
    }
  }, [selectedItemIdOverride]);

  const selectItemId = (itemId) => {
    setSelectedItemId(itemId);
    onItemSelected(itemId);
  };

  const onClickItem = (itemId) => {
    selectItemId(itemId);
  };

  const onClickDelete = () => {
    setIsAlertOpen(true);
  };

  const onClickCreate = async (...args) => {
    const newItemId = uuid();
    selectItemId(newItemId);
    await onCreate(newItemId, ...args);

    // Display a "Success" message for one second after we've created a new item.
    setShouldDisplayCreateSuccess(true);
    setTimeout(() => setShouldDisplayCreateSuccess(false), 1000);
  };

  /**
   * Handles the close even from the Alert UI.
   * @param  {boolean} didConfirmDelete
   *      Whether or not the use confirmed their desire to delete the item.
   */
  const onCloseAlert = (didConfirmDelete) => {
    if (didConfirmDelete) {
      const idToDelete = selectedItemId;

      selectItemId(null);
      onDelete(idToDelete);
    }
    setIsAlertOpen(false);
  };

  return (
    <div>
      <Alert
        isOpen={isAlertOpen}
        confirmButtonText={`Delete ${listItemName}`}
        cancelButtonText="Cancel"
        onClose={onCloseAlert}
        intent={Intent.DANGER}
      >
        Are you sure you want to delete the &ldquo;{selectedItem ? selectedItem.name : ''}&ldquo;{' '}
        {listItemName}?
      </Alert>
      <div>
        {items.map((item) => (
          <ListItem
            key={item.id}
            item={item}
            selectedItemId={selectedItemId}
            onClick={onClickItem}
          />
        ))}
        <Flex className={buttonContainerStyles}>
          {shouldAllowCreate && (
            <Box w={3 / 4} px="4px">
              {CreateButtonComponent ? (
                <CreateButtonComponent onCreate={onClickCreate} />
              ) : (
                <WTSButton
                  wtsIntent={shouldDisplayCreateSuccess ? wtsIntent.success : wtsIntent.default}
                  onClick={onClickCreate}
                  icon={shouldDisplayCreateSuccess ? IconNames.TICK : IconNames.ADD}
                  fullWidth
                >
                  {shouldDisplayCreateSuccess ? 'Created' : 'Create New'}
                </WTSButton>
              )}
            </Box>
          )}
          <Box w={shouldAllowCreate ? 1 / 4 : 1} px="4px">
            <WTSButton
              wtsIntent={wtsIntent.danger}
              onClick={onClickDelete}
              disabled={!shouldAllowDelete || !selectedItemId}
              fullWidth
            >
              Delete
            </WTSButton>
          </Box>
        </Flex>
        {children && <div>{children}</div>}
      </div>
    </div>
  );
};

ListManager.propTypes = {
  CreateButtonComponent: PropTypes.func,
  items: PropTypes.arrayOf(listItemPropType).isRequired,
  listItemName: PropTypes.string,
  onCreate: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  onItemSelected: PropTypes.func,
  shouldAllowCreate: PropTypes.bool,
  shouldAllowDelete: PropTypes.bool,
  selectedItemIdOverride: PropTypes.string,
};

ListManager.defaultProps = {
  CreateButtonComponent: null,
  listItemName: 'item',
  onCreate: () => {},
  onItemSelected: () => {},
  shouldAllowCreate: false,
  shouldAllowDelete: true,
  selectedItemIdOverride: null,
};

export default ListManager;
