import React from 'react';
import PropTypes from 'prop-types';
import cxs from 'cxs';

const canvas = cxs({
  display: 'block',
  height: 'auto',
  margin: '0 auto',
  maxWidth: '100%',
});

/**
 * A canvas component for a renderer
 *
 * @class      RendererPlayer (name)
 * @param      {Object}  props               The props
 * @param      {Boolean}  props.isSettingUp  If the renderer used for this canvas is setting up
 * @param      {Object}  ref                 A React ref to the canvas
 */
const RendererCanvas = React.forwardRef(({ isSettingUp }, ref) => (
  <canvas
    ref={ref}
    className={canvas}
    style={{
      cursor: isSettingUp ? 'wait' : 'default',
    }}
  />
));

RendererCanvas.propTypes = {
  isSettingUp: PropTypes.bool,
};

RendererCanvas.defaultProps = {
  isSettingUp: false,
};

export default RendererCanvas;
