/* eslint-env browser */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import { routePaths } from '../constants/index.js';
import { actions, operations, selectors } from '../store/index.js';
import { waymarkAuthKey } from '../api/constants.js';
import Login from './Login.js';
import TemplateWorkspace from './TemplateWorkspace.js';
import TemplateSelection from './TemplateSelection.js';
import TopMenu from './TopMenu.js';

/**
 * Route that requires a user to log in.
 * @param  {node}   component         Component to render if the user is logged in 
 * @param  {Object} {...routeProps}   Props to pass to the route
 */
const LoginRequiredRoute = ({ component: Component, ...routeProps }) => {
  const isUserAuthenticated = useSelector(selectors.getIsUserAuthenticated);

  return (
    <Route
      {...routeProps}
      render={(props) =>
        isUserAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

LoginRequiredRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

const routes = [
  { path: routePaths.login, component: Login, routeComponent: Route, hasTopMenu: false, exact: false },
  {
    path: routePaths.templateWorkspace,
    component: TemplateWorkspace,
    hasTopMenu: true,
    exact: false,
  },
  {
    path: routePaths.templateSelection,
    component: TemplateSelection,
    hasTopMenu: true,
    exact: true,
  },
];

const topMenuPaths = routes.filter((route) => route.hasTopMenu).map((route) => route.path);

/**
 * Top-level application router for the Waymark Template Studio.
 */
const App = () => {
  const dispatch = useDispatch();

  // Set a global interceptor to handle 401 responses, which typically means a user's auth token has expired. 
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      // Adds a global handler for an expired auth token
      if (error.response.status === 401) {
        dispatch(operations.apiRequestUnauthorized());
      }

      // Reject as per usual
      return Promise.reject(error);
    }
  );

  // If we render before the authUser data has been found and set, we'll always redirect
  // back to login on first render.
  const [isRenderReady, setIsRenderReady] = useState(false);

  // Only once, look for auth data and populate the store if it's found.
  useEffect(() => {
    const checkForStoredUser = async () => {
      const stringifiedAuthData = localStorage.getItem(waymarkAuthKey);

      if (stringifiedAuthData) {
        const authData = JSON.parse(stringifiedAuthData);
        // Set the access token in the Authorization header.
        axios.defaults.headers.common.Authorization = `Bearer ${authData.accessToken}`;
        await dispatch(actions.setUser(authData));
      }
      setIsRenderReady(true);
    };

    checkForStoredUser();
  }, []);

  if (!isRenderReady) return null;

  return (
    <Router>
      <Route path={topMenuPaths} component={TopMenu} />
      {/* Top-level components -- should only render one at a time */}
      <Switch>
        {routes.map(({ path, exact, component, routeComponent }) => {
          const RouteComponent = routeComponent || LoginRequiredRoute;
          return (
            <RouteComponent key={path} path={path} exact={exact} component={component} />
          )
        })}
        {/* If we're at the root, let's take the user to the templates screen */}
        <Redirect from="/" to="/templates" />
      </Switch>
    </Router>
  );
};

export default process.env.NODE_ENV === 'production' ? App : hot(module)(App);
