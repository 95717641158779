/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { createSelector, createSlice } from 'redux-starter-kit';

import { fetchTemplate, fetchTemplates, updateTemplate } from '../api/index.js';
import { supportLoadingStates } from './utils.js';

/**
 * `templates` slice for managing template-level data.
 * 
 * SLICE DEPENDENCIES:
 *  [ NONE ]
 */

const { initialState, caseReducers } = supportLoadingStates(['fetchAllTemplates']);
const defaultState = {
  ...initialState,
  all: [],
  shouldFetchAllTemplates: true,
};

const templates = createSlice({
  slice: 'templates',
  initialState: defaultState,
  reducers: {
    ...caseReducers,
    receivedTemplates: (state, action) => {
      state.all = action.payload;
      state.shouldFetchAllTemplates = false;
    },
    receivedTemplate: (state, action) => {
      const { template: newTemplate } = action.payload;
      state.all = state.all
        .filter((template) => template.id !== newTemplate.id)
        .concat(newTemplate);
    },
  },
});

templates.selectors.getTemplates = createSelector(
  ['templates.all'],
  (allTemplates) =>
    allTemplates.map((template) =>
      // We want these to be fully-formed JS Date objects tho, which can't be stored in state.
      ({
        ...template,
        createdAt: new Date(template.createdAt),
        modifiedAt: new Date(
          template.unpublishedVersionModifiedAt || template.publishedVersionModifiedAt,
        ),
      }),
    ),
);

templates.selectors.getTemplateById = (state, templateId) =>
  createSelector(
    [templates.selectors.getTemplates],
    (allTemplates) => _.find(allTemplates, { id: templateId }),
  )(state);

templates.selectors.getLatestVersionByTemplateId = (state, templateId) => {
  const template = templates.selectors.getTemplateById(state, templateId);
  return template ? template.unpublishedVersion || template.publishedVersion : null;
};

templates.selectors.getIsFetchingTemplates = createSelector([
  'templates.isFetchAllTemplatesInProgress',
]);

templates.selectors.getShouldFetchAllTemplates = createSelector(
  ['templates.shouldFetchAllTemplates', templates.selectors.getIsFetchingTemplates],
  (shouldFetchAllTemplates, isFetchingTemplates) => shouldFetchAllTemplates && !isFetchingTemplates,
);

templates.operations = {
  getTemplates() {
    return async (dispatch, getState) => {
      if (!templates.selectors.getShouldFetchAllTemplates(getState())) {
        return;
      }

      dispatch(templates.actions.fetchAllTemplatesInProgress());
      try {
        const response = await fetchTemplates();
        dispatch(templates.actions.receivedTemplates(response.data));
        dispatch(templates.actions.fetchAllTemplatesCompleted());
      } catch (e) {
        dispatch(templates.actions.fetchAllTemplatesFailed(e.message));
      }
    };
  },
  fetchTemplateById(templateId) {
    return async (dispatch, getState) => {
      const cachedTemplate = templates.selectors.getTemplateById(getState(), templateId);
      if (cachedTemplate) {
        return cachedTemplate;
      }

      try {
        const templateRes = await fetchTemplate(templateId);
        const template = templateRes.data;
        dispatch(templates.actions.receivedTemplate({ template }));
        return template;
      } catch (e) {
        console.error(e);
        return null;
      }
    };
  },
  updateTemplate(templateId, payload) {
    return async (dispatch) => {
      try {
        const templateRes = await updateTemplate(templateId, payload);
        dispatch(templates.actions.receivedTemplate({ template: templateRes.data }));
      } catch (e) {
        console.error(e);
      }
    };
  },
};

export default templates;
