export * from './propTypes.js';

export const routePaths = {
  login: '/login',
  templateSelection: '/templates',
  templateWorkspace: '/templates/:templateId',
};

export const wtsIntent = {
  alt: 'alt', // The blue one.
  blended: 'blended', // The one that blends in with the panel.
  default: 'default', // The gray one.
  danger: 'danger', // The deep red one.
  primary: 'primary', // The yellow one.
  selected: 'selected', // The light gray one.
  success: 'success', // The green one.
};

export const templateGroups = {
  all: 'All',
  recent: 'Recent',
  unpublished: 'Unpublished',
  published: 'Published',
};

export const templateImportStatus = {
  none: 'none',
  uploading: 'uploading',
  queued: 'queued',
  creatingTemplate: 'creatingTemplate',
  creatingVersion: 'creatingVersion',
  addingImportToVersion: 'addingImportToVersion',
  complete: 'complete',
  failed: 'failed',
};

export const templateSettingTypes = {
  basic: 'basic',
  overrides: 'overrides',
  groups: 'groups',
  switches: 'switches',
  music: 'music',
};

export const templateLicenseTypes = {
  tvReady: {
    label: 'TV ready',
    value: 'tvReady',
  },
  web: {
    label: 'Web only - not licensed for TV',
    value: 'web',
  },
};

export const overrideTypes = {
  color: 'color',
  font: 'font',
  image: 'image',
  text: 'text',
};

export const contentOverrideTypes = [overrideTypes.text, overrideTypes.image];

export const templateManifestOverrideKeys = {
  content: 'content',
  color: {
    fillColor: 'fillColor',
    fillEffect: 'fillEffect',
    strokeColor: 'strokeColor',
    gradientFill: 'gradientFill',
  },
  font: 'font',
};

export const overrideDetailFormLabels = {
  [overrideTypes.color]: {
    name: {
      label: 'Override name',
      labelSubtext: 'Describe the group of layers',
    },
    placeholder: {
      label: 'CSS hex code',
      labelSubtext: 'The templates default value for this color',
    },
    displayName: {
      label: 'Display name',
      labelSubtext: 'This name may be shown to users',
    },
    frameNumber: {
      label: 'Frame number',
      labelSubtext: 'Set a frame number that best represents this color',
    },
  },
  [overrideTypes.image]: {
    name: {
      label: 'Override name',
      labelSubtext: 'Describe the group of layers',
    },
    placeholder: {
      label: 'Placeholder image',
      labelSubtext: 'The image that overrides layer content',
    },
    frameNumber: {
      label: 'Frame number',
      labelSubtext: 'Overrides any layer frame numbers',
    },
  },
  [overrideTypes.text]: {
    name: {
      label: 'Override name',
      labelSubtext: 'Describe the group of layers',
    },
    placeholder: {
      label: 'Placeholder content',
      labelSubtext: 'The text that overrides layer content',
    },
    frameNumber: {
      label: 'Frame number',
      labelSubtext: 'Overrides any layer frame numbers',
    },
  },
};

export const templateQueueStatus = {
  none: 'none',
  queued: 'queued',
  creatingTemplate: 'creatingTemplate',
  // `complete` is unused because the client only polls for waiting and inProgress templates
  complete: 'complete',
  // `failed` is unused because the client only polls for waiting and inProgress templates
  failed: 'failed',
};

export const importStatuses = {
  waiting: 'waiting',
  inProgress: 'inProgress',
  complete: 'complete',
  failed: 'failed',
};
