import _ from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cxs from 'cxs';
import { useSelector, useDispatch } from 'react-redux';

import { blurIfEnterKeyPress } from '../utils/dom.js';
import { useMirroredState } from '../utils/hooks.js';
import { sceneSwitchPropType } from '../constants/index.js';
import { actions, operations, selectors } from '../store/index.js';
import { collapsibleMenuContentContainer, WTSFormGroup, WTSInputGroup, WTSDropdownMenu } from './lib/index.js';
import { darkBackground, defaultText, selectedText, selectedTextBackground } from './lib/colors.js';
import CollapsibleMenu from './CollapsibleMenu.js';
import ListManager from './ListManager.js';

const groupProperties = {
  ':hover': {
    cursor: 'pointer',
  },
};

const defaultGroupStyles = cxs({
  ...groupProperties,
});
const selectedGroupStyles = cxs({
  ...groupProperties,
  color: selectedText,
  backgroundColor: selectedTextBackground,
});

const GroupDisplay = ({ groupId, isSelected, onClick }) => {
  const group = useSelector((state) => selectors.getSceneGroupByUUID(state, groupId));
  const onClickGroup = () => {
    onClick(groupId, isSelected);
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className={isSelected ? selectedGroupStyles : defaultGroupStyles} onClick={onClickGroup}>
      {group.name}
    </div>
  );
};

GroupDisplay.propTypes = {
  groupId: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const groupListStyles = cxs({
  fontSize: '11px',
  lineHeight: 1.27,
  color: defaultText,
  backgroundColor: darkBackground,
  padding: '6px',
});

const SwitchDetailForm = ({ sceneSwitch }) => {
  const sceneSwitchName = sceneSwitch ? sceneSwitch.name : '';
  const sceneSwitchFrameNumber = sceneSwitch ? sceneSwitch.frameNumber : '';
  const [localName, setLocalName] = useMirroredState(sceneSwitchName);
  const [localFrameNumber, setLocalFrameNumber] = useMirroredState(sceneSwitchFrameNumber);

  const allGroups = useSelector(selectors.getTemplateManifestGroups);
  const dispatch = useDispatch();

  const updateSceneSwitch = (updatedSwitch) => {
    dispatch(operations.updateTemplateManifest(actions.updateSceneSwitch, updatedSwitch));
  };

  /**
   * `name` field handlers
   */

  const onChangeSwitchName = (e) => {
    setLocalName(e.currentTarget.value);
  };

  const onSubmitSwitchName = () => {
    if (localName !== sceneSwitchName) {
      const modifiedSwitch = {
        ...sceneSwitch,
        name: localName,
      };
      updateSceneSwitch(modifiedSwitch);
    }
  };

  /**
   * `frameNumber` field handlers
   */

  const onChangeSwitchFrameNumber = (e) => {
    setLocalFrameNumber(e.currentTarget.value);
  };

  const onSubmitSwitchFrameNumber = () => {
    if (localFrameNumber !== sceneSwitchFrameNumber) {
      const modifiedSwitch = {
        ...sceneSwitch,
        // Make sure we're passing an integer.
        frameNumber: parseInt(localFrameNumber, 10),
      };
      updateSceneSwitch(modifiedSwitch);
    }
  };

  /**
   * `groups` field handlers
   */
  const onToggleGroupSelection = (groupId, isCurrentlySelected) => {
    const newSwitch = { ...sceneSwitch };
    const currentGroups = [...sceneSwitch.groups];
    // If it's selected now, we want to de-select the group by removing it from the list.
    if (isCurrentlySelected) {
      newSwitch.groups = _.without(currentGroups, groupId);
      // Otherwise, let's add it to the list
    } else {
      currentGroups.push(groupId);
      newSwitch.groups = currentGroups;
    }
    updateSceneSwitch(newSwitch);
  };

  /**
   * `defaultSelection` field handlers
   */
  const onChangeDefaultSelection = (menuItem) => {
    const modifiedSwitch = {
      ...sceneSwitch,
      defaultSelection: menuItem.value,
    };
    updateSceneSwitch(modifiedSwitch);
  };

  if (!sceneSwitch) {
    return null;
  }

  let menuItems = sceneSwitch
    ? sceneSwitch.groups.map((groupId) => {
      const group = _.find(allGroups, { id: groupId });
      return group ? { label: group.name, value: group.id } : null;
    })
    : [];
  
  // Remove empty items in the case the group was somehow removed.
  menuItems = _.filter(menuItems, _.identity);

  const dropdownSelection = _.find(menuItems, { value: sceneSwitch.defaultSelection });

  return (
    <form className={collapsibleMenuContentContainer}>
      <WTSFormGroup label="Switch name" labelFor="name" labelSubtext="Describe the switch groups">
        <WTSInputGroup
          id="name"
          type="text"
          value={localName}
          onChange={onChangeSwitchName}
          onBlur={onSubmitSwitchName}
          onKeyPress={blurIfEnterKeyPress}
        />
      </WTSFormGroup>
      <WTSFormGroup
        label="Frame number"
        labelFor="frameNumber"
        labelSubtext="Overrides layer frame numbers"
      >
        <WTSInputGroup
          id="frameNumber"
          type="number"
          value={localFrameNumber}
          onChange={onChangeSwitchFrameNumber}
          onBlur={onSubmitSwitchFrameNumber}
          onKeyPress={blurIfEnterKeyPress}
        />
      </WTSFormGroup>
      <WTSFormGroup
        label="Groups included in this switch"
        labelSubtext="Enable switching between these groups"
      >
        <div className={groupListStyles}>
          {allGroups.map((group) => (
            <GroupDisplay
              key={group.id}
              groupId={group.id}
              isSelected={sceneSwitch.groups.includes(group.id)}
              onClick={onToggleGroupSelection}
            />
          ))}
        </div>
      </WTSFormGroup>
      <WTSDropdownMenu
        label="Default selection"
        labelSubtext="The initially selected group"
        menuItems={menuItems}
        onItemSelect={onChangeDefaultSelection}
        value={dropdownSelection}
      />
    </form>
  );
};

SwitchDetailForm.propTypes = {
  sceneSwitch: sceneSwitchPropType,
};

SwitchDetailForm.defaultProps = {
  sceneSwitch: null,
};

const SwitchesPanelContent = () => {
  const [selectedSwitchId, setSelectedSwitchId] = useState(null);
  const dispatch = useDispatch();

  const sceneSwitches = useSelector(selectors.getTemplateManifestSwitches);
  const selectedSwitch = _.find(sceneSwitches, { id: selectedSwitchId });

  const onClickSwitch = (sceneSwitchId) => {
    setSelectedSwitchId(sceneSwitchId);
  };

  const onDeleteSwitch = (sceneSwitchId) => {
    dispatch(operations.updateTemplateManifest(actions.deleteSceneSwitch, sceneSwitchId));
  };

  const onCreateSwitch = async (newSwitchId) => {
    await dispatch(operations.updateTemplateManifest(actions.createSceneSwitch, newSwitchId));
  };

  return (
    <div>
      <CollapsibleMenu title="Switches">
        <ListManager
          items={sceneSwitches}
          listItemName="switch"
          onCreate={onCreateSwitch}
          onDelete={onDeleteSwitch}
          onItemSelected={onClickSwitch}
          shouldAllowCreate
        />
      </CollapsibleMenu>
      <CollapsibleMenu title="Switch properties">
        <SwitchDetailForm sceneSwitch={selectedSwitch} />
      </CollapsibleMenu>
    </div>
  );
};

export default SwitchesPanelContent;
