/** @jsx jsx */
import { jsx } from '@emotion/core';
import _ from 'lodash';
import cxs from 'cxs';
import { ControlGroup, Intent, Radio } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fitFillAlignments } from '@stikdev/waymark-author-web-renderer';

import { selectors } from '../store/index.js';
import CollapsibleMenu from './CollapsibleMenu.js';
import {
  DynamicPropertyUpdateDispatch,
  DynamicPropertyUpdateState,
} from './contextProviders/DynamicPropertyUpdateProvider.js';
import { layerTypes } from '../constants/TemplateManifest.js';
import {
  collapsibleMenuContentContainer,
  WTSFormGroup,
  WTSInputGroup,
  WTSSwitch,
  WTSDropdownMenu,
} from './lib/index.js';

const switchMenuItem = cxs({
  display: 'flex',
  alignItems: 'center',
});

const labelSubtitleProperties = {
  fontSize: '11px',
  color: '#888888',
  marginTop: '-5px',
  marginBottom: '3px',
  marginRight: '18px',
};

const switchSubtitle = cxs({
  ...labelSubtitleProperties,
  marginTop: 0,
});

const uneditableMessage = cxs({
  height: '134px',
  fontSize: '18px',
  textAlign: 'center',
  padding: '30px 10px 0',
});

const editableContentFormFields = cxs({
  maxWidth: '242px',
  width: '100%',
});

/**
 * Text placeholder content and vertical alignment form fields.
 * @param  {string}   onChange  Callback function for on change event.
 * @param  {string}   value     Text placeholder content.
 */
const TextPlaceholderFormField = ({ onBlur, onChange, value }) => (
  // SS TODO: Add vertical alignment dropdown.
  <WTSFormGroup
    intent={Intent.NONE}
    label="Placeholder Content"
    labelFor="placeholder"
    labelSubtext="Placeholder text and vertical alignment"
  >
    <WTSInputGroup
      id="placeholder"
      intent={Intent.NONE}
      onBlur={onBlur}
      onChange={onChange}
      type="text"
      value={value}
    />
  </WTSFormGroup>
);

TextPlaceholderFormField.propTypes = {
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

const VideoOptionChoiceFormField = ({
  option,
  isDefaultVideo,
  onChangeIsUsed,
  onChangeName,
  onBlurName,
  onChangeDefaultVideo,
}) => {
  const onToggleUsed = () => {
    onChangeIsUsed(option, !option.isUsed);
  };

  const onChangeVideoOptionName = (event) => {
    onChangeName(option, event.target.value);
  };

  const onBlurVideoOptionName = (event) => {
    onBlurName(option, event.target.value);
  };

  const onChangeDefault = () => {
    onChangeDefaultVideo(option);
  };

  return (
    <ControlGroup
      css={{
        alignItems: 'center',
      }}
      fill
    >
      <WTSSwitch
        intent={Intent.NONE}
        onChange={onToggleUsed}
        checked={option.isUsed}
        title="Is Video Option Used?"
        disabled={isDefaultVideo}
      />
      <WTSInputGroup
        intent={Intent.NONE}
        onChange={onChangeVideoOptionName}
        onBlur={onBlurVideoOptionName}
        type="text"
        value={option.name}
        disabled={!option.isUsed}
        title="Video Option Name"
        placeholder="Video Option Name"
      />
      <Radio
        onChange={onChangeDefault}
        checked={isDefaultVideo}
        disabled={!option.isUsed}
        title="Is Default Video Option?"
        css={{
          marginLeft: 7,
        }}
      />
    </ControlGroup>
  );
};

VideoOptionChoiceFormField.propTypes = {
  onChangeIsUsed: PropTypes.func.isRequired,
  onChangeName: PropTypes.func.isRequired,
  onBlurName: PropTypes.func.isRequired,
  onChangeDefaultVideo: PropTypes.func.isRequired,
  isDefaultVideo: PropTypes.bool.isRequired,
  option: PropTypes.shape({
    name: PropTypes.string.isRequired,
    isUsed: PropTypes.bool.isRequired,
  }).isRequired,
};

/**
 * Renders a content form for dynamic text and layer images.
 * @param  {string}  layerUUID  The layer the content applies to.
 */
const DynamicLayerContentForm = ({ layerUUID }) => {
  const projectManifestData = useSelector((state) => selectors.getLayerByUUID(state, layerUUID));
  const fontOverrides = useSelector(selectors.getTemplateManifestFontOverrides);
  // Right now this is making the assumption that we only have 1 video layer in each project manifest.
  const videoAssets = useSelector(selectors.getActiveProjetManifestVideoAssets);

  const layerType = projectManifestData.type;

  const [formValues, setFormValues] = useState({});
  const [isContentEditable, setIsContentEditable] = useState(false);

  const templateManifestData = useSelector((state) =>
    selectors.getTemplateManifestData(state, layerUUID),
  );

  const onUpdateDynamicProperties = useContext(DynamicPropertyUpdateDispatch);
  const isContentOverrideSet = useContext(DynamicPropertyUpdateState);

  // If the content override was set externally to this component, let's update component state to reflect that!
  useEffect(() => {
    if (isContentOverrideSet) {
      setIsContentEditable(false);
    }
  }, [isContentOverrideSet]);

  // NOTE This was previously causing issues -- becuause state updates are queued and not updated immediately, we
  // were inadvertantly syncing with the previous state in certain cases. Now we rely on an explicitly provided
  const updateDynamicContent = async (shouldSendContent, newContentValues = null) => {
    if (shouldSendContent && !newContentValues) {
      console.error(
        'ERROR: Must explicitly provide content to `updateDynamicContent` in order to sync changes.',
      );
    }
    const updateValue = shouldSendContent ? { content: newContentValues } : { content: null };
    await onUpdateDynamicProperties(updateValue);
  };

  const getDefaultFormValuesImage = (editableContentToggled = false) => {
    const placeholder = _.get(templateManifestData, 'content.placeholder', {
      id: projectManifestData.location.id,
      location: projectManifestData.location,
      modifications: projectManifestData.modifications,
      name: projectManifestData.name,
      type: projectManifestData.type,
    });

    // If this resetting to default was triggered by the editable content switch, we can safely set freeformCropping to false as our new default.
    // Otherwise, we default to true for freeform cropping here because:
    // 1. That matches the editor's default behavior for when a freeformCropping value is not provided.
    // 2. There are old templates that don't have this property set at all, and we want to default to the editor's behavior in those cases.
    let freeformCropping;

    if (editableContentToggled) {
      freeformCropping = _.get(templateManifestData, 'content.freeformCropping', false);
    } else {
      freeformCropping = _.get(templateManifestData, 'content.freeformCropping', true);
    }

    const fitFillAlignment = _.get(
      templateManifestData,
      'content.fitFillAlignment',
      fitFillAlignments.centerCenter,
    );

    return { placeholder, freeformCropping, fitFillAlignment };
  };

  const getDefaultFormValuesVideo = () => {
    const videoAssetsForFootageLayer = videoAssets.filter(({ footageCompositionFor }) => {
      const [compositionId, layerIndex] = footageCompositionFor;
      return (
        compositionId === projectManifestData.parentCompositionId &&
        layerIndex === projectManifestData.index
      );
    });

    const placeholder = _.get(templateManifestData, 'content.placeholder', {
      location: videoAssetsForFootageLayer[0].location,
      modifications: videoAssetsForFootageLayer[0].modifications,
      name: videoAssetsForFootageLayer[0].name,
      type: videoAssetsForFootageLayer[0].type,
      isUsed: true,
    });

    const options = _.get(
      templateManifestData,
      'content.options',
      videoAssetsForFootageLayer.map((asset) => ({
        name: asset.name,
        type: asset.type,
        isUsed: true,
        modifications: asset.modifications,
        location: asset.location,
      })),
    );

    return { placeholder, options };
  };

  // This function is used to get the default form values for the layer type.
  // When the optional parameter editableContentToggled is true, it means a manual toggle of the editable content switch has occurred,
  // and we can safely default freeformCropping to false for images.
  const getDefaultFormValues = (editableContentToggled = false) => {
    let defaultFormValues;
    switch (layerType) {
      case layerTypes.image:
        defaultFormValues = getDefaultFormValuesImage(editableContentToggled);
        break;

      case layerTypes.waymarkVideo:
        defaultFormValues = getDefaultFormValuesVideo();
        break;

      default:
        defaultFormValues = {
          placeholder: _.get(
            templateManifestData,
            'content.placeholder',
            projectManifestData.textContent,
          ),
        };
        break;
    }

    defaultFormValues.frameNumber = _.get(
      templateManifestData,
      'content.frameNumber',
      projectManifestData.frameNumber,
    );

    return defaultFormValues;
  };

  useEffect(() => {
    // When a new layer is selected, ensure that the editable content
    // switch and form reflects the layer's current state.
    const currentContentValue = _.get(templateManifestData, 'content');
    setIsContentEditable(
      !_.isEmpty(currentContentValue) && !_.get(currentContentValue, 'override'),
    );

    const defaultFormValues = getDefaultFormValues();
    setFormValues(defaultFormValues);
  }, [layerUUID]);

  const onToggleContentEditable = () => {
    const contentEditable = !isContentEditable;
    setIsContentEditable(contentEditable);
    // When the editable content switch is toggled, re-populate the
    // form with data from the project manifest.
    const defaultFormValues = getDefaultFormValues(true);
    setFormValues(defaultFormValues);
    updateDynamicContent(contentEditable, defaultFormValues);
  };

  const onToggleFontEditable = async (event) => {
    const isEditable = event.target.checked;

    // If we're toggling ON here, let's get the correct override to use.
    const payload = { font: null };
    if (isEditable) {
      const fontOverride = _.find(fontOverrides, ({ originalTypography }) =>
        _.isEqual(originalTypography, projectManifestData.rendererFont),
      );
      payload.font = { override: fontOverride.id };
    }
    await onUpdateDynamicProperties(payload);
  };

  const onFrameNumberChange = (event) => {
    const currentFormValues = _.cloneDeep(formValues);
    currentFormValues.frameNumber = parseInt(event.currentTarget.value, 10);
    setFormValues(currentFormValues);
  };

  const onPlaceholderChange = (event) => {
    const currentFormValues = _.cloneDeep(formValues);
    currentFormValues.placeholder = event.currentTarget.value;
    setFormValues(currentFormValues);
  };

  const onBlur = (event) => {
    event.preventDefault();
    if (isContentEditable) {
      const shouldSendContent = true;
      updateDynamicContent(shouldSendContent, formValues);
    }
  };

  const onSelectFreeformCropping = (freeformCroppingSelection) => {
    const currentFormValues = _.cloneDeep(formValues);
    currentFormValues.freeformCropping = freeformCroppingSelection.value;
    setFormValues(currentFormValues);
    updateDynamicContent(true, currentFormValues);
  };

  const onSelectFitFillAlignment = async (fitFillAlignmentSelection) => {
    const currentFormValues = _.cloneDeep(formValues);
    currentFormValues.fitFillAlignment = fitFillAlignmentSelection.value;
    setFormValues(currentFormValues);
    updateDynamicContent(true, currentFormValues);
  };

  const onChangeIsVideoAssetUsed = (option, isUsed) => {
    const currentFormValues = _.cloneDeep(formValues);
    const videoOption = currentFormValues.options.find(({ location }) =>
      _.isEqual(location, option.location),
    );
    videoOption.isUsed = isUsed;
    setFormValues(currentFormValues);
    updateDynamicContent(true, currentFormValues);
  };

  const onChangeVideoAssetName = (option, name) => {
    const currentFormValues = _.cloneDeep(formValues);
    const videoOption = currentFormValues.options.find(({ location }) =>
      _.isEqual(location, option.location),
    );
    // Also change the placeholder if it changes
    if (_.isEqual(option, currentFormValues.placeholder)) {
      currentFormValues.placeholder.name = name;
    }
    videoOption.name = name;
    setFormValues(currentFormValues);
    return currentFormValues;
  };

  const onBlurVideoAssetName = (option, name) => {
    const currentFormValues = onChangeVideoAssetName(option, name);
    updateDynamicContent(true, currentFormValues);
  };

  const onChangeDefaultVideoAsset = (option) => {
    const currentFormValues = _.cloneDeep(formValues);
    const videoOption = currentFormValues.options.find(({ location }) =>
      _.isEqual(location, option.location),
    );
    // If the option is selected as the default it has to be used
    videoOption.isUsed = true;
    currentFormValues.placeholder = videoOption;
    setFormValues(currentFormValues);
    updateDynamicContent(true, currentFormValues);
  };

  const menuItems = [
    {
      label: 'Fit my entire image',
      value: true,
    },
    {
      label: 'Fill the whole container',
      value: false,
    },
  ];

  const defaultFreeformCroppingValue = _.get(formValues, 'freeformCropping');
  const freeFormCroppingValue = defaultFreeformCroppingValue ? menuItems[0] : menuItems[1];

  const alignmentMenuItems = Object.keys(fitFillAlignments).map((alignment) => ({
    label: alignment,
    value: fitFillAlignments[alignment],
  }));
  const defaultAlignmentCroppingValue = _.get(
    formValues,
    'fitFillAlignment',
    fitFillAlignments.centerCenter,
  );
  const alignmentCroppingValue = alignmentMenuItems.find(
    (menuItem) => menuItem.value === defaultAlignmentCroppingValue,
  );

  const isFontEditable = Boolean(_.get(templateManifestData, 'font.override'));

  return (
    <div>
      <CollapsibleMenu title={`${_.upperFirst(layerType)} content`}>
        <form className={collapsibleMenuContentContainer} id="textContent-form">
          <WTSFormGroup intent={Intent.NONE} label="Content Editable" labelFor="contentEditable">
            <div className={switchMenuItem}>
              <div className={switchSubtitle}>Allow people to change the content</div>
              <WTSSwitch
                id="contentEditable"
                type="contentEditable"
                intent={Intent.NONE}
                onChange={onToggleContentEditable}
                checked={isContentEditable}
                large
              />
            </div>
          </WTSFormGroup>
          {isContentEditable ? (
            <div className={editableContentFormFields}>
              {layerType === layerTypes.text && (
                <TextPlaceholderFormField
                  onChange={onPlaceholderChange}
                  onBlur={onBlur}
                  value={formValues.placeholder}
                />
              )}
              <WTSFormGroup
                intent={Intent.None}
                label="Frame number"
                labelFor="frameNumber"
                labelSubtext="The frame number to jump to when editing"
              >
                <WTSInputGroup
                  id="frameNumber"
                  intent={Intent.None}
                  onChange={onFrameNumberChange}
                  onBlur={onBlur}
                  type="number"
                  value={formValues.frameNumber}
                />
              </WTSFormGroup>
              {layerType === layerTypes.image && (
                <>
                  <WTSDropdownMenu
                    label="Default cropping behavior"
                    labelSubtext="Fill container, or fit entire image"
                    menuItems={menuItems}
                    onItemSelect={onSelectFreeformCropping}
                    value={freeFormCroppingValue}
                  />
                  <WTSDropdownMenu
                    label="Image Alignment for 'Fit My Entire Image'"
                    labelSubtext="The alignment within the container when a user opts to 'fit my entire image'"
                    menuItems={alignmentMenuItems}
                    onItemSelect={onSelectFitFillAlignment}
                    value={alignmentCroppingValue}
                  />
                </>
              )}
              {layerType === layerTypes.waymarkVideo && (
                <WTSFormGroup
                  intent={Intent.None}
                  label="Video Options"
                  labelSubtext="The video options a user can choose from"
                >
                  <div
                    css={{
                      display: 'flex',
                      fontSize: '.8em',
                      margin: '9px 0 5px',
                    }}
                  >
                    <div>Used</div>
                    <div css={{ flex: 'auto', textAlign: 'center' }}>Name</div>
                    <div>Default</div>
                  </div>
                  {formValues.options.map((option) => (
                    <VideoOptionChoiceFormField
                      key={JSON.stringify(option.location)}
                      onChangeIsUsed={onChangeIsVideoAssetUsed}
                      onChangeName={onChangeVideoAssetName}
                      onBlurName={onBlurVideoAssetName}
                      onChangeDefaultVideo={onChangeDefaultVideoAsset}
                      option={option}
                      isDefaultVideo={_.isEqual(option, formValues.placeholder)}
                    />
                  ))}
                </WTSFormGroup>
              )}
            </div>
          ) : (
            <div className={uneditableMessage}>You have turned off editing on this content.</div>
          )}
          {layerType === layerTypes.text && (
            <WTSFormGroup intent={Intent.NONE} label="Font Editable" labelFor="fontEditable">
              <div className={switchMenuItem}>
                <div className={switchSubtitle}>Allow people to change the font</div>
                <WTSSwitch
                  id="fontEditable"
                  type="fontEditable"
                  intent={Intent.NONE}
                  onChange={onToggleFontEditable}
                  checked={isFontEditable}
                  large
                />
              </div>
            </WTSFormGroup>
          )}
        </form>
      </CollapsibleMenu>
    </div>
  );
};

DynamicLayerContentForm.propTypes = {
  layerUUID: PropTypes.string.isRequired,
};

export default DynamicLayerContentForm;
