/* eslint-disable no-param-reassign */
import _ from 'lodash';
import {
  registerInteractiveEvents as rendererRegisterInteractiveEvents,
  unregisterInteractiveEvents as rendererUnregisterInteractiveEvents,
  getObjectsUnderInteraction,
} from '@stikdev/waymark-author-web-renderer';

export { createOutlineObject, removeOutlineObject } from '@stikdev/waymark-author-web-renderer';

/**
 * Gets a full pixisJS.DisplayObject via the display object's waymarkId.
 *
 * @param      {pixijs.DisplayObject}      displayObjectId    A display object's waymarkId.
 * @param      {WaymarkAuthorWebRenderer}  renderer           The renderer
 */
export function getDisplayObject(displayObjectId, renderer) {
  return renderer.findLayerObject(displayObjectId);
}

/**
 * Gets a list of matching pixisJS.DisplayObject via the display object's waymarkId.
 *
 * @param      {pixijs.DisplayObject}      displayObjectId    A display object's waymarkId.
 * @param      {WaymarkAuthorWebRenderer}  renderer           The renderer
 */
export function getDisplayObjects(displayObjectId, renderer) {
  return renderer.filterLayerObjects(displayObjectId);
}

/**
 * Finds a display object, enables its interactivity, and registers click events.
 *
 * @param      {object[]}                 events            The events to be registered
 * @param      {string}                   events[].type       The name of the event trigger
 * @param      {function}                 events[].loggingCallback  The logging callback
 * @param      {object}                   wtsLayer         A Waymark Template Studio layer.
 * @param      {*}                        callbackContext  The logging callback context, the "this" the callback is called with
 * @param      {WaymarkAuthorWebRenderer} renderer         The renderer
 */
export function registerInteractiveEvents(events, wtsLayer, callbackContext, renderer) {
  const displayObjects = getDisplayObjects(wtsLayer.uuid, renderer);
  if (displayObjects.length) {
    events.forEach((event) => {
      displayObjects.forEach((displayObject) => {
        rendererRegisterInteractiveEvents(displayObject, event.type, event.loggingCallback, callbackContext)
      })
    });
  }
}

/**
 * Finds a display object, disables its interactivity, and unregisters click events.
 *
 * @param      {object[]}                  events            The events to be registered
 * @param      {string}                    events[].type       The name of the event trigger
 * @param      {function}                  events[].loggingCallback  The logging callback
 * @param      {object}                    wtsLayer         A Waymark Template Studio layer.
 * @param      {*}                         callbackContext  The logging callback context, the "this" the callback is called with
 * @param      {WaymarkAuthorWebRenderer}  renderer         The renderer
 */
export function unregisterInteractiveEvents(events, wtsLayer, callbackContext, renderer) {
  const displayObjects = getDisplayObjects(wtsLayer.uuid, renderer);
  if (displayObjects.length) {
    events.forEach((event) => {
      displayObjects.forEach((displayObject) => {
        rendererUnregisterInteractiveEvents(displayObject, event.type, event.loggingCallback, callbackContext)
      })
    });
  }
}

/**
 * Gets the uuids of the layers under the the interaction.
 *
 * @param      {PIXI.InteractionEvent}      event     The event (click, mousemove, etc.)
 * @param      {WaymarkAuthorWebRenderer}   renderer  The renderer
 * @return     {String[]}  The layer uuids under the interaction.
 */
export const getLayerUUIDsUnderInteraction = (event, renderer) => {
  const layers = getObjectsUnderInteraction(event, renderer)
  return _.uniq(_.map(layers, 'waymarkUUID'))
};
