/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from '@blueprintjs/core';
import { WTSButton } from './lib/index.js';

const PublishDialogContents = ({
  publishDialogError,
  isPublishing,
  onCancelPublish,
  onSubmitPublish,
}) => {
  const [isSlugValid, setIsSlugValid] = useState(true);
  const slugInputRef = useRef(null);

  const onSubmit = (event) => {
    event.preventDefault();
    const slug = slugInputRef.current.value;
    if (!slug) {
      setIsSlugValid(false);
      return;
    }

    navigator.clipboard
      .writeText(slug)
      .catch((e) => console.error('Error copying slug to clipboard:', e));

    onSubmitPublish(slug);
  };

  const onTextChange = () => {
    const slug = slugInputRef.current.value;
    setIsSlugValid(Boolean(slug));
  };

  return (
    <form
      onSubmit={onSubmit}
      css={{
        padding: '20px 20px 0',
        width: '100%',
      }}
    >
      <FormGroup
        label="Video Template Slug"
        labelFor="slug"
        intent={isSlugValid && !publishDialogError ? 'default' : 'danger'}
        helperText={publishDialogError}
      >
        <input
          name="slug"
          className={`bp3-input ${isSlugValid && !publishDialogError ? '' : 'bp3-intent-danger'}`}
          ref={slugInputRef}
          type="text"
          placeholder="What is the slug of the template?"
          onChange={onTextChange}
          css={{
            width: '100%',
          }}
        />
      </FormGroup>
      <FormGroup css={{ flexDirection: 'row-reverse' }}>
        <WTSButton type="button" onClick={onCancelPublish}>
          Cancel
        </WTSButton>
        <WTSButton type="submit" wtsIntent="primary" disabled={!isSlugValid} loading={isPublishing}>
          Submit
        </WTSButton>
      </FormGroup>
    </form>
  );
};

PublishDialogContents.propTypes = {
  onCancelPublish: PropTypes.func.isRequired,
  onSubmitPublish: PropTypes.func.isRequired,
  publishDialogError: PropTypes.oneOfType(PropTypes.string, PropTypes.null),
  isPublishing: PropTypes.bool,
};

PublishDialogContents.defaultProps = {
  publishDialogError: null,
  isPublishing: false,
};

export default PublishDialogContents;
