(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("prop-types"), require("cross-fetch/polyfill"), require("@stikdev/video-processing-service-sdk"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "prop-types", "cross-fetch/polyfill", "@stikdev/video-processing-service-sdk"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react"), require("prop-types"), require("cross-fetch/polyfill"), require("@stikdev/video-processing-service-sdk")) : factory(root["react"], root["prop-types"], root["cross-fetch/polyfill"], root["@stikdev/video-processing-service-sdk"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, (__WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_prop_types__, __WEBPACK_EXTERNAL_MODULE_cross_fetch_polyfill__, __WEBPACK_EXTERNAL_MODULE__stikdev_video_processing_service_sdk__) => {
return 