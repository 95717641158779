import React from 'react';
import cxs from 'cxs';
import { useSelector, useDispatch } from 'react-redux';

import { blurIfEnterKeyPress } from '../utils/dom.js';
import { useMirroredState } from '../utils/hooks.js';
import { operations, selectors } from '../store/index.js';
import { WTSAnchorButton, WTSFormGroup, WTSInputGroup } from './lib/index.js';
import CollapsibleMenu from './CollapsibleMenu.js';

const collapseContentStyles = cxs({
  margin: '18px 12px',
});

const BasicSettingsPanelContent = () => {
  const activeTemplateId = useSelector(selectors.getActiveTemplateId);
  const template = useSelector((state) => selectors.getTemplateById(state, activeTemplateId));
  const version = useSelector(selectors.getActiveVersion);

  const templateName = template ? template.name : '';
  const totalTemplatizationTime = template ? template.totalTemplatizationTime || '' : '';

  const [localName, setLocalName] = useMirroredState(templateName);
  const [localTemplatizationTime, setLocalTemplatizationTime] = useMirroredState(totalTemplatizationTime);

  const dispatch = useDispatch();

  const onChangeName = (e) => {
    setLocalName(e.currentTarget.value);
  };

  const onChangeTemplatizationTime = (e) => {
    setLocalTemplatizationTime(e.currentTarget.value);
  };

  const syncTemplateChange = async (payload) => {
    await dispatch(operations.updateTemplate(activeTemplateId, payload));
  };

  const onBlurNameInput = () => {
    if (localName !== templateName) {
      syncTemplateChange({ name: localName });
    }
  };

  const onBlurTemplatizationTimeInput = () => {
    if (localTemplatizationTime !== totalTemplatizationTime) {
      syncTemplateChange({ totalTemplatizationTime: localTemplatizationTime });
    }
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    e.currentTarget.blur();
  };

  return (
    <>
      <CollapsibleMenu title="Template info">
        <form className={collapseContentStyles} onSubmit={onFormSubmit}>
          <WTSFormGroup
            label="Template name"
            labelFor="templateName"
            labelSubtext="As it appears in the template database"
          >
            <WTSInputGroup
              id="templateName"
              type="text"
              value={localName}
              onBlur={onBlurNameInput}
              onChange={onChangeName}
              onKeyPress={blurIfEnterKeyPress}
            />
          </WTSFormGroup>
        </form>
      </CollapsibleMenu>
      <CollapsibleMenu title="Properties">
        <form className={collapseContentStyles} onSubmit={onFormSubmit}>
          <WTSFormGroup
            label="Total templatization time"
            labelFor="templatizationTime"
            labelSubtext="Number of minutes required to configure this template"
          >
            <WTSInputGroup
              id="templatizationTime"
              type="number"
              value={localTemplatizationTime}
              onBlur={onBlurTemplatizationTimeInput}
              onChange={onChangeTemplatizationTime}
              onKeyPress={blurIfEnterKeyPress}
            />
          </WTSFormGroup>
          <WTSAnchorButton
            fullWidth
            href={version.importArchiveURL}
            icon="import"
            target="_blank"
            text="Download After Effects import"
          />
        </form>
      </CollapsibleMenu>
    </>
  );
};

export default BasicSettingsPanelContent;
