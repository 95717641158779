import PropTypes from 'prop-types';
import { layerTypes } from './TemplateManifest.js';

export const templatePropType = PropTypes.shape({
  name: PropTypes.string,
  slug: PropTypes.string,
  publishedVersion: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  unpublishedVersion: PropTypes.string,
  createdAt: PropTypes.instanceOf(Date),
  modifiedAt: PropTypes.instanceOf(Date),
});

// Pulled from https://github.com/Javascript-Ninja/react-router-prop-types/blob/master/src/index.ts
export const locationPropType = PropTypes.shape({
  hash: PropTypes.string.isRequired,
  key: PropTypes.string, // only in createBrowserHistory and createMemoryHistory
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  state: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
  ]), // only in createBrowserHistory and createMemoryHistory
});

// Pulled from https://github.com/Javascript-Ninja/react-router-prop-types/blob/master/src/index.ts
export const historyPropType = PropTypes.shape({
  action: PropTypes.oneOf(['PUSH', 'REPLACE', 'POP']).isRequired,
  block: PropTypes.func.isRequired,
  canGo: PropTypes.func, // only in createMemoryHistory
  createHref: PropTypes.func.isRequired,
  entries: PropTypes.arrayOf(locationPropType), // only in createMemoryHistory
  go: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  goForward: PropTypes.func.isRequired,
  index: PropTypes.number, // only in createMemoryHistory
  length: PropTypes.number,
  listen: PropTypes.func.isRequired,
  location: locationPropType.isRequired,
  push: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
});

export const projectManifestLayerPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    layerTypes.audio,
    layerTypes.composition,
    layerTypes.image,
    layerTypes.null,
    layerTypes.preComp,
    layerTypes.shape,
    layerTypes.solid,
    layerTypes.text,
    layerTypes.video,
    layerTypes.waymarkAudio,
    layerTypes.waymarkVideo,
  ]).isRequired,
  startFrame: PropTypes.number,
  endFrame: PropTypes.number,
  uuid: PropTypes.string.isRequired,
  resizingStrategy: PropTypes.string,
  textContent: PropTypes.string,
  verticalAlignment: PropTypes.string,
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string,
  fillEffect: PropTypes.string,
  gradientFill: PropTypes.arrayOf(PropTypes.string),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  imageAssetPath: PropTypes.string,
  isInConfiguration: PropTypes.bool,
  childLayers: PropTypes.arrayOf(PropTypes.object),
});

export const projectManifestCompositionPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  childLayers: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.string,
});

export const groupPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  layers: PropTypes.arrayOf(PropTypes.string),
});

export const sceneSwitchPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  groups: PropTypes.arrayOf(PropTypes.string),
  defaultSelection: PropTypes.string,
});

export const overridePropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  frameNumber: PropTypes.number,
  type: PropTypes.string,
});

export const audioTrackPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
});
