/* eslint-disable no-param-reassign */
import _ from 'lodash';

import { isValidUUID } from '../utils/uuid.js';
import { layerTypes } from '../constants/TemplateManifest.js';

/**
 * A utility to eliminate redux boilerplate around creating and managing state for loading states and associated errors.
 * For each provided name prefix, this helper creates the following:
 *
 *    Default state values for:
 *      - is<Name>InProgress: <bool>
 *      - <name>Error: <null>
 *
 *    Case reducer functions for:
 *      - <name>InProgress
 *      - <name>Completed
 *      - <name>Failed
 *      - clear<Name>Error
 *      (more about case reducers here: https://redux-starter-kit.js.org/api/createslice#reducers)
 *
 * Example usage:
 *    ```
 *    const { initialState, caseReducers } = supportLoadingStates(['login']);
 *    const loginSlice = createSlice({
 *      slice: 'login',
 *      defaultState: {
 *        ...initialState,
 *        // Other initial state as needed.
 *        data: null,
 *      },
 *      reducers: {
 *        ...caseReducers,
 *        // Add additional reducers here for separate concerns
 *        receivedData: (state, actions) => {
 *          state.data = action.payload;
 *        },
 *        // Alternatively, extend any of the provided case reducers if there's additional reducer functionality needed for those actions.
 *        loginCompleted: _.flow(caseReducers.loginCompleted, (state) => {
            state.myOtherVariable = 'something related to login';
          }),
 *      }
 *    });
 *
 *        initialState.loginError         // null
 *        initialState.isLoginInProgress  // false
 *
 *        loginSlice.actions.loginInProgress()  // sets `isLoginInProgress` to true, `loginError` to null
 *        loginSlice.actions.loginCompleted()   // sets `isLoginInProgress` to false, `loginError` to null
 *        loginSlice.actions.loginFailed()      // sets `isLoginInProgress` to false, `loginError` to action.payload
 *        loginSlice.actions.clearLoginError()  // sets `loginError` to null
 *
 * NOTE: This helper does not aim to manage fetched data that may be associated with loading states.
 * For example, `supportLoadingStates('createTemplate')` does not attempt to smartly store the template that gets created
 * in state as part of `createTemplateComplete()`. You have to manage that yourself. WHY? Not all loading states have
 * associated data (e.g., login), and unlike the `inProgress`, `completed`, `failed` states that get repeated all the
 * time, we don't have strong conventions about how fetched data gets named or stored within our store state.
 *
 * @param  {string[]}   namePrefixes
 *    Array of named prefixes that should each have distinct state values and caseReducers for
 *    loading states and errors.
 * @returns   {object}
 * @returns   {object.initialState}   Object of initial state values for this loading state.
 * @returns   {object.caseReducers}   Object of case reducer functions for managing the loading and error state values.
 */
export const supportLoadingStates = (namePrefixes) => {
  if (!Array.isArray(namePrefixes) && typeof namePrefixes === 'string') {
    // No need to break if we know what they're trying to do...
    namePrefixes = [namePrefixes];
  }

  const initialState = {};
  const caseReducers = {};

  namePrefixes.forEach((namePrefix) => {
    const capitalizedPrefix = namePrefix.slice(0, 1).toUpperCase() + namePrefix.slice(1);
    const stateNames = {
      inProgress: `is${capitalizedPrefix}InProgress`,
      error: `${namePrefix}Error`,
    };

    const actionNames = {
      inProgress: `${namePrefix}InProgress`,
      completed: `${namePrefix}Completed`,
      failed: `${namePrefix}Failed`,
      clearError: `clear${capitalizedPrefix}Error`,
    };

    initialState[stateNames.inProgress] = false;
    initialState[stateNames.error] = null;

    /* 
    NOTE: This utility is meant to be used with react-starter-kit's `createSlice` or `createReducer`, which is how
    we get away with authoring the caseReducers to look like they are mutating state. Under the hood, those utils 
    use `immer` to "create the next immutable state by mutating the current one". 
    More on that here: https://redux-starter-kit.js.org/api/createreducer
    */
    caseReducers[actionNames.inProgress] = (state) => {
      state[stateNames.inProgress] = true;
      state[stateNames.error] = null;
    };
    caseReducers[actionNames.completed] = (state) => {
      state[stateNames.inProgress] = false;
      state[stateNames.error] = null;
    };
    caseReducers[actionNames.failed] = (state, action) => {
      state[stateNames.inProgress] = false;
      state[stateNames.error] = action.payload;
    };
    caseReducers[actionNames.clearError] = (state) => {
      state[stateNames.error] = null;
    };
  });

  return {
    initialState,
    caseReducers,
  };
};

/**
 * Simple utility to return the list of duplicate elements from the provided array.
 * @param  {string[]} items     List of items
 */
export const getDuplicates = (items) => {
  const repeatItems = items.filter((item) => items.indexOf(item) !== items.lastIndexOf(item));
  return _.uniq(repeatItems);
};

/**
 * Helper for validating naming uniqueness within the same property across slices.
 * Throws an error if a non-unique name is found.
 * @param  {object[]} collection  Array of redux slices
 * @param  {string[]} keyNames    Named properties (which must have an object as a value) to validate.
 */
export const ensureUniquePropertyList = (collection, keyNames) => {
  keyNames.forEach((keyName) => {
    const names = _.flatten(collection.map((item) => Object.keys(item[keyName])));
    const repeatNames = getDuplicates(names);
    if (repeatNames.length) {
      throw Error(`Found duplicate ${keyName}: ${repeatNames.join(', ')}`);
    }
  });
};

/**
 * A helper method to format a layersExtendedAttributes object.
 * @param  {string} layerType        The type of layer being added to the template manifest. 
 *                                   Should be shape, solid, text, or image.
 * @param  {object} initialLayerData Path and value to be stored in the template manifest.
 * @return {object}                  Formatted layersExtendedAttributes object.
 */
export const createDynamicLayer = (layerType, initialLayerData = null) => {
  const dynamicLayer = {
    fillColor: null,
    strokeColor: null,
    fillEffect: null
  };

  if (layerType === layerTypes.text || layerType === layerTypes.image) {
    dynamicLayer.content = null;
  };

  if (layerType === layerTypes.text) {
    dynamicLayer.font = null;
  }

  if (layerType === layerTypes.shape) {
    dynamicLayer.gradientFill = null;
  }

  if (!_.isEmpty(initialLayerData)) {
    const updateDotPath = Object.entries(initialLayerData)[0][0];
    let updateValue = Object.entries(initialLayerData)[0][1];
    if (isValidUUID(updateValue)) {
      updateValue = {
        override: updateValue
      };
    };
    
    _.set(
      dynamicLayer,
      `${updateDotPath}`,
      updateValue,
    );
  }

  return dynamicLayer;
}

/**
 * Returns a unique key for a template + version number combo
 * @param  {string} templateId
 * @param  {string} versionNumber
 * @returns {string}
 */
export const getUniqueVersionKey = (templateId, versionNumber) => `${templateId}:${versionNumber}`;
