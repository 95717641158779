/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { createSelector, createSlice } from 'redux-starter-kit';

import {
  fetchTemplate,
  getAfterEffectsProject,
  updateVersion,
  uploadAfterEffectsProject,
} from '../api/index.js';
import { importStatuses, templateManifestOverrideKeys } from '../constants/index.js';
import { supportLoadingStates } from './utils.js';
import templates from './templates.js';
import workspaceVersions from './workspaceVersions.js';
import workspaceTemplateManifest from './workspaceTemplateManifest.js';
import { uuid } from '../utils/uuid.js';
import { primaryCompositionID } from '../utils/projectManifest.js';
import { layerTypes } from '../constants/TemplateManifest.js';

/**
 * `workspace` slice -- owner of core application logix related to the workspace, with offloaded
 * state management in the dependent slices listed below.
 *
 * SLICE DEPENDENCIES:
 *   - `templates`
 *   - `workspaceVersions`
 *   - `workspaceTemplateManifest`
 */

const { initialState, caseReducers } = supportLoadingStates(['reimport']);
const defaultState = {
  ...initialState,
  selectedLayers: [],
  selectedTemplateSetting: null,
  shouldAllowScrubToSelectedLayer: true,
  expandedCompositions: [primaryCompositionID],
  layerTypeFilter: Object.values(layerTypes),
  layerNameFilter: null,
  shouldShowEditedLayers: true,
  shouldShowUneditedLayers: true,
};

const workspace = createSlice({
  slice: 'workspace',
  initialState: defaultState,
  reducers: {
    ...caseReducers,
    leavingWorkspace: (state) => {
      state.selectedLayers = [];
    },
    selectLayers: (state, action) => {
      state.selectedTemplateSetting = null;
      const { selectedLayers, shouldScrubToLayer } = action.payload;
      state.selectedLayers = _.uniq([...state.selectedLayers, ...selectedLayers]);
      state.shouldAllowScrubToSelectedLayer = shouldScrubToLayer;
    },
    deselectLayers: (state, action) => {
      state.selectedTemplateSetting = null;
      const { layersToDeselect } = action.payload;
      state.selectedLayers = _.without(state.selectedLayers, ...layersToDeselect);
    },
    deselectAllLayers: (state) => {
      state.selectedTemplateSetting = null;
      state.selectedLayers = [];
    },
    selectTemplateSetting: (state, action) => {
      state.selectedTemplateSetting = action.payload;
      state.selectedLayers = [];
    },
    /**
     * Adds compositions to the array of expanded compositions
     *
     * @param      {Object}  state   The state
     * @param      {String[]}  action.payload.compositions  The payload contains an array of composition ids i.e. ['comp_0', 'comp_1', ...]
     */
    expandCompositions: (state, action) => {
      const { compositions } = action.payload;
      const newExpandedCompositions = _.uniq([...state.expandedCompositions, ...compositions]);
      state.expandedCompositions = newExpandedCompositions;
    },
    /**
     * Removes compositions from the array of expanded compositions
     *
     * @param      {Object}  state   The state
     * @param      {String[]}  action.payload.compositions  The payload contains an array of composition ids i.e. ['comp_0', 'comp_1', ...]
     */
    collapseCompositions: (state, action) => {
      const { compositions } = action.payload;
      const newExpandedCompositions = _.without(state.expandedCompositions, ...compositions);
      state.expandedCompositions = newExpandedCompositions;
    },
    /**
     * Removes all compositions from the array of expanded compositions
     */
    collapseAllCompositions: (state) => {
      state.expandedCompositions = [];
    },
    /**
     * Adds a layer type to the list of filtered types
     *
     * @param      {Object}  state   The state
     * @param      {String}  action.payload.layerType  The payload contains a layer type identifier
     */
    addLayerTypeFilter: (state, action) => {
      const { layerType } = action.payload;
      const newLayerTypeFilter = _.uniq(state.layerTypeFilter.concat([layerType]));
      state.layerTypeFilter = newLayerTypeFilter;
    },
    /**
     * Removes a layer type from the list of filtered types
     *
     * @param      {Object}  state   The state
     * @param      {String}  action.payload.layerType  The payload contains a layer type identifier
     */
    removeLayerTypeFilter: (state, action) => {
      const { layerType } = action.payload;
      const newLayerTypeFilter = _.without(state.layerTypeFilter, layerType);
      state.layerTypeFilter = newLayerTypeFilter;
    },
    /**
     * Sets all layer type filters to on
     */
    setAllLayerTypeFilters: (state) => {
      state.layerTypeFilter = Object.values(layerTypes);
    },
    /**
     * Removes all layer type filters
     */
    clearAllLayerTypeFilters: (state) => {
      state.layerTypeFilter = [];
    },
    /**
     * Sets the layer name filter, a search term used to match agains
     *
     * @param      {Object}  state   The state
     * @param      {String}  action.payload.name  The payload contains the name search string
     */
    setLayerNameFilter: (state, action) => {
      const { name } = action.payload;
      state.layerNameFilter = name;
    },
    /**
     * Clear out the name search filter
     */
    clearLayerNameFilter: (state) => {
      state.layerNameFilter = null;
    },
    /**
     * Sets the filter to hide/show edited layers.
     *
     * @param      {Object}  state   The state
     * @param      {Boolean}  action.payload  The payload states if we should show (true) or hide (false) edited layers
     */
    setShouldShowEditedLayers: (state, action) => {
      state.shouldShowEditedLayers = action.payload;
    },
    /**
     * Sets the filter to hide/show unedited layers.
     *
     * @param      {Object}  state   The state
     * @param      {Boolean}  action.payload  The payload states if we should show (true) or hide (false) unedited layers
     */
    setShouldShowUneditedLayers: (state, action) => {
      state.shouldShowUneditedLayers = action.payload;
    },
  },
});

workspace.selectors.getSelectedTemplateSetting = createSelector([
  'workspace.selectedTemplateSetting',
]);
workspace.selectors.getIsReimportInProgress = createSelector(['workspace.isReimportInProgress']);
workspace.selectors.getSelectedLayers = createSelector(['workspace.selectedLayers']);
workspace.selectors.getShouldAllowScrubToSelectedLayer = createSelector([
  'workspace.shouldAllowScrubToSelectedLayer',
]);
workspace.selectors.getExpandedCompositions = createSelector(['workspace.expandedCompositions']);
workspace.selectors.getExpandedCompositions = createSelector(['workspace.expandedCompositions']);
workspace.selectors.getLayerTypeFilter = createSelector(['workspace.layerTypeFilter']);
workspace.selectors.getLayerNameFilter = createSelector(['workspace.layerNameFilter']);
workspace.selectors.getShouldShowEditedLayers = createSelector([
  'workspace.shouldShowEditedLayers',
]);
workspace.selectors.getShouldShowUneditedLayers = createSelector([
  'workspace.shouldShowUneditedLayers',
]);

/**
 * A utility selector for components to have a way to tell when the selectedLayers changed without
 * needing to do a deep equality check.
 * @returns  {string}  String of concatenated layer UUIDs
 */
workspace.selectors.getSelectedLayersKey = createSelector(
  [workspace.selectors.getSelectedLayers],
  (selectedLayers) => {
    const sortedLayers = selectedLayers.concat([]).sort()
    sortedLayers.join('')
  }
);

/**
 * Returns a unique list of layer types for all currently selected layers.
 * @returns {string[]}  Array of layer type strings.
 */
workspace.selectors.getSelectedLayerTypes = (state) => {
  const selectedLayers = workspace.selectors.getSelectedLayers(state);
  const allLayerTypes = selectedLayers.map((layer) => {
    const foundLayer = workspaceVersions.selectors.getLayerByUUID(state, layer);
    return foundLayer.type;
  });

  return _.uniq(allLayerTypes);
};

/**
 * START TEMPLATE MANIFEST
 * Template manifest selectors that depend on current selections within the UI.
 */

 /**
  * Returns a list of compositions for that have been filtered by the current name search & filter UI
  * Layers are altered to indicate additional editing done by the Waymark author.
  *
  * @returns {Object[]}  Array of compositions
  */
workspace.selectors.getFilteredCompositions = createSelector(
  [
    workspaceVersions.selectors.getAllCompositions,
    workspaceTemplateManifest.selectors.getEditableLayerUUIDS,
    workspaceTemplateManifest.selectors.getTemplateManifest,
    workspace.selectors.getLayerTypeFilter,
    workspace.selectors.getLayerNameFilter,
    workspace.selectors.getShouldShowEditedLayers,
    workspace.selectors.getShouldShowUneditedLayers,
  ],
  (
    allCompositions,
    editedLayerUUIDs,
    templateManifest,
    layerTypeFilter,
    layerNameFilter,
    shouldShowEditedLayers,
    shouldShowUneditedLayers,
  ) =>
    allCompositions.map((composition) => {
      const filteredChildLayers = []

      composition.childLayers.forEach((layer) => {
        const isLayerEdited = editedLayerUUIDs.includes(layer.uuid);
        let editedFrameNumber = null

        // If the layer's type is one of the ones we want to filter by
        if (
          layerTypeFilter.includes(layer.type) &&
          // If the layer name contains the search string
          (!layerNameFilter || layer.name.toLowerCase().search(layerNameFilter) >= 0) &&
          // If the layer is edited and we want to see edited layers
          ((isLayerEdited && shouldShowEditedLayers) ||
            // Or if the layer is unedited and we want to see unedited layers
            (!isLayerEdited && shouldShowUneditedLayers))
        ) {

          // If the layer is edited, overwrite the frame number from the temaplate manifest
          if (isLayerEdited) {
            // The user might not have edited the value, which would result in it being an empty string ''
            editedFrameNumber = _.get(templateManifest, `layersExtendedAttributes[${layer.uuid}].content.frameNumber`, null)
          }

          filteredChildLayers.push({
            ...layer,
            isEdited: isLayerEdited,
            // alter the frame
            frameNumber: _.isNumber(editedFrameNumber) ? editedFrameNumber: layer.frameNumber,
          })
        }
      });

      return {
        ...composition,
        childLayers: filteredChildLayers,
      };
    }),
);

/**
 * If all selected layers have the same override value for the provided override type,
 * then return that override value (UUID). Otherwise, return null.
 * @param  {Object} state
 * @param  {string} overrideType
 * @return {string || null}
 */
workspace.selectors.getSharedOverrideUUID = (state, overrideType) => {
  const selectedLayers = workspace.selectors.getSelectedLayers(state);
  const getOverride = (templateManifestValue) => {
    if (!templateManifestValue) return false;
    return _.includes(Object.keys(templateManifestValue), 'override');
  };

  const templateManifestLayer = workspaceTemplateManifest.selectors.getTemplateManifestData(
    state,
    selectedLayers[0],
  );
  const valueToCompare = _.get(templateManifestLayer, overrideType);
  const isOverrideObject = getOverride(valueToCompare);

  // If there is a valid override and only one selected layer, return that layer's override.
  if (isOverrideObject && selectedLayers.length === 1) return valueToCompare.override;

  /*
    A layer could have:
      1) A null value
      2) An object with individal data (content only)
      3) An object with an override id

    We only want to show a default selected override if every selected layer
    already has the same override, if not, allow the user to select
    any content override.
  */
  if (isOverrideObject) {
    for (
      let selectedLayerIndex = 1;
      selectedLayerIndex < selectedLayers.length;
      selectedLayerIndex += 1
    ) {
      const templateManifestData = workspaceTemplateManifest.selectors.getTemplateManifestData(
        state,
        selectedLayers[selectedLayerIndex],
      );
      const templateManifestValue = _.get(templateManifestData, overrideType);
      const isOverride = getOverride(templateManifestValue);

      if (!isOverride || templateManifestValue.override !== valueToCompare.override) {
        return null;
      }
    }

    return valueToCompare.override;
  }

  return null;
};

/**
 * Returns a list of color properties that all selected layers share.
 * @param   {Object}    state
 * @returns {string[]}  Array of color property types
 */
workspace.selectors.getSharedColorFields = (state) => {
  const selectedLayers = workspace.selectors.getSelectedLayers(state);
  let validColorProperties = Object.values(templateManifestOverrideKeys.color);

  selectedLayers.forEach((layerUUID) => {
    const foundLayer = _.cloneDeep(workspaceVersions.selectors.getLayerByUUID(state, layerUUID));
    // Remove null keys from layer.
    const nonNullProperties = _.pickBy(foundLayer, _.identity);
    const layerProperties = Object.keys(nonNullProperties);
    validColorProperties = _.filter(validColorProperties, (colorType) =>
      _.includes(layerProperties, colorType),
    );
  });

  // TODO: `gradientFill` special handling -- could we create a system where we define
  // validation functions for each property, and they all get called here (without needing to
  // have all the logic held internally.)
  if (validColorProperties.includes(templateManifestOverrideKeys.color.gradientFill)) {
    // If all selected layers have a gradientFill property, ensure they have the same number
    // of gradient steps before including gradientFill in the returned `validColorProperties` array.
    const gradientFillStepCounts = selectedLayers.map((layerUUID) => {
      const foundLayer = workspaceVersions.selectors.getLayerByUUID(state, layerUUID);
      return foundLayer.gradientFill.length;
    });

    // Remove gradientFill if, e.g., one layer has 2 editable gradient steps, and another layer has 3.
    if (_.uniq(gradientFillStepCounts).length > 1) {
      return _.without(validColorProperties, templateManifestOverrideKeys.color.gradientFill);
    }
  }

  return validColorProperties;
};

/**
 * Checks all selected layers to see whether they share the same override value for the `gradientFill`
 * property at the requested gradient step index.
 * @param  {Object} state         Store state
 * @return {string | null []}
 *    Array of either override UUIDs or `null` values of length N, where N is the number
 *    of steps in the gradient fill, as determined by the number of steps in the shape
 *    layer's gradient fill value.
 */
workspace.selectors.getSharedGradientFillOverridesByStep = (state) => {
  /* This selector should *really* only be used / needed if we've already determined externally
  that we are "eligible" to offer a gradientFill configuration based on the selectedLayers.
  But to protect against the case where that's not true, we'll check here internally as well. */
  const sharedColorFields = workspace.selectors.getSharedColorFields(state);
  if (!sharedColorFields.includes(templateManifestOverrideKeys.color.gradientFill)) {
    return [];
  }

  const selectedLayers = workspace.selectors.getSelectedLayers(state);

  /* TODO: This is quite inelegant. But to achieve the goal of always returning an
  array of consistent length, we need a source of truth in the null state, and that
  comes from the value provided on the layer itself from the projectManifest. */
  const exampleLayerData = workspaceVersions.selectors.getLayerByUUID(state, selectedLayers[0]);
  const gradientStepCount = exampleLayerData.gradientFill.length;

  const templateManifestDataArray = selectedLayers.map((layerUUID) =>
    workspaceTemplateManifest.selectors.getTemplateManifestData(state, layerUUID),
  );

  // Go through the template manifest data for each selected layer, and for each
  // step in the gradient fill, return the override value, or null.
  const gradientFillValuesByStep = templateManifestDataArray.map((manifestData) => {
    const { gradientFill } = manifestData;
    const gradientSteps = new Array(gradientStepCount).fill(null);

    if (!gradientFill) {
      return gradientSteps;
    }

    // Ensure we respect the order or the keys when returning the steps array.
    Object.keys(gradientFill).forEach((key) => {
      const stepIndex = parseInt(key, 10);
      const stepValue = gradientFill[key];
      const stepOverride = _.get(stepValue, 'override');

      if (stepOverride) {
        gradientSteps[stepIndex] = stepOverride;
      } else {
        gradientSteps[stepIndex] = stepValue;
      }
    });

    return gradientSteps;
  });

  // If we're using this selector, it means that all selected layers have a `gradientFill`
  // property with the same amount of steps. So for each step, let's return either the shared
  // override ID, or if there is none, `null`.
  const overrideIds = _.zip(...gradientFillValuesByStep).map((stepValues) => {
    const uniqueValues = _.uniq(stepValues);
    if (uniqueValues.length !== 1) {
      return null;
    }
    return uniqueValues[0];
  });

  // Return the full override objects.
  return overrideIds.map(
    (overrideId) => workspaceTemplateManifest.selectors.getOverrideByUUID(state, overrideId) || null,
  );
};

// Filter function for `getSortedGroupsForSelectedLayers` selector.
const areLayersInGroup = (layers, group) => _.isEqual(_.intersection(layers, group.layers), layers);

// Returns an array `[selectedLayerGroups, otherGroups] ` where
// `selectedLayerGroups` are groups that include all selected layers, and `otherGroups`
// are all remaining groups.
workspaceTemplateManifest.selectors.getSortedGroupsForSelectedLayers = createSelector(
  [
    workspace.selectors.getSelectedLayers,
    workspaceTemplateManifest.selectors.getTemplateManifestGroups,
  ],
  (selectedLayers, groups) => {
    const selectedLayerGroups = groups.filter((group) => areLayersInGroup(selectedLayers, group));
    const otherGroups = groups.filter((group) => !areLayersInGroup(selectedLayers, group));
    return [selectedLayerGroups, otherGroups];
  },
);
/**
 * END TEMPLATE MANIFEST
 */

const createNewFontOverride = (overrideKey, rendererFont) => ({
  id: uuid(),
  name: overrideKey,
  placeholder: {
    fontFamily: rendererFont.family,
    fontSizeAdjustment: 0,
    fontStyle: rendererFont.style,
    fontWeight: rendererFont.weight,
  },
  type: 'font',
  frameNumber: null,
  originalTypography: rendererFont,
});

export const getUpdatedFontData = (version, textLayers) => {
  const { templateManifest } = version;

  /* We want to ensure this method only returns NEW font data -- namely, only
  overrides for new fonts that haven't had an override created yet, and text layers that
  have not previously been defaulted to a font editabile state by being mapped to an override. */
  const existingFontOverrides = _.filter(templateManifest.overrides, { type: 'font' });

  const newFontOverrides = {};

  textLayers.forEach((textLayer) => {
    const { rendererFont } = textLayer;

    if (!rendererFont) {
      throw Error(`Could not find a rendererFont on the textLayer ${textLayer.uuid}`);
    }

    // Check to see if we have already created a font override for this text layer's font
    // properties. We store the original rendererFont definition on font overrides as
    // "originalTypography" which should be immutable and therefore safe to use in comparison.
    const existingOverride = _.find(existingFontOverrides, { originalTypography: rendererFont });

    if (existingOverride) {
      // If we've found a font key that's not accounted for in our existing overrides, let's handle it!
    } else if (rendererFont) {
      const overrideKey = `${rendererFont.family}.${rendererFont.style}.${rendererFont.weight}`;
      let override = newFontOverrides[overrideKey];

      if (!override) {
        override = createNewFontOverride(overrideKey, rendererFont);
        newFontOverrides[overrideKey] = override;
      }
    }
  });

  return { newFontOverrides };
};

workspace.operations = {
  /**
   * Set up the workspace to be worked on for the provided template and version number.
   * @param  {string}   templateId
   * @param  {string}   versionNumber
   */
  setUpWorkspace({ templateId, versionNumber }) {
    return async (dispatch) => {
      dispatch(workspaceVersions.actions.setWorkspaceVersion({ templateId, versionNumber }));

      // Let's always get the latest and greatest from the server when we set up the workspace.
      // We don't want to run the risk of relying on stale data in the `versions` store state cache.
      const forceFetch = true;
      const version = await dispatch(
        workspaceVersions.operations.fetchTemplateVersion(templateId, versionNumber, forceFetch),
      );

      // Either way, we have to set our template manifest to populate the studio.
      dispatch(workspaceTemplateManifest.actions.setTemplateManifest(version.templateManifest));
      await dispatch(workspaceTemplateManifest.actions.workspaceSetupCompleted());
    };
  },

  /**
   * Attempts to apply an after effects project to the currently active version within the workspace.
   * Requires one of: (a) An existing `afterEffectsImportId` or (b) a newly uploaded AE project's confirmationCode
   * to use as the new import.
   *
   * @param  {Object}   importSourceOptions
   * @param  {FormData} importSourceOptions.confirmationCode
   *     Confirmation code for the after effects import
   * @param  {number}   importSourceOptions.afterEffectsImportId
   *     ID of an existing AE import record
   * @param  {boolean}  [shouldForceApply]
   *     Whether to force apply the import, even if there are missing layers.
   */
  applyNewProjectArchive(importSourceOptions, shouldForceApply = false) {
    return async (dispatch, getState) => {
      dispatch(workspace.actions.clearReimportError());

      const storeState = getState();
      const templateId = workspaceVersions.selectors.getActiveTemplateId(storeState);
      const versionNumber = workspaceVersions.selectors.getActiveVersionNumber(storeState);

      const { confirmationCode } = importSourceOptions;
      let { afterEffectsImportId } = importSourceOptions;

      // We must have either an id or the form data for a new import to use as the import source.
      if (!(confirmationCode || afterEffectsImportId)) {
        const message = 'Must provide either confirmationCode or afterEffectsImportId';
        console.error(message);

        return {
          isError: true,
          errorMessage: message,
          afterEffectsImportId: null,
        };
      }

      // Remove selected layers since they may not appear in the new manifest.
      dispatch(workspace.actions.deselectAllLayers());

      let aeImportRes;
      // First, if we need to create a fresh import, let's do it.
      if (!afterEffectsImportId) {
        dispatch(workspace.actions.reimportInProgress());

        try {
          aeImportRes = await uploadAfterEffectsProject(confirmationCode);
          afterEffectsImportId = aeImportRes.data.id;

          while (aeImportRes.data.status !== importStatuses.complete) {
            /* eslint-disable-next-line no-await-in-loop */
            await new Promise((res) => {
              setTimeout(res, 2500);
            });
            /* eslint-disable-next-line no-await-in-loop */
            aeImportRes = await getAfterEffectsProject(aeImportRes.data.id);

            if (aeImportRes.data.status === importStatuses.failed) {
              throw new Error(`Import failed with error message ${aeImportRes.data.error}`);
            }
          }

          aeImportRes = await getAfterEffectsProject(aeImportRes.data.id);

        } catch (e) {
          console.error(e);
          dispatch(workspace.actions.reimportFailed());

          return {
            isError: true,
            errorMessage: e.message,
            afterEffectsImportId: null,
          };
        }
      }

      // Apply the import to the current working version.
      try {
        const versionResponse = await updateVersion(
          templateId,
          versionNumber,
          {
            afterEffectsImport: afterEffectsImportId,
            shouldForceAfterEffectsImport: shouldForceApply,
          },
          ['templateManifest', 'projectManifest'],
        );

        // Update the new project manifest and template manifest.
        const version = versionResponse.data;
        await dispatch(
          workspaceVersions.actions.updatedVersionProjectManifest({ templateId, version }),
        );
        await dispatch(
          workspaceTemplateManifest.actions.setTemplateManifest(version.templateManifest),
        );

        // Re-fetching template to make sure it's fully serialized with the new version data
        const fullTemplateResponse = await fetchTemplate(templateId);
        const fullTemplate = fullTemplateResponse.data;
        await dispatch(templates.actions.receivedTemplate({ template: fullTemplate }));

        dispatch(workspace.actions.reimportCompleted());

        return versionResponse.data;
      } catch (e) {
        console.error(e);
        dispatch(workspace.actions.reimportFailed());
        const errorMessage = _.get(e, 'response.data.message', '');

        return {
          isError: true,
          errorMessage,
          afterEffectsImportId,
        };
      }
    };
  },
};

export default workspace;
