/* eslint-disable jsx-a11y/no-noninteractive-tabindex, jsx-a11y/no-noninteractive-element-interactions */
import _ from 'lodash';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import cxs from 'cxs';

import WaymarkAuthorWebRenderer from '../WaymarkAuthorWebRenderer.js';
import {
  addFullscreenEventListener,
  removeFullscreenEventListener,
  requestFullscreen,
  exitFullscreen,
  isFullscreenEnabled,
  keyCodes,
  PerformanceProfiler,
} from '../utils/index.js';
import RendererControls from './RendererControls.js';
import RendererCanvas from './RendererCanvas.js';

const stageWrapper = cxs({
  outline: 'inherit',
});

/**
 * A player component for a renderer that holds controls and a place to drop a
 * canvas.
 *
 * @class RendererPlayer (name)
 * @param {object} props The react component props
 * @param {boolean} props.hasGlobalKeyboardListeners Are keyboard controls (space, etc) used for controlling the renderer
 * @param {boolean} props.isControlsEnabled Are the controls for the player created
 * @param {Function} props.onRendererCreated Callback to pass a reference to the renderer up to the consumer
 * @param {object} props.projectManifest The project manifest to use for creation of the renderer
 * @param {Function} props.onCanvasElementCreated Callback for when the canvas for the renderer has been created
 * @param {Function} props.onPerformanceProfilerCreated Callback for when the performance profiler is created
 * @param {Function} props.onRendererSetup Callback for when setup succeeds
 * @param {Function} props.onRendererSetupError Callback for when setup fails
 * @param {object} props.rendererOptions Options to pass to the renderer on creation
 * @param {*} props.scrubberChildren React children to use for rendering the scrubber
 * @param {string} props.scrubberClassName Class name for styling purposes
 * @param {boolean} props.shouldCreatePerformanceProfiler If a performance profilerer component should be created along with the player
 * @param {string} props.trackClassName Class name for styling purposes
 * @param {string} props.trackCompleteClassName Class name for styling purposes
 * @public
 */
const RendererPlayer = ({
  projectManifest,
  onRendererCreated,
  hasGlobalKeyboardListeners,
  isControlsEnabled,
  onCanvasElementCreated,
  onPerformanceProfilerCreated,
  onRendererSetup,
  onRendererSetupError,
  rendererOptions,
  scrubberChildren,
  scrubberClassName,
  shouldCreatePerformanceProfiler,
  trackClassName,
  trackCompleteClassName,
}) => {
  const renderer = useMemo(() => new WaymarkAuthorWebRenderer(rendererOptions), []);

  const [isRendererPlaying, setIsRendererPlaying] = useState(false);
  const [isRendererMuted, setIsRendererMuted] = useState(renderer.isMuted);
  const [isRendererSetup, setIsRendererSetup] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [rendererCurrentFrame, setRendererCurrentFrame] = useState(0);

  const performanceProfiler = useMemo(
    () => (shouldCreatePerformanceProfiler ? new PerformanceProfiler(renderer) : null),
    [renderer, shouldCreatePerformanceProfiler],
  );

  const canvasEl = useRef(null);
  const playerEl = useRef(null);

  const onFrameRender = ({ frameNumber }) => {
    setRendererCurrentFrame(Math.round(frameNumber));
  };

  const onRendererStop = () => {
    setIsRendererPlaying(false);
  };
  const onRendererPlaying = () => {
    setIsRendererPlaying(true);
  };

  const onFullscreenChange = () => {
    setIsFullscreen(isFullscreenEnabled());
  };

  const onPlay = () => {
    (async () => {
      try {
        await renderer.play();
      } catch (error) {
        console.error(error);
      }
    })();
  };

  const onStop = () => {
    (async () => {
      try {
        await renderer.stop();
      } catch (error) {
        console.error(error);
      }
    })();
  };

  const onMute = () => {
    renderer.mute();
    setIsRendererMuted(true);
  };

  const onUnmute = () => {
    renderer.unmute();
    setIsRendererMuted(false);
  };

  const onGoToFrame = (frameNumber) => {
    (async () => {
      try {
        await renderer.goToFrame(frameNumber);
        setRendererCurrentFrame(frameNumber);
      } catch (error) {
        console.error(error);
      }
    })();
  };

  const onToggleFullscreen = () => {
    if (isFullscreen) {
      exitFullscreen();
    } else {
      requestFullscreen(playerEl.current);
    }
  };

  const onKeyPress = (event) => {
    if ([keyCodes.SPACE, keyCodes.ENTER].includes(event.which)) {
      event.preventDefault();
      if (renderer.isPlaying) {
        onStop();
      } else {
        onPlay();
      }
    }
  };

  const onRendererSetupEnd = () => {
    setIsRendererSetup(true);
  };

  // Create the renderer instance and add listeners.
  useEffect(() => {
    addFullscreenEventListener(onFullscreenChange);
    renderer.on('frameRender:end', onFrameRender);
    renderer.on('setup:end', onRendererSetupEnd);
    renderer.on('playback:complete', onRendererStop);
    renderer.on('playback:stop', onRendererStop);
    renderer.on('playback:play', onRendererPlaying);
    setIsRendererPlaying(renderer.isPlaying);
    setIsRendererSetup(renderer.isSetup);

    onRendererCreated(renderer);

    if (shouldCreatePerformanceProfiler) {
      onPerformanceProfilerCreated(performanceProfiler);
    }

    onCanvasElementCreated(canvasEl);

    return () => {
      removeFullscreenEventListener(onFullscreenChange);
      renderer.off('frameRender:end', onFrameRender);
      renderer.off('setup:end', onRendererSetupEnd);
      renderer.off('playback:complete', onRendererStop);
      renderer.off('playback:stop', onRendererStop);
      renderer.off('playback:play', onRendererPlaying);

      if (renderer.isSetup) {
        renderer.tearDown();
      } else if (projectManifest) {
        throw new Error('Component unmounted while renderer is setting up.');
      }
    };
  }, []);

  // Setup (or tear down and set up) the renderer with the provided `projectManifest`.
  useEffect(() => {
    const setUpRenderer = async () => {
      const projectManifestClone = _.cloneDeep(projectManifest);

      setIsRendererSetup(false);
      if (renderer.isSetup) {
        renderer.tearDown();
      }

      try {
        await renderer.setup({
          videoData: projectManifestClone,
          view: canvasEl.current,
        });
      } catch (e) {
        if (onRendererSetupError) {
          onRendererSetupError(e);
          return;
        }

        throw e;
      }

      onRendererSetup();

      setIsRendererSetup(true);
    };

    if (projectManifest) {
      // Previously this was handled by a use-deep-compare-effect third party node module.
      // However, the useDeepCompareEffect method still fired when changes were made to an
      // unrelated RendererPlayer property from the template studio.
      if (renderer.videoData && _.isEqual(renderer.videoData, projectManifest)) {
        return;
      }

      setUpRenderer();
    }
  }, [projectManifest]);

  // If we have a project manifest, and the renderer isn't already set up, then setup is in progress.
  const isSettingUp = !!projectManifest && !isRendererSetup;

  return (
    <div ref={playerEl}>
      <div tabIndex={-1} onKeyDown={onKeyPress} role="region" className={stageWrapper}>
        <RendererCanvas ref={canvasEl} isSettingUp={isSettingUp} />
      </div>
      {isRendererSetup ? (
        <RendererControls
          onPlay={onPlay}
          onStop={onStop}
          onMute={onMute}
          onUnmute={onUnmute}
          isEnabled={isControlsEnabled}
          isPlaying={isRendererPlaying}
          isMuted={isRendererMuted}
          onGoToFrame={onGoToFrame}
          currentFrame={rendererCurrentFrame}
          totalFrames={renderer.duration}
          onToggleFullscreen={onToggleFullscreen}
          isFullscreen={isFullscreen}
          hasGlobalKeyboardListeners={hasGlobalKeyboardListeners}
          scrubberChildren={scrubberChildren}
          scrubberClassName={scrubberClassName}
          trackClassName={trackClassName}
          trackCompleteClassName={trackCompleteClassName}
        />
      ) : null}
    </div>
  );
};

const rendererOptionsShape = PropTypes.shape({
  // eslint-disable-next-line react/forbid-prop-types
  additionalPixiOptions: PropTypes.object,
});

RendererPlayer.propTypes = {
  hasGlobalKeyboardListeners: PropTypes.bool,
  isControlsEnabled: PropTypes.bool,
  onRendererCreated: PropTypes.func,
  projectManifest: PropTypes.instanceOf(WaymarkAuthorWebRenderer),
  onCanvasElementCreated: PropTypes.func,
  onPerformanceProfilerCreated: PropTypes.func,
  onRendererSetup: PropTypes.func,
  onRendererSetupError: PropTypes.func,
  rendererOptions: PropTypes.instanceOf(rendererOptionsShape),
  scrubberChildren: PropTypes.node,
  scrubberClassName: PropTypes.string,
  shouldCreatePerformanceProfiler: PropTypes.bool,
  trackClassName: PropTypes.string,
  trackCompleteClassName: PropTypes.string,
};

RendererPlayer.defaultProps = {
  hasGlobalKeyboardListeners: true,
  isControlsEnabled: true,
  projectManifest: null,
  onRendererCreated: () => {},
  onCanvasElementCreated: () => {},
  onPerformanceProfilerCreated: () => {},
  onRendererSetup: () => {},
  onRendererSetupError: null,
  rendererOptions: {},
  scrubberChildren: null,
  scrubberClassName: '',
  shouldCreatePerformanceProfiler: false,
  trackClassName: '',
  trackCompleteClassName: '',
};

export default RendererPlayer;
