import { Collapse, Icon } from '@blueprintjs/core';
import cxs from 'cxs';
import { IconNames } from '@blueprintjs/icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { darkBackground, defaultBackground, defaultText } from './lib/colors.js';
import { defaultFontFamily } from './lib/index.js';

const menuTitle = cxs({
  fontSize: '12px',
  textAlign: 'left',
  color: defaultText,
  fontFamily: defaultFontFamily,
  fontWeight: 'bold',
  backgroundColor: defaultBackground,
  height: '32px',
  lineHeight: '32px',
  paddingLeft: '13px',
  border: `1px solid ${darkBackground}`,
});

const titleIcon = cxs({
  float: 'right',
  paddingTop: '8px',
  paddingRight: '5px',
});

/**
 * Collapsible menu
 * @param  {element}  props.children  Collapsible menu contents.
 * @param  {string}   props.title     Collapsible menu title.
 */
const CollapsibleMenu = (props) => {
  const [isOpen, setIsOpen] = useState(true);
  const icon = isOpen ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN;

  return (
    <div>
      <div className={menuTitle}>
        {props.title}
        <span style={{ pointerEvents: 'auto' }}>
          <Icon
            className={titleIcon}
            icon={icon}
            iconSize={Icon.SIZE_STANDARD}
            onClick={() => setIsOpen(!isOpen)}
          />
        </span>
      </div>
      <Collapse isOpen={isOpen}>{props.children}</Collapse>
    </div>
  );
};

CollapsibleMenu.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired,
  title: PropTypes.string.isRequired,
};

export default CollapsibleMenu;
