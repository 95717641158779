import React from 'react';
import cxs from 'cxs';
import PropTypes from 'prop-types';
import { Flex, Box } from 'reflexbox';

import WTSDropdownMenu from './WTSDropdownMenu.js';

// MB TODO: This is silly, let's just use the baked-in styles functionality of react-select.

const emptyColorPreview = cxs({
  width: '20px',
  height: '20px',
  borderRadius: '2px',
  border: 'solid 1px #646464',
  backgroundColor: '#333333',
  marginLeft: 'auto',
  marginRight: 'auto',
});

const emptyColorPreviewLine = cxs({
  width: '15px',
  height: '15px',
  borderBottom: 'solid 1px #646464',
  transform: 'rotate(135deg)',
  marginTop: '6px',
  marginLeft: '6px',
});

/**
 * Displays a square with a provided color or an empty one.
 * @param  {string} hexcode      The color to preview.
 */
const ColorPreview = ({ hexcode }) => {
  if (hexcode) {
    return <div className={emptyColorPreview} style={{ backgroundColor: `${hexcode}` }} />;
  }

  return (
    <div className={emptyColorPreview}>
      <div className={emptyColorPreviewLine} />
    </div>
  );
};

ColorPreview.defaultProps = {
  hexcode: null,
};

ColorPreview.propTypes = {
  hexcode: PropTypes.string,
};


const colorPreviewBoxStyles = cxs({
  bottom: '2px',
  position: 'relative',
});


const WTSColorSelect = ({ selectedHexcode, ...props}) => (
  <Flex justify='space-between' align='center'>
    <Box w={5/6}>
      <WTSDropdownMenu
        {...props}
      />
    </Box>
    <Box w={1/6} className={colorPreviewBoxStyles}>
      <ColorPreview hexcode={selectedHexcode} />
    </Box>
  </Flex>
)

WTSColorSelect.propTypes = {
  selectedHexcode: PropTypes.string,
}

WTSColorSelect.defaultProps = {
  selectedHexcode: null,
}

export default WTSColorSelect;