import React from 'react';
import { useSelector } from 'react-redux';

import { templateSettingTypes } from '../constants/index.js';
import { selectors } from '../store/index.js';
import DynamicPropertyUpdateProvider from './contextProviders/DynamicPropertyUpdateProvider.js';
import BasicSettingsPanelContent from './BasicSettingsPanelContent.js';
import GroupsPanelContent from './GroupsPanelContent.js';
import MusicPanelContent from './MusicPanelContent.js';
import OverridesPanelContent from './OverridesPanelContent.js';
import SwitchesPanelContent from './SwitchesPanelContent.js';

const settingsEditingComponents = {
  [templateSettingTypes.basic]: BasicSettingsPanelContent,
  [templateSettingTypes.overrides]: OverridesPanelContent,
  [templateSettingTypes.groups]: GroupsPanelContent,
  [templateSettingTypes.switches]: SwitchesPanelContent,
  [templateSettingTypes.music]: MusicPanelContent,
};

const DynamicSettingsPanelContent = () => {
  const selectedTemplateSetting = useSelector(selectors.getSelectedTemplateSetting);

  const PlaceholderComponent = () => <div>No content found for {selectedTemplateSetting}</div>;

  const SettingsComponent =
    settingsEditingComponents[selectedTemplateSetting] || PlaceholderComponent;

  return (
    <DynamicPropertyUpdateProvider>
      <SettingsComponent />
    </DynamicPropertyUpdateProvider>
  );
};

export default DynamicSettingsPanelContent;
