/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, Colors, Icon, Popover } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { layerTypes } from '../constants/TemplateManifest.js';
import {
  projectManifestLayerPropType,
  projectManifestCompositionPropType,
} from '../constants/propTypes.js';
import {
  darkBackground,
  defaultText,
  panelBackground,
  selectedText,
  selectedTextBackground,
} from './lib/colors.js';
import { WTSIcon } from './lib/index.js';

/**
 * List item that displays the name and type of a layer.
 *
 * @class      LayerList
 * @param      {Object}    props                       The props
 * @param      {Object}    props.layer                 The information about the layer to display
 * @param      {Boolean}   props.isEdited              If this layer has been edited
 * @param      {Boolean}   props.isSelected            If this layer has been selected
 * @param      {string}    props.collapseIcon          The icon used to represent if a layer's children are shown or hidden
 * @param      {Element}   props.children              The child component to render the child layers, if they exist
 * @param      {Object}    props.itemStyles            Styles to apply to the item div
 * @param      {Function}  props.onToggleChildLayers   The callback function if the list item is a composition that is being opened
 * @param      {Function}  props.onSelectLayer         The callback function if the layer is being selected by the user for editing, arguments (layer, isUsingMetaKey)
 * @param      {Function}  props.onDeselectLayer       The callback function if this layer is being deselected, arguments (layer, isUsingMetaKey)
 * @param      {Function}  props.onSelectPreComp       The callback function if this layer is a precomp and the user wants a detail view
 */
const LayerListItem = ({
  layer,
  isSelected,
  isVisible,
  collapseIcon,
  children,
  itemStyles,
  onToggleChildLayers,
  onSelectLayer,
  onSelectPreComp,
  onToggleVisibility,
  onGoToFrame,
}) => {
  const displayName = layer.name.length > 25 ? `${layer.name.substring(0, 25)}...` : layer.name;
  const [isGoToFrameOpen, setIsGoToFrameOpen] = useState(false)
  const rightClickTargetRef = useRef(null)

  const onClickToggleVisibility = (event) => {
    event.stopPropagation()
    // If the layer is hidden in the project manifest (i.e. it was hidden when exported by the Waymark Author), don't allow the user to change its visibility
    if (!layer.isRenderable) { return }

    onToggleVisibility(layer, !isVisible)
  }

  const onClickOpenPreComp = (event) => {
    event.stopPropagation()
    onSelectPreComp(layer.referencedComposition);
  };

  const onClickLayer = (event) => {
    // If the user is holding down command, the selection will select multiple layers or deselect just the layer in question
    const isUsingMetaKey = event.metaKey;
    const isUsingShiftKey = event.shiftKey;
    onSelectLayer(layer, isUsingMetaKey, isUsingShiftKey);
  };

  const onCloseGoToFrame = () => {
    setIsGoToFrameOpen(false)
  }

  const onClickGoToFrame = () => {
    onGoToFrame(layer.frameNumber)
    setIsGoToFrameOpen(false)
  }

  useEffect(() => {
    const onRightClickLayer = (event) => {
      // If the layer is a composition or we have no callback, we have nothing to advance to
      if (!layer.childLayers && onGoToFrame) {
        event.preventDefault()
        setIsGoToFrameOpen(true)
      }
    }

    rightClickTargetRef.current.addEventListener('contextmenu', onRightClickLayer)
    return () => {
      rightClickTargetRef.current.removeEventListener('contextmenu', onRightClickLayer)
    }
  }, [layer, rightClickTargetRef, onGoToFrame, setIsGoToFrameOpen])

  const isComposition = layer.childLayers
  const hasChildLayers = isComposition && Boolean(layer.childLayers.length)

  return (
    /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
    <li
      onClick={onClickLayer}
      css={{
        fontSize: '12px',
        listStyleType: 'none',
        color: isSelected ? selectedText : defaultText,
        backgroundColor: isSelected ? selectedTextBackground : panelBackground,
        opacity: layer.isRenderable || isComposition ? 1 : '0.6',
        position: 'relative',
      }}
    >
      <Popover
        isOpen={isGoToFrameOpen}
        onClose={onCloseGoToFrame}
        fill
      >
        <div
          ref={rightClickTargetRef}
          css={{
            border: `1px solid ${darkBackground}`,
          }}
        >
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              borderLeftWidth: isComposition ? 0 : 5,
              borderLeftStyle: 'solid',
              borderLeftColor: layer.isEdited ? Colors.BLUE1 : 'transparent',
              paddingLeft: 7,
              height: '23px',
              ...itemStyles,
            }}
          >
            {!isComposition && onToggleVisibility && (
              <button
                onClick={onClickToggleVisibility}
                disabled={!layer.isRenderable}
                css={{
                  backgroundColor: darkBackground,
                  width: 17,
                  height: 17,
                  border: 'none',
                  padding: '0 2px',
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'center',
                  marginRight: 6,
                }}
                title={!layer.isRenderable ? 'This layer was hidden during export' : 'Toggle layer visibility'}
              >
                { isVisible && (
                  <Icon
                    icon={IconNames.EYE_OPEN}
                    iconSize={12}
                    color={defaultText}
                  />
                )}
              </button>
            )}

            <WTSIcon
              css={{
                marginRight: '10px',
              }}
              iconType={layer.type}
              theme={isSelected ? 'dark' : 'light'}
            />
            <span
              css={{
                flex: 'auto',
                userSelect: 'none',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                wordWrap: 'normal',
              }}
            >
              {`${displayName}`}
            </span>

            {hasChildLayers && (
              <span style={{ pointerEvents: 'auto' }}>
                <Icon
                  css={{
                    paddingRight: '5px',
                    float: 'right',
                    paddingTop: '3px',
                  }}
                  icon={collapseIcon}
                  iconSize={Icon.SIZE_STANDARD}
                  onClick={onToggleChildLayers}
                  htmlTitle="Collapse/Expand layers"
                />
              </span>
            )}
            {layer.type === layerTypes.preComp && (
              <Icon
                icon={IconNames.CHEVRON_RIGHT}
                onClick={onClickOpenPreComp}
                htmlTitle="Open composition detail view"
              />
            )}
          </div>
        </div>
        <Button
          text={`Go to frame ${layer.frameNumber}`}
          onClick={onClickGoToFrame}
          minimal
        />
      </Popover>
      {children && <div>{children}</div>}
    </li>
  );
};

LayerListItem.propTypes = {
  layer: PropTypes.oneOfType([projectManifestLayerPropType, projectManifestCompositionPropType])
    .isRequired,
  isSelected: PropTypes.bool,
  isVisible: PropTypes.bool,
  onToggleChildLayers: PropTypes.func,
  onSelectLayer: PropTypes.func,
  onSelectPreComp: PropTypes.func,
  onToggleVisibility: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(null)]),
  onGoToFrame: PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(null)]),
  collapseIcon: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.instanceOf(null)]),
  // eslint-disable-next-line react/forbid-prop-types
  itemStyles: PropTypes.object,
};

LayerListItem.defaultProps = {
  onSelectPreComp: () => {},
  onSelectLayer: () => {},
  onToggleChildLayers: () => {},
  onToggleVisibility: null,
  onGoToFrame: null,
  isSelected: false,
  isVisible: false,
  collapseIcon: '',
  children: null,
  itemStyles: {},
};

export default LayerListItem;
