import { useState, useEffect } from 'react';

/**
 * Custom hook for creating a state value local to a component that can be changed
 * within the component, but which also gets updated whenever a provided value changes.
 * Helpful when managing controlled input components where we don't want to sync the
 * changes to the store on change, but also want the component to be controlled in order
 * to programatically update the component whenever the value in the store changes (even
 * after first render).
 * @param  {*} valueToMirror
 *        The "source of truth" value (generally a value from the store state). Keeping
 *        this value up to date takes precendence over local changes.
 * @return {Array} [stateValue, setStateValue] Same return interface as `setState`
 */
/* eslint-disable-next-line import/prefer-default-export */
export const useMirroredState = (valueToMirror) => {
  const [stateValue, setStateValue] = useState(valueToMirror);

  // Keep the local value in sync with the
  useEffect(() => {
    setStateValue(valueToMirror);
  }, [valueToMirror]);

  return [stateValue, setStateValue];
};
