import cxs from 'cxs';
import { Menu, MenuItem } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import React from 'react';

import { WTSIcon } from './lib/index.js';
import { darkBackground, defaultText, panelBackground } from './lib/colors.js';

const contextMenu = cxs({
  maxWidth: '200px',
  width: '100%',
});

const contextMenuItem = cxs({
  backgroundColor: panelBackground,
  border: `1px solid ${darkBackground}`,
  color: defaultText,
  fontSize: '12px',
  ':hover': {
    backgroundColor: panelBackground,
  },
});

const VideoPlaybackContextMenu = ({ contextMenuCoordinates, menuItems, onSelectMenuItem }) => (
  <div style={{ position: 'fixed', left: contextMenuCoordinates.x, top: contextMenuCoordinates.y }}>
    <Menu className={contextMenu}>
      {menuItems.map((item) => (
        <MenuItem
          className={contextMenuItem}
          key={item.uuid}
          onClick={() => onSelectMenuItem(item)}
          text={item.name}
          icon={<WTSIcon theme='light' iconType={item.type} />}
        />
      ))}
    </Menu>
  </div>
);

VideoPlaybackContextMenu.propTypes = {
  contextMenuCoordinates: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }).isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelectMenuItem: PropTypes.func.isRequired,
};

export default VideoPlaybackContextMenu;
