/* eslint-env browser */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useState, useRef } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useSelector } from 'react-redux';

import CompositionListPanelStack from './CompositionListPanelStack.js';
import store, { selectors } from '../store/index.js';
import TemplateSettingsList from './TemplateSettingsList.js';

/**
 * Renders a list of editable and non-editable layers for a template and
 * a component to filter the layers by type.
 *
 * All components rendered in NavigationSidebar have access to
 * VisibleLayerStateContext and VisibleLayersDispatchContext.
 */
const NavigationSidebar = () => {
  const scrollRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const templateManifest = useSelector(selectors.getTemplateManifest);
  let compositions = useSelector(selectors.getAllCompositions);

  /**
   * Reset the scroll position when a panel changes
   *
   * @param      {number}  newScrollPosition  The new scroll position
   */
  const onResetScrollPosition = (newScrollPosition) => {
    scrollRef.current.scroll({ top: newScrollPosition });
  };

  useDeepCompareEffect(() => {
    compositions = selectors.getAllCompositions(store.getState());
  }, [templateManifest]);

  useEffect(() => {
    const updateScrollPosition = ({ target }) => {
      setScrollPosition(target.scrollTop);
    };
    scrollRef.current.addEventListener('scroll', updateScrollPosition);
    return () => {
      scrollRef.current.removeEventListener('scroll', updateScrollPosition);
    };
  }, [setScrollPosition]);

  return (
    <div
      css={{
        overflowY: 'scroll',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
      ref={scrollRef}
    >
      <TemplateSettingsList />
      {Boolean(compositions.length) && (
        <>
          <CompositionListPanelStack
            compositions={compositions}
            scrollPosition={scrollPosition}
            onResetScrollPosition={onResetScrollPosition}
          />
        </>
      )}
    </div>
  );
};

export default NavigationSidebar;
