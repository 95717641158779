import _ from 'lodash';

import { editingFormTemplates, editingFormTypes } from '../constants/EditingForm.js';

/**
 * Get editing form field by type
 * @param  {string} formFieldType    Editing form field type.
 */
const getEditingFormFieldTemplate = (formFieldType) =>
  _.cloneDeep(editingFormTemplates[formFieldType]);

/**
 * Stamp out editing form field properties based on the form field type.
 * @param  {object} editingFormField      Form field that needs additional
 *                                        information.
 * @param  {string} editingFormFieldType  Editing field type.
 * @param  {object} editingFormFieldData  Data to apply to editingFormField.
 */
const parseFormFieldProperties = (editingFormField, editingFormFieldType, editingFormFieldData) => {
  switch (editingFormFieldType) {
    case editingFormTypes.audio: {
      // eslint-disable-next-line no-param-reassign
      editingFormField.selectOptions = editingFormFieldData.selectOptions;
      break;
    }

    case editingFormTypes.color: {
      break;
    }

    case editingFormTypes.image: {
      // eslint-disable-next-line no-param-reassign
      editingFormField.height = editingFormFieldData.height;
      // eslint-disable-next-line no-param-reassign
      editingFormField.width = editingFormFieldData.width;
      break;
    }

    case editingFormTypes.sceneSwitch: {
      // eslint-disable-next-line no-param-reassign
      editingFormField.selectOptions = editingFormFieldData.selectOptions;
      break;
    }

    case editingFormTypes.text: {
      // eslint-disable-next-line no-param-reassign
      editingFormField.characterLimit = null;
      break;
    }

    case editingFormTypes.footageComposition: {
      // eslint-disable-next-line no-param-reassign
      editingFormField.selectOptions = []
      editingFormFieldData.options.forEach(({ name, location, type, isUsed }) => {
        if (isUsed) {
          editingFormField.selectOptions.push({
            label: name,
            configurationValue: {
              type,
              location,
            },
          })
        }
      });
      break;
    }

    case editingFormTypes.video: {
      // eslint-disable-next-line no-param-reassign
      editingFormField.inPoint = editingFormFieldData.projectManifestLayer.ip;
      editingFormField.outPoint = editingFormFieldData.projectManifestLayer.op;
      break;
    }

    case editingFormTypes.font: {
      const respectedPath = editingFormFieldData.configurationPaths[0];
      // eslint-disable-next-line no-param-reassign
      editingFormField.respectedPathMappings[respectedPath] = [respectedPath];
      break;
    }

    default: {
      break;
    }
  }

  return editingFormField;
};

/**
 * Create editing form field based on the editing field type.
 * @param  {string} editingFieldType    Editing field type.
 * @param  {object} editingObject       Layer UUIDs the editing action should
 *                                      target.
 * @param  {array} configurationPaths   Layer configuration paths that should be updated
 *                                      via the editingFormField.
 */
// eslint-disable-next-line import/prefer-default-export
export const createEditingFormField = (editingFieldType, editingObject, configurationPaths) => {
  let editingFormField = getEditingFormFieldTemplate(editingFieldType);

  // Define global properties present on all editing form fields.
  editingFormField.editingFieldKey = editingObject.id;
  editingFormField.label = editingObject.name;
  // Round the display time to a maximum of 2 decimal places, or if no frame number was provided,
  // set it to null.
  editingFormField.displayTime = (editingObject.frameNumber || editingObject.frameNumber === 0) ? Math.round((editingObject.frameNumber / 30) * 100) / 100 : null;

  // Not every editing field has a singular list of paths (font), so only attempt to add
  // all configruation paths if paths is present top-level on editingFormField.
  if (editingFormField.paths) {
    configurationPaths.forEach((path) => {
      editingFormField.paths.push(path);
    });
  } else {
    // Otherwise, we likely need to do something else with the configuration paths based on
    // the editingFieldType, so add the configuration paths to the editingObject for use in
    // parseFormFieldProperties.
    const modifiedEditingObject = _.cloneDeep(editingObject)
    modifiedEditingObject.configurationPaths = configurationPaths;
    return parseFormFieldProperties(editingFormField, editingFieldType, modifiedEditingObject);
  }

  // Define properties on editing form field specific to the form field type.
  editingFormField = parseFormFieldProperties(editingFormField, editingFieldType, editingObject);

  return editingFormField;
};
