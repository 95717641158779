/**
 * Core/shared colors used throughout the Waymark Template Studio application.
 */

// Appplication
export const appBackground = '#272727';
export const panelBackground = '#303030';
export const darkBackground = '#161616';

// Typography
export const defaultText = '#b1b1b1';
export const mutedText = '#7f7f7f';
export const selectedText = '#161616';
export const selectedTextBackground = '#b1b1b1';
export const labelText = '#e3e3e3';

// Elements

// WTS Intent
export const altBackground = '#37a0ef'; // blue
export const blendedBackground = panelBackground; // dark gray
export const defaultBackground = '#3d3d3d'; // gray
export const primaryBackground = '#e4d84d'; // yellow
export const selectedBackground = selectedTextBackground; // light gray
export const successBackground = '#80c16d'; // green
export const dangerBackground = '#4f3131'; // deep red

// Buttons
export const darkButtonText = '#161616';
export const lightButtonText = '#e1e1e1';

// Form fields
export const inputFieldBackground = darkBackground;

// Links
export const linkText = '#37a0ef';

// Icons
export const darkIcon = panelBackground;
export const lightIcon = defaultText;
