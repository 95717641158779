export const defaultFillModifications = {
  fit: 'crop',
  zoom: {
    z: 1,
    x: 0.5,
    y: 0.5,
  },
};

export const defaultFitModifications = {
  fit: 'fill',
  cropping: {
    x: 0,
    y: 0,
    width: 1,
    height: 1,
  },
  backgroundFill: '#ffffff00',
  fillColor: '#ffffff00',
  padding: 1,
};
