import cxs from 'cxs';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DynamicPropertyUpdateDispatch } from './contextProviders/DynamicPropertyUpdateProvider.js';
import { WTSButton, WTSFormGroup, WTSSwitch } from './lib/index.js';
import { overridePropType } from '../constants/propTypes.js';
import { actions, operations, selectors } from '../store/index.js';
import { getUpdatedFontData } from '../store/workspace.js';

const switchSubtitle = cxs({
  marginRight: '18px',
  display: 'inline-block',
});

const fontOverrideItem = cxs({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const fontActionButtons = cxs({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center',
});

const findFontOverride = (fontOverrides, rendererFont) =>
  fontOverrides.find(({ originalTypography }) => _.isEqual(originalTypography, rendererFont));

const RenderTextLayerFontOverride = ({ fontOverrides, layer }) => {
  const onUpdateDynamicProperties = useContext(DynamicPropertyUpdateDispatch);
  const templateManifestData =
    useSelector((state) => selectors.getTemplateManifestData(state, layer.uuid));
  const isFontEditable = Boolean(_.get(templateManifestData, 'font.override'));
  const projectManifestData = useSelector((state) => selectors.getLayerByUUID(state, layer.uuid));
  const activeTemplateId = useSelector(selectors.getActiveTemplateId);
  const activeVersionNumber = useSelector(selectors.getActiveVersionNumber);

  const dispatch = useDispatch();

  const onChangeTextLayerFontOverride = async (event) => {
    const isEditable = event.target.checked;

    // If we're toggling ON here, let's get the correct override to use.
    const payload = { font: null };
    if (isEditable) {

      // Create an override for the font if none exists. This is only possible for projects that
      // were imported before the font override creation was moved to the server.
      // TODO: Move this logic into a migration
      let override = findFontOverride(fontOverrides, projectManifestData.rendererFont);
      if (!override) {
        const version = await dispatch(
          operations.fetchTemplateVersion(activeTemplateId, activeVersionNumber, true),
        );
        const updatedFontData = getUpdatedFontData(version, [ layer ]);
        await dispatch(operations.updateTemplateManifest(
          actions.setNewFontOverideData,
          updatedFontData,
        ));
        [ override ] = Object.values(updatedFontData.newFontOverrides);
      }

      payload.font = { override: override.id };
    }
    await onUpdateDynamicProperties(payload, [ layer.uuid ]);
  };

  return (
    <div className={fontOverrideItem}>
      <div className={switchSubtitle}>{layer.name}</div>
      <WTSSwitch checked={isFontEditable} onChange={onChangeTextLayerFontOverride} />
    </div>
  );
};

RenderTextLayerFontOverride.propTypes = {
  fontOverrides: PropTypes.arrayOf(PropTypes.object).isRequired,
  layer: PropTypes.object.isRequired,
};

const EditableFontOverrideContent = ({ override }) => {
  const activeTemplateId = useSelector(selectors.getActiveTemplateId);
  const activeVersionNumber = useSelector(selectors.getActiveVersionNumber);
  const dispatch = useDispatch();

  const { layers, name } = override;

  const fontOverrides = useSelector(selectors.getTemplateManifestFontOverrides);
  const onUpdateDynamicProperties = useContext(DynamicPropertyUpdateDispatch);

  const onClickEnableAllEditable = async () => {
    await Promise.all(layers.map(async (layer) => {

      // Create an override for the font if none exists. This is only possible for projects that
      // were imported before the font override creation was moved to the server.
      // TODO: Move this logic into a migration
      let fontOverride = findFontOverride(fontOverrides, layer.rendererFont);
      if (!fontOverride) {
        const version = await dispatch(
          operations.fetchTemplateVersion(activeTemplateId, activeVersionNumber, true),
        );
        const updatedFontData = getUpdatedFontData(version, [ layer ]);
        await dispatch(operations.updateTemplateManifest(
          actions.setNewFontOverideData,
          updatedFontData,
        ));
        [ fontOverride ] = Object.values(updatedFontData.newFontOverrides);
      }

      await onUpdateDynamicProperties({ font: { override: fontOverride.id } }, [ layer.uuid ]);
    }));
  };

  const onClickDisableAllEditable = async () => {
    const textLayerUUIDs = layers.map(({ uuid }) => uuid);
    await onUpdateDynamicProperties({ font: null }, textLayerUUIDs);
  };

  return (
    <WTSFormGroup
      label={`Turn font editability on/off for layers with font "${name}"`}
      labelSubtext="Change editability for each layer with this font individually using switches, or all at once using buttons."
    >
      {layers.map(
        (layer) => <RenderTextLayerFontOverride fontOverrides={fontOverrides} layer={layer} />
      )}
      <div className={fontActionButtons}>
        <WTSButton onClick={onClickEnableAllEditable}>Turn On All</WTSButton>
        <WTSButton onClick={onClickDisableAllEditable}>Turn Off All</WTSButton>
      </div>
    </WTSFormGroup>
  );
};

EditableFontOverrideContent.propTypes = {
  override: overridePropType.isRequired,
};

export default EditableFontOverrideContent;

