import waymarkAuthorCustomProperties from './waymarkAuthorCustomProperties.js';

export { waymarkAuthorCustomProperties };
export { createWaymarkAudioFromLayer, AudioMediaHandler } from './waymarkAudio/index.js';
export { createCompositionFromLayer } from './composition.js';
export { createImageFromLayer } from './image.js';
export { applyMaskToLayer, applyTrackMatte, trackMatteTypes } from './mask.js';
export { createNullFromLayer } from './null.js';
export {
  applyShapePropertyFromBodymovin,
  constructPolyBezierFromData,
  createGroupFromBodymovin,
  createShapeFromLayer,
} from './shapes/index.js';
export { createSolidFromLayer } from './solid.js';
export {
  createTextFromLayer,
  waymarkTextCustomProperties,
  getResizingStrategyFromMetadata,
} from './text/index.js';
export { transformColorKeyframes } from './tweens.js';
export {
  addAsset,
  applyDisplayObjectProperties,
  applyMediaLayerModifications,
  applyProperty,
  applyTimeRemapProperties,
  applyTransformsToObject,
  applyVisibilityBoundsToObject,
  defaultImageMediaModifications,
  defaultVideoMediaModifications,
  extractPixels,
  getObjectsUnderInteraction,
  loadAllAssets,
  loadAssetForLayer,
  loadQueuedAssets,
  setDirty,
} from './utils/index.js';
export {
  createWaymarkVideoFromLayer,
  VideoMediaHandler,
  PlayError,
  playResult,
} from './waymarkVideo/index.js';
