import _ from 'lodash';
import React, { useState } from 'react';
import { read as readClipboard, write as clipboardyWrite } from 'clipboardy';
import { detect } from 'detect-browser';
import { Flex, Box } from 'reflexbox';
import PropTypes from 'prop-types';
import cxs from 'cxs';
import { useSelector, useDispatch } from 'react-redux';
import { Menu, MenuItem, Popover, PopoverPosition, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { overrideTypes } from '../constants/index.js';
import { actions, operations, selectors } from '../store/index.js';
import { WTSButton, WTSIcon, wtsIconTypes } from './lib/index.js';
import CollapsibleMenu from './CollapsibleMenu.js';
import OverrideDetailForm from './OverrideDetailForm.js';
import ListManager from './ListManager.js';

const OverrideItem = ({ overrideType, onClick }) => {
  const onItemClick = () => {
    onClick(overrideType);
  };

  return (
    <MenuItem
      text={_.upperFirst(overrideType)}
      icon={<WTSIcon iconType={wtsIconTypes[overrideType]} />}
      onClick={onItemClick}
    />
  );
};

OverrideItem.propTypes = {
  overrideType: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const manageColorOverridesContainerStyles = cxs({
  margin: '12px 18px',
});

const popoverTargetStyles = cxs({
  width: '100%',
});

const OverrideCreateButton = ({ onCreate }) => (
  <Popover position={PopoverPosition.BOTTOM} targetClassName={popoverTargetStyles}>
    {/* Popover Target */}
    <WTSButton icon={IconNames.ADD} fullWidth>
      Create New
    </WTSButton>
    {/* Popover Content */}
    <Menu>
      <OverrideItem overrideType={wtsIconTypes.color} onClick={onCreate} />
      <OverrideItem overrideType={wtsIconTypes.image} onClick={onCreate} />
      <OverrideItem overrideType={wtsIconTypes.text} onClick={onCreate} />
    </Menu>
  </Popover>
);

OverrideCreateButton.propTypes = {
  onCreate: PropTypes.func.isRequired,
};

const OverridesPanelContent = () => {
  const [selectedOverrideId, setSelectedOverrideId] = useState(null);
  const [isCopyOperationRunning, setIsCopyOperationRunning] = useState(false);
  const [isPasteOperationRunning, setIsPasteOperationRunning] = useState(false);
  const dispatch = useDispatch();

  const overrides = useSelector(selectors.getVisibleTemplateManifestOverrides);

  const onCreateOverride = async (newOverrideId, newOverrideType) => {
    await dispatch(
      operations.updateTemplateManifest(actions.createOverride, {
        id: newOverrideId,
        type: newOverrideType,
      }),
    );
  };

  const onClickOverride = (overrideId) => {
    setSelectedOverrideId(overrideId);
  };

  const onDeleteOverride = (overrideId) => {
    dispatch(operations.updateTemplateManifest(actions.deleteOverride, overrideId));
  };

  const onClickCopyColors = async () => {
    setIsCopyOperationRunning(true);
    const colorOverrides = overrides.filter(({ type }) => type === overrideTypes.color);

    await clipboardyWrite(JSON.stringify(colorOverrides));
    setTimeout(() => {
      setIsCopyOperationRunning(false);
    }, 1000);
  };

  const onClickPasteColors = async () => {
    setIsPasteOperationRunning(true);
    const content = await readClipboard();
    const overrides = JSON.parse(content);
    await dispatch(operations.updateTemplateManifest(actions.createOverrides, overrides));
    setTimeout(() => {
      setIsPasteOperationRunning(false);
    }, 1000);
  };

  const overrideItems = overrides.map((override) => ({
    ...override,
    wtsIconType: override.type,
  }));

  const textLayers = useSelector(selectors.getTextLayers);

  const fonts = {};

  const fontKey = (font) => `${font.family}.${font.weight}.${font.style}`;

  const textLayerGroups = {};
  textLayers.forEach((layer) => {
    const key = fontKey(layer.rendererFont);
    if (!(key in fonts)) {
      fonts[key] = layer.rendererFont;
      textLayerGroups[key] = [layer];
    } else {
      textLayerGroups[key].push(layer);
    }
  });

  const waymarkFontPrefix = 'WaymarkVideo__';
  Object.entries(textLayerGroups).forEach(([key, layers], index) => {
    const font = fonts[key];
    const source = font.family.startsWith(waymarkFontPrefix) ? 'Waymark' : 'Google';
    const family = font.family.startsWith(waymarkFontPrefix)
      ? font.family.substring(waymarkFontPrefix.length)
      : font.family;
    const name = `${family} ${font.weight} ${font.style} (${source})`;
    overrideItems.push({
      type: 'font',
      id: index.toString(),
      layers,
      name,
      wtsIconType: 'font',
    });
  });

  const selectedOverride = overrideItems.find(({ id }) => id === selectedOverrideId);

  // Browser "Paste" functionality isn't working in Firefox 87.0 for macOS
  const isFirefox = detect().name === 'firefox';

  return (
    <div>
      <CollapsibleMenu title="Overrides">
        <ListManager
          items={overrideItems}
          listItemName="override"
          onDelete={onDeleteOverride}
          onItemSelected={onClickOverride}
          CreateButtonComponent={OverrideCreateButton}
          onCreate={onCreateOverride}
          shouldAllowDelete={Boolean(selectedOverride && selectedOverride.type !== 'font')}
          shouldAllowCreate
        >
          <Flex className={manageColorOverridesContainerStyles}>
            <Box w={1 / 2} px="4px">
              <WTSButton
                disabled={isFirefox || isCopyOperationRunning}
                onClick={onClickCopyColors}
                fullWidth
              >
                {isCopyOperationRunning ? 'Copied!' : 'Copy colors'}
              </WTSButton>
            </Box>
            <Box w={1 / 2} px="4px">
              <WTSButton
                disabled={isFirefox || isPasteOperationRunning}
                onClick={onClickPasteColors}
                fullWidth
              >
                {isPasteOperationRunning ? 'Pasted!' : 'Paste colors'}
              </WTSButton>
            </Box>
          </Flex>
        </ListManager>
      </CollapsibleMenu>
      {selectedOverride && <OverrideDetailForm override={selectedOverride} />}
    </div>
  );
};

export default OverridesPanelContent;
