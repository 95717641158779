/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, InputGroup } from '@blueprintjs/core';
import { WTSButton } from './lib/index.js';

const ReimportDialogContents = ({ onSubmit }) => {
  const confirmationCodeInputRef = useRef(null)

  const onSubmitCode = (event) => {
    event.preventDefault()
    const confirmationCode = confirmationCodeInputRef.current.value
    if (!confirmationCode) {
      return
    }

    onSubmit(confirmationCode)
  }

  const submitButton = (
    <WTSButton
      type='submit'
      wtsIntent="primary"
      css={{
        height: 'auto',
      }}
    >
      Submit
    </WTSButton>
  )

  return (
    <form
      onSubmit={onSubmitCode}
      css={{
        padding: 20,
      }}
    >
      <FormGroup
        label="Confirmation Code"
        labelFor="confirmationCode"
        css={{
          marginBottom: 0
        }}
      >
        <InputGroup
          name='confirmationCode'
          inputRef={confirmationCodeInputRef}
          type='text'
          placeholder='ex. DNmXXG2kiP'
          rightElement={submitButton}
          css={{
            width: '100%'
          }}
        />
      </FormGroup>
    </form>
  )
}

ReimportDialogContents.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default ReimportDialogContents