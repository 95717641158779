import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import cxs from 'cxs';
import PropTypes from 'prop-types';
import { Icon, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useSelector, useDispatch } from 'react-redux';

import { actions, operations, selectors } from '../store/index.js';
import { wtsIntent, groupPropType } from '../constants/index.js';
import { collapsibleMenuContentContainer, WTSButton, WTSFormGroup, WTSInputGroup, WTSDropdownMenu } from './lib/index.js';
import { defaultText, successBackground } from './lib/colors.js';
import CollapsibleMenu from './CollapsibleMenu.js';


const deleteIconStyles = cxs({
  paddingRight: '6px',
  ':hover': {
    cursor: 'pointer',
  },
});

const groupDisplayItemStyles = cxs({
  color: defaultText,
  fontSize: '11px',
  marginTop: '3px',
});

const GroupDisplay = ({ group, onClickDelete }) => {
  const onClick = () => {
    onClickDelete(group);
  }

  return (
    <div className={groupDisplayItemStyles}>
      <Icon
        icon={IconNames.DELETE}
        onClick={onClick}
        className={deleteIconStyles}
        iconSize={12}
      />
      <span>{group.name}</span>
    </div>
  )
};

GroupDisplay.propTypes = {
  group: groupPropType.isRequired,
  onClickDelete: PropTypes.func.isRequired,
};

const groupListStyles = cxs({
  fontSize: '12px',
  marginTop: '12px',
});

const feedbackProperties = {
  fontSize: '11px',
  marginTop: '12px',
  textAlign: 'center',
};

const successStyles = cxs({
  ...feedbackProperties,
  color: successBackground,
})

const errorStyles = cxs({
  ...feedbackProperties,
  color: 'red',
})

const groupToDropdownOption = (group) => ({
  label: group.name,
  value: group.id,
})


/**
 * Collapsible menu component for adding layers to a new or existing Group.
 */
const GroupSelect = () => {
  const [selectedLayerGroups, otherGroups] = useSelector(selectors.getSortedGroupsForSelectedLayers);
  const selectedLayers = useSelector(selectors.getSelectedLayers);
  const selectedLayersKey = useSelector(selectors.getSelectedLayersKey);
  const dispatch = useDispatch();

  // Group name field needs
  const [newGroupName, setNewGroupName] = useState('');
  const [newGroupSuccessMessage, setNewGroupSuccessMessage] = useState('');
  const [newGroupErrorMessage, setNewGroupErrorMessage] = useState('');

  // Group dropdown needs
  const [selectedGroupId, setSelectedGroupID] = useState('');
  const [addGroupSuccessMessage, setAddGroupSuccessMessage] = useState('');
  const groupValues = otherGroups.map(groupToDropdownOption);

  const selectedDropdownValue = selectedGroupId ? groupToDropdownOption(_.find(otherGroups, { id: selectedGroupId})) : null;

  const layerOrLayers = `layer${selectedLayers.length > 1 ? 's' : ''}`;

  const clearSuccessMessages = () => {
    setAddGroupSuccessMessage('');
    setNewGroupSuccessMessage('');
  }

  // Whenever the selected layer(s) change, we should reset state values as needed.
  useEffect(() => {
    clearSuccessMessages();
    setNewGroupErrorMessage('');
    setNewGroupName('');
    setSelectedGroupID('');
  }, [selectedLayersKey])

  const onSelectGroup = (newGroupOption) => {
    clearSuccessMessages();
    setSelectedGroupID(newGroupOption.value);
  }

  const onAddLayersToSelectedGroup = async () => {
    const selectedGroup = _.find(otherGroups, { id: selectedGroupId });
    setSelectedGroupID('');

    const updatedGroup = {
      ...selectedGroup,
      layers: _.uniq(_.concat(selectedGroup.layers, selectedLayers)),
    };

    await dispatch(operations.updateTemplateManifest(
      actions.updateSceneGroup,
      updatedGroup
    ));

    setAddGroupSuccessMessage(`Added selected ${layerOrLayers} to ${selectedGroup.name}.`);
  }

  const onChangeNewGroupName = (e) => {
    clearSuccessMessages();

    // Clear any existing errors.
    if (newGroupErrorMessage) {
      setNewGroupErrorMessage('');
    }

    setNewGroupName(e.currentTarget.value);
  };

  const isNewGroupNameUnique = () => {
    const allGroups = selectedLayerGroups.concat(otherGroups);
    return !_.find(allGroups, { name: newGroupName });
  }

  const attemptCreateNewGroup = async () => {
    if (!isNewGroupNameUnique()) {
      setNewGroupErrorMessage(`A group named "${newGroupName}" already exists. Please choose another name.`);
      return;
    }

    await dispatch(operations.updateTemplateManifest(
      actions.createSceneGroup,
      { name: newGroupName, layers: selectedLayers }
    ));
    setNewGroupSuccessMessage(`Added selected ${layerOrLayers} to a new group: ${newGroupName}`);
    setNewGroupName('');
  }

  const onCreateNewGroup = async () => {
    attemptCreateNewGroup();
  }

  const onNewGroupKeyPress = (e) => {
    if (e.keyCode === 13 || e.which === 13) {
      attemptCreateNewGroup();
      e.currentTarget.blur();
    }
  }

  const onRemoveLayersFromGroup = (group) => {
    clearSuccessMessages();

    const updatedGroup = {
      ...group,
      layers: _.without(group.layers, ...selectedLayers),
    };
    dispatch(operations.updateTemplateManifest(
      actions.updateSceneGroup,
      updatedGroup
    ));
  };

  return (
    <CollapsibleMenu title="Groups">
      <form className={collapsibleMenuContentContainer}>
        <WTSFormGroup
          intent={Intent.None}
          label="Create a new group"
          labelFor="groupName"
          labelSubtext="Describe these layers with a group name"
        >
          <WTSInputGroup
            id="groupName"
            type="text"
            intent={Intent.None}
            value={newGroupName}
            onChange={onChangeNewGroupName}
            onKeyPress={onNewGroupKeyPress}
          />
          <WTSButton
            wtsIntent={wtsIntent.default}
            onClick={onCreateNewGroup}
            disabled={!newGroupName}
            fullWidth
          >
            Create group
          </WTSButton>
          <div className={successStyles}>{newGroupSuccessMessage}</div>
          <div className={errorStyles}>{newGroupErrorMessage}</div>
        </WTSFormGroup>
        <WTSDropdownMenu
          label="Or add to an existing group"
          menuItems={groupValues}
          onItemSelect={onSelectGroup}
          value={selectedDropdownValue}
        />
        <WTSButton
          wtsIntent={wtsIntent.default}
          onClick={onAddLayersToSelectedGroup}
          disabled={!selectedGroupId}
          fullWidth
        >
          Add {layerOrLayers} to group
        </WTSButton>
        <div className={successStyles}>{addGroupSuccessMessage}</div>
        {selectedLayerGroups.length > 0 && (
          <div className={groupListStyles}>
            <div>Selected {layerOrLayers} already a member of:</div>
            {selectedLayerGroups.map((group) => (
              <GroupDisplay key={group.id} group={group} onClickDelete={onRemoveLayersFromGroup} />
            ))}
          </div>
        )}
      </form>
    </CollapsibleMenu>
  );
};

export default GroupSelect;
