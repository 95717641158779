import _ from 'lodash';
import React, { useState } from 'react';
import cxs from 'cxs';
import { useSelector, useDispatch } from 'react-redux';

import { blurIfEnterKeyPress } from '../utils/dom.js';
import { useMirroredState } from '../utils/hooks.js';
import { groupPropType } from '../constants/index.js';
import { actions, operations, selectors } from '../store/index.js';
import { collapsibleMenuContentContainer, WTSFormGroup, WTSInputGroup } from './lib/index.js';
import CollapsibleMenu from './CollapsibleMenu.js';
import ListManager from './ListManager.js';
import RemovableLayerListItem from './RemovableLayerListItem.js';


const layerListStyles = cxs({
  marginTop: '12px',
});

const GroupDetailForm = ({ group }) => {
  const groupName = group ? group.name : '';
  const [localName, setLocalName] = useMirroredState(groupName);
  const dispatch = useDispatch();

  if (!group) {
    return null;
  }

  const onChangeGroupName = (e) => {
    setLocalName(e.currentTarget.value);
  };

  const updateGroup = (updatedGroup) => {
    dispatch(operations.updateTemplateManifest(actions.updateSceneGroup, updatedGroup));
  };

  const onUpdateGroupName = () => {
    if (localName !== groupName) {
      const modifiedGroup = {
        ...group,
        name: localName,
      };
      updateGroup(modifiedGroup);
    }
  };

  const onRemoveGroupLayer = (layerUUID) => {
    const layers = _.without(group.layers, layerUUID);
    const modifiedGroup = {
      ...group,
      layers,
    };
    updateGroup(modifiedGroup);
  };

  return (
    <form className={collapsibleMenuContentContainer}>
      <WTSFormGroup label="Group name" labelFor="name" labelSubtext="Describe the group of layers">
        <WTSInputGroup
          id="name"
          type="text"
          value={localName}
          onChange={onChangeGroupName}
          onBlur={onUpdateGroupName}
          onKeyPress={blurIfEnterKeyPress}
        />
      </WTSFormGroup>
      <WTSFormGroup label="Layers in this group" labelSubtext="Add new layers via the layers panel">
        <div className={layerListStyles}>
          {group.layers.map((layerUUID) => (
            <RemovableLayerListItem key={layerUUID} layerUUID={layerUUID} onRemove={onRemoveGroupLayer} />
          ))}
        </div>
      </WTSFormGroup>
    </form>
  );
};

GroupDetailForm.propTypes = {
  group: groupPropType,
};

GroupDetailForm.defaultProps = {
  group: null,
};

const GroupsPanelContent = () => {
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const dispatch = useDispatch();

  const groups = useSelector(selectors.getTemplateManifestGroups);
  const selectedGroup = _.find(groups, { id: selectedGroupId });

  const onClickGroup = (groupId) => {
    setSelectedGroupId(groupId);
  };

  const onDeleteGroup = (groupId) => {
    dispatch(operations.updateTemplateManifest(actions.deleteSceneGroup, groupId));
  };

  return (
    <div>
      <CollapsibleMenu title="Groups">
        <ListManager
          items={groups}
          listItemName="group"
          onDelete={onDeleteGroup}
          onItemSelected={onClickGroup}
        />
      </CollapsibleMenu>
      <CollapsibleMenu title="Group properties">
        <GroupDetailForm group={selectedGroup} />
      </CollapsibleMenu>
    </div>
  );
};

export default GroupsPanelContent;
