/* eslint-disable no-param-reassign */
import { configureStore, combineReducers } from 'redux-starter-kit';
import thunk from 'redux-thunk';

import { getDuplicates, ensureUniquePropertyList } from './utils.js';
import authUserSlice from './authUser.js';
import loginSlice from './login.js';
import templateImportSlice from './templateImport.js';
import templatesSlice from './templates.js';
import workspaceSlice from './workspace.js';
import workspaceTemplateManifestSlice from './workspaceTemplateManifest.js';
import workspaceVersionsSlice from './workspaceVersions.js';

/**
 * Helper for constructing redux store export components for discrete slices.
 * @param  {Object[]} slices  Array of slice objects
 */
const createStoreFromSlices = (slices) => {
  // Validate that we don't have repeated slice names.
  const duplicateSliceNames = getDuplicates(
    slices.map((slice) => slice.slice),
    'slices',
  );
  if (duplicateSliceNames.length) {
    throw Error(`Multiple slices found with the same name: ${duplicateSliceNames.join(', ')}`);
  }

  // An error will be thrown if same-named actions, selectors, or operations are found across slices.
  ensureUniquePropertyList(slices, ['actions', 'selectors', 'operations']);

  const reducers = slices.reduce((accum, slice) => {
    accum[slice.slice] = slice.reducer;
    return accum;
  }, {});

  const actions = slices.reduce(
    (accum, slice) => ({
      ...accum,
      ...slice.actions,
    }),
    {},
  );

  const selectors = slices.reduce(
    (accum, slice) => ({
      ...accum,
      ...slice.selectors,
    }),
    {},
  );

  const operations = slices.reduce(
    (accum, slice) => ({
      ...accum,
      ...slice.operations,
    }),
    {},
  );

  const store = configureStore({
    reducer: combineReducers(reducers),
    // Manually specifying just thunk middleware here. redux-starter-kit makes an attempt to respect
    // process.env.NODE_ENV and only include thunk middleware in production builds, but unfortunately this
    // does not currently work for non-Node.js builds (because process.env doesn't exist in the browser).
    // We were seeing major slowdowns in production for templates that had very large projectManifest objects due to the
    // redux-immutable-state-invariant middleware.
    middleware: [thunk],
  });

  return {
    actions,
    operations,
    selectors,
    store,
  };
};

const storeData = createStoreFromSlices([
  authUserSlice,
  loginSlice,
  templateImportSlice,
  templatesSlice,
  workspaceSlice,
  workspaceTemplateManifestSlice,
  workspaceVersionsSlice,
]);

export const { actions, operations, selectors } = storeData;

export default storeData.store;
