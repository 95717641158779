import { AnchorButton, Button } from '@blueprintjs/core';
import cxsComponent from 'cxs/component.js';
import PropTypes from 'prop-types';

import { makeImportant } from '../../utils/styles.js';
import { wtsIntent } from '../../constants/index.js';
import {
  altBackground,
  blendedBackground,
  dangerBackground,
  darkBackground,
  darkButtonText,
  defaultBackground,
  defaultText,
  lightButtonText,
  primaryBackground,
  selectedBackground,
  selectedText,
  successBackground,
} from './colors.js';

/**
 * BUTTONS
 */

const wtsIntentProperties = {
  [wtsIntent.alt]: {
    backgroundColor: makeImportant(altBackground),
    borderColor: makeImportant(altBackground),
    color: makeImportant(darkButtonText),
  },
  [wtsIntent.blended]: {
    backgroundColor: makeImportant(blendedBackground),
    borderColor: makeImportant(darkBackground),
    color: makeImportant(defaultText),
  },
  [wtsIntent.danger]: {
    backgroundColor: makeImportant(dangerBackground),
    borderColor: makeImportant(darkBackground),
    color: makeImportant(lightButtonText),
  },
  [wtsIntent.default]: {
    backgroundColor: makeImportant(defaultBackground),
    borderColor: makeImportant(darkBackground),
    color: makeImportant(lightButtonText),
  },
  [wtsIntent.primary]: {
    backgroundColor: makeImportant(primaryBackground),
    borderColor: makeImportant(primaryBackground),
    color: makeImportant(darkButtonText),
  },
  [wtsIntent.selected]: {
    backgroundColor: makeImportant(selectedBackground),
    borderColor: makeImportant(darkBackground),
    color: makeImportant(selectedText),
  },
  [wtsIntent.success]: {
    backgroundColor: makeImportant(successBackground),
    borderColor: makeImportant(successBackground),
    color: makeImportant(darkButtonText),
  },
};

const defaultButtonProperties = {
  backgroundImage: makeImportant('none'),
  borderRadius: 0,
  borderStyle: 'solid',
  borderWidth: '1px',
  fontSize: '12px',
  fontWeight: 'bold',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'center',
  height: '32px',
  width: 'auto',
};

const parseButtonProps = (props) => {
  const styleProperties = wtsIntentProperties[props.wtsIntent || 'default'];
  return {
    ...defaultButtonProperties,
    ...styleProperties,
    width: props.fullWidth ? '100%' : 'auto',
  };
};

/**
 * Intended to be a simple way to specify which of our button styles you'd like.
 */
const WTSButton = cxsComponent(Button)(parseButtonProps);
WTSButton.propTypes = {
  wtsIntent: PropTypes.oneOf(Object.values(wtsIntent)),
  fullWidth: PropTypes.bool,
};

export const WTSAnchorButton = cxsComponent(AnchorButton)(parseButtonProps);
WTSAnchorButton.propTypes = {
  wtsIntent: PropTypes.oneOf(Object.values(wtsIntent)),
  fullWidth: PropTypes.bool,
};

export default WTSButton;