/* eslint-env browser */
/* global WAYMARK_HOST, API_SERVER */
import axios from 'axios';
import _ from 'lodash';
import Ajv from 'ajv';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Alert, Colors, Dialog, EditableText, Popover, Intent } from '@blueprintjs/core';
import cxs from 'cxs';
import { Flex, Box } from 'reflexbox';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { useMirroredState } from '../utils/hooks.js';
import store, { operations, selectors } from '../store/index.js';
import { darkBackground, labelText } from './lib/colors.js';
import { WTSButton } from './lib/index.js';
import { historyPropType, routePaths } from '../constants/index.js';
import TemplateTag from './TemplateTag.js';
import PublishDialogContents from './PublishDialogContents.js';
import ReimportDialogContents from './ReimportDialogContents.js';
import TemplateManifestInterpreter from '../utils/TemplateManifestInterpreter.js';
import {
  configurationInterpreterSchema,
  formDescriptionSchema,
} from '../constants/editingSchemas.js';

const navBarStyles = cxs({
  backgroundColor: darkBackground,
});

const navButtonStyles = cxs({
  margin: '0 6px',
  minWidth: '76px',
});

const ExitButton = ({ history }) => {
  const onExit = () => {
    history.push('/templates');
  };

  return (
    <WTSButton className={navButtonStyles} onClick={onExit}>
      Exit
    </WTSButton>
  );
};

ExitButton.propTypes = {
  history: historyPropType.isRequired,
};

const RoutedBackButton = withRouter(ExitButton);

const templateTagStyles = cxs({
  display: 'inline-block',
  margin: '0 12px',
});

window.LIST_PUBLISHED_VERSIONS = async () => {
  const state = store.getState();

  const templateVersion = selectors.getActiveVersion(state);
  const templateId = templateVersion ? templateVersion.videoTemplate : '';
  const versionNumber = templateVersion ? templateVersion.version : '';

  const response = await axios.get(
    `${API_SERVER}/api/video-templates/${templateId}/${versionNumber}/temporary-published-bundles/`,
  );

  return response;
};

const layerListStyle = cxs({
  fontSize: '11px',
  paddingTop: '6px',
  color: labelText,
});
const nameStyle = cxs({
  fontWeight: 'bold',
  paddingRight: '8px',
});

const LayerDisplay = ({ fontName, layerUUID, footageCompositionNames }) => {
  const layer = useSelector((state) => selectors.getLayerByUUID(state, layerUUID));
  return (
    <Flex className={layerListStyle} justify="space-between" title={layer ? layer.uuid : null}>
      <Box className={nameStyle}>{layer && layer.name}</Box>
      {fontName && <Box>{fontName}</Box>}
      {footageCompositionNames.map((name) => (
        <Box key={name}>{name}</Box>
      ))}
    </Flex>
  );
};

LayerDisplay.propTypes = {
  fontName: PropTypes.string,
  footageCompositionNames: PropTypes.arrayOf(PropTypes.string),
  layerUUID: PropTypes.string.isRequired,
};

LayerDisplay.defaultProps = {
  fontName: null,
  footageCompositionNames: [],
};

const UUID_LIST_IDENTIFIER = 'UUIDS_JSON:';
const MISSING_FONTS_UUID_LIST_IDENTIFIER = 'MISSING_FONT_LAYER_UUIDS_JSON:';
const MISSING_FOOTAGE_COMPOSITIONS_IDENTIFIER = 'MISSING_FOOTAGE_COMPOSITIONS_JSON:';

const REIMPORT_ERROR = {
  missingFootageCompositions: 'missingFootageCompositions',
  missingLayers: 'missingLayers',
  missingFonts: 'missingFonts',
  unknown: 'unknown',
};

/**
 * Parse details from a reimport error.
 *
 * Returns an object with the following keys:
 *   errorType   Reimport error type
 *   layers      Affected layers or null
 *
 * @param  {object} reimportError Reimport error object
 * @return {object}               Response object
 */
const getReimportErrorDetails = (reimportError) => {
  if (reimportError.afterEffectsImportId) {
    const { errorMessage } = reimportError;
    if (errorMessage.includes(MISSING_FONTS_UUID_LIST_IDENTIFIER)) {
      const missingLayersListString = errorMessage
        .slice(errorMessage.indexOf(MISSING_FONTS_UUID_LIST_IDENTIFIER))
        .replace(MISSING_FONTS_UUID_LIST_IDENTIFIER, '');

      return {
        errorType: REIMPORT_ERROR.missingFonts,
        layers: JSON.parse(missingLayersListString),
      };
    }

    if (errorMessage.includes(UUID_LIST_IDENTIFIER)) {
      const missingLayersListString = errorMessage
        .slice(errorMessage.indexOf(UUID_LIST_IDENTIFIER))
        .replace(UUID_LIST_IDENTIFIER, '');

      return {
        errorType: REIMPORT_ERROR.missingLayers,
        layers: JSON.parse(missingLayersListString),
      };
    }

    if (errorMessage.includes(MISSING_FOOTAGE_COMPOSITIONS_IDENTIFIER)) {
      const missingFootageCompositionsListString = errorMessage
        .slice(errorMessage.indexOf(MISSING_FOOTAGE_COMPOSITIONS_IDENTIFIER))
        .replace(MISSING_FOOTAGE_COMPOSITIONS_IDENTIFIER, '');

      return {
        errorType: REIMPORT_ERROR.missingFootageCompositions,
        layers: JSON.parse(missingFootageCompositionsListString),
      };
    }
  }

  return {
    errorType: REIMPORT_ERROR.unknown,
    layerUUIDs: null,
  };
};

const dangerStyle = cxs({
  color: Colors.RED4,
});

const layerListWrapper = cxs({
  marginTop: '20px',
});

const AlertContents = ({ reimportError }) => {
  const { errorType, layers } = getReimportErrorDetails(reimportError);

  if (errorType === REIMPORT_ERROR.missingLayers) {
    return (
      <div>
        <p>
          We successfully uploaded the After Effects project, but found layers that have already
          been configured here in the Studio to be missing in the new AE project manifest.
        </p>
        <p className={dangerStyle}>
          If you choose to continue, all existing configuration work will be lost.
        </p>
        <p>
          Click &quot;Override&quot; to move forward with applying the new AE project to this
          version.
        </p>
        <div className={layerListWrapper}>
          <div>Missing layers:</div>
          {layers.map(({ uuid }) => (
            <LayerDisplay key={uuid} layerUUID={uuid} />
          ))}
        </div>
      </div>
    );
  }

  if (errorType === REIMPORT_ERROR.missingFonts) {
    return (
      <div>
        <p>
          We successfully uploaded the After Effects project, but found fonts that have already been
          configured here in the Studio to be missing or changed in the new AE project manifest.
        </p>
        <p className={dangerStyle}>
          If you choose to continue, all existing configuration work will be lost.
        </p>
        <p>
          Click &quot;Override&quot; to move forward with applying the new AE project to this
          version.
        </p>
        <div className={layerListWrapper}>
          <div>Layers with missing or changed fonts:</div>
          {layers.map(({ fontName, uuid }) => (
            <LayerDisplay key={uuid} fontName={fontName} layerUUID={uuid} />
          ))}
        </div>
      </div>
    );
  }

  if (errorType === REIMPORT_ERROR.missingFootageCompositions) {
    return (
      <div>
        <p>
          We successfully uploaded the After Effects project, but found footage compositions
          configured here in the Studio to be missing or changed in the new AE project manifest.
        </p>
        <p className={dangerStyle}>
          If you choose to continue, all existing configuration work will be lost.
        </p>
        <p>
          Click &quot;Override&quot; to move forward with applying the new AE project to this
          version.
        </p>
        <div className={layerListWrapper}>
          <div>Layers with missing or changed footage compositions:</div>
          {Object.keys(layers).map((layerUuid) => (
            <LayerDisplay
              key={layerUuid}
              footageCompositionNames={layers[layerUuid].footageCompositionNames}
              layerUUID={layerUuid}
            />
          ))}
        </div>
      </div>
    );
  }

  return <div>There was an error during import. Please close, refresh, and try again.</div>;
};

AlertContents.propTypes = {
  reimportError: PropTypes.shape({
    errorMessage: PropTypes.string,
    afterEffectsImportId: PropTypes.string,
  }),
};

AlertContents.defaultProps = {
  reimportError: null,
};

/**
 * Shared menu component.
 * Should handle content + menu buttons based on the active route.
 * Shows up on TemplateWorkspace and Import views.
 */
const TopMenu = ({ match }) => {
  const templateVersion = useSelector(selectors.getActiveVersion);
  const templateId = templateVersion ? templateVersion.videoTemplate : '';
  const template = useSelector((state) => selectors.getTemplateById(state, templateId));
  const isReimportInProgress = useSelector(selectors.getIsReimportInProgress);

  const [reimportError, setReimportError] = useState(null);
  const [isReimportDialogOpen, setIsReimportDialogOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isPublishDialogOpen, setIsPublishDialogOpen] = useState(false);
  const [publishDialogError, setPublishDialogError] = useState(null);
  const [isRepublishingColors, setIsRepublishingColors] = useState(false);
  const [isRepublishColorsDialogOpen, setIsRepublishColorsDialogOpen] = useState(false);
  const [republishColorsDialogError, setRepublishColorsDialogError] = useState(null);

  const templateName = template ? template.name : '';
  const versionNumber = templateVersion ? templateVersion.version : '';
  const hasLayerConflictError = !_.isEmpty(reimportError) && reimportError.afterEffectsImportId;

  const [localTemplateName, setLocalTemplateName] = useMirroredState(templateName);
  const dispatch = useDispatch();

  const onChangeTemplateName = (newName) => {
    setLocalTemplateName(newName);
  };

  const onConfirmTemplateName = () => {
    // Only attempt to sync if the name has actually changed.
    if (templateName !== localTemplateName) {
      dispatch(operations.updateTemplate(templateId, { name: localTemplateName }));
    }
  };

  const onClickEditorPreview = async () => {
    const state = store.getState();
    const projectManifest = selectors.getActiveProjectManifest(state);
    const templateManifest = selectors.getTemplateManifest(state);
    const interpreter = new TemplateManifestInterpreter(templateManifest, projectManifest);

    // TODO: This method could use a more descriptive name (i.e. isn't templateManifest already JSON?)
    const convertedJSON = await interpreter.convertTemplateManifestToJSON();
    console.log('PARSED TEMPLATE MANIFEST', templateManifest);
    console.log('CONVERTED EDITING FORM', convertedJSON.editingForm);
    console.log('CONVERTED EDITING ACTIONS', convertedJSON.editingActions);
    console.log('CONVERTED PLACEHOLDER CONFIGURATION', convertedJSON.placeholderConfiguration);
    console.log('CONVERTED CONFIGURATION SCHEMA', convertedJSON.configurationSchema);

    const ajv = new Ajv({ allErrors: true });
    const editingActionsValidator = ajv.compile(configurationInterpreterSchema);
    const isEditingActionsValid = editingActionsValidator(JSON.parse(convertedJSON.editingActions));

    if (!isEditingActionsValid) {
      console.error('Invalid editing actions', editingActionsValidator.errors);
    }

    const editingFormValidator = ajv.compile(formDescriptionSchema);
    const isEditingFormValid = editingFormValidator(JSON.parse(convertedJSON.editingForm));

    // SS TODO:  When previewing a template, there will likely be validation errors on the
    // editing form since the schema is testing for a complete editing form file.
    // Log these errors so we can use them when we implement error handling, but still
    // allow the user to navigate to the preview.
    if (!isEditingFormValid) {
      console.error('Invalid editing form', editingFormValidator.errors);
    }

    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', `${WAYMARK_HOST}/template-studio/preview/`);
    // Not setting to _blank so that a new tab isn't created during *every* preview
    // but will open a new tab per-template
    form.setAttribute('target', `${templateName}EditorPreview`);

    const projectManifestField = document.createElement('input');
    projectManifestField.setAttribute('type', 'hidden');
    projectManifestField.setAttribute('name', 'project_manifest');
    projectManifestField.setAttribute('value', JSON.stringify(projectManifest));
    form.appendChild(projectManifestField);

    const templateManifestField = document.createElement('input');
    templateManifestField.setAttribute('type', 'hidden');
    templateManifestField.setAttribute('name', 'template_manifest');
    templateManifestField.setAttribute('value', JSON.stringify(templateManifest));
    form.appendChild(templateManifestField);

    const editingActionsField = document.createElement('input');
    editingActionsField.setAttribute('type', 'hidden');
    editingActionsField.setAttribute('name', 'editing_actions');
    editingActionsField.setAttribute('value', convertedJSON.editingActions);
    form.appendChild(editingActionsField);

    const editingFormField = document.createElement('input');
    editingFormField.setAttribute('type', 'hidden');
    editingFormField.setAttribute('name', 'editing_form');
    editingFormField.setAttribute('value', convertedJSON.editingForm);
    form.appendChild(editingFormField);

    const templateConfigurationField = document.createElement('input');
    templateConfigurationField.setAttribute('type', 'hidden');
    templateConfigurationField.setAttribute('name', 'template_configuration');
    templateConfigurationField.setAttribute('value', convertedJSON.placeholderConfiguration);
    form.appendChild(templateConfigurationField);

    const templateStudioAPIHost = document.createElement('input');
    templateStudioAPIHost.setAttribute('type', 'hidden');
    templateStudioAPIHost.setAttribute('name', 'template_studio_api_host');

    templateStudioAPIHost.setAttribute('value', API_SERVER);
    form.appendChild(templateStudioAPIHost);
    // TODO: Does this need to be appended to the body? We're going to keep on appending more and more forms to the DOM.

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  };

  /**
   * Attempts to replace the current version's project manifest with either (a) an existing
   * afterEffectsImportId, or a newly uploaded AE project archive.

   * @param  {Object}     payload
   * @param  {FormData}   payload.confirmationCode          Code that points to the location of an import bundle
   * @param  {number}     payload.afterEffectsImportId      ID of an exisitng after effects import
   * @param  {boolean}    [shouldForceApply]
   *      Whether to force apply the new import if there are layers in the templateManifest that aren't
   *      present in the new projectManifest.
   */
  const attemptApplyNewImport = async (payload, shouldForceApply = false) => {
    const uploadResponse = await dispatch(
      operations.applyNewProjectArchive(payload, shouldForceApply),
    );

    if (uploadResponse.isError) {
      setReimportError(uploadResponse);
      setIsAlertOpen(true);
    } else {
      setReimportError(null);
    }
  };

  /**
   * Reimport actions.
   */
  const onClickReimport = () => {
    setIsReimportDialogOpen(true);
  };

  const onReimportInteraction = (isOpen) => {
    setIsReimportDialogOpen(isOpen);
  };

  const onSubmitReimport = (confirmationCode) => {
    attemptApplyNewImport({ confirmationCode });
    setIsReimportDialogOpen(false);
  };

  /**
   * Remove the active user and send them back to the login screen.
   */
  const onClickLogout = () => {
    dispatch(operations.logoutUser());
  };

  /**
   * If we're showing this alert, we warned the user about a conflict with layers within the templateManifest
   * not being found in the new projectManifest. If they confirmed that alert, they wish
   * to apply the new project manifest anyway, and lose their existing templateManifest changes.
   * @param  {boolean}   didConfirmForceApply
   */
  const onCloseAlert = (didConfirmForceApply) => {
    if (didConfirmForceApply && hasLayerConflictError) {
      attemptApplyNewImport({ afterEffectsImportId: reimportError.afterEffectsImportId }, true);
    }

    setIsAlertOpen(false);
  };

  /**
   * Publishes the current state of the template to a s3 bucket with the given slug
   *
   * @param      {string}  videoTemplateSlug  The video template slug
   * @return     {Object}  A formatted response object in the form of {error: 'my error message', data: {}}
   */
  const publishVersion = async (videoTemplateSlug) => {
    const state = store.getState();

    const projectManifest = selectors.getActiveProjectManifest(state);
    const templateManifest = selectors.getTemplateManifest(state);
    const interpreter = new TemplateManifestInterpreter(templateManifest, projectManifest);

    const convertedJSON = await interpreter.convertTemplateManifestToJSON();

    const publishOptions = {
      editingForm: JSON.parse(convertedJSON.editingForm),
      editingActions: JSON.parse(convertedJSON.editingActions),
      placeholderConfiguration: JSON.parse(convertedJSON.placeholderConfiguration),
      configurationSchema: JSON.parse(convertedJSON.configurationSchema),
      videoTemplateSlug,
    };

    let responseData;
    let responseError;
    try {
      const response = await axios.post(
        `${API_SERVER}/api/video-templates/${templateId}/${versionNumber}/temporary-published-bundles/`,
        publishOptions,
      );
      responseData = response.data;
    } catch (e) {
      // If the response came back as JSON, we can extract the error message
      if (typeof e.response.data === 'object') {
        responseError = e.response.data.message;
        // Otherwise just dump the response as the error message
      } else {
        responseError = e.response.data;
      }
    }

    return {
      data: responseData,
      error: responseError,
    };
  };

  /**
   * Republishes the display name of colors to the bundle associated with a given slug
   *
   * @param      {string}  videoTemplateSlug  The video template slug
   * @return     {Object}  A formatted response object in the form of {error: 'my error message', data: {}}
   */
  const republishColors = async (videoTemplateSlug) => {
    const publishOptions = {
      videoTemplateSlug,
    };

    let responseData;
    let responseError;
    try {
      const response = await axios.post(
        `${API_SERVER}/api/video-templates/${templateId}/${versionNumber}/republish-colors/`,
        publishOptions,
      );
      responseData = response.data;
    } catch (e) {
      // If the response came back as JSON, we can extract the error message
      if (typeof e.response.data === 'object') {
        responseError = e.response.data.message;
        // Otherwise just dump the response as the error message
      } else {
        responseError = e.response.data;
      }
    }

    return {
      data: responseData,
      error: responseError,
    };
  };

  const onClickPublishVersion = () => {
    setIsPublishDialogOpen(true);
  };

  const onCancelPublish = () => {
    setIsPublishDialogOpen(false);
    setPublishDialogError(null);
  };

  const onSubmitPublish = async (slug) => {
    setIsPublishing(true);
    const { error } = await publishVersion(slug);
    setIsPublishing(false);
    if (error) {
      setPublishDialogError(error);
    } else {
      setPublishDialogError(null);
      setIsPublishDialogOpen(false);
    }
  };

  const onClickRepublishColors = () => {
    setIsRepublishColorsDialogOpen(true);
  };

  const onCancelRepublishColors = () => {
    setIsRepublishColorsDialogOpen(false);
    setRepublishColorsDialogError(null);
  };

  const onSubmitRepublishColors = async (slug) => {
    setIsRepublishingColors(true);
    const { error } = await republishColors(slug);
    setIsRepublishingColors(false);
    if (error) {
      setRepublishColorsDialogError(error);
    } else {
      setRepublishColorsDialogError(null);
      setIsRepublishColorsDialogOpen(false);
    }
  };

  // Default error messages, if something went wrong before / during the import.
  const extraAlertProps = {};
  let confirmButtonText = 'Close';

  // If we got a valid response that included the import ID, we have a conflict between the templateManifest
  // and the projectManifest. Let's tell the user.
  if (hasLayerConflictError) {
    confirmButtonText = 'Override';
    extraAlertProps.cancelButtonText = 'Cancel';
  }

  return (
    <Flex className={navBarStyles} justify="space-between" align="center" py={1} px={2}>
      {/* Left buttons */}
      <Box>
        {match.path === routePaths.templateWorkspace && (
          <>
            <RoutedBackButton />
          </>
        )}
      </Box>
      <Box>
        {template && versionNumber && (
          <>
            <EditableText
              onChange={onChangeTemplateName}
              onConfirm={onConfirmTemplateName}
              value={localTemplateName}
            />
            &nbsp;&mdash;&nbsp;
            <TemplateTag wrapperClass={templateTagStyles} template={template} />
          </>
        )}
      </Box>
      {/* Right buttons */}
      <Box>
        {match.path === routePaths.templateWorkspace && (
          <>
            <Popover isOpen={isReimportDialogOpen} onInteraction={onReimportInteraction}>
              <WTSButton
                className={navButtonStyles}
                onClick={onClickReimport}
                loading={isReimportInProgress}
              >
                Reimport
              </WTSButton>
              <ReimportDialogContents onSubmit={onSubmitReimport} />
            </Popover>
            <WTSButton className={navButtonStyles} onClick={onClickEditorPreview}>
              Editor Preview
            </WTSButton>
            <WTSButton
              className={navButtonStyles}
              disabled={isRepublishColorsDialogOpen}
              onClick={onClickRepublishColors}
            >
              Republish Colors
            </WTSButton>
            <WTSButton
              className={navButtonStyles}
              disabled={isPublishDialogOpen}
              wtsIntent="primary"
              onClick={onClickPublishVersion}
            >
              Publish Version
            </WTSButton>
          </>
        )}
        {match.path === routePaths.templateSelection && (
          <WTSButton className={navButtonStyles} onClick={onClickLogout}>
            Logout
          </WTSButton>
        )}
      </Box>
      <Alert
        isOpen={isAlertOpen}
        confirmButtonText={confirmButtonText}
        onClose={onCloseAlert}
        intent={hasLayerConflictError ? Intent.DANGER : Intent.DEFAULT}
        {...extraAlertProps}
      >
        <AlertContents reimportError={reimportError} />
      </Alert>
      <Dialog isOpen={isPublishDialogOpen} onClose={onCancelPublish} intent={Intent.DEFAULT}>
        <PublishDialogContents
          isPublishing={isPublishing}
          publishDialogError={publishDialogError}
          onCancelPublish={onCancelPublish}
          onSubmitPublish={onSubmitPublish}
        />
      </Dialog>
      <Dialog
        isOpen={isRepublishColorsDialogOpen}
        onClose={onCancelRepublishColors}
        intent={Intent.DEFAULT}
      >
        <PublishDialogContents
          isPublishing={isRepublishingColors}
          publishDialogError={republishColorsDialogError}
          onCancelPublish={onCancelRepublishColors}
          onSubmitPublish={onSubmitRepublishColors}
        />
      </Dialog>
    </Flex>
  );
};

TopMenu.propTypes = {
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.object,
    path: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

export default TopMenu;
