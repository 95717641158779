// Schema structure defaults for keys with a more complicated set of possible values.
const schemaDefaultsByKey = {
  modifications: {
    oneOf: [
      { type: 'null' },
      { type: 'object' },
    ],
  },
};

/**
 * Get a JSON schema typeof for a JSON property value.
 *
 * Supported output types: string, number, boolean, null, object, array
 * Unsupported output types: integer ("number" will be returned for integers)
 *
 * @param  {any}     value  JSON property value
 * @return {string}         JSON schema typeof
 */
const jsonSchemaTypeof = (value) => {
  if ([null, undefined].includes(value)) {
    // typeof null -> 'object'
    // typeof undefined -> 'undefined'
    return 'null';
  }

  if (Array.isArray(value)) {
    // typeof [] -> 'object'
    return 'array';
  }

  return typeof value;
};

/**
 * Create a configuration schema object.
 * @param  {string}        propertyKey         Property key
 * @param  {string|object} propertyValue       Corresponding value
 * @param  {object}        configurationSchema Schema to add object to.
 * @param  {string}        parentPropertyPath  If dealing with a nested property,
 *                                             the parent property's path used to
 *                                             construct the current property path.
 */
// eslint-disable-next-line import/prefer-default-export
export const createSchemaObject = (
  propertyKey,
  propertyValue,
  configurationSchema,
  parentPropertyPath = null,
) => {
  const basePropertyPath = parentPropertyPath ? `${parentPropertyPath}/properties` : '#/properties';

  const objectID = `${basePropertyPath}/${propertyKey}`;
  const partialSchemaValue =
    ([null, undefined].includes(propertyValue) && propertyKey in schemaDefaultsByKey)
      ? schemaDefaultsByKey[propertyKey]
      : { type: jsonSchemaTypeof(propertyValue) };

  // eslint-disable-next-line no-param-reassign
  configurationSchema[propertyKey] = {
    $id: objectID,
    ...partialSchemaValue,
  };

  if (partialSchemaValue.type === 'object') {
    const nestedObjects = Object.entries(propertyValue);
    const properties = configurationSchema[propertyKey].properties || {};

    nestedObjects.forEach(([nestedPropertyKey, nestedPropertyValue]) => {
      createSchemaObject(nestedPropertyKey, nestedPropertyValue, properties, objectID);
      // eslint-disable-next-line no-param-reassign
      configurationSchema[propertyKey].properties = properties;
    });
  }

  return configurationSchema;
};
