/* eslint-disable radix */

const colorToHex = (color) => {
  const hexColor = parseInt(Math.round(color * 255)).toString(16);
  return hexColor.length === 1 ? `0${hexColor}` : hexColor;
};

/**
 * Converts a color array to a hex code.
 * @param  {array} 	colorArray 	Array of color values.
 * @return {string}            	Hex string.
 */
export const colorArrayToHex = (colorArray) => {
  const red = colorToHex(colorArray[0]);
  const green = colorToHex(colorArray[1]);
  const blue = colorToHex(colorArray[2]);

  // Convert to a base16 string
  return `#${red}${green}${blue}`;
};

/**
 * Checks if a string is a valid hexcode.
 * @param  {string} 	colorString 	String to validate.
 * @return {boolean}            		String validation result.
 */
export const isValidHex = (colorString) => /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(colorString);
