import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';

export const VideoPlaybackContextMenuContext = createContext();

/**
 * React Context Provider to share visible layer state between the
 * LayerList and LayerListFilter components.
 */
const VideoPlaybackContextMenuProvider = (props) => {
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

  return (
    <VideoPlaybackContextMenuContext.Provider value={[isContextMenuOpen, setIsContextMenuOpen]}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div onClick={() => setIsContextMenuOpen(false)}>
        {props.children}
      </div>
    </VideoPlaybackContextMenuContext.Provider>
  );
};

VideoPlaybackContextMenuProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired,
};

export default VideoPlaybackContextMenuProvider;
