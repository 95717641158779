/* eslint-disable no-param-reassign */

// Vendor
import { Extract, Rectangle, RenderTexture } from 'pixi.js';

// Local
import { textureQuality } from '../../settings.js';

export {
  addAsset,
  loadQueuedAssets,
  loadAllAssets,
  loadAsset,
  loadAssetForLayer,
  loadUrl,
} from './assets.js';

export { getObjectsUnderInteraction } from './interaction.js';

export {
  applyBlendModeToContainer,
  applyProperty,
  applyVisibilityBoundsToObject,
  applyAlphaToObject,
  applyTransformsToObject,
  applyBlendMode,
  applyDisplayObjectProperties,
  filterTweensOfProperties,
  applyLayerAudioProperties,
  applyTimeRemapProperties,
} from './properties.js';

export * from './mediaModifications.js';

/**
 * Set a layer as dirty.
 * This flags a layer as changed, so PixiJS knows to update the canvas on the next render operation.
 * This should always be called when a layer is edited directly.
 *
 * @param {object}  layer  PixiJS Layer
 */
export function setDirty(layer) {
  /* eslint-disable no-param-reassign */
  if ('dirty' in layer) {
    layer.dirty = typeof layer.dirty === 'boolean' ? true : layer.dirty + 1;
  }

  if ('clearDirty' in layer) {
    layer.clearDirty = typeof layer.clearDirty === 'boolean' ? true : layer.clearDirty + 1;
  }
  /* eslint-enable no-param-reassign */
}

/**
 * Extract pixels from a PixiJS display object.
 * This is PIXI.renderer.extract.pixels copied verbatim, with
 * slight adjustments to support custom x, y, width, and height attributes.
 * The PixiJS method reads the entire display object by default, which is
 * nonperformant.
 *
 * @param  {object}  renderer PixiJS renderer
 * @param  {number}  x        Read x position
 * @param  {number}  y        Read y position
 * @param  {number}  width    Read width
 * @param  {number}  height   Read height
 * @param  {object}  target   PixiJS display object
 * @returns {Uint8[]}          Uint8Array of pixel data
 */
export function extractPixels(renderer, x, y, width, height, target) {
  if (!renderer) {
    return null;
  }

  /* eslint-disable prefer-destructuring */
  const TEMP_RECT = new Rectangle();
  const BYTES_PER_PIXEL = 4;

  let resolution;
  let frame;
  let renderTexture;
  let generated = false;

  if (target) {
    if (target instanceof RenderTexture) {
      renderTexture = target;
    } else {
      renderTexture = renderer.generateTexture(target);
      generated = true;
    }
  }

  if (renderTexture) {
    resolution = renderTexture.baseTexture.resolution;
    frame = renderTexture.frame;

    // bind the buffer
    renderer.renderTexture.bind(renderTexture);
  } else {
    resolution = renderer.resolution;

    frame = TEMP_RECT;
    frame.width = renderer.width;
    frame.height = renderer.height;

    renderer.renderTexture.bind(null);
  }

  // read pixels to the array
  const { gl } = renderer;

  // Begin Waymark modifications
  const normalizedX = (x + frame.x) * resolution;
  const normalizedY = (y + frame.y) * resolution;
  const normalizedWidth = width * resolution;
  const normalizedHeight = height * resolution;

  const webglPixels = new Uint8Array(BYTES_PER_PIXEL * normalizedWidth * normalizedHeight);

  gl.readPixels(
    normalizedX,
    normalizedY,
    normalizedWidth,
    normalizedHeight,
    gl.RGBA,
    gl.UNSIGNED_BYTE,
    webglPixels,
  );
  // End Waymark modifications

  if (generated) {
    renderTexture.destroy(true);
  }

  Extract.arrayPostDivide(webglPixels, webglPixels);

  /* eslint-enable prefer-destructuring */
  return webglPixels;
}

export const TEXTURE_QUALITY_TO_SIZE = {
  [textureQuality.high]: Infinity,
  [textureQuality.medium]: 1920,
  [textureQuality.low]: 1080,
};
