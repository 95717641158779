// Local
import {
  croppingToImgixRect,
  cssColorArrayToImgixColorString,
  cssColorToImgixColor,
  floatToImgixNumber,
} from './imgixAPITransforms.js';

export const assetTypes = {
  audio: 'audio',
  bitmapFont: 'bitmapFont',
  composition: 'composition',
  image: 'image',
  scaledImage: 'scaledImage',
  video: 'video',
};

export const assetPrefixes = {
  [assetTypes.audio]: 'audio_',
  [assetTypes.composition]: 'comp_',
  [assetTypes.image]: 'image_',
  [assetTypes.scaledImage]: 'image_',
  [assetTypes.video]: 'video_',
};

export const assetFileExtensions = {
  [assetTypes.audio]: ['.mp3', '.m4a', '.wav', '.aac', '.aif', '.aiff'],
  [assetTypes.video]: ['.mp4', '.mov', '.webm'],
  // Images have no listed file extensions because image URLs can be extensionless
};

export const layerTypes = {
  preComp: 0,
  solid: 1,
  image: 2,
  null: 3,
  shape: 4,
  text: 5,
  // AE audio layer type. We don't support this, we require audio
  // to be our custom waymarkAudio type (101)
  audio: 6,
  // AE video layer type. We don't support this, we require video
  // to be our custom waymarkVideo type (100)
  video: 9,
  // Custom Waymark video layer type. Audio with this layer type
  // should occur only once on the main composition and nowhere
  // else. The main animation timeline will be synced to this
  // layer when it is created.
  waymarkVideo: 100,
  // Custom Waymark audio layer type. Audio with this layer type
  // should occur only once on the main composition and nowhere
  // else. This layer can be generated by the user's background
  // audio settings in the author extension.
  waymarkAudio: 101,
};

/**
 * These video codecs are exported by the template studio with the mimetypes and filenames listed
 * further below.
 */
export const supportedVideoCodecs = {
  mediumH264: 'mediumH264',
  highH264: 'highH264',
  mediumH265: 'mediumH265',
  highH265: 'highH265',
  mediumVP9: 'mediumVP9',
  highVP9: 'highVP9',
};

/**
 * Mimetypes for each supported video codec. These mimetypes were read from Cloudinary exports
 * via the asset-preparation get_mimetype_for_filepath script.
 */
export const supportedVideoCodecMimetypes = {
  // Cloudinary instructions: { audio_codec: 'aac', format: 'mp4', video_codec: 'h264' }
  [supportedVideoCodecs.mediumH264]: 'video/mp4; codecs="avc1.42C028, mp4a.40.2"',
  [supportedVideoCodecs.highH264]: 'video/mp4; codecs="avc1.42C020, mp4a.40.2"',

  // Cloudinary instructions: { audio_codec: 'aac', format: 'mp4', video_codec: 'h265' }
  [supportedVideoCodecs.mediumH265]: 'video/mp4; codecs="hvc1.1.6.L120.90, mp4a.40.2"',
  [supportedVideoCodecs.highH265]: 'video/mp4; codecs="hvc1.1.6.L93.90, mp4a.40.2"',

  // Cloudinary instructions: { audio_codec: 'vorbis', format: 'webm', video_codec: 'vp9' }
  [supportedVideoCodecs.mediumVP9]: 'video/webm; codecs="vp9, vorbis"',
  [supportedVideoCodecs.highVP9]: 'video/webm; codecs="vp9, vorbis"',
};

/**
 * Filenames for each supported video codec.
 * Format: {quality}.{type}.{extension}
 */
export const supportedVideoCodecFilenames = {
  [supportedVideoCodecs.mediumH264]: 'medium.h264.mp4',
  [supportedVideoCodecs.highH264]: 'high.h264.mp4',
  [supportedVideoCodecs.mediumH265]: 'medium.h265.mp4',
  [supportedVideoCodecs.highH265]: 'high.h265.mp4',
  [supportedVideoCodecs.mediumVP9]: 'medium.vp9.webm',
  [supportedVideoCodecs.highVP9]: 'high.vp9.webm',
};

export const preferredVideoCodecsByQuality = {
  high: [
    supportedVideoCodecs.highVP9,
    supportedVideoCodecs.highH265,
    supportedVideoCodecs.highH264,
  ],
  medium: [
    supportedVideoCodecs.mediumVP9,
    supportedVideoCodecs.mediumH265,
    supportedVideoCodecs.mediumH264,
  ],
  low: [
    supportedVideoCodecs.mediumVP9,
    supportedVideoCodecs.mediumH265,
    supportedVideoCodecs.mediumH264,
  ],
};

/**
 * Imgix param definitions.
 * Each member includes a modifications object dotpath for the source value, an Imgix param for the
 * destination, and an optional transform (null for none).
 *
 * Example usage:
 *   const modifications = {
 *     cropping: { x: 0, y: 0, width: 500, height: 500, },
 *     ...
 *   };
 *
 *   const paramName = 'cropping';
 *
 *   const imgixQueryParams = {};
 *
 *   const { dotPath, param, transform } = imgixParamDefinitions[paramName];
 *   const value = _.get(modifications, dotPath);
 *   imgixQueryParams[param] = transform ? transform(value) : value;
 */
export const imgixParamDefinitions = {
  // Root modifications definitions
  backgroundFill: {
    dotPath: 'backgroundFill',
    param: 'bg',
    transform: cssColorToImgixColor,
  },
  cropping: {
    dotPath: 'cropping',
    param: 'rect',
    transform: croppingToImgixRect,
  },
  fillColor: {
    dotPath: 'fillColor',
    param: 'fill-color',
    transform: cssColorToImgixColor,
  },
  fit: {
    dotPath: 'fit',
    param: 'fit',
    transform: null,
  },
  padding: {
    dotPath: 'padding',
    param: 'pad',
    transform: null,
  },

  // Zoom modifications definitions
  zoomX: {
    dotPath: 'zoom.x',
    param: 'fp-x',
    transform: null,
  },
  zoomY: {
    dotPath: 'zoom.y',
    param: 'fp-y',
    transform: null,
  },
  zoomZ: {
    dotPath: 'zoom.z',
    param: 'fp-z',
    transform: null,
  },

  // Adjustments modifications definitions
  noiseReduction: {
    dotPath: 'adjustments.noiseReduction',
    param: 'nr',
    transform: floatToImgixNumber,
  },
  noiseReductionSharpen: {
    dotPath: 'adjustments.noiseReductionSharpen',
    param: 'nrs',
    transform: floatToImgixNumber,
  },
  blur: {
    dotPath: 'adjustments.blur',
    param: 'blur',
    transform: (value) => value * 2,
  },
  brightness: {
    dotPath: 'adjustments.brightness',
    param: 'bri',
    transform: floatToImgixNumber,
  },
  contrast: {
    dotPath: 'adjustments.contrast',
    param: 'con',
    transform: floatToImgixNumber,
  },
  duotone: {
    dotPath: 'adjustments.duotone',
    param: 'duotone',
    transform: cssColorArrayToImgixColorString,
  },
  duotoneAlpha: {
    dotPath: 'adjustments.duotoneAlpha',
    param: 'duotone-alpha',
    transform: floatToImgixNumber,
  },
  exposure: {
    dotPath: 'adjustments.exposure',
    param: 'exp',
    transform: floatToImgixNumber,
  },
  highlight: {
    dotPath: 'adjustments.highlight',
    param: 'high',
    transform: floatToImgixNumber,
  },
  monochrome: {
    dotPath: 'adjustments.monochrome',
    param: 'monochrome',
    transform: cssColorToImgixColor,
  },
  saturation: {
    dotPath: 'adjustments.saturation',
    param: 'sat',
    transform: floatToImgixNumber,
  },
  shadow: {
    dotPath: 'adjustments.shadow',
    param: 'shad',
    transform: floatToImgixNumber,
  },
  sharpen: {
    dotPath: 'adjustments.sharpen',
    param: 'sharp',
    transform: floatToImgixNumber,
  },
  unsharpMask: {
    dotPath: 'adjustments.unsharpMask',
    param: 'usm',
    transform: floatToImgixNumber,
  },
  vibrance: {
    dotPath: 'adjustments.vibrance',
    param: 'vib',
    transform: floatToImgixNumber,
  },
};

// URLs are assumed to be used within the Waymark Template Studio.
export const apiResourceByAssetType = {
  image: '/api/video-template-images/',
  audio: '/api/video-template-audio/',
  video: '/api/video-template-videos/',
};

export const waymarkLocationTypes = {
  socialproofImagesWeb: 'socialproofImagesWeb',
  socialproofCustomerAssets: 'socialproofCustomerAssets',
  socialproofS3Assets: 'socialproofS3Assets',
  waymarkMattkahlScratch: 'waymarkMattkahlScratch',
  waymarkTemplateStudio: 'waymarkTemplateStudio',
};

// Different alignment types for a texture layer
export const fitFillAlignments = {
  topLeft: 'TL',
  topCenter: 'TC',
  topRight: 'TR',
  centerLeft: 'CL',
  centerCenter: 'CC',
  centerRight: 'CR',
  bottomLeft: 'BL',
  bottomCenter: 'BC',
  bottomRight: 'BR',
};
