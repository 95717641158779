/* eslint-env browser */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '@blueprintjs/core';
import { Flex, Box } from 'reflexbox';
import cxs from 'cxs';

import { actions, operations, selectors } from '../store/index.js';
import { locationPropType } from '../constants/index.js';
import { darkBackground, panelBackground } from './lib/colors.js';
import VideoPlayback from './VideoPlayback.js';
import NavigationSidebar from './NavigationSidebar.js';
import DynamicLayerPanel from './DynamicLayerPanel.js';
import VideoPlaybackContextMenuProvider from './contextProviders/VideoPlaybackContextMenuProvider.js';
import { SharedRendererProvider } from './useSharedRenderer.js';

const TOP_MENU_HEIGHT = 50;

/**
 * Hook for listening to window resizing and returning the modified window.innerHeight.
 */
const useWindowHeight = () => {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return height;
};

/**
 * Top-level component for the /templates/:slug route.
 */
const TemplateWorkspace = ({ match }) => {
  // SS TODO: We only have 'nonEditable' layers as of right now, but when we
  // start adding support for dynamic properties we'll have to grab the
  // editable and nonEditable layers.
  const { templateId } = match.params;
  const latestVersionNumber = useSelector((state) =>
    selectors.getLatestVersionByTemplateId(state, templateId),
  );

  const dispatch = useDispatch();
  const isLoading = useSelector(selectors.getIsFetchingVersion);
  const innerHeight = useWindowHeight();

  /* Whenever we're starting fresh with a new template workspace,
  let's make sure we fetch the template and clear any stale `selectedLayers`. */
  useEffect(() => {
    dispatch(operations.fetchTemplateById(templateId));
    return () => {
      dispatch(actions.leavingWorkspace());
    };
  }, [templateId]);

  // Make sure our workspace is in a good state anytime our template or version number changes.
  useEffect(() => {
    // If we don't have both the template ID and the version number, we're not yet ready to set up yet.
    if (!(templateId && latestVersionNumber)) return;

    dispatch(operations.setUpWorkspace({ templateId, versionNumber: latestVersionNumber }));
  }, [templateId, latestVersionNumber]);

  const panelHeight = innerHeight - TOP_MENU_HEIGHT;
  const panelStyles = cxs({
    backgroundColor: panelBackground,
    border: `solid 1px ${darkBackground}`,
    height: `${panelHeight}px`,
    maxWidth: '276px',
    position: 'relative',
  });

  return (
    <VideoPlaybackContextMenuProvider>
      <SharedRendererProvider>
        <Flex justify="space-between" align="top">
          {/* Left panel */}
          <Box w={[1 / 3, 1 / 3, 1 / 4, 1 / 4]} className={panelStyles}>
            <NavigationSidebar />
          </Box>
          {/* Center content */}
          <Box w={[1 / 3, 1 / 3, 1 / 2, 1 / 2]} justify="center">
            <VideoPlayback />
          </Box>
          {/* Right panel */}
          <Box w={[1 / 3, 1 / 3, 1 / 4, 1 / 4]} className={panelStyles}>
            {isLoading ? <Spinner /> : <DynamicLayerPanel />}
          </Box>
        </Flex>
      </SharedRendererProvider>
    </VideoPlaybackContextMenuProvider>
  );
};

TemplateWorkspace.propTypes = {
  match: PropTypes.shape({ location: locationPropType, params: PropTypes.object }).isRequired,
};

export default TemplateWorkspace;
