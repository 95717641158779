import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { createContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isValidUUID } from '../../utils/uuid.js';
import { getConfigurationValuesFromExtendedAttributes } from '../../utils/templateManifestAttributes.js';
import { templateManifestOverrideKeys } from '../../constants/index.js';
import store, { actions, operations, selectors } from '../../store/index.js';

export const DynamicPropertyUpdateDispatch = createContext();
export const DynamicPropertyUpdateState = createContext();

/**
 * React context provider to share dynamic property update logic.
 */
const DynamicPropertyUpdateProvider = (props) => {
  const sharedContentOverride = useSelector((state) =>
    selectors.getSharedOverrideUUID(state, templateManifestOverrideKeys.content),
  );

  let isContentOverrideSelected = Boolean(sharedContentOverride);
  const selectedLayers = useSelector(selectors.getSelectedLayers);
  const dispatch = useDispatch();

  /**
   * Given a layerUUID and updateObject, determine if the the update action should result in
   * adding the layer to the template manifest, deleting the layer from the template manifest,
   * or updating the layer.
   * @param  {string} layerUUID    UUID of the layer to create, delete, or update.
   * @param  {object} updateObject Update key and value.
   * @return {boolean}             If the layer should be updated, i.e., the layer did not
   *                               need to be created or deleted.
   */
  const createDeleteOrShouldUpdate = (layerUUID, updateObject) => {
    const updateDotPath = Object.keys(updateObject)[0];
    const updateValue = Object.values(updateObject)[0];
    const currentEditableFields = selectors.getTemplateManifestData(store.getState(), layerUUID);

    // A null or non-null value was selected but the layer has
    // other editable fields. This value is updated and returned
    // as determinations are made.
    let shouldUpdate = true;

    // A non-null value was selected and the layer has no previous
    // editable fields
    const shouldCreate = _.isEmpty(currentEditableFields) && updateValue;

    // Flatten gradient fill values.
    const configurationValues = getConfigurationValuesFromExtendedAttributes(currentEditableFields);

    // A null value was selected and the layer has no other editable
    // fields except the one targeted here.
    const editableProperties = Object.keys(
      _.pickBy(configurationValues, _.identity)
    );

    const shouldDelete =
      editableProperties.length === 1 && editableProperties[0] === updateDotPath && !updateValue;

    if (shouldCreate) {
      // Grab the layer type so the object is instantiated with
      // the correct keys and add the layer to the template manifest.
      const layerType = selectors.getLayerByUUID(store.getState(), layerUUID).type;
      dispatch(
        operations.updateTemplateManifest(actions.createLayersExtendedAttributes, {
          layerUUID,
          layerType,
          updateValue: updateObject,
        }),
      );

      shouldUpdate = false;
      return shouldUpdate;
    } else if (shouldDelete) {
      // Delete the layer from the template manifest.
      dispatch(operations.updateTemplateManifest(actions.deleteLayersExtendedAttributes, layerUUID));

      shouldUpdate = false;
      return shouldUpdate;
    }

    // If the layer did not need to be added to or deleted from
    // the template manifest, return shouldUpdate so the layer
    // will be updated later on.
    return shouldUpdate;
  };

  /**
   * Update a layer(s) in the template manifest with new or updated editable fields.
   * @param  {object} updateObject         Update dotpath and value.
   * @param  {array}  unselectedLayerUUIDs UUIDs of layers to update. Optional, if not provided,
   *                                       will default to Redux selectedLayers.
   */
  const onUpdateDynamicProperties = async (updateObject, unselectedLayerUUIDs = null) => {
    // SS TODO: Right now we're pretty confident that only one update key/value pair is being
    // sent, but in the future we should add some validation here to ensure our payload
    // is formatted the correct way.
    const updateDotPath = Object.keys(updateObject)[0];
    const updateValue = Object.values(updateObject)[0];

    // If content is being updated with an override, update the state so that listenting
    // content components can update their UIs.
    if (
      updateValue &&
      (updateDotPath === templateManifestOverrideKeys.content && isValidUUID(updateValue))
    ) {
      isContentOverrideSelected = true;
    } else {
      isContentOverrideSelected = false;
    }

    // If specific layers were passed to this function, e.g., if an
    // override is removed from a layer via the override panel,
    // update those layers instead of the selected layers (which
    // should be an empty array in that scenario.)
    let layersToUpdate = unselectedLayerUUIDs || selectedLayers;
    layersToUpdate = _.reduce(
      layersToUpdate,
      (accum, layerUUID) => {
        const shouldUpdate = createDeleteOrShouldUpdate(layerUUID, updateObject);
        if (shouldUpdate) accum.push(layerUUID);

        return accum;
      },
      [],
    );

    if (layersToUpdate.length) {
      dispatch(
        operations.updateTemplateManifest(actions.updateDynamicProperties, {
          layerUUIDS: layersToUpdate,
          updateValue: updateObject,
        }),
      );
    }
  };

  return (
    <DynamicPropertyUpdateDispatch.Provider value={onUpdateDynamicProperties}>
      <DynamicPropertyUpdateState.Provider value={isContentOverrideSelected}>
        {props.children}
      </DynamicPropertyUpdateState.Provider>
    </DynamicPropertyUpdateDispatch.Provider>
  );
};

DynamicPropertyUpdateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DynamicPropertyUpdateProvider;
