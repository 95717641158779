/* eslint-env browser */
import PropTypes from 'prop-types';
import React from 'react';
import cxs from 'cxs';

import { primaryBackground, successBackground } from './lib/colors.js';
import { templatePropType } from '../constants/index.js';
import { WTSTag } from './lib/index.js';

const versionNumberStyle = cxs({
  margin: '0 6px',
});

/**
 * Tag for a template -- either `DRAFT` or `PUBLISHED`
 * @param  {WTSTemplate}  template                    Template object
 * @param  {string}       template.publishedVersion   Published version number
 * @param  {string}       template.unpublishedVersion Unpublished version number
 */
const TemplateTag = ({ template, wrapperClass }) => {
  // Nothing to do if we don't have a template.
  if (!template) return null;

  const { publishedVersion, unpublishedVersion } = template;

  // This template is a draft if there's an unpublished version, or if there's no version
  // (shouldn't happen, but we should still handle it.)
  const isDraft = !!unpublishedVersion || (!unpublishedVersion && !publishedVersion);
  const latestVersion = unpublishedVersion || publishedVersion;
  const latestVersionColor = isDraft ? primaryBackground : successBackground;
  const tagText = isDraft ? 'Draft' : 'Published';
  const wtsIntent = isDraft ? 'primary' : 'success';

  return (
    <div className={wrapperClass}>
      <WTSTag wtsIntent={wtsIntent}>{tagText}</WTSTag>
      <span className={versionNumberStyle} style={{ color: latestVersionColor }}>
        {latestVersion}
      </span>
    </div>
  );
};

TemplateTag.propTypes = {
  template: templatePropType,
  wrapperClass: PropTypes.string,
};

TemplateTag.defaultProps = {
  template: null,
  wrapperClass: '',
};

export default TemplateTag;
