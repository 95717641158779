import _ from 'lodash';
import { useDispatch } from 'react-redux';

import store, { actions, selectors } from '../store/index.js';

/**
 * Hook for handling layer selection events in a composition panel
 *
 * @param      {String[]}  layerUUIDs    The uuids of the layers in a composition panel
 * @return     {Object}   return
 * @return     {Function} return.selectLayer    A callback function for when a layer is selected/deselected
 */
const useSelectLayers = (layerUUIDs) => {
  const dispatch = useDispatch()

  /**
   * Called on select layer.
   * If the user is holding down command the selection will select multiple layers
   *
   * @param      {Object}   layer           The layer
   * @param      {boolean}  isUsingMetaKey  Indicates if using meta key
   * @param      {boolean}  isUsingShiftKey  Indicates if using shift key
   */
  const selectLayer = (layer, isUsingMetaKey, isUsingShiftKey) => {
    const selectedLayerUUIDs = selectors.getSelectedLayers(store.getState());

    const isSelected = selectedLayerUUIDs.includes(layer.uuid)

    // If we're shift clicking, we'll add all layers from the start of the selection to the layer we clicked
    if (isUsingShiftKey && !isUsingMetaKey && selectedLayerUUIDs.length) {
      // Get the array of uuids in between the clicks
      const startIndex = layerUUIDs.indexOf(selectedLayerUUIDs[0])
      const endIndex = layerUUIDs.indexOf(layer.uuid)
      const selectedLayers = layerUUIDs.slice(Math.min(startIndex, endIndex), Math.max(startIndex, endIndex) + 1)

      // Deselect layers outside of the array
      dispatch(actions.deselectLayers({ layersToDeselect: _.without(selectedLayerUUIDs, ...selectedLayers) }));
      // Select new layers
      dispatch(actions.selectLayers({ selectedLayers }));
    } else {
      if (!isUsingMetaKey) {
        // If we're not using the command key, but are clicking a selected layer, deselect everything else
        if (isSelected) {
          dispatch(
            actions.deselectLayers({
              layersToDeselect: _.without(selectedLayerUUIDs, layer.uuid),
            }),
          );
        // If it's not selected, deselect everything else
        } else {
          dispatch(actions.deselectAllLayers())
        }
      }
      // If we're selecting a selected layer OR If we're using the command key and we're selecting the only selected layer, deselect it
      if (isSelected && (selectedLayerUUIDs.length === 1 || isUsingMetaKey)) {
        dispatch(actions.deselectLayers({ layersToDeselect: [layer.uuid] }));
      // Otherwise select the layer
      } else {
        dispatch(actions.selectLayers({ selectedLayers: [layer.uuid] }));
      }
    }
  };

  return selectLayer
}

export default useSelectLayers