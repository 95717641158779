// Vendor
import _ from 'lodash';

// Local
import settings from '../settings.js';
import {
  registeredAssetParsingPlugins,
  bitmapFontAssetParsingPlugin,
} from './assetParsingPlugins.js';
import { simplePathJoin } from '../utils/index.js';
import { assetTypes, layerTypes, assetPrefixes, assetFileExtensions } from './constants.js';
import { filterLayerData } from './layerData.js';

/**
 * @namespace AssetDataParsing
 * @public
 */

/**
 * Get the asset type for asset data.
 *
 * @param  {object} assetData Asset data from the project manifest
 * @returns {string}           Asset type
 * @memberof AssetDataParsing
 * @public
 */
export function getAssetDataType(assetData) {
  // Check the assetData.type attribute.
  // This attribute is not guaranteed to exist. It isn't exported by bodymovin, it's added by the
  // template studio or by a manual manifest edit.
  //
  // Example:
  //   {
  //     type: 'image',
  //     ...
  //   }
  if (typeof assetData.type !== 'undefined') {
    if (assetData.type in assetTypes) {
      return assetData.type;
    }

    throw new Error(`assetData has unknown type ${assetData.type}: ${JSON.stringify(assetData)}`);
  }

  // Warn against typeless assetData for everything except for known-to-be compositions.
  // Ideally we change bodymovin so there are "type" properties on all of these assets.
  // We're excluding compositions to reduce noise (esp. in resource-constrained environments like download rendering).
  if (
    settings.IS_ASSET_DATA_WARNINGS_ENABLED &&
    assetData.id &&
    !assetData.id.startsWith(assetPrefixes[assetTypes.composition])
  ) {
    console.warn(
      `assetData has no 'type' attribute. Typeless assetData is deprecated and should only occur for manifests that have not been processed by the template studio: ${JSON.stringify(
        assetData,
      )}`,
    );
  }

  // Check the ID for a prefix. These image_, comp_, etc prefixes are generated by bodymovin.
  // Asset data generated elsewhere, such as by the template studio, is not guaranteed to have one
  // of these prefixes.
  //
  // Example:
  //   {
  //     id: 'image_0',
  //     ...
  //   }
  for (let i = 0; i < Object.keys(assetTypes).length; i += 1) {
    const assetType = Object.values(assetTypes)[i];
    if (
      assetType in assetPrefixes &&
      assetData.id &&
      assetData.id.startsWith(assetPrefixes[assetType])
    ) {
      return assetType;
    }
  }

  // Template studio asset data has a `type` and bodymovin asset data has a image_, comp_, etc
  // prefix so there are no known cases where the asset data won't be identified at this point,
  // outside of manual asset data modifications.
  // However, valid asset data technically isn't required to have a type attribute or an ID prefix,
  // so other cases still need to be covered.

  // Check for assetData.layers. Only composition asset data has this attribute.
  //
  // Example:
  //   {
  //     layers: [],
  //     ...
  //   }
  if (typeof assetData.layers !== 'undefined') {
    return assetTypes.composition;
  }

  // Check for u+p path attributes. All non-composition asset data that hasn't been identified so
  // far should have these attributes.
  //
  // Example:
  //   {
  //     u: 'images/',
  //     p: 'img_0.png',
  //     ...
  //   }
  if (typeof assetData.u !== 'undefined' && typeof assetData.p !== 'undefined') {
    const path = simplePathJoin(assetData.u, assetData.p);
    const foundAssetType = Object.keys(assetFileExtensions).find((assetType) =>
      assetFileExtensions[assetType].find((extension) => path.indexOf(extension) !== -1),
    );

    // Image URLs are not required to contain an image file extension,
    // to support URLs from ivory business searches.
    return foundAssetType || assetTypes.image;
  }

  // It is impossible to identify asset data that is missing type, layers, ID prefix, and u+p
  // attributes.
  throw new Error(
    `assetData is missing required type, layers, ID prefix, or u+p attributes to identify type: ${JSON.stringify(
      assetData,
    )}`,
  );
}

/**
 * Helper method to get a url for an asset, a shortcut to not have to match the registered plugin every time
 *
 * @param   {object}    asset           Asset from project manifest
 * @returns  {Promise}                   Promise that resolves to the asset url
 * @memberof AssetDataParsing
 * @public
 */
export async function getAssetUrl(asset) {
  const matchedAssetParsingPlugin =
    registeredAssetParsingPlugins[_.get(asset, 'location.plugin', 'default')];
  return matchedAssetParsingPlugin.assetToURL(asset, settings.ASSET_QUALITY);
}

/**
 * Helper method to find an asset by layer data
 *
 * @param {object[]} assets Array of assets from the project manifest
 * @param {object} layerData The data about the layer from the project manifest
 * @param {object} layerModifications An object of modifications from a layer that can change the URL requested
 * @returns  {Promise}                   Promise that resolves to the asset url
 * @memberof AssetDataParsing
 * @public
 */
export async function findAndGetAssetUrl(assets, layerData, layerModifications = {}) {
  const asset = assets.find(({ id }) => id === layerData.refId);
  if (!asset) {
    throw Error(`No asset found for layer "${layerData.nm}" with refId ${layerData.refId}`);
  }

  // Passed layerModifications will override asset modifications
  _.set(asset, 'modifications', {
    ...asset.modifications,
    ...layerModifications,
  });

  const url = await getAssetUrl(asset);

  return {
    asset,
    url,
  };
}

/**
 * Update the asset in the video data's assets Array
 *
 * @param      {object[]}  assets        The assets
 * @param      {object}    newAsset      The new asset
 * @param      {boolean}   shouldCreate  if the asset is not found, can we create a new asset?
 * @memberof AssetDataParsing
 * @returns {object} The updated asset object
 * @public
 */
export function updateAssetData(assets, newAsset, shouldCreate = false) {
  if (_.isUndefined(newAsset.id)) {
    throw Error('Could not update asset data for asset without an id');
  }
  const asset = assets.find(({ id }) => id === newAsset.id);

  if (!asset) {
    if (!shouldCreate) {
      throw Error(`Could not find asset with id ${newAsset.id}`);
    }

    assets.push(newAsset);
    return newAsset;
  }

  // Otherwise, update the asset in place
  Object.entries(newAsset).forEach(([key, value]) => {
    asset[key] = value;
  });

  return asset;
}

// TODO: Make this use the UUIDs
export const formatFontAssetId = ({ family = 'default', weight = 400, style = 'normal' }) =>
  `${family}.${weight}.${style}`;

/**
 * Migrates the now-deprecated "fonts" object from the project manifest to the new asset object model.
 *
 * @param {object} projectManifest The Project Manifest
 */
export function migrateFontsToAssets(projectManifest) {
  const rendererFonts = _.get(projectManifest, 'fonts.rendererFonts', {});

  const fonts = Object.keys(rendererFonts);

  if (!projectManifest.assets) {
    // eslint-disable-next-line no-param-reassign
    projectManifest.assets = [];
  }

  fonts.forEach((font) => {
    const { family, weight, style } = rendererFonts[font];
    const fontId = formatFontAssetId(rendererFonts[font]);
    const asset = {
      id: fontId,
      type: assetTypes.bitmapFont,
      isItalic: style !== 'normal',
      weight,
      location: {
        plugin: bitmapFontAssetParsingPlugin.name,
        // TODO: This needs to be a UUID or else it will fuck up
        legacyId: family,
      },
    };

    // Loop through all of the layers and alter their layer to use a refID with the new fontID
    // Alter the font information as well just as a matter of traceablity
    const matchingLayers = filterLayerData(projectManifest, ['t.d.k[0].s.f', font]);
    matchingLayers.forEach((layerData) => {
      // eslint-disable-next-line no-param-reassign
      layerData.refId = fontId;
      _.set(layerData, 't.d.k[0].s.f', fontId);
    });

    // Add the default font for this matching weight & style
    const isItalic = style[0] !== 'normal';
    const defaultFontAsset = {
      id: formatFontAssetId({ family: 'default', weight, style }),
      type: assetTypes.bitmapFont,
      weight,
      isItalic,
      location: {
        plugin: bitmapFontAssetParsingPlugin.name,
        id: 'default',
      },
    };

    projectManifest.assets.push(asset, defaultFontAsset);
  });

  const textLayers = filterLayerData(projectManifest, ['ty', layerTypes.text]);
  // Ensure that 400 normal is always accessible if the template has text
  if (fonts.length || textLayers.length) {
    projectManifest.assets.push({
      id: formatFontAssetId({ family: 'default', weight: 400, style: 'normal' }),
      type: assetTypes.bitmapFont,
      weight: 400,
      isItalic: false,
      location: {
        plugin: bitmapFontAssetParsingPlugin.name,
        id: 'default',
      },
    });
  }
}
