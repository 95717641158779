/**
 * Core/shared components related to typography and text throughout the WTS application.
 */
import cxsComponent from 'cxs/component.js';

import { defaultText } from './colors.js';

export const defaultFontFamily = 'SFProText, roboto, sans-serif';

// Naming subject to change.
export const SectionTitle = cxsComponent('h2')({
  fontFamily: defaultFontFamily,
  fontSize: '24px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  color: defaultText,
});

export const Paragraph = cxsComponent('p')({
  color: defaultText,
  fontFamily: defaultFontFamily,
  fontSize: '12px',
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
  lineHeight: 'normal',
  textAlign: 'left',
});
