import _ from 'lodash';
import Ajv from 'ajv';
import { findLayerDataByUUID } from '@stikdev/waymark-author-web-renderer/manifest.js';

import { createSchemaObject } from './configurationSchema.js';
import { createEditingAction, getEditingActionType } from './editingActions.js';
import { createEditingFormField } from './editingForm.js';
import { parseLayerType } from './projectManifest.js';
import { getOverrideMembersFromLayersExtendedAttributes } from './templateManifest.js';
import {
  getConfigurationValuesFromExtendedAttributes,
  templateManifestAttributes,
} from './templateManifestAttributes.js';
import {
  editingActionTypes,
  switchCaseOperation,
  switchEditingEvent,
} from '../constants/EditingActions.js';
import { getVideoTemplateVideo } from '../api/index.js';
import { editingFormTypes } from '../constants/EditingForm.js';
import { configurationSchemaBase, layerTypes } from '../constants/TemplateManifest.js';
import {
  contentOverrideTypes,
  overrideTypes,
  templateManifestOverrideKeys,
} from '../constants/index.js';
import templateManifestSchema from '../constants/templateManifestSchema.json';
import { defaultFillModifications, defaultFitModifications } from '../constants/ImageAlignment.js';
import { fitFillAlignments } from '@stikdev/waymark-author-web-renderer';

/**
 * An async implementation of `Array.prototype.forEach`.
 * @param {Array} array
 * @param {Function} callback
 */
const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index = index + 1) {
    await callback(array[index], index, array);
  }
};

/**
 * Helper class that ingests a template manifest and project manifest and
 * creates four JSON objects:
 *   configurationSchema
 *   editingActions
 *   editingForm
 *   placeholderConfiguration
 *
 * JSON objects are created to the current specs of our Waymark Store
 * editing experience.
 */
class TemplateManifestInterpreter {
  constructor(templateManifest, projectManifest) {
    this.templateManifest = _.cloneDeep(templateManifest);
    this.projectManifest = _.cloneDeep(projectManifest);
    this.editingEvents = [];
    this.editingForm = [];
    this.placeholderConfiguration = {};
    this.configurationSchema = {};
    this.sceneSwitchOverrideTracker = [];
  }

  /**
   * Get template manifest override object by override ID.
   *
   * @param  {string} overrideID   Override ID to search by.
   */
  getOverride(overrideID) {
    return _.find(this.templateManifest.overrides, (override) => override.id === overrideID);
  }

  /**
   * Get scene group object from ID.
   *
   * @param  {string} sceneGroupID    ID of scene group to search for.
   */
  getSceneGroup(sceneGroupID) {
    return _.find(
      this.templateManifest.sceneGroups,
      (sceneGroup) => sceneGroup.id === sceneGroupID,
    );
  }

  /**
   * Create a placeholder configuration object. Every layer in a template
   * manifest should have a placeholder configuration object whether or
   * not it has a property override or belongs to a scene switch.
   *
   * @param  {object} layerData         General data about a layer used to create
   *                                    a configuration object.
   * @param  {object} dynamicAttributes Dynamic attributes on a layer.
   */
  async createPlaceholderConfigurationObject(layerData, dynamicAttributes) {
    this.placeholderConfiguration[layerData.path] = {};
    const configurationObject = this.placeholderConfiguration[layerData.path];
    const configurationValues = getConfigurationValuesFromExtendedAttributes(dynamicAttributes);

    await asyncForEach(Object.entries(configurationValues), async (attribute) => {
      let attributeDotPath = attribute[0];
      let attributeValue = attribute[1];
      // This is an array of [dotpath, value] pairs that can also be added to the configuration
      let additionalEntries = [];

      // For every image, add an additional entry to the placeholder configuration that copies the fitFillAlignment as well as default modifications
      if ([layerTypes.image].includes(layerData.type) && !attributeValue.override) {
        if (attributeDotPath === 'content' && attributeValue.fitFillAlignment) {
          additionalEntries.push(['fitFillAlignment', attributeValue.fitFillAlignment]);
        }

        if (attributeValue.freeformCropping === true) {
          additionalEntries.push(['content.modifications', defaultFitModifications]);
        } else if (attributeValue.freeformCropping === false) {
          additionalEntries.push(['content.modifications', defaultFillModifications]);
        } else {
          // In cases where the freeformCropping is not defined, we want to default to the "fit" modifications
          // This is because "fit my entire image" is the editor UI default if no value is set, so we want to
          // to ensure studio -> editor parity in those cases.
          additionalEntries.push(['content.modifications', defaultFitModifications]);
        }
      }

      if (attributeValue.override) {
        let overrideAttributeValue = this.getOverride(attributeValue.override);
        // In the configuration, we want this stored as `typography` for flexibility.
        if (attributeDotPath === 'font') {
          attributeDotPath = 'typography';
        }

        if ([layerTypes.image].includes(layerData.type) && attributeDotPath === 'content') {
          attributeValue.placeholder = _.pick(overrideAttributeValue.placeholder, [
            'location',
            'type',
            'id',
            'modifications',
          ]);

          if (attributeValue.fitFillAlignment) {
            additionalEntries.push(['fitFillAlignment', attributeValue.fitFillAlignment]);
          }
        }
        // We don't need the "name" and "isUsed" values
      } else if (layerData.type === layerTypes.waymarkVideo && attributeDotPath === 'content') {
        // If there's only one option, convert it to a VPS video
        // TODO: Eventually everything will be VPS and we'll need to migrate the values within templateManifests
        if (attributeValue.options.length === 1) {
          const videoTemplateVideo = await getVideoTemplateVideo(
            attributeValue.placeholder.location.id,
          );

          // Now we're going to look for this asset so that we can fill its native width and height
          // TODO: When we retire timecodes from the extension, this can be removed
          const matchingAsset = this.projectManifest.assets.find(
            (asset) =>
              asset.type === 'video' && _.get(asset, 'location.id') === videoTemplateVideo.data.id,
          );

          if (!matchingAsset) {
            throw new Error(
              'Could not find an asset in the project manifest that matches the video template video being used in the placeholder configuration.',
            );
          }

          attributeValue.placeholder.location = {
            sourceVideo: videoTemplateVideo.data.vpsSourceKey,
            plugin: 'waymark-vps',
            legacyTimecode: {
              nativeVideoWidth: matchingAsset.w,
              nativeVideoHeight: matchingAsset.h,
            },
          };
        }
        // I don't think we need 'modifications' for now
        attributeValue.placeholder = _.pick(attributeValue.placeholder, [
          'location',
          'type',
          'modifications',
          'id',
        ]);
      }

      // `setWith` is just like _.set, but ensures we're translating dotPaths that contain numeric
      // parts to object keys rather than array indexes.
      _.setWith(configurationObject, attributeDotPath, attributeValue.placeholder, Object);

      // Loop through and apply any/all additional entries
      additionalEntries.forEach(([dotPath, value]) => {
        _.setWith(configurationObject, dotPath, value, Object);
      });
    });
  }

  /**
   * Create editing event and add it to global editing events.
   *
   * @param  {string} configurationPath    Layer configuration path that the
   *                                       editing event should listen to.
   * @param  {string} editingActionType    Editing action type.
   * @param  {array}  targets              Layer UUIDs the editing action should
   *                                       target.
   */
  createEditingEvent(configurationPath, editingActionType, targets) {
    const editingAction = createEditingAction(editingActionType, targets);

    this.editingEvents.push({
      path: configurationPath,
      actions: [editingAction],
    });
  }

  /**
   * Create a placeholder configuration object, editing form field and audio
   * source object for audio sources defined in a template manifest.
   * defined in a template manifest
   */
  parseBackgroundAudioSources() {
    const defaultAudioSelection = _.find(
      this.templateManifest.backgroundAudio.options,
      (option) => option.id === this.templateManifest.backgroundAudio.defaultSelection,
    );

    const audioLayerUUID = _.find(this.projectManifest.layers, { ty: 101 }).meta.uuid;

    if (!audioLayerUUID) {
      throw new Error('No background audio layer found!');
    }

    // Create an audio editing action.
    // TODO: Change the audio content to use the `waymarkAudio--` key eventually. For now it uses `backgroundAudio`.
    const configurationPath = 'backgroundAudio';
    this.createEditingEvent(configurationPath, editingActionTypes.audioResource, [audioLayerUUID]);

    // This is strictly for `volumeChanges` and other modifications (not `content`)
    this.createEditingEvent(`waymarkAudio--${audioLayerUUID}`, editingActionTypes.layerAudio, [
      audioLayerUUID,
    ]);
    this.placeholderConfiguration[`waymarkAudio--${audioLayerUUID}`] = { volumeChanges: [] };

    // Format the editing form select options (also used as configuration values) with
    // all of the data stored in the template manifest *except* the name. Editing actions
    // grab their passthrough values directly from the configuration, and having a name
    // key in the audio editing action will cause the editing action to fail.
    const editingFormSelectOptions = _.map(
      this.templateManifest.backgroundAudio.options,
      (audioOption) => ({
        label: audioOption.name,
        configurationValue: {
          location: audioOption.location,
          type: audioOption.type,
        },
      }),
    );

    if (defaultAudioSelection) {
      // Create the placeholder configuration object with the default
      // audio option configuration value.
      this.placeholderConfiguration[configurationPath] = _.find(
        editingFormSelectOptions,
        (option) => option.configurationValue.location.id === defaultAudioSelection.id,
      ).configurationValue;
    }

    // Create an editing form field.
    this.createAudioEditingFormField(configurationPath, editingFormSelectOptions);
  }

  createAudioEditingFormField(path, selectOptions) {
    const backgroundAudioEditingData = {
      id: path,
      name: 'Audio',
      selectOptions,
    };

    const backgroundAudioEditingForm = createEditingFormField(
      editingFormTypes.audio,
      backgroundAudioEditingData,
      [path],
    );
    this.editingForm.push(backgroundAudioEditingForm);
  }

  /**
   * Loop through all dynamic layers in a template manifest and:
   *  - Create a placeholder configuration object.
   *  - Create an editing form field for the layer or add the layer's path to an existing
   *    override editing form field.
   *  - Create an editing action for the layer or add the layer's UUID to an existing override
   *    editing action.
   */
  async parseLayersExtendedAttributes() {
    const layersExtendedAttributes = Object.entries(this.templateManifest.layersExtendedAttributes);

    await asyncForEach(layersExtendedAttributes, async ([layerUUID, layerAttributes]) => {
      const projectManifestLayer = findLayerDataByUUID(this.projectManifest, layerUUID);
      let layerType = parseLayerType(projectManifestLayer.ty);
      const configurationPath = `${layerType}--${layerUUID}`;

      // Get non-null attributes.
      let dynamicAttributes = _.pickBy({ ...layerAttributes }, _.identity);
      const layerData = {
        path: configurationPath,
        type: layerType,
        uuid: layerUUID,
      };

      if (layerType === layerTypes.image && dynamicAttributes.content) {
        layerData.height = null;
        layerData.width = null;

        // If the image does not have a content override, only add certain data to the
        // placeholder configuration.
        if (!dynamicAttributes.content.override) {
          dynamicAttributes = {
            ...dynamicAttributes,
            content: _.pick(dynamicAttributes.content, [
              'frameNumber',
              'placeholder.location',
              'placeholder.modifications',
              'placeholder.type',
              'fitFillAlignment',
              'freeformCropping',
            ]),
          };
        }

        // TODO: Can we unify this code with what's in `updateOverrideEntries` so we're only handling
        // freeform cropping in one place?
        let hasFreeFormCropping = false;
        const hasContentOverride = !!dynamicAttributes.content.override;
        if (hasContentOverride) {
          const override = this.getOverride(dynamicAttributes.content.override);
          hasFreeFormCropping = override.freeformCropping;
        } else {
          hasFreeFormCropping = layerAttributes.content.freeformCropping;
        }

        // If free form cropping is not enabled on the layer, get the original image
        // asset width and height for the editing form field to ensure correct cropping.
        if (!hasFreeFormCropping) {
          const projectManifestImageAsset = _.find(
            this.projectManifest.assets,
            (asset) => asset.id === projectManifestLayer.refId,
          );
          layerData.height = projectManifestImageAsset.h;
          layerData.width = projectManifestImageAsset.w;
        }
      }

      // Create a placeholder configuration object for each layer.
      // Placeholder configuration should contain an object for
      // *every* editable layer whether or not it has a property override
      // or belongs to a scene switch.
      await this.createPlaceholderConfigurationObject(layerData, dynamicAttributes);

      await asyncForEach(
        Object.entries(dynamicAttributes),
        async ([attributeType, attributeValue]) => {
          const attributeHelper = new templateManifestAttributes[attributeType](
            attributeType,
            attributeValue,
          );
          const configurationValues = attributeHelper.getConfigurationValues(
            `${configurationPath}.`,
          );

          const isGradientFillType =
            attributeType === templateManifestOverrideKeys.color.gradientFill;
          const editingActionType = getEditingActionType(attributeType, layerType);
          const foundSceneGroup = _.find(this.templateManifest.sceneGroups, (sceneGroup) =>
            _.includes(sceneGroup.layers, layerUUID),
          );

          const editingFormData = {
            ...layerData,
            id: layerUUID,
            name: projectManifestLayer.nm,
          };

          /*
        `gradientFill` has 0-N distinct editable properties that should be mapped to our normal
        color editing fields based on the override value. But regardless of how many individual editable
        properties the `gradientFill` value translates into (i.e., how many steps make up the gradient),
        it relies on one (1) editing action that operates on the top-level `gradientFill` video
        configuration object as a whole. Reference to the full gradientFill object when
        constructing the necessary renderer changes from the action is the only way we can determine
        what stepIndex to provide to the renderer API.
        */
          if (isGradientFillType) {
            this.createEditingEvent(`${configurationPath}.${attributeType}`, editingActionType, [
              layerUUID,
            ]);
          }

          await asyncForEach(
            Object.entries(configurationValues),
            async ([attributePath, value]) => {
              const editingFormFieldData = {
                ...value,
                ...editingFormData,
                projectManifestLayer,
              };

              // If this is an image or video, we do not want the `.content` path that was specified in previous incarnations.
              // The `.content` specification is now handled by the caller and not by the form field definition.
              // NOTE: This is a band-aid until a proper restructuring happens with this interpreter.
              if (
                [layerTypes.waymarkVideo, layerTypes.image].includes(layerType) &&
                attributeType === 'content'
              ) {
                const placeholder = {
                  content: { ...editingFormFieldData.placeholder },
                };

                attributePath = attributePath.replace('.content', '');
                editingFormFieldData.placeholder = placeholder;
              }

              // If a layer belongs to a scene group AND has a content override, do some special shit.
              if (foundSceneGroup && value.override && attributeType === 'content') {
                const trackingData = _.find(
                  this.sceneSwitchOverrideTracker,
                  (trackingObject) =>
                    trackingObject.sceneGroup === foundSceneGroup.id &&
                    trackingObject.override === value.override,
                );

                // If there's tracking data, it means that another layer in this group has the same content
                // override applied to it and we've already created an editing form field for it.
                if (trackingData) {
                  const existingContentField = trackingData.editingFormField;
                  existingContentField.paths.push(attributePath);
                  // Update scene switch but only update the content field
                  this.updateSceneSwitchEditingField(existingContentField, foundSceneGroup, true);
                } else {
                  // If we have a content override within a scene switch, we want to create the form field
                  // with the same frame number as the switch.
                  const parentSceneSwitch = _.find(
                    this.templateManifest.sceneSwitches,
                    (sceneSwitch) => sceneSwitch.groups.includes(foundSceneGroup.id),
                  );
                  // We should always find a switch here, but if for whatever reason we don't let's not break.
                  editingFormFieldData.frameNumber = parentSceneSwitch
                    ? parentSceneSwitch.frameNumber
                    : null;

                  const editingFormField = createEditingFormField(layerType, editingFormFieldData, [
                    attributePath,
                  ]);

                  this.updateSceneSwitchEditingField(editingFormField, foundSceneGroup);
                  this.sceneSwitchOverrideTracker.push({
                    sceneGroup: foundSceneGroup.id,
                    override: value.override,
                    editingFormField,
                  });
                }

                // An attribute still needs an editing event if the layer belongs to
                // a scene switch. The scene switch editing event only controls
                // which layers are visible, not any content or other properties.
                this.createEditingEvent(attributePath, editingActionType, [layerUUID]);

                // If the attribute has an override, add the attribute path to the override
                // editing action but do not add it to the override editing form field.
                this.updateOverrideEntries(
                  value.override,
                  editingActionType,
                  layerData,
                  attributePath,
                  false,
                );
              } else if (value.override) {
                // If the attribute has an override, add the attribute path or layer to
                // the correct editing form field or editing action respectively.
                this.updateOverrideEntries(
                  value.override,
                  editingActionType,
                  layerData,
                  attributePath,
                  // shouldAddToEditingFormField
                  true,
                  /* `shouldEnsureEditingAction` => We created a shared action for all gradientFill
              changes (above -- see special handling for `gradientFill`), so we *don't* want a
              conflicting per-step-value target added to the color override action here. */
                  !isGradientFillType,
                );
              } else if (foundSceneGroup) {
                // If the layer belongs to a scene group, create an editing form field
                // for the attribute and add it to the correct scene switch case.
                const editingFormField = createEditingFormField(layerType, editingFormFieldData, [
                  attributePath,
                ]);

                this.updateSceneSwitchEditingField(editingFormField, foundSceneGroup);

                // An attribute still needs an editing event if the layer belongs to
                // a scene switch. The scene switch editing event only controls
                // which layers are visible, not any content or other properties.
                this.createEditingEvent(attributePath, editingActionType, [layerUUID]);
              } else {
                // If the attribute does not have an override and the layer does
                // not belong to a scene group, create an editing form field and
                // editing event for the attribute.
                this.createEditingEvent(attributePath, editingActionType, [layerUUID]);

                if (layerType === layerTypes.solid || layerType === layerTypes.shape) {
                  layerType = editingFormTypes.color;
                } else if (layerType === layerTypes.waymarkVideo) {
                  // Footage Composition is considered a legacy form field at this point. We are only using it if multiple choices
                  // have been exported from After Effects
                  if (dynamicAttributes.content.options.length > 1) {
                    layerType = editingFormTypes.footageComposition;
                  } else {
                    layerType = editingFormTypes.video;
                  }
                }
                const editingFormField = createEditingFormField(layerType, editingFormFieldData, [
                  attributePath,
                ]);
                this.editingForm.push(editingFormField);
              }
            },
          );
        },
      );
    });
  }

  /**
   * Add editing form fields to scene switch case operation.
   *
   * @param  {object} editingFormField              Form field to add to operation.
   * @param  {object} sceneGroup                    Form field's corresponding scene group.
   * @param  {bool}   shouldUpdateEditingFormField  If a layer should be added to an existing form field
   *                                                instead of adding an entire new form field.
   */
  updateSceneSwitchEditingField(editingFormField, sceneGroup, shouldUpdateEditingFormField) {
    if (this.templateManifest.sceneSwitches.length) {
      const foundSceneSwitch = _.find(this.templateManifest.sceneSwitches, (sceneSwitch) =>
        _.includes(sceneSwitch.groups, sceneGroup.id),
      );
      const sceneSwitchEditingField = _.find(
        this.editingForm,
        (formField) => formField.editingFieldKey === foundSceneSwitch.id,
      );

      const selectOption = _.find(
        sceneSwitchEditingField.selectOptions,
        (option) => option.configurationValue === sceneGroup.id,
      );

      if (shouldUpdateEditingFormField) {
        const contentFieldToUpdate = _.find(
          selectOption.contentFields,
          (contentField) => contentField.editingFieldKey === editingFormField.editingFieldKey,
        );
        contentFieldToUpdate.paths = editingFormField.paths;
      } else {
        selectOption.contentFields.push(editingFormField);
      }
    }
  }

  /**
   * Add layer configuration paths and UUIDs to override editing
   * events and editing form fields.
   *
   * @param  {string} overrideID                  ID of override to update.
   * @param  {string} actionType                  Editing action type.
   * @param  {object} layerData                   General data about layer being added to
   *                                              override objects.
   * @param  {string} [path]                      Layer path to add to editing form field.
   * @param  {bool}   [shouldAddToEditingFormField]
   *    Whether a layer should be added to the override's editing form field.
   * @param  {bool}   [shouldEnsureEditingAction]
   *    Whether an action should be either created or added for this override entry.
   */
  updateOverrideEntries(
    overrideID,
    actionType,
    layerData,
    path = null,
    shouldAddToEditingFormField = true,
    shouldEnsureEditingAction = true,
  ) {
    const override = this.getOverride(overrideID);
    const configurationPath = `${override.type}Override--${override.id}`;
    const editingEvent = _.find(this.editingEvents, (event) => event.path === configurationPath);
    const editingAction = _.find(editingEvent.actions, (action) => action.type === actionType);

    if (shouldEnsureEditingAction) {
      if (editingAction) {
        editingAction.targets.push(layerData.uuid);
      } else {
        const newEditingAction = createEditingAction(actionType, [layerData.uuid]);

        editingEvent.actions.push(newEditingAction);
      }
    }

    if (shouldAddToEditingFormField) {
      let editingFormField;
      if (override.type === overrideTypes.font) {
        const modifiedPath = _.replace(path, 'font', 'typography');
        editingFormField = _.find(this.editingForm, { type: overrideTypes.font });
        const matchingOverride = editingFormField.fontOverrides[configurationPath];

        matchingOverride.paths.push(modifiedPath);
      } else {
        editingFormField = _.find(this.editingForm, (formField) =>
          _.includes(formField.paths, configurationPath),
        );

        editingFormField.paths.push(path);
      }

      /* If we DON'T want the image to have freeform cropping and we still don't have a height
      and width set, let's set it using the layerData. */
      if (
        override.type === layerTypes.image &&
        !override.freeformCropping &&
        (!editingFormField.height || !editingFormField.width)
      ) {
        editingFormField.height = layerData.height;
        editingFormField.width = layerData.width;
      }
    }
  }

  /**
   * For each override in a template manifest:
   *   Create placeholder configuration object.
   *   Create editing event.
   *   Create editing form field.
   */
  parseOverrides() {
    const { layersExtendedAttributes } = this.templateManifest;
    this.templateManifest.overrides.forEach((override) => {
      const configurationPath = `${override.type}Override--${override.id}`;
      let configurationValue = override.placeholder;

      if (override.freeformCropping === true) {
        _.set(configurationValue, 'modifications', defaultFitModifications);
      } else if (override.freeformCropping === false) {
        _.set(configurationValue, 'modifications', defaultFillModifications);
      } else {
        // In cases where the freeformCropping is not defined, we want to default to the "fit" modifications for this override.
        // This is because "fit my entire image" is the editor UI default if no value is set, so we want to
        // to ensure studio -> editor parity in those cases.
        _.set(configurationValue, 'modifications', defaultFitModifications);
      }

      if (override.type === overrideTypes.image) {
        configurationValue = {
          content: _.pick(configurationValue, ['location', 'type', 'modifications']),
          fitFillAlignment: _.get(override, 'fitFillAlignment', fitFillAlignments.centerCenter),
        };
      }
      // Add override values to placeholder configuration.
      this.placeholderConfiguration[configurationPath] = configurationValue;

      // Create a largely unfilled editing action to add to as we parse layers.
      this.editingEvents.push({
        path: configurationPath,
        actions: [],
      });

      // Get all of the layers that belong to the override to determine if we should
      // create an individual editing form field for the override.
      const overrideLayers = getOverrideMembersFromLayersExtendedAttributes(
        layersExtendedAttributes,
        override.id,
      );
      let shouldAddOverrideToEditingForm = true;

      // Color and content overrides are handled differently. Layers that belong to color
      // override should be added to its editing form field no matter what, but content
      // is a bit more complex. For example, if a layer has a content override applied to
      // it but it also belongs to a scene switch, the editing field should be added to
      // the scene switch component. But if the override has other layers that belong to
      // it that are NOT a part of a scene switch, an individual editing field needs to
      // be created. Sigh.
      if (contentOverrideTypes.includes(override.type)) {
        if (overrideLayers.length) {
          const overrideLayerUUIDs = _.reduce(
            Object.entries(overrideLayers),
            (accum, layerProperties) => {
              accum.push(Object.keys(layerProperties[1])[0]);
              return accum;
            },
            [],
          );

          // Determine if there are any layers that do not belong to groups.
          const layersThatDoNotBelongToGroups = _.reduce(
            overrideLayerUUIDs,
            (accum, layerUUID) => {
              const foundSceneGroup = _.find(this.templateManifest.sceneGroups, (sceneGroup) =>
                _.includes(sceneGroup.layers, layerUUID),
              );
              if (!foundSceneGroup) accum.push(layerUUID);
              return accum;
            },
            [],
          );

          // If all of the override's layers belong to groups, do not create an individual editing field.
          if (!layersThatDoNotBelongToGroups.length) shouldAddOverrideToEditingForm = false;
        }
      }

      // Inline helper to avoid duplicate code below.
      const createEditingFormFieldForOverride = (overrideObj, path) => {
        const editingFormField = createEditingFormField(overrideObj.type, overrideObj, [path]);

        this.editingForm.push(editingFormField);
        return editingFormField;
      };

      // If there are no layers using the override, do not add it to the editing form.
      // In theory this should only happen during the preview flow.
      if (overrideLayers.length && shouldAddOverrideToEditingForm) {
        /* We only want one font editing field for the time being, so let's add the current override path to
        the existing font field, if there is one. */
        if (override.type === overrideTypes.font) {
          let fontEditingFormField = _.find(this.editingForm, { type: override.type });
          if (!fontEditingFormField) {
            fontEditingFormField = createEditingFormFieldForOverride(override, configurationPath);
            // Let's not use the name as the font override for the editing field.
            fontEditingFormField.label = 'Font';
          } else {
            // We currently only respect one font per template, so we can be confident
            // that any additional font override paths should be mapped to the first item
            // in respectedPathMappings. In the future when we respect more than one font,
            // this logic will not hold up. We don't currently have the information to
            // intelligently map font overrides to more than one path, so there will have
            // to be UI changes and additional information from the templatizer.
            const respectedPath = Object.keys(fontEditingFormField.respectedPathMappings)[0];
            fontEditingFormField.respectedPathMappings[respectedPath].push(configurationPath);
          }
          /* Regardless of whether this was just created or not, let's add the default
           "template intent" values for this override. */
          fontEditingFormField.fontOverrides[configurationPath] = {
            originalTypography: { ...override.placeholder },
            paths: [configurationPath],
          };
        } else {
          createEditingFormFieldForOverride(override, configurationPath);
        }
      }
    });
  }

  /**
   * Create an editing event that controls the visiblity of layers.
   * @param  {string} configurationPath  Configuration path that editing event
   *                                     should listen to.
   * @param  {obect}  sceneSwitch        Object from template manifest that
   *                                     editing event is based on.
   */
  createVisibilityEditingEvent(configurationPath, sceneSwitch) {
    const editingEvent = _.cloneDeep(switchEditingEvent);
    editingEvent.path = configurationPath;

    // Create a switch case operation for every scene group.
    const switchCaseOperations = [];
    sceneSwitch.groups.forEach((sceneGroupID) => {
      const foundSceneGroup = this.getSceneGroup(sceneGroupID);
      _.find(this.templateManifest.sceneGroups, (sceneGroup) => sceneGroup.id === sceneGroupID);

      const editingOperation = _.cloneDeep(switchCaseOperation);
      editingOperation.case = foundSceneGroup.id;
      switchCaseOperations.push(editingOperation);
    });

    // Create an editing action that sets visibility to true and an editing
    // action that sets visibility to false for each sceneGroup. Add editing
    // action to corresponding switchCaseOperation.
    sceneSwitch.groups.forEach((sceneGroupID) => {
      const foundSceneGroup = this.getSceneGroup(sceneGroupID);
      const trueEditingAction = createEditingAction(
        editingActionTypes.displayObjectVisibility,
        foundSceneGroup.layers,
        true,
      );

      const trueSwitchCase = _.find(
        switchCaseOperations,
        (editingOperation) => editingOperation.case === foundSceneGroup.id,
      );
      trueSwitchCase.actions.push(trueEditingAction);

      const falseEditingAction = _.cloneDeep(trueEditingAction);
      falseEditingAction.value.payload = false;

      const falseSwitchCases = _.filter(
        switchCaseOperations,
        (editingOperation) => editingOperation !== trueSwitchCase,
      );
      falseSwitchCases.forEach((falseSwitchCase) => {
        falseSwitchCase.actions.push(falseEditingAction);
      });
    });

    switchCaseOperations.forEach((editingOperation) => {
      editingEvent.actions.switch.push(editingOperation);
    });

    this.editingEvents.push(editingEvent);
  }

  /**
   * Create an scene switch editing form field.
   * @param  {array} configurationPaths  Configuration paths that editing form
   *                                     field should update.
   * @param  {obect}  sceneSwitch        Object from template manifest that
   *                                     editing form field is based on.
   */
  createSceneSwitchEditingFormField(configurationPaths, sceneSwitch) {
    // eslint-disable-next-line no-param-reassign
    sceneSwitch.selectOptions = [];
    sceneSwitch.groups.forEach((sceneGroupID) => {
      const foundSceneGroup = this.getSceneGroup(sceneGroupID);
      sceneSwitch.selectOptions.push({
        configurationValue: foundSceneGroup.id,
        label: foundSceneGroup.name,
        contentFields: [],
      });
    });

    const editingFormField = createEditingFormField(
      editingFormTypes.sceneSwitch,
      sceneSwitch,
      configurationPaths,
    );

    this.editingForm.push(editingFormField);
  }

  /**
   * For every scene switch in a template manifest:
   * Create a placeholder configuration object.
   * Create a visibility editing event.
   * Create an editing form field.
   */
  parseSceneSwitches() {
    this.templateManifest.sceneSwitches.forEach((sceneSwitch) => {
      const configurationPath = `sceneSwitch--${sceneSwitch.id}`;
      const defaultSceneGroup = _.find(
        this.templateManifest.sceneGroups,
        (sceneGroup) => sceneGroup.id === sceneSwitch.defaultSelection,
      );

      this.placeholderConfiguration[configurationPath] = defaultSceneGroup.id;

      this.createVisibilityEditingEvent(configurationPath, sceneSwitch);
      this.createSceneSwitchEditingFormField([configurationPath], sceneSwitch);
    });
  }

  /**
   * Create a configuration schema based on a placeholder configuration.
   */
  convertPlaceholdersToSchema() {
    Object.entries(this.placeholderConfiguration).forEach((placeholder) => {
      const propertyKey = placeholder[0];
      const propertyValue = placeholder[1];
      createSchemaObject(propertyKey, propertyValue, this.configurationSchema);
    });
  }

  /**
   * Validate and stringify:
   *   The placeholder configuration
   *   The editing events
   *   The editing form fields
   *   The configuration schema
   * And add the background audio sources to the editing actions.
   * @param  {object} backgroundAudioSources  Audio sources defined in a template manifest.
   */
  formatJSONObjects() {
    const configurationSchema = configurationSchemaBase;
    configurationSchema.properties = this.configurationSchema;

    // Validate the placeholder configuration against its schema.
    const ajv = new Ajv({ allErrors: true });
    const validator = ajv.compile(configurationSchema);
    const isValid = validator(this.placeholderConfiguration);
    if (!isValid) {
      console.error('Configuration validation error: ', validator.errors);
    }

    return {
      placeholderConfiguration: JSON.stringify(this.placeholderConfiguration),
      configurationSchema: JSON.stringify(configurationSchema),
      editingActions: JSON.stringify({ events: this.editingEvents }),
      editingForm: JSON.stringify({ editingFormFields: this.editingForm }),
    };
  }

  /**
   * Parse a template manifest into:
   *   Editing actions
   *   Editing form
   *   Placeholder configuration
   *   Configuration schema
   */
  async convertTemplateManifestToJSON() {
    const ajv = new Ajv({ allErrors: true });
    const validator = ajv.compile(templateManifestSchema);
    const isValid = validator(this.templateManifest);

    // Don't parse scene switches and groups if no scene switches have been added.
    if (this.templateManifest.sceneSwitches.length) {
      this.parseSceneSwitches();
    }

    this.parseOverrides();
    await this.parseLayersExtendedAttributes();
    if (!_.isEmpty(this.templateManifest.backgroundAudio)) {
      this.parseBackgroundAudioSources();
    }

    // Create the schema after everything has been added
    // to the placeholder configuration.
    this.convertPlaceholdersToSchema();

    const formattedJSONObjects = this.formatJSONObjects();

    if (!isValid) {
      console.error('Invalid template manifest', validator.errors);
    }

    return formattedJSONObjects;
  }
}

export default TemplateManifestInterpreter;
