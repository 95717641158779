/**
 * Home for core/shared components and styles used throughout the Waymark Template Studio application.
 * This file holds small components and styling that's used throughout the application, and also exports
 * larger or more involved components defined in sibling files.
 *
 * These components are largely (though not exclusively) wrappers around existing blueprintjs components
 * that we style and/or modify.
 */

import cxs from 'cxs';
import cxsComponent from 'cxs/component.js';
import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, FormGroup, Switch, Tag } from '@blueprintjs/core';

import { makeImportant } from '../../utils/styles.js';
import {
  altBackground,
  blendedBackground,
  dangerBackground,
  defaultBackground,
  inputFieldBackground,
  labelText,
  primaryBackground,
  selectedBackground,
  successBackground,
} from './colors.js';
import { defaultFontFamily } from './typography.js';
import { wtsIntent } from '../../constants/index.js';


// Export components defined in sibiling files for organizational purposes.
export { default as WTSButton, WTSAnchorButton } from './WTSButton.js';
export { default as WTSColorSelect } from './WTSColorSelect.js';
export { default as WTSDropdownMenu } from './WTSDropdownMenu.js';
export { default as WTSIcon, wtsIconTypes } from './WTSIcon.js';

/**
 * FORM CONTROL
 */
const inputStyleProperties = {
  backgroundColor: makeImportant(inputFieldBackground),
  background: makeImportant(inputFieldBackground),
  fontFamily: defaultFontFamily,
  fontSize: '12px',
  border: 'solid 1px #3d3d3d',
  height: '23px',
};

const inputGroupStyle = cxs({
  '> input': {
    ...inputStyleProperties,
  },
});

export const WTSInputGroup = ({ ...props }) => (
  <InputGroup className={inputGroupStyle} {...props} />
);

const labelStyleProperties = {
  color: makeImportant(labelText),
  fontFamily: defaultFontFamily,
  fontSize: '12px',
  fontWeight: 'normal',
  textAlign: 'left',
  textShadow: '0 -1px 0 #333333',
};

const formGroupStyle = cxs({
  '> label': {
    ...labelStyleProperties,
  },
});

export const wtsLabelSubtextProperties = {
  fontSize: '11px',
  color: '#888888',
  marginTop: '-5px',
  marginBottom: '3px',
  marginRight: '18px',
};

const labelSubtextStyles = cxs({
  ...wtsLabelSubtextProperties,
});

export const WTSFormGroup = ({ children, labelSubtext, ...props }) => (
  <FormGroup className={formGroupStyle} {...props}>
    {labelSubtext ? <div className={labelSubtextStyles}>{labelSubtext}</div> : null}
    {children}
  </FormGroup>
);

WTSFormGroup.propTypes = {
  children: PropTypes.node.isRequired,
  labelSubtext: PropTypes.string,
};

WTSFormGroup.defaultProps = {
  labelSubtext: '',
};

const switchStyle = cxs({
  ...labelStyleProperties,
  fontSize: makeImportant('24px'),
  marginTop: makeImportant(0),
  marginBottom: 0,
});

export const WTSSwitch = ({ ...props }) => <Switch className={switchStyle} {...props} />;

/**
 * General display
 */
const INTENT_TO_COLOR = {
  [wtsIntent.alt]: altBackground,
  [wtsIntent.blended]: blendedBackground,
  [wtsIntent.danger]: dangerBackground,
  [wtsIntent.default]: defaultBackground,
  [wtsIntent.primary]: primaryBackground,
  [wtsIntent.selected]: selectedBackground,
  [wtsIntent.success]: successBackground,
};

const tagStyle = {
  fontSize: '10px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  letterSpacing: '1px',
  padding: '0 6px',
  minHeight: 0,
};

export const WTSTag = cxsComponent(Tag)((props) => ({
  ...tagStyle,
  backgroundColor: makeImportant(INTENT_TO_COLOR[props.wtsIntent || wtsIntent.default]),
}));

WTSTag.propTypes = {
  wtsIntent: PropTypes.oneOf(Object.values(wtsIntent)),
};

/**
 * Shared styles
 */
export const hiddenInputStyle = cxs({
  width: '0.1px',
  height: '0.1px',
  opacity: 0,
  overflow: 'hidden',
  position: 'absolute',
  zIndex: -1,
});

export const collapsibleMenuContentContainer = cxs({
  padding: '24px 12px',
});