import _ from 'lodash'
import { useState } from 'react';
import { DisplayObjectVisibilityChangeOperation } from '@stikdev/waymark-author-web-renderer';

import store, { selectors } from '../store/index.js';

/**
 * Hook for toggling layer visibility inside of a composition panel
 *
 * @param      {WaymarkAuthorWebRenderer}  renderer  The renderer for this template
 * @param      {Object[]}  layers    The layers rendered by a this composition panel
 * @return     {Object}   return
 * @return     {String[]} return.visibleLayerUUIDs     An array of all of the visible layers
 * @return     {Function} return.toggleVisibility    A callback function for when the visibility should be toggled
 */
const useLayerVisibility = (renderer, layers) => {
  /**
   * TODO: A potential improvement for this would be to make the layer visibility into a declarative implementation similar to layer selection
   * This would involve:
   *  - Storing the visibility state of the layers in the store (workplace slice, likely)
   *  - Setting every layer's visibility state whenever anything changes
   */
  const defaultVisibilityUUIDs = _.map(layers.filter((layer) => layer.isRenderable), 'uuid')
  // If the layer is hidden in the project manifest (i.e. it was hidden when exported by the Waymark Author), don't allow the user to change its visibility
  const excludedLayerUUIDs = _.map(layers.filter((layer) => !layer.isRenderable), 'uuid')
  const [visibleLayerUUIDs, setVisibleLayerUUIDs] = useState(defaultVisibilityUUIDs)


  /**
   * Called when a layer is going to toggle its visibility on or off
   *
   * @param      {Object}   toggledLayer       The toggled layer
   * @param      {string}   toggledLayer.uuid  The toggled layer's uuid
   * @param      {boolean}  isVisible          Indicates what the visible state of the layer should be
   */
  const toggleVisibility = async (toggledLayer, isVisible) => {
    if (!renderer) { return }

    const selectedLayerUUIDs = selectors.getSelectedLayers(store.getState());

    // If we're toggling the visibility of a layer in the selection, toggle them all (except excluded layers)
    const layersToToggle = selectedLayerUUIDs.includes(toggledLayer.uuid) ? _.without(selectedLayerUUIDs, ...excludedLayerUUIDs) : [toggledLayer.uuid]

    if (isVisible) {
      setVisibleLayerUUIDs(_.uniq(visibleLayerUUIDs.concat(layersToToggle)))
    } else {
      setVisibleLayerUUIDs(_.without(visibleLayerUUIDs, ...layersToToggle))
    }

    const changesList = layersToToggle.map((layer) => ({
      type: DisplayObjectVisibilityChangeOperation.type,
      payload: {
        isVisible,
        layer,
      }
    }))

    renderer.applyChangeList(changesList)
  }

  return { visibleLayerUUIDs, toggleVisibility }
}

export default useLayerVisibility
