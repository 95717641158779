/* eslint-env browser */
/* eslint-disable import/extensions */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
/* eslint-enable import/extensions */

/* eslint-disable-next-line import/no-extraneous-dependencies */
import 'normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/select/lib/css/blueprint-select.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './index.css';
import App from './components/App.js';
import store from './store/index.js';

const rootElement = document.createElement('div');
document.body.appendChild(rootElement);
// Apply the dark blueprint theme.
document.body.classList.add('bp3-dark');

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootElement,
);
