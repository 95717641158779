import { useState, useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

/**
 * Utility hook used to resize the layer list panel when their contents change
 *
 * @param      {Object}    props                     The props
 * @param      {Ref}       props.panelRef            A react ref to the panel element
 * @param      {String}    props.panelCompositionId  The panel composition identifier used when the panel changes
 * @param      {Function}  props.onPanelResize       The on panel resize callback
 */
const usePanelResize = ({ panelRef, panelCompositionId, onPanelResize }) => {
  useEffect(() => {
    const onPanelSizeChange = () => {
      const { height } = panelRef.current.getBoundingClientRect();
      onPanelResize({ height, panelCompositionId, panelRef });
    };

    const panelResizeObserver = new ResizeObserver(onPanelSizeChange);
    onPanelSizeChange();
    panelResizeObserver.observe(panelRef.current);

    return () => {
      panelResizeObserver.unobserve(panelRef.current);
    };
  }, [onPanelResize, panelRef]);
};

export default usePanelResize;
