/**
 * Takes an `onKeyPress` event. If the key pressed is ENTER, it will 
 * blur the event target.
 * @param  {event}    e         Standard html keypress event (`onKeyPress` in react land).
 */
/* eslint-disable-next-line import/prefer-default-export */
export const blurIfEnterKeyPress = (e) => {
  if (e.keyCode === 13 || e.which === 13) {
    e.preventDefault();
    e.currentTarget.blur();
  }
};
