/* eslint-env browser */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Intent } from '@blueprintjs/core';
import {
  darkBackground,
  defaultBackground,
} from './lib/colors.js';
import { WTSFormGroup, WTSInputGroup, WTSButton } from './lib/index.js';
import { getProjectDownload } from '../api/index.js';


const DownloadProjectForm = ({ className }) => {

  const confirmationCodeInputRef = useRef(null)
  const [downloadIntent, setDownloadIntent] = useState(Intent.NONE)
  const [downloadMessage, setDownloadMessage] = useState(null)

  const clearDownloadState = () => {
    setDownloadIntent(Intent.NONE);
    setDownloadMessage(null)
  }

  const onSubmitDownload = async (event) => {
    event.preventDefault()
    const confirmationCode = confirmationCodeInputRef.current.value
    if(!confirmationCode) {
      return
    }

    try {
      const aeImportRes = await getProjectDownload(confirmationCode);
      window.location.assign(aeImportRes.data.url);
      setDownloadIntent(Intent.SUCCESS);
      setDownloadMessage('Project Downloaded')
    } catch (error) {
      setDownloadIntent(Intent.DANGER);
      if (_.get(error, 'response.status') === 404) {
        setDownloadMessage('Invalid confirmation code.')
      } else {
        setDownloadMessage('Something went wrong. Contact us for more detail.')
      }
    }
  };

  return (
    <form
      onSubmit={onSubmitDownload}
      className={className}
      css={{
        padding: 20,
        backgroundColor: darkBackground,
      }}
    >
      <WTSFormGroup
        label="Download project via confirmation code"
        labelFor="downloadProjectCode"
        helperText={downloadMessage}
        intent={downloadIntent}
      >
        <WTSInputGroup
          id="downloadProjectCode"
          type="text"
          placeholder='ex. DNmXXG2kiP'
          css={{
            marginBottom: 5,
            backgroundColor: defaultBackground,
          }}
          inputRef={confirmationCodeInputRef}
          intent={downloadIntent}
          onChange={clearDownloadState}
        />
      </WTSFormGroup>
      <WTSButton
        wtsIntent="primary"
        type="submit"
        fullWidth
      >
        Download
      </WTSButton>
    </form>
  )
}

DownloadProjectForm.propTypes = {
  className: PropTypes.string,
}

DownloadProjectForm.defaultProps = {
  className: '',
}

export default DownloadProjectForm
