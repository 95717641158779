/**
 * Waits for a predicate function to return truthy, with an optional timeout.
 *
 * Note: Multiple callees using the same predicate will be released in an
 * arbitrary order due to the polling implementation used by this method.
 *
 * This code is from stikdev/ivory:web/client/shared/utils/async.js
 *
 * @param {Function} predicate - A function that, when evaluating to a truthy value, will cause the
 *                               Promise to resolve.
 * @param {object} options
 * @param {object} [options.pollingInterval=100] - The interval at which to poll the predicate (in milliseconds).
 * @param {object} [options.pollingInterval=8000] - The timeout at which to reject the Promise if the predicate has yet
 *                                                  to evaluate as true (in milliseconds).
 *
 */
/* eslint-disable-next-line import/prefer-default-export */
export const waitFor = async (predicate, options) => {
  const mergedOptions = Object.assign(
    {
      pollingInterval: 100,
      timeout: 8000,
    },
    options,
  );

  const startTime = new Date();

  const waitingFor = new Promise((resolve, reject) => {
    (function waitForPredicate() {
      // If we've timed out, let's reject
      const timeElapsed = Math.round(new Date() - startTime);
      if (timeElapsed > mergedOptions.timeout) {
        reject();
        return;
      }

      // If our predicate is truthy, let's resolve
      const predicateResult = predicate();
      if (predicateResult) {
        resolve(predicateResult);
        return;
      }

      // Otherwise, let's due it again in `pollingInterval` number of milliseconds
      setTimeout(waitForPredicate, mergedOptions.pollingInterval);
    })();
  });

  return waitingFor;
};

/**
 *
 * @param {Array} array Array
 * @param {*} callback
 */
export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index += 1) {
    await callback(array[index], index, array);
  }
};
