import React from 'react';
import PropTypes from 'prop-types';

/**
 * Error boundary wrapper component that catches component errors
 * Built from React Docs: https://reactjs.org/docs/error-boundaries.html
 *
 * @class      ErrorBoundary (name)
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
}

export default ErrorBoundary