import _ from 'lodash';
import cxs from 'cxs';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { defaultText } from './lib/colors.js';
import { selectors } from '../store/index.js';

const deleteIconStyles = cxs({
  paddingRight: '6px',
  ':hover': {
    cursor: 'pointer',
  },
});

const removableLayerListItemStyles = cxs({
  color: defaultText,
  fontSize: '11px',
  marginTop: '3px',
});

const RemovableLayerListItem = ({ layerUUID, onRemove, displayProperties }) => {
  const layer = useSelector((state) => selectors.getLayerByUUID(state, layerUUID));

  const onRemoveLayer = (propertyToDelete = null) => {
    // This component can pass a specific property to its consuming component, or 
    // can default to taking the only delete action available on the layer.
    onRemove(layerUUID, propertyToDelete);
  };

  if (displayProperties.length) {
    return (
      <>
        {displayProperties.map((property) => (
          <div className={removableLayerListItemStyles} key={property}>
            <Icon
              icon={IconNames.DELETE}
              onClick={() => onRemoveLayer(property)}
              className={deleteIconStyles}
              iconSize={12}
            />
            <span>{_.get(layer, 'name', layerUUID)}: {_.startCase(property)}</span>
          </div>
        ))}
      </>
    )
  }

  return (
    <div className={removableLayerListItemStyles}>
      <Icon
        icon={IconNames.DELETE}
        onClick={() => onRemoveLayer()}
        className={deleteIconStyles}
        iconSize={12}
      />
      <span>{_.get(layer, 'name', layerUUID)}</span>
    </div>
  );
};

RemovableLayerListItem.defaultProps = {
  displayProperties: []
};

RemovableLayerListItem.propTypes = {
  layerUUID: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  displayProperties: PropTypes.arrayOf(PropTypes.string)
};

export default RemovableLayerListItem;
