/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Dialog, Intent } from '@blueprintjs/core';

import { WTSButton } from './lib/index.js';

/**
 * Creates as prompt-for-continue dialog.
 * The user will be required to enter a specific text string into an input field before continuing.
 */
const PromptForContinueDialog = ({
  children,
  continueButtonMessage,
  intent,
  isOpen,
  onClose,
  requiredInputText,
}) => {
  const [isInputVerified, setIsInputVerified] = useState(false);
  const inputRef = useRef(null);

  const onTextChange = () => {
    const content = inputRef.current.value;
    setIsInputVerified(content === requiredInputText);
  };

  const onClickCancel = () => {
    onClose(false);
  };

  const onClickContinue = () => {
    onClose(true);
  };

  return (
    <Dialog
      intent={intent}
      isOpen={isOpen}
    >
      <div
        css={{
          padding: '20px 20px 0',
          width: '100%',
        }}
      >
        {children}
        <div css={{ padding: '20px 0' }}>
          Please type &quot;{requiredInputText}&quot; to continue:<br />
          <input
            css={{ width: '100%' }}
            onChange={onTextChange}
            ref={inputRef}
            type="text"
          />
        </div>
        <div
          css={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <WTSButton type='button' onClick={onClickCancel}>Cancel</WTSButton>
          <WTSButton
            css={{ marginLeft: '20px' }}
            disabled={!isInputVerified}
            onClick={onClickContinue}
            type="button"
            wtsIntent="primary"
          >
            {continueButtonMessage}
          </WTSButton>
        </div>
      </div>
    </Dialog>
  );
};

PromptForContinueDialog.propTypes = {
  children: PropTypes.node,
  continueButtonMessage: PropTypes.string,
  intent: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  requiredInputText: PropTypes.string.isRequired,
};

PromptForContinueDialog.defaultProps = {
  children: (<></>),
  continueButtonMessage: 'Continue',
  isOpen: false,
  intent: Intent.DEFAULT,
};

export default PromptForContinueDialog;
