/* eslint-env browser */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import cxs from 'cxs';
import PropTypes from 'prop-types';
import React from 'react';
import { Box } from 'reflexbox';
import { Menu } from '@blueprintjs/core';

import { makeImportant } from '../utils/styles.js';
import { templateGroups } from '../constants/index.js';
import {
  darkBackground,
  defaultBackground,
  defaultText,
  selectedTextBackground,
  selectedText,
} from './lib/colors.js';
import { SectionTitle, WTSFormGroup, WTSInputGroup } from './lib/index.js';
import DownloadProjectForm from './DownloadProjectForm.js';

const horizontalPanelPadding = '24px';

/* Negative margin enables menu items to stretch the full width of the container,
offsetting the padding on the containing `Box` below. */
const menuStyle = cxs({
  background: makeImportant(defaultBackground),
  backgroundColor: makeImportant(defaultBackground),
  color: makeImportant(defaultText),
  margin: `0 -${horizontalPanelPadding}`,
  minWidth: 0,
});

const menuItemStyle = cxs({
  borderRadius: 0,
  borderBottom: `solid 1px ${darkBackground}`,
  paddingLeft: '18px',
  '&.bp3-active': {
    backgroundColor: makeImportant(selectedTextBackground),
    background: makeImportant(selectedTextBackground),
    color: makeImportant(selectedText),
  },
});

/**
 * Side panel "menu" section of the template selection view. Responsible for the template search form
 * and the menu of template group options.
 * @param  {string}   activeTemplateGroup      Visible template group
 * @param  {function} onChangeSearchQuery       Callback for when the search query gets hit
 * @param  {function} onChangeActiveTemplateGroup  Callback for when template group selection changes
 */
const TemplateSelectionSidePanel = ({
  activeTemplateGroup,
  onChangeSearchQuery,
  onChangeActiveTemplateGroup,
}) => {
  const onClickTemplateGroup = (e) => onChangeActiveTemplateGroup(e.currentTarget.text);
  const onSearchChange = (e) => onChangeSearchQuery(e.currentTarget.value);

  return (
    <section>
      <Box p={`0 ${horizontalPanelPadding}`}>
        <SectionTitle>Templates</SectionTitle>
        <WTSFormGroup label="Search" labelFor="templateSearch">
          <WTSInputGroup id="templateSearch" type="text" onChange={onSearchChange} />
        </WTSFormGroup>
        <Menu className={menuStyle}>
          <Menu.Item
            className={menuItemStyle}
            text={templateGroups.all}
            onClick={onClickTemplateGroup}
            active={activeTemplateGroup === templateGroups.all}
          />
          <Menu.Item
            className={menuItemStyle}
            text={templateGroups.recent}
            onClick={onClickTemplateGroup}
            active={activeTemplateGroup === templateGroups.recent}
          />
          <Menu.Item
            className={menuItemStyle}
            text={templateGroups.unpublished}
            onClick={onClickTemplateGroup}
            active={activeTemplateGroup === templateGroups.unpublished}
          />
          <Menu.Item
            className={menuItemStyle}
            text={templateGroups.published}
            onClick={onClickTemplateGroup}
            active={activeTemplateGroup === templateGroups.published}
          />
        </Menu>

        <DownloadProjectForm
          css={{
            margin: `10px -${horizontalPanelPadding}`,
          }}
        />
      </Box>
    </section>
  );
};

TemplateSelectionSidePanel.propTypes = {
  activeTemplateGroup: PropTypes.string.isRequired,
  onChangeSearchQuery: PropTypes.func.isRequired,
  onChangeActiveTemplateGroup: PropTypes.func.isRequired,
};

export default TemplateSelectionSidePanel;
