/* global API_SERVER */
import axios from 'axios';

/**
 * Create a new user
 * @param  {object} data  New user authentication data
 * @param  {string} data.username   Username for login
 * @param  {string} data.password   Password for login
 */
export const createUser = (data) =>
  axios.post(`${API_SERVER}/api/users/`, {
    ...data,
  });

/**
 * Retrieve user information based on their username.
 * @param  {string} username
 */
export const getUser = (username) => axios.get(`${API_SERVER}/api/users/${username}/`);
