import { Intent } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

import { WTSFormGroup } from './index.js';

const customStyles = {
  control: () => ({
    display: 'flex',
    alignItems: 'left',
    border: 0,
    background: '#ffffff',
    '&:hover': {
      boxShadow: 'none',
    },
    height: '20px',
    borderRadius: '0 3px 3px 0',
    fontSize: '13px',
    lineHeight: '20px',
    minHeight: '20px',
  }),
  menu: () => ({
    backgroundColor: 'white',
    fontSize: '13px',
    lineHeight: '20px',
  }),
  option: (provided) => ({
    ...provided,
    paddingTop: '0px',
    minHeight: '20px',
    height: '20px',
    overflowY: 'hidden',
    fontSize: '13px',
    color: '#252525',
    lineHeight: '20px !important',
  }),
  dropdownIndicator: () => ({
    borderImageSource: 'linear-gradient(to bottom, #4ca2f9, #005cff)',
    borderImageSlice: '1',
    backgroundImage:
      'linear-gradient(to bottom, #6cb3fa, #057eff), linear-gradient(to bottom, #4ca2f9, #005cff)',
    height: '20px',
    borderRadius: '0 3px 3px 0',
    float: 'right',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};

/**
 * Dropdown menu shell component.
 * @param  {string} label         Form field label.
 * @param  {string} labelSubtext  Form field description.
 * @param  {array}  menuItems     Menu items to render.
 * @param  {func}   onItemSelect  Function called after an item is selected.
 * @param  {object} value         Default dropdown selection.
 */
const WTSDropdownMenu = ({ label, labelSubtext, menuItems, onItemSelect, value }) => {
  const onSelectMenuItem = (item) => {
    onItemSelect(item);
  };

  return (
    <WTSFormGroup
      intent={Intent.NONE}
      label={label}
      labelSubtext={labelSubtext}
    >
      <Select
        value={value}
        hideSelectedOptions
        isSearchable={false}
        onChange={onSelectMenuItem}
        options={menuItems}
        styles={customStyles}
      />
    </WTSFormGroup>
  );
};

WTSDropdownMenu.defaultProps = {
  label: '',
  labelSubtext: '',
  menuItems: [],
  value: null,
};

WTSDropdownMenu.propTypes = {
  label: PropTypes.string,
  labelSubtext: PropTypes.string,
  menuItems: PropTypes.arrayOf(PropTypes.object),
  onItemSelect: PropTypes.func.isRequired,
  value: PropTypes.shape({ 
    label: PropTypes.string, 
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object]), 
  }),
};

export default WTSDropdownMenu;
