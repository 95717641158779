/** @jsx jsx */
import { jsx } from '@emotion/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup, Collapse, InputGroup, Intent, Switch } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { actions, selectors } from '../store/index.js';
import { WTSIcon, wtsIconTypes } from './lib/index.js';
import { layerTypes } from '../constants/TemplateManifest.js';
import { defaultBackground, darkBackground, defaultText } from './lib/colors.js';

/**
 * Component that filters layers by type or a common category of layers, e.g.,
 * all text layers or all layers that have overrides applied to them.
 *
 * @class      FilterSwitch
 * @param      {Object}  props            The props
 * @param      {String}  props.layerType  The layer type
 */
const FilterSwitch = ({ layerType }) => {
  const dispatch = useDispatch();
  const layerTypeFilter = useSelector(selectors.getLayerTypeFilter);
  const isChecked = layerTypeFilter.includes(layerType);

  const onSwitch = () => {
    if (isChecked) {
      dispatch(actions.removeLayerTypeFilter({ layerType }));
    } else {
      dispatch(actions.addLayerTypeFilter({ layerType }));
    }
  };

  return (
    <Switch onChange={onSwitch} checked={isChecked}>
      <span css={{ display: 'inline-flex', alignItems: 'center' }}>
        <WTSIcon iconType={wtsIconTypes[layerType]} css={{ marginRight: 5 }} />
        <span>{_.upperFirst(layerType)}</span>
      </span>
    </Switch>
  );
};

FilterSwitch.propTypes = {
  layerType: PropTypes.oneOf(Object.values(layerTypes)).isRequired,
};

/**
 * The component to render the filter and search box at the bottom of the layer list panel
 */
const LayerListFilter = ({ className }) => {
  const dispatch = useDispatch();
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const layerTypeFilter = useSelector(selectors.getLayerTypeFilter);
  const areAllLayerTypesVisible = layerTypeFilter.length === Object.values(layerTypes).length;

  const shouldShowEditedLayers = useSelector(selectors.getShouldShowEditedLayers);
  const shouldShowUneditedLayers = useSelector(selectors.getShouldShowUneditedLayers);

  /**
   * Called when a user searches for a layer by name in the search box
   *
   * @param      {Event}    event         The input change event
   * @param      {Element}  event.target  The target element
   */
  const onSearchLayers = ({ target }) => {
    const { value } = target;
    if (!value) {
      dispatch(actions.clearLayerNameFilter());
    } else {
      dispatch(actions.setLayerNameFilter({ name: value.toLowerCase() }));
    }
  };

  /**
   * Called when the user wants to toggle all layer types on or off
   * If there are any layers types filtered out, toggling this will turn them all on
   */
  const onToggleAllLayersType = () => {
    if (areAllLayerTypesVisible) {
      dispatch(actions.clearAllLayerTypeFilters());
    } else {
      dispatch(actions.setAllLayerTypeFilters());
    }
  };

  const onChangeShowEditedLayers = () => {
    dispatch(actions.setShouldShowEditedLayers(true));
    dispatch(actions.setShouldShowUneditedLayers(false));
  };
  const onChangeShowUneditedLayers = () => {
    dispatch(actions.setShouldShowUneditedLayers(true));
    dispatch(actions.setShouldShowEditedLayers(false));
  };
  const onChangeShowAllEditingStateLayers = () => {
    dispatch(actions.setShouldShowEditedLayers(true));
    dispatch(actions.setShouldShowUneditedLayers(true));
  }
  const onClickToggleFilter = () => {
    setIsFilterPanelOpen(!isFilterPanelOpen);
  };

  // If any of the filters are active, show via a button intent change
  const areFiltersActive =
    !areAllLayerTypesVisible || !shouldShowEditedLayers || !shouldShowUneditedLayers;

  return (
    <div className={className}>
      <Collapse isOpen={isFilterPanelOpen}>
        <div
          css={{
            padding: 10,
            backgroundColor: darkBackground,
            color: defaultText,
          }}
        >
          <div css={{ marginBottom: 4 }}>Layer Types:</div>
          <Switch
            checked={areAllLayerTypesVisible}
            label="Show All Layer Types"
            onChange={onToggleAllLayersType}
          />
          <FilterSwitch layerType={layerTypes.text} />
          <FilterSwitch layerType={layerTypes.image} />
          <FilterSwitch layerType={layerTypes.preComp} />
          <FilterSwitch layerType={layerTypes.shape} />
          <FilterSwitch layerType={layerTypes.solid} />
          <FilterSwitch layerType={layerTypes.waymarkVideo} />
          <hr />
          <ButtonGroup
            fill
          >
            <Button
              text='All'
              active={shouldShowEditedLayers && shouldShowUneditedLayers}
              intent={Intent.PRIMARY}
              onClick={onChangeShowAllEditingStateLayers}
              small
            />
            <Button
              text='Edited'
              active={shouldShowEditedLayers && !shouldShowUneditedLayers}
              intent={Intent.PRIMARY}
              onClick={onChangeShowEditedLayers}
              small
            />
            <Button
              text='Unedited'
              active={shouldShowUneditedLayers && !shouldShowEditedLayers}
              intent={Intent.PRIMARY}
              onClick={onChangeShowUneditedLayers}
              small
            />
          </ButtonGroup>
        </div>
      </Collapse>
      <div
        css={{
          display: 'flex',
          padding: 4,
          backgroundColor: defaultBackground,
        }}
      >
        <InputGroup
          type="search"
          leftIcon={IconNames.SEARCH}
          placeholder="Search Layers"
          css={{ marginRight: 4, flex: 'auto' }}
          onChange={onSearchLayers}
          small
        />
        <Button
          icon={IconNames.FILTER}
          text="Filter"
          onClick={onClickToggleFilter}
          intent={areFiltersActive ? Intent.WARNING : Intent.NONE}
          small
        />
      </div>
    </div>
  );
};

LayerListFilter.propTypes = {
  className: PropTypes.string,
};

LayerListFilter.defaultProps = {
  className: '',
};

export default LayerListFilter;
