import CompositionPanel from './CompositionPanel.js';
import store, { selectors } from '../store/index.js';


/**
 * Hook for handling the opening of new composition panels
 *
 * @param      {function}  openPanel      The BlueprintJS api function for opening new panelstack items
 * @param      {function}  onPanelResize  The panel resize callback that we pass down to all subsequent panels
 * @return     {Function}  openComposition    A callback function for opening a new panel
 */
const useOpenCompositionPanel = (openPanel, onPanelResize) => {
  /**
   * Called when a composition is selected for a detail view
   *
   * @param      {String}  compositionId  The composition identifier
   */
  const openComposition = (compositionId) => {
    const compositions = selectors.getFilteredCompositions(store.getState());
    const selectedComposition = compositions.find(({ id }) => id === compositionId);

    // Call the BlueprintJS api to generate a new panel for this composition
    openPanel({
      component: CompositionPanel,
      title: selectedComposition.name,
      props: {
        openPanel,
        onPanelResize,
        panelCompositionId: compositionId,
      },
    });
  };

  return openComposition
}

export default useOpenCompositionPanel