/* global API_SERVER */
/* eslint-env browser */
import axios from 'axios';

import { waymarkAuthKey } from './constants.js';

/**
 * Create access token for a user based on the provided authentication credentials
 * @param  {object} data            User's authentication data
 * @param  {string} data.username   Username for login
 * @param  {string} data.password   Password for login
 */
// eslint-disable-next-line import/prefer-default-export
export const fetchAccessToken = async (data) => {
  try {
    const response = await axios.post(`${API_SERVER}/api/access-tokens/`, {
      ...data,
    });

    const accessToken = response.data.token;

    // Set the access token in the Authorization header.
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // Store the access token in local storage.
    localStorage.setItem(
      waymarkAuthKey,
      JSON.stringify({
        accessToken,
        username: data.username,
      }),
    );

    return accessToken;
  } catch (e) {
    throw e;
  }
};
