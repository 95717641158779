/* eslint-env browser */
import { write as clipboardyWrite } from 'clipboardy';
import _ from 'lodash';
import cxs from 'cxs';
import { Icon, Popover, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import ContentOverrideSelect from './ContentOverrideSelect.js';
import DynamicLayerColorOverrideMenu from './DynamicLayerColorOverrideMenu.js'
import DynamicPropertyUpdateProvider from './contextProviders/DynamicPropertyUpdateProvider.js';
import DynamicLayerContentForm from './DynamicLayerContentForm.js';
import DynamicSettingsPanelContent from './DynamicSettingsPanelContent.js';
import GroupSelect from './GroupSelect.js';
import { layerTypes } from '../constants/TemplateManifest.js';
import { darkBackground, defaultBackground, defaultText, lightButtonText } from './lib/colors.js';
import { selectors } from '../store/index.js';
import { WTSIcon, VerticallyAndHorizontallyCenter } from './lib/index.js';


const layerMetaStyles = cxs({
  fontSize: '12px',
  color: defaultText,
});

const layerIconStyles = cxs({
  display: 'inline-block',
  verticalAlign: 'bottom',
  paddingRight: '6px',
});

const popoverStyles = cxs({
  padding: '5px'
})

const PopoverContent = () => (<span className={popoverStyles}>UUID copied to clipboard.</span>);

const LayerDisplay = ({layerUUID}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const layer = useSelector((state) => selectors.getLayerByUUID(state, layerUUID));

  const copyUUIDToClipboard = async () => {
    setIsPopoverOpen(true);
    await clipboardyWrite(layerUUID);
    setTimeout(() => setIsPopoverOpen(false), 1000);
  }

  return (
    <div className={layerMetaStyles}>
      <WTSIcon iconType={layer.type} className={layerIconStyles} />
      <span>
        {layer.name}
        <Popover
          className={popoverStyles}
          isOpen={isPopoverOpen}
          position={Position.TOP}
        >
          <Icon
            icon={IconNames.CLIPBOARD}
            onClick={copyUUIDToClipboard}
            iconSize={12}
          />
          <PopoverContent />
        </Popover>
      </span>
    </div>
  )
};

LayerDisplay.propTypes = {
  layerUUID: PropTypes.string.isRequired,
};

const wrapperStyle = cxs({
  padding: '6px 13px',
  backgroundColor: defaultBackground,
  border: `solid 1px ${darkBackground}`,
});

const titleStyles = cxs({
  fontSize: '11px',
  color: '#888888',
  textAlign: 'center',
});

const SelectedLayersTitle = ({ selectedLayers }) => (
  <div className={wrapperStyle}>
    <div className={titleStyles}>{`${selectedLayers.length} layers selected`}</div>
    {selectedLayers.map((layerUUID) => (
      <LayerDisplay layerUUID={layerUUID} key={layerUUID} />
      ))}
  </div>
);

SelectedLayersTitle.propTypes = {
  selectedLayers: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const selectedCompositionMessage = cxs({
  fontSize: '18px',
  textAlign: 'center',
  color: lightButtonText,
  padding: '0 20px',
  lineHeight: '1.33',
});

const internalPanelStyles = cxs({
  overflowY: 'scroll',
  maxHeight: '100%',
  paddingBottom: '32px',
});

/**
 * Renders a message when an uneditable layer type is selected.
 * @param  {string}   message   Message to display to user.
 */
const UneditableSelectionMessage = ({ message }) => (
  <VerticallyAndHorizontallyCenter>
    <div className={selectedCompositionMessage}>{message}</div>
  </VerticallyAndHorizontallyCenter>
);

UneditableSelectionMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

/**
 * Managing component for the right panel. This component will render
 * content and forms based on the selected layer(s) or menu items.
 */
const DynamicLayerPanel = () => {
  const selectedLayers = useSelector(selectors.getSelectedLayers);
  const selectedLayerTypes = useSelector(selectors.getSelectedLayerTypes);

  const selectedTemplateSetting = useSelector(selectors.getSelectedTemplateSetting);

  const contentEligibleTypes = [layerTypes.image, layerTypes.text, layerTypes.waymarkVideo];
  const shouldRenderContentForm =
    selectedLayers.length === 1 && _.includes(contentEligibleTypes, selectedLayerTypes[0]);

  const shouldRenderContentOverride = selectedLayerTypes.reduce(
    (accum, type) => accum && contentEligibleTypes.includes(type),
    true,
  );

  return (
    <div className={internalPanelStyles}>
      {selectedTemplateSetting ? (
        <DynamicSettingsPanelContent />
      ) : null}
      {selectedLayers.length ? (
        <>
          <SelectedLayersTitle selectedLayers={selectedLayers} />
          <DynamicPropertyUpdateProvider>
            {shouldRenderContentOverride && <ContentOverrideSelect overrideType={selectedLayerTypes[0]} />}
            {shouldRenderContentForm && (
              <DynamicLayerContentForm layerUUID={selectedLayers[0]} />
            )}
            <DynamicLayerColorOverrideMenu />
            <GroupSelect />
          </DynamicPropertyUpdateProvider>
        </>
      ) : null}
    </div>
  )
};

export default DynamicLayerPanel;
