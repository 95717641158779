/* global API_SERVER */
import axios from 'axios';
import _ from 'lodash';

/**
 * Retrieve user information based on their username.
 */
export const fetchTemplates = () => axios.get(`${API_SERVER}/api/video-templates/`);

/**
 * Create a new video template
 */
export const createTemplate =
  (payload) => axios.post(`${API_SERVER}/api/video-templates/`, payload);

/**
 * Fetch video template by ID
 * @param  {string} templateId  Public id of video template.
 */
export const fetchTemplate = (templateId) =>
  axios.get(`${API_SERVER}/api/video-templates/${templateId}/`);

/**
 * Update a template
 * @param  {string} templateId  Public id of video template.
 * @param  {Object} payload     PATCH payload for the template
 */
export const updateTemplate = (templateId, payload) =>
  axios.patch(`${API_SERVER}/api/video-templates/${templateId}/`, payload);

/**
 * Get or create an unpublished template version to work on in the workspace.
 * @param  {string} templateId  Public id of video template.
 */
export const getOrCreateUnpublishedVersion = (templateId) =>
  axios.put(`${API_SERVER}/api/video-templates/${templateId}/unpublished/`);

/**
 * Fetch the version for the provided template id and version number ('v2')
 * @param  {string} templateId
 * @param  {string} versionNumber
 */
export const fetchVersion = (templateId, versionNumber, additionalFields = []) =>
  axios.get(
    `${API_SERVER}/api/video-templates/${templateId}/${versionNumber}/?additional_fields=${additionalFields.join(
      ',',
    )}`,
  );

/**
 * Update a specific template version with the provided data
 * @param  {string} templateId  Public id of video template.
 * @param  {string} version     Template version numver (e.g., 'v3').
 * @param  {Object} body        Request body with the version field data to be updated.
 */
export const updateVersion = async (templateId, version, body, additionalFields = []) => {
  let url = `${API_SERVER}/api/video-templates/${templateId}/${version}/`;

  if (additionalFields.length) {
    url += `?additional_fields=${additionalFields.join(',')}`;
  }

  try {
    const response = await axios.patch(url, body);
    return response
  } catch (error) {
    // Check if the error was a size limit, and note it in the console for easier debugging
    if (_.get(error, 'response.status', 413)) {
      console.error('Patch size is too large, increase json body limit.')
    }

    // Reraise the error
    throw (error)
  }
}
