/**
 * Editing action constants. Used to generate valid editing actions
 * that power the Waymark Author Renderer.
 */
export const editingActionTypes = {
  text: {
    content: 'TEXT_CONTENT',
    fillColor: 'TEXT_FILL_COLOR',
    strokeColor: 'TEXT_STROKE_COLOR',
    fillEffect: 'EFFECT_FILL_COLOR',
    font: 'FONT_PROPERTY',
  },
  image: {
    content: 'LAYER_IMAGE',
    fillEffect: 'EFFECT_FILL_COLOR',
  },
  solid: {
    fillColor: 'SOLID_FILL_COLOR',
    fillEffect: 'EFFECT_FILL_COLOR',
  },
  shape: {
    fillColor: 'SHAPE_FILL_COLOR',
    fillEffect: 'EFFECT_FILL_COLOR',
    gradientFill: 'SHAPE_GRADIENT_FILL_COLOR_STEPS',
    strokeColor: 'SHAPE_STROKE_COLOR',
  },
  preComp: {
    fillEffect: 'EFFECT_FILL_COLOR',
  },
  displayObjectVisibility: 'DISPLAY_OBJECT_VISIBILITY',
  audioResource: 'WAYMARK_AUDIO_ASSET',
  layerAudio: 'LAYER_AUDIO',
  // This was previously mistakenly VIDEO_LAYER_RESOURCE and not WAYMARK_VIDEO_ASSET
  waymarkVideo: {
    content: 'LAYER_VIDEO',
    fillEffect: 'EFFECT_FILL_COLOR',
  },
};

/**
 * Operations for each case of a switch action set. These
 * operations are used to determine whether the actions of
 * each case will be executed. Currently only an "equals"
 * operation is supported.
 */
const caseOperations = {
  // Execute case actions if event value equals case value
  equals: 'equals',
};

const valueOperations = {
  // Pass the event value into the action value. This is used
  // for actions that require a user-defined value, such as a
  // text form field input.
  passthrough: 'passthrough',
  // Set the action value to the specified payload. This is
  // used for actions with fixed values, such as font colors
  // controlled by themes.
  setExplicit: 'setExplicit',
};

const explicitEditingAction = {
  type: '',
  targets: [],
  value: {
    operation: valueOperations.setExplicit,
    payload: '',
  },
};

const passthroughEditingAction = {
  type: '',
  targets: [],
  value: {
    operation: valueOperations.passthrough,
  },
};

export const switchEditingEvent = {
  path: '',
  actions: {
    type: 'switch',
    switch: [],
  },
};

export const switchCaseOperation = {
  case: '',
  operation: caseOperations.equals,
  actions: [],
};

export const editingActionTemplates = {
  explicit: explicitEditingAction,
  passthrough: passthroughEditingAction,
};
