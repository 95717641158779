import React from 'react';
import PropTypes from 'prop-types';

/**
 * A plain play icon
 * Used in the renderer control bar when the video is paused
 *
 * @class      PlayIcon (name)
 * @param      {Object}  props       The props
 * @param      {string}  props.fill  The fill color
 */
export const PlayIcon = ({ fill }) => (
  <svg viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg" alt="Play">
    <title>Play</title>
    <g stroke="none" fill={fill}>
      <path d="M6,5.00087166 C6,4.4481055 6.389052,4.22231543 6.8757627,4.50043583 L18.6954859,11.2545633 C19.4159491,11.6662566 19.4162615,12.3335649 18.6954859,12.7454367 L6.8757627,19.4995642 C6.39209232,19.7759472 6,19.5553691 6,18.9991283 L6,5.00087166 Z" />
    </g>
  </svg>
);

PlayIcon.propTypes = {
  fill: PropTypes.string,
};

PlayIcon.defaultProps = {
  fill: '#FFFFFF',
};

/**
 * A plain pause icon
 * Used in the renderer control bar when the video is playing
 *
 * @class      PauseIcon (name)
 * @param      {Object}  props       The props
 * @param      {string}  props.fill  The fill color
 */
export const PauseIcon = ({ fill }) => (
  <svg viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg" alt="Pause">
    <title>Pause</title>
    <g stroke="none" fill={fill}>
      <rect x="6" y="4" width="4" height="16" rx="1" />
      <rect x="14" y="4" width="4" height="16" rx="1" />
    </g>
  </svg>
);

PauseIcon.propTypes = {
  fill: PropTypes.string,
};

PauseIcon.defaultProps = {
  fill: '#FFFFFF',
};

/**
 * A plain restart icon
 * Used in the renderer control bar
 *
 * @class      RestartIcon (name)
 * @param      {Object}  props       The props
 * @param      {string}  props.stroke  The stroke color
 */
export const RestartIcon = ({ stroke }) => (
  <svg viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <title>Restart</title>
    <g stroke={stroke} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" fill="none">
      <path
        transform="translate(12.500000, 13.309524) scale(1, -1) translate(-12.500000, -13.309524) "
        d="M11,20 L12.5,20 C16.0898509,20 19,17.0045718 19,13.3095238 C19,9.61447584 16.0898509,6.61904762 12.5,6.61904762 C8.91014913,6.61904762 6,9.61447584 6,13.3095238"
      />
      <polyline
        transform="translate(10.397059, 6.619048) scale(1, -1) translate(-10.397059, -6.619048) "
        points="11.9411765 3.45238095 8.85294118 6.61904762 11.9411765 9.78571429"
      />
    </g>
  </svg>
);

RestartIcon.propTypes = {
  stroke: PropTypes.string,
};

RestartIcon.defaultProps = {
  stroke: '#FFFFFF',
};

/**
 * Used in the renderer control bar when video is in full screen
 *
 * @class      ExitFullscreenIcon (name)
 * @param      {Object}  props       The props
 * @param      {string}  props.stroke  The stroke color
 */
export const ExitFullscreenIcon = ({ stroke }) => (
  <svg viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <title>Exit Fullscreen</title>
    <g stroke={stroke} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" fill="none">
      <polyline
        transform="translate(6.000000, 6.000000) scale(-1, -1) translate(-6.000000, -6.000000) "
        points="4 8 4 4 8 4"
      />
      <polyline
        transform="translate(18.000000, 6.000000) scale(-1, -1) translate(-18.000000, -6.000000) "
        points="16 4 20 4 20 8"
      />
      <polyline
        transform="translate(6.000000, 18.000000) scale(-1, -1) translate(-6.000000, -18.000000) "
        points="8 20 4 20 4 16"
      />
      <polyline
        transform="translate(18.000000, 18.000000) scale(-1, -1) translate(-18.000000, -18.000000) "
        points="20 16 20 20 16 20"
      />
    </g>
  </svg>
);
ExitFullscreenIcon.propTypes = {
  stroke: PropTypes.string,
};

ExitFullscreenIcon.defaultProps = {
  stroke: '#FFFFFF',
};

/**
 * Used the renderer control bar when video is in normal view
 *
 * @class      EnterFullscreenIcon (name)
 * @param      {Object}  props       The props
 * @param      {string}  props.stroke  The stroke color
 */
export const EnterFullscreenIcon = ({ stroke }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <title>Enter Fullscreen</title>
    <g stroke={stroke} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" fill="none">
      <polyline points="4 8 4 4 8 4" />
      <polyline points="16 4 20 4 20 8" />
      <polyline points="8 20 4 20 4 16" />
      <polyline points="20 16 20 20 16 20" />
    </g>
  </svg>
);
EnterFullscreenIcon.propTypes = {
  stroke: PropTypes.string,
};

EnterFullscreenIcon.defaultProps = {
  stroke: '#FFFFFF',
};

// Used in desktop video player's control bar when video is muted
export const UnmuteIcon = ({ color }) => (
  <svg viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <title>Unmute Audio</title>
    <g stroke="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none">
      <path
        d="M16.9775872,19.4994052 C19.402129,17.8869397 21,15.1300712 21,12 C21,8.87770643 19.41006,6.12676325 16.9956455,4.51263603"
        stroke={color}
      />
      <path
        d="M14.3183914,15.9996035 C15.5239076,15.1398117 16.3183914,13.6698063 16.3183914,12.0008035 C16.3183914,10.3359477 15.527851,8.86910187 14.3273703,8.00842402"
        stroke={color}
      />
      <path
        d="M5.18079844,15 L3,15 C2.44771525,15 2,14.5522847 2,14 L2,10 C2,9.44771525 2.44771525,9 3,9 L5.18086412,9 L9.61951407,5.56573228 C10.2655067,5.06591549 10.8034874,5.31698502 10.8034874,6.14016022 L10.8034874,17.8599643 C10.8034874,18.6805924 10.2734045,18.9403127 9.61951407,18.4343767 L5.18079844,15 Z"
        fill={color}
      />
    </g>
  </svg>
);

UnmuteIcon.propTypes = {
  color: PropTypes.string,
};

UnmuteIcon.defaultProps = {
  color: '#FFFFFF',
};

// Used in desktop video player's control bar when video is unmuted
export const MuteIcon = ({ color }) => (
  <svg viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <title>Mute Audio</title>
    <g stroke="none" fill="none">
      <path
        d="M6,10.7935945 L14.8034874,17.1070796 L14.8034874,17.8599643 C14.8034874,18.6805924 14.2734045,18.9403127 13.6195141,18.4343767 L9.18079844,15 L7,15 C6.44771525,15 6,14.5522847 6,14 L6,10.7935945 Z M10.5747424,7.92152991 L13.6195141,5.56573228 C14.2655067,5.06591549 14.8034874,5.31698502 14.8034874,6.14016022 L14.8034874,10.9542052 L10.5747424,7.92152991 Z"
        fill={color}
      />
      <path
        d="M5,7 L19.1421356,17.1421356"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

MuteIcon.propTypes = {
  color: PropTypes.string,
};

MuteIcon.defaultProps = {
  color: '#FFFFFF',
};
