export const GUID_REGEX_PATTERN = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}/;

export const VideoEditingFieldTypes = {
  audio: 'audio',
  color: 'color',
  font: 'font',
  image: 'image',
  layoutSelector: 'layout_selector',
  logo: 'logo',
  text: 'text',
  textSelector: 'text_selector',
  theme: 'theme',
  footageComposition: 'footage_composition',
};

export const formDescriptionSchema = {
  definitions: {
    globalProperties: {
      type: 'object',
      properties: {
        editingFieldKey: {
          oneOf: [
            {
              type: 'string',
              // regex pattern matching in JSON schema needs a string, so get the
              // source string of the regex pattern (a string without the leading/trailing "/")
              pattern: GUID_REGEX_PATTERN.source,
            },
            { const: 'backgroundAudio' },
          ],
        },
        displayTime: {
          oneOf: [
            {
              type: 'number',
              minimum: 0,
              // "Welcome to the machine" is 37 second long
              // Until this is retired the maximum needs to be at least 37.
              maximum: 40,
            },
            { type: 'null' },
          ],
        },
        // The label isn't currently being displayed to the user in the Editor,
        // but when it is, I can imagine having extra validation on this property
        label: { type: 'string' },
      },
      required: ['editingFieldKey', 'displayTime', 'label'],
    },
    paths: {
      type: 'array',
      minItems: 1,
      items: { type: 'string' },
    },
    characterLimit: {
      oneOf: [
        {
          type: 'number',
          minimum: 1,
        },
        { type: 'null' },
      ],
    },
    location: {
      oneOf: [
        {
          type: 'object',
          properties: {
            plugin: {
              type: 'string',
            },
            id: {
              type: 'string',
            },
          },
          required: ['plugin', 'id'],
          additionalProperties: false,
        },
        {
          type: 'object',
          properties: {
            plugin: {
              type: 'string',
            },
            type: {
              type: 'string',
            },
            key: {
              type: 'string',
            },
          },
          required: ['plugin', 'type', 'key'],
          additionalProperties: false,
        },
      ],
    },
    selectOptions: {
      type: 'array',
      // SS TODO: This is temporary! MinItems should be 2, not 1, but we removed some unused
      // audio tracks so a template temporarily has only one audio option, but will have more
      // very soon.
      minItems: 1,
      items: {
        type: 'object',
        properties: {
          label: { type: 'string' },
          configurationValue: {
            oneOf: [
              { type: 'string' },
              {
                type: 'object',
                properties: {
                  type: {
                    type: 'string',
                  },
                  location: { $ref: '#/definitions/location' },
                },
                required: ['type', 'location'],
              },
            ],
          },
        },
        required: ['label', 'configurationValue'],
      },
    },
    textEditingField: {
      allOf: [
        { $ref: '#/definitions/globalProperties' },
        {
          properties: {
            type: { const: VideoEditingFieldTypes.text },
            characterLimit: { $ref: '#/definitions/characterLimit' },
            paths: { $ref: '#/definitions/paths' },
            splitDirection: {
              type: 'string',
              enum: ['left', 'right'],
            },
          },
          required: ['type', 'paths', 'characterLimit'],
        },
      ],
    },
    textSelectorEditingField: {
      allOf: [
        { $ref: '#/definitions/globalProperties' },
        {
          properties: {
            type: { const: VideoEditingFieldTypes.textSelector },
            paths: { $ref: '#/definitions/paths' },
            selectOptions: { $ref: '#/definitions/selectOptions' },
            splitDirection: {
              type: 'string',
              enum: ['left', 'right'],
            },
          },
          required: ['type', 'paths', 'selectOptions'],
        },
      ],
    },
    imageEditingField: {
      allOf: [
        { $ref: '#/definitions/globalProperties' },
        {
          properties: {
            type: { const: VideoEditingFieldTypes.image },
            paths: { $ref: '#/definitions/paths' },
            aspectRatio: {
              oneOf: [{ type: 'number' }, { type: 'null' }],
            },
            width: {
              oneOf: [{ type: 'number' }, { type: 'null' }],
            },
            height: {
              oneOf: [{ type: 'number' }, { type: 'null' }],
            },
          },
          required: ['type', 'paths'],
        },
      ],
    },
    audioEditingField: {
      allOf: [
        { $ref: '#/definitions/globalProperties' },
        {
          properties: {
            type: { const: VideoEditingFieldTypes.audio },
            paths: { $ref: '#/definitions/paths' },
            selectOptions: { $ref: '#/definitions/selectOptions' },
          },
          required: ['type', 'paths', 'selectOptions'],
        },
      ],
    },
    colorEditingField: {
      allOf: [
        { $ref: '#/definitions/globalProperties' },
        {
          properties: {
            type: { const: VideoEditingFieldTypes.color },
            paths: { $ref: '#/definitions/paths' },
          },
          required: ['type', 'paths'],
        },
      ],
    },
    fontEditingField: {
      allOf: [
        { $ref: '#/definitions/globalProperties' },
        {
          properties: {
            type: { const: VideoEditingFieldTypes.font },
            fontOverrides: {
              type: 'object',
              patternProperties: {
                '^(fontOverride--)[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}': {
                  originalTypography: {
                    type: 'object',
                    properties: {
                      fontFamily: { type: 'string' },
                      fontSizeAdjustment: { type: 'number' },
                      fontStyle: { type: 'string' },
                      fontWeight: { type: 'number' },
                    },
                    required: ['fontFamily', 'fontSizeAdjustment', 'fontStyle', 'fontWeight'],
                  },
                  paths: { $ref: '#/definitions/paths' },
                },
              },
              additionalProperties: false,
            },
            respectedPathMappings: {
              type: 'object',
              description:
                "Respected path mappings defines how many font overrides should be respected for a template, and which additional font overrides should respect their selected value. This field allows us to have as many font overrides as we want defined in a template's configuration while still controlling how many font options are exposed to the user via the editor.",
              patternProperties: {
                '^(fontOverride--)[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}': {
                  type: 'array',
                  items: { type: 'string' },
                },
              },
              additionalProperties: false,
            },
          },
        },
      ],
      required: ['type', 'fontOverrides', 'respectedPathMappings'],
    },
    layoutSelectorEditingField: {
      allOf: [
        { $ref: '#/definitions/globalProperties' },
        {
          properties: {
            type: { const: VideoEditingFieldTypes.layoutSelector },
            paths: { $ref: '#/definitions/paths' },
            selectOptions: {
              type: 'array',
              minItems: 2,
              items: {
                type: 'object',
                properties: {
                  configurationValue: { oneOf: [{ type: 'string' }, { type: 'boolean' }] },
                  label: { type: 'string' },
                  contentFields: {
                    type: 'array',
                    items: {
                      // This means that the array must contain at least
                      // one of the following values, but what if it"s
                      // empty? I.e., one layout option has a combo of
                      // text/image/logo/etc., but the alternate option
                      // is nothing?
                      anyOf: [
                        { $ref: '#/definitions/textEditingField' },
                        { $ref: '#/definitions/textSelectorEditingField' },
                        { $ref: '#/definitions/imageEditingField' },
                      ],
                    },
                  },
                },
              },
            },
          },
          required: ['type', 'paths', 'selectOptions'],
        },
      ],
    },
    footageCompositionSelectorEditingField: {
      allOf: [
        { $ref: '#/definitions/globalProperties' },
        {
          properties: {
            type: { const: VideoEditingFieldTypes.footageComposition },
            paths: { $ref: '#/definitions/paths' },
            selectOptions: { $ref: '#/definitions/selectOptions' },
          },
          required: ['type', 'paths', 'selectOptions'],
        },
      ],
    },
    videoEditingField: {
      allOf: [
        { $ref: '#/definitions/globalProperties' },
        {
          properties: {
            type: { const: VideoEditingFieldTypes.video },
            paths: { $ref: '#/definitions/paths' },
            inPoint: { type: 'number' },
            outPoint: { type: 'number' },
          },
          required: ['type', 'paths', 'inPoint', 'outPoint'],
        },
      ],
    },
  },

  type: 'object',
  properties: {
    editingFormFields: {
      type: 'array',
      minItems: 1,
      items: {
        anyOf: [
          { $ref: '#/definitions/audioEditingField' },
          { $ref: '#/definitions/colorEditingField' },
          { $ref: '#/definitions/fontEditingField' },
          { $ref: '#/definitions/imageEditingField' },
          { $ref: '#/definitions/layoutSelectorEditingField' },
          { $ref: '#/definitions/textEditingField' },
          { $ref: '#/definitions/textSelectorEditingField' },
          { $ref: '#/definitions/footageCompositionSelectorEditingField' },
          { $ref: '#/definitions/videoEditingField' },
        ],
      },
    },
  },
  required: ['editingFormFields'],
};


/**
 * Types of actions that can be handled by the configuration
 * intepreter. Right now each action type matches its
 * WaymarkAuthorWebRenderer applyChange equivalent, but that
 * is not an architecturally enforced constraint.
 */
export const actionTypes = {
  waymarkAudioAsset: 'WAYMARK_AUDIO_ASSET',
  displayObjectVisibility: 'DISPLAY_OBJECT_VISIBILITY',
  shapeFillColor: 'SHAPE_FILL_COLOR',
  shapeGradientFillColor: 'SHAPE_GRADIENT_FILL_COLOR_STEPS',
  solidFillColor: 'SOLID_FILL_COLOR',
  effectFillColor: 'EFFECT_FILL_COLOR',
  shapeStrokeColor: 'SHAPE_STROKE_COLOR',
  imageAsset: 'IMAGE_ASSET',
  imagePath: 'IMAGE_PATH',
  textContent: 'TEXT_CONTENT',
  textFillColor: 'TEXT_FILL_COLOR',
  textStrokeColor: 'TEXT_STROKE_COLOR',
  textFontProperties: 'FONT_PROPERTY',
  // This is used in footage compositions, i.e. themes
  // This was previously mistakenly VIDEO_LAYER_RESOURCE and not WAYMARK_VIDEO_ASSET
  videoLayerResource: 'WAYMARK_VIDEO_ASSET',
  // This is used in layer videos, i.e. inline-videos
  videoLayerProperties: 'LAYER_VIDEO',
  audioLayerProperties: 'LAYER_AUDIO',
  imageLayerProperties: 'LAYER_IMAGE',
};


/**
 * Types of action object sets. An action set can be an array
 * or an object, where objects define rules of execution for
 * each member of the set. Currently only a "switch" object
 * type is supported.
 */
export const actionSetTypes = {
  // Match action sets using a case operation.
  switch: 'switch',
};

/**
 * Operations for each case of a switch action set. These
 * operations are used to determine whether the actions of
 * each case will be executed. Currently only an "equals"
 * operation is supported.
 */
export const caseOperations = {
  // Execute case actions if event value equals case value
  equals: 'equals',
};

/**
 * Operations for each action value.
 */
export const valueOperations = {
  // Set the action value to the specified payload. This is
  // used for actions with fixed values, such as font colors
  // controlled by themes.
  setExplicit: 'setExplicit',
  // Pass the event value into the action value. This is used
  // for actions that require a user-defined value, such as a
  // text form field input.
  passthrough: 'passthrough',
};


/**
 * volumeChange types for LAYER_AUDIO and LAYER_VIDEO edit actions.
 */
export const layerVolumeChangeTypes = {
  targetDucking: 'targetDucking',
};

/**
 * Configuration interpreter schema.
 */
export const configurationInterpreterSchema = {
  title: 'Configuration Interpreter',
  type: 'object',
  definitions: {
    valueSetExplicitOperation: {
      properties: {
        operation: { const: valueOperations.setExplicit },
        payload: {},
      },
      required: ['operation', 'payload'],
    },
    valuePassthroughOperation: {
      properties: {
        operation: { const: valueOperations.passthrough },
      },
      required: ['operation'],
    },
    assetLocation: {
      type: 'object',
      properties: {
        plugin: { type: 'string' },
        id: { type: 'string' },
      },
    },
    assetData: {
      properties: {
        payload: {
          type: 'object',
          properties: {
            // 'u' and 'p' properties will be deprecated eventually,
            // because file+URL paths can now be handled with plugins.
            // TODO: Split this into two separate schemas or remove
            // the u/p properties.
            type: { type: 'string' },
            id: { type: 'string' },
            u: { type: 'string' },
            p: { type: 'string' },
            e: { type: 'number' },
            w: { type: 'number' },
            h: { type: 'number' },
            location: { $ref: '#/definitions/assetLocation' },
            modifications: {
              type: 'object',
            },
          },
          required: ['id', 'location', 'type'],
        },
      },
    },
    targetDuckingVolumeChange: {
      properties: {
        type: { const: layerVolumeChangeTypes.targetDucking },
        duckingTarget: { type: 'string' },
        targetVolume: { type: 'string' },
      },
      required: ['type', 'duckingTarget', 'targetVolume'],
    },
    layerAudioData: {
      properties: {
        payload: {
          type: 'object',
          properties: {
            layer: { type: 'string' },
            isMuted: { type: 'boolean' },
            volume: { type: 'number' },
            content: {
              type: 'object',
              properties: {
                type: { type: 'string' },
                key: { type: 'string' },
                location: { $ref: '#/definitions/assetLocation' },
              },
            },
            volumeChanges: {
              type: 'array',
              items: {
                anyOf: [{ $ref: '#/definitions/targetDuckingVolumeChange' }],
              },
            },
          },
          required: ['layer'],
        },
      },
    },
    layerVideoData: {
      properties: {
        payload: {
          type: 'object',
          properties: {
            layer: { type: 'string' },
            isMuted: { type: 'boolean' },
            volume: { type: 'number' },
            content: {
              type: 'object',
              properties: {
                type: { type: 'string' },
                key: { type: 'string' },
                location: { $ref: '#/definitions/assetLocation' },
              },
            },
            contentTrimStartTime: { type: 'number' },
            contentTrimDuration: { type: 'number' },
            contentPlaybackDuration: { type: 'number' },
            volumeChanges: {
              type: 'array',
              items: {
                anyOf: [{ $ref: '#/definitions/targetDuckingVolumeChange' }],
              },
            },
          },
          required: ['layer'],
        },
      },
    },
    layerImageData: {
      properties: {
        payload: {
          type: 'object',
          properties: {
            layer: { type: 'string' },
            content: {
              type: 'object',
              properties: {
                type: { type: 'string' },
                key: { type: 'string' },
                location: { $ref: '#/definitions/assetLocation' },
              },
            },
            fitFillAlignment: { type: 'string' },
          },
          required: ['layer'],
        },
      },
    },
    value: {
      anyOf: [
        { $ref: '#/definitions/valueSetExplicitOperation' },
        { $ref: '#/definitions/valuePassthroughOperation' },
      ],
    },
    waymarkAudioAssetEditAction: {
      properties: {
        type: { const: actionTypes.waymarkAudioAsset },
        value: {
          allOf: [{ $ref: '#/definitions/value' }, { $ref: '#/definitions/assetData' }],
        },
      },
      required: ['type', 'value'],
    },
    displayObjectVisibility: {
      properties: {
        type: { const: actionTypes.displayObjectVisibility },
        value: {
          allOf: [
            { $ref: '#/definitions/value' },
            {
              properties: {
                payload: { type: 'boolean' },
              },
            },
          ],
        },
      },
      required: ['type', 'value'],
    },
    shapeFillColorEditAction: {
      properties: {
        type: { const: actionTypes.shapeFillColor },
        targets: {
          type: 'array',
          items: { type: 'string' },
        },
        value: {
          allOf: [
            { $ref: '#/definitions/value' },
            {
              properties: {
                payload: { type: 'string' },
              },
            },
          ],
        },
      },
      required: ['type', 'targets', 'value'],
    },
    shapeGradientFillColorEditAction: {
      properties: {
        type: { const: actionTypes.shapeGradientFillColor },
        targets: {
          type: 'array',
          items: { type: 'string' },
        },
        value: {
          allOf: [
            { $ref: '#/definitions/value' },
            {
              properties: {
                payload: { type: 'object' },
              },
            },
          ],
        },
      },
      required: ['type', 'targets', 'value'],
    },
    solidFillColorEditAction: {
      properties: {
        type: { const: actionTypes.solidFillColor },
        targets: {
          type: 'array',
          items: { type: 'string' },
        },
        value: {
          allOf: [
            { $ref: '#/definitions/value' },
            {
              properties: {
                payload: { type: 'string' },
              },
            },
          ],
        },
      },
      required: ['type', 'targets', 'value'],
    },
    effectFillColorEditAction: {
      properties: {
        type: { const: actionTypes.effectFillColor },
        targets: {
          type: 'array',
          items: { type: 'string' },
        },
        value: {
          allOf: [
            { $ref: '#/definitions/value' },
            {
              properties: {
                payload: { type: 'string' },
              },
            },
          ],
        },
      },
      required: ['type', 'targets', 'value'],
    },
    shapeStrokeColorEditAction: {
      properties: {
        type: { const: actionTypes.shapeStrokeColor },
        targets: {
          type: 'array',
          items: { type: 'string' },
        },
        value: {
          allOf: [
            { $ref: '#/definitions/value' },
            {
              properties: {
                payload: { type: 'string' },
              },
            },
          ],
        },
      },
      required: ['type', 'targets', 'value'],
    },
    imageAssetEditAction: {
      properties: {
        type: { const: actionTypes.imageAsset },
        value: {
          allOf: [{ $ref: '#/definitions/value' }, { $ref: '#/definitions/assetData' }],
        },
      },
      required: ['type', 'value'],
    },
    imagePathEditAction: {
      properties: {
        type: { const: actionTypes.imagePath },
        targets: {
          type: 'array',
          items: { type: 'string' },
        },
        value: {
          allOf: [
            { $ref: '#/definitions/value' },
            {
              properties: {
                payload: { type: 'string' },
              },
            },
          ],
        },
      },
      required: ['type', 'targets', 'value'],
    },
    textContentEditAction: {
      properties: {
        type: { const: actionTypes.textContent },
        targets: {
          type: 'array',
          items: { type: 'string' },
        },
        value: {
          allOf: [
            { $ref: '#/definitions/value' },
            {
              properties: {
                payload: { type: 'string' },
              },
            },
          ],
        },
      },
      required: ['type', 'targets', 'value'],
    },
    textFillColorEditAction: {
      properties: {
        type: { const: actionTypes.textFillColor },
        targets: {
          type: 'array',
          items: { type: 'string' },
        },
        value: {
          allOf: [
            { $ref: '#/definitions/value' },
            {
              properties: {
                payload: { type: 'string' },
              },
            },
          ],
        },
      },
      required: ['type', 'targets', 'value'],
    },
    textStrokeColorEditAction: {
      properties: {
        type: { const: actionTypes.textStrokeColor },
        targets: {
          type: 'array',
          items: { type: 'string' },
        },
        value: {
          allOf: [
            { $ref: '#/definitions/value' },
            {
              properties: {
                payload: { type: 'string' },
              },
            },
          ],
        },
      },
      required: ['type', 'targets', 'value'],
    },
    videoLayerResourceEditAction: {
      properties: {
        type: { const: actionTypes.videoLayerResource },
        value: {
          allOf: [
            { $ref: '#/definitions/value' },
            {
              properties: {
                payload: { type: 'string' },
              },
            },
          ],
        },
      },
      required: ['type', 'value'],
    },
    textFontPropertiesEditAction: {
      properties: {
        type: { const: actionTypes.textFontProperties },
        targets: {
          type: 'array',
          items: { type: 'string' },
        },
        value: {
          allOf: [
            { $ref: '#/definitions/value' },
            {
              properties: {
                payload: { type: 'string' },
              },
            },
          ],
        },
      },
      required: ['type', 'targets', 'value'],
    },
    videoLayerPropertiesEditAction: {
      properties: {
        type: { const: actionTypes.videoLayerProperties },
        targets: {
          type: 'array',
          items: { type: 'string' },
        },
        value: {
          allOf: [{ $ref: '#/definitions/value' }, { $ref: '#/definitions/layerVideoData' }],
        },
      },
      required: ['type', 'targets', 'value'],
    },
    audioLayerPropertiesEditAction: {
      properties: {
        type: { const: actionTypes.audioLayerProperties },
        targets: {
          type: 'array',
          items: { type: 'string' },
        },
        value: {
          allOf: [{ $ref: '#/definitions/value' }, { $ref: '#/definitions/layerAudioData' }],
        },
      },
      required: ['type', 'targets', 'value'],
    },
    imageLayerPropertiesEditAction: {
      properties: {
        type: { const: actionTypes.imageLayerProperties },
        targets: {
          type: 'array',
          items: { type: 'string' },
        },
        value: {
          allOf: [{ $ref: '#/definitions/value' }, { $ref: '#/definitions/layerImageData' }],
        },
      },
      required: ['type', 'targets', 'value'],
    },
    actionSet: {
      type: 'array',
      items: {
        // Setting anyOf with a large number of different objects is dangerous.
        // If one item contains an error, the error will propogate through every
        // every editAction listed in this array, causing a flood of exceptions.
        // This can potentially be avoided using draft-07 if/then chaining.
        // See app/constants/VideoEditingFormDescriptionSchema.js for more info.
        anyOf: [
          { $ref: '#/definitions/waymarkAudioAssetEditAction' },
          { $ref: '#/definitions/displayObjectVisibility' },
          { $ref: '#/definitions/shapeFillColorEditAction' },
          { $ref: '#/definitions/shapeGradientFillColorEditAction' },
          { $ref: '#/definitions/solidFillColorEditAction' },
          { $ref: '#/definitions/effectFillColorEditAction' },
          { $ref: '#/definitions/shapeStrokeColorEditAction' },
          { $ref: '#/definitions/imageAssetEditAction' },
          { $ref: '#/definitions/imagePathEditAction' },
          { $ref: '#/definitions/textContentEditAction' },
          { $ref: '#/definitions/textFillColorEditAction' },
          { $ref: '#/definitions/textStrokeColorEditAction' },
          { $ref: '#/definitions/videoLayerResourceEditAction' },
          { $ref: '#/definitions/textFontPropertiesEditAction' },
          { $ref: '#/definitions/videoLayerPropertiesEditAction' },
          { $ref: '#/definitions/audioLayerPropertiesEditAction' },
          { $ref: '#/definitions/imageLayerPropertiesEditAction' },
        ],
      },
    },
    switchActionSet: {
      type: 'object',
      properties: {
        type: { const: actionSetTypes.switch },
        switch: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              case: {
                oneOf: [{ type: 'boolean' }, { type: 'string' }],
              },
              operation: {
                type: 'string',
                enum: [caseOperations.equals],
              },
              actions: {
                anyOf: [
                  { $ref: '#/definitions/actionSet' },
                  { $ref: '#/definitions/switchActionSet' },
                ],
              },
            },
            required: ['case', 'operation', 'actions'],
          },
        },
      },
      required: ['type', 'switch'],
    },
  },
  properties: {
    events: {
      description: 'Form field events',
      type: 'array',
      items: {
        type: 'object',
        properties: {
          path: { type: 'string' },
          actions: {
            anyOf: [{ $ref: '#/definitions/actionSet' }, { $ref: '#/definitions/switchActionSet' }],
          },
        },
        required: ['path', 'actions'],
      },
    },
  },
  required: ['events'],
};
