import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cxs from 'cxs';
import { keyCodes, onMouseMoveDuringDrag } from '../utils/index.js';

const scrubber = cxs({
  position: 'relative',
  width: '100%',
  height: '14px',
  padding: '5px 0',
});

const track = cxs({
  width: '100%',
  height: '4px',
  borderRadius: '6px',
  backgroundColor: '#fff',
});

const trackComplete = cxs({
  position: 'absolute',
  height: '4px',
  left: '0',
  top: '5px',
  backgroundColor: '#96dbfa',
});

const PlayerScrubber = ({
  children,
  className,
  duration,
  isEnabled,
  trackClassName,
  trackCompleteClassName,
  value,
  onChange,
}) => {
  const [handlePostion, setHandlePosition] = useState(`${(value / duration) * 100}%`);
  const [isDragging, setIsDragging] = useState(false);

  const trackElement = useRef(null);
  const handleElement = useRef(null);

  const onKeyPress = (event) => {
    if (
      [
        keyCodes.LEFT_ARROW,
        keyCodes.DOWN_ARROW,
        keyCodes.RIGHT_ARROW,
        keyCodes.UP_ARROW,
      ].indexOf(event.which) >= 0
    ) {
      event.preventDefault();

      if (!isEnabled) {
        return;
      }

      // Move ahead 10 frames if the shift key is held, otherwise 1 frame at a time
      const valueChange = event.shiftKey ? 10 : 1;

      if ([keyCodes.LEFT_ARROW, keyCodes.DOWN_ARROW].indexOf(event.which) >= 0) {
        onChange(value - valueChange);
      } else {
        onChange(value + valueChange);
      }
    }

  };

  const onSelectScrubberPosition = (clickEvent) => {
    if (!isEnabled) {
      return;
    }

    const trackBounds = trackElement.current.getBoundingClientRect();
    const trackLeft = trackBounds.left;
    const trackWidth = trackBounds.width;

    const proportionComplete = Math.max(
      0,
      Math.min(1, (clickEvent.pageX - trackLeft) / trackWidth),
    );
    const newValue = Math.round(duration * proportionComplete);
    onChange(newValue);
  };

  const onMoveHandle = (mouseDownEvent) => {
    if (!isEnabled || typeof mouseDownEvent.pageX === 'undefined') {
      return;
    }

    setIsDragging(true);
    mouseDownEvent.target.focus();

    mouseDownEvent.preventDefault();
    const trackBounds = trackElement.current.getBoundingClientRect();
    const trackLeft = trackBounds.left;
    const trackWidth = trackBounds.width;

    // Sets the handle position and updates the current frame
    const dragHandle = (handleX) => {
      const proportionComplete = Math.max(0, Math.min(1, (handleX - trackLeft) / trackWidth));

      let newValue;
      // If the handle is outside the bounds, return the min or max
      if (handleX <= trackLeft) {
        newValue = 0;
      } else if (handleX >= trackLeft + trackWidth) {
        newValue = duration;
      } else {
        newValue = Math.round(duration * proportionComplete);
      }

      // Round it so we're only working in whole frames
      onChange(newValue);

      setHandlePosition(`${proportionComplete * 100}%`);
    };

    dragHandle(mouseDownEvent.pageX);
    onMouseMoveDuringDrag(dragHandle, () => {
      setIsDragging(false);
    });
  };

  const completionPercent = isDragging ? handlePostion : `${(value / duration) * 100}%`;

  const handle = cxs({
    position: 'absolute',
    transform: 'translate(-7px, -50%)',
    top: '50%',
    width: '14px',
    height: '14px',
    cursor: 'grab',
    borderRadius: '50%',
    border: `solid 2px ${isEnabled ? '#96dbfa' : '#ccc'}`,
    backgroundColor: '#fff',
    touchAction: 'pan-x',
  });

  return (
    <div
      className={`${scrubber} ${className}`}
      onMouseDown={onSelectScrubberPosition}
      onKeyDown={onKeyPress}
      role="menubar"
      tabIndex={0}
    >
      <div className={`${track} ${trackClassName}`} ref={trackElement}>
        <div
          className={`${trackComplete} ${trackCompleteClassName}`}
          style={{ width: completionPercent }}
        />
        { children }
      </div>
      <div
        className={handle}
        onMouseDown={onMoveHandle}
        onTouchStart={onMoveHandle}
        onKeyDown={onKeyPress}
        ref={handleElement}
        tabIndex={0}
        role="menubar"
        style={{
          left: completionPercent,
        }}
      />
    </div>
  );
};

PlayerScrubber.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  duration: PropTypes.number,
  isEnabled: PropTypes.bool,
  trackClassName: PropTypes.string,
  trackCompleteClassName: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

PlayerScrubber.defaultProps = {
  children: null,
  className: '',
  duration: 1,
  isEnabled: true,
  trackClassName: '',
  trackCompleteClassName: '',
  value: 0,
};

export default PlayerScrubber;
