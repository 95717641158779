/* eslint-env browser */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions, selectors } from '../store/index.js';
import CompositionListItem from './CompositionListItem.js';
import usePanelResize from './usePanelResize.js';
import useSelectLayers from './useSelectLayers.js';
import useLayerVisibility from './useLayerVisibility.js';
import useSharedRenderer from './useSharedRenderer.js';
import useOpenCompositionPanel from './useOpenCompositionPanel.js';

/**
 * Panel that shows a list of all of a template's compositions
 *
 * @class      CompositionListPanel
 * @param      {Object}    props                     The props
 * @param      {string}    props.panelCompositionId  The id for this panel (referenced on resizing and opening/closing panels)
 * @param      {Function}  props.openPanel           The BlueprintJS-supplied API for opening new panels: https://blueprintjs.com/docs/#core/components/panel-stack
 * @param      {Function}  props.onPanelResize       Callback function for when the panel changes in size
 */
const CompositionListPanel = ({ panelCompositionId, openPanel, onPanelResize }) => {
  const dispatch = useDispatch();
  const expandedCompositions = useSelector(selectors.getExpandedCompositions);
  const filteredCompositions = useSelector(selectors.getFilteredCompositions);
  const selectedLayerUUIDs = useSelector(selectors.getSelectedLayers);

  const allLayers = _.flatten(_.map(filteredCompositions, 'childLayers'))
  const layerUUIDs = _.map(allLayers, 'uuid')
  const { renderer, isRendererSetup } = useSharedRenderer()

  const selectLayer = useSelectLayers(layerUUIDs)
  const { visibleLayerUUIDs, toggleVisibility } = useLayerVisibility(renderer, allLayers)

  const panelRef = useRef(null);
  usePanelResize({ panelRef, panelCompositionId, onPanelResize });

  const openComposition = useOpenCompositionPanel(openPanel, onPanelResize)

  const onGoToFrame = (frameNumber) => {
    renderer.goToFrame(frameNumber)
  }

  /**
   * Called when a composition expands (shows) its layers
   *
   * @param      {String}  compositionId  The composition identifier
   */
  const onExpandLayers = (compositionId) => {
    dispatch(
      actions.expandCompositions({
        compositions: [compositionId],
      }),
    );
  };

  /**
   * Called when a composition collapses (hides) its layers
   *
   * @param      {String}  compositionId  The composition identifier
   */
  const onCollapseLayers = (compositionId) => {
    dispatch(
      actions.collapseCompositions({
        compositions: [compositionId],
      }),
    );
  };


  return (
    <ul
      css={{
        marginBlockStart: '0px',
        marginBlockEnd: '0px',
        padding: '0px',
      }}
      ref={panelRef}
    >
      {filteredCompositions.map((composition) => (
        <CompositionListItem
          key={composition.id}
          composition={composition}
          onOpenComposition={openComposition}
          areLayersExpanded={expandedCompositions.includes(composition.id)}
          isRendererSetup={isRendererSetup}
          selectedLayerUUIDs={selectedLayerUUIDs}
          visibleLayerUUIDs={visibleLayerUUIDs}
          onExpandLayers={onExpandLayers}
          onCollapseLayers={onCollapseLayers}
          onGoToFrame={onGoToFrame}
          onSelectLayer={selectLayer}
          onToggleVisibility={toggleVisibility}
        />
      ))}
    </ul>
  );
};

CompositionListPanel.propTypes = {
  panelCompositionId: PropTypes.string.isRequired,
  openPanel: PropTypes.func.isRequired,
  onPanelResize: PropTypes.func.isRequired,
};

export default CompositionListPanel;
