/* eslint-disable import/prefer-default-export */

/**
 * Creates event listeners on the windows for when the mouse/touch is moving or
 * dragging. Events need to be on the window to avoid mouse/touchup events that
 * could fire not on the original mouse/touch down object
 *
 * @param      {Function}  moveCallback     The callback to fire whenever the
 *                                          mouse/touch is moved. Arguments are
 *                                          (x, y) coords of the event.
 * @param      {Function}  mouseUpCallback  The callback to fire when the mouse drag is done. Arguments are
 *                                          (x, y) coords of the event.
 * @return     {<type>}    { description_of_the_return_value }
 */
export const onMouseMoveDuringDrag = (moveCallback, mouseUpCallback) => {
  const onMouseMove = (mouseMoveEvent) => {
    // Stop scroll effect from happening on mobile
    mouseMoveEvent.preventDefault();
    if (typeof mouseMoveEvent.pageX !== 'undefined' && typeof mouseMoveEvent.pageY !== 'undefined') {
      moveCallback(mouseMoveEvent.pageX, mouseMoveEvent.pageY);
    }
  };

  // Fire the callback on move
  window.addEventListener('mousemove', onMouseMove);
  window.addEventListener('touchmove', onMouseMove);

  // Remove the now unneeded handlers
  const scrubListeners = (event) => {
    event.preventDefault();
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('touchmove', onMouseMove);

    // Remove the listener that was just fired
    window.removeEventListener('mouseup', scrubListeners);
    window.removeEventListener('touchend', scrubListeners);

    if (typeof event.pageX !== 'undefined' && typeof event.pageY !== 'undefined') {
      mouseUpCallback(event.pageX, event.pageY);
    }
  };

  // Add a listener for when we finish the move
  window.addEventListener('mouseup', scrubListeners);
  window.addEventListener('touchend', scrubListeners);
};
