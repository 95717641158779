/* eslint-disable no-param-reassign */
import { createSelector, createSlice } from 'redux-starter-kit';

import { createUser } from '../api/index.js';
import authUserSlice from './authUser.js';
import { supportLoadingStates } from './utils.js';

/**
 * `login` slice -- manages the UI needs for the login and new user flows.
 * For authentication and logged in user needs, see `store/authUser.js`.
 * 
 * SLICE DEPENDENCIES:
 *  - `authUser`
 */
const { initialState, caseReducers } = supportLoadingStates(['login', 'createNewUser']);

const defaultState = {
  /* NOTE: just demonstrating that the initialState from `supportLoadingStates` should be added to 
  here if and when the slice grows to include other things */
  ...initialState,
};

const login = createSlice({
  slice: 'login',
  initialState: defaultState,
  reducers: {
    ...caseReducers,
  },
});

// Login selectors
login.selectors.getIsLoggingIn = createSelector(['login.isLoginInProgress']);
login.selectors.getLoginError = createSelector(['login.loginError']);

// New user selectors
login.selectors.getIsCreatingNewUser = createSelector(['login.isCreateNewUserInProgress']);
login.selectors.getNewUserError = createSelector(['login.createNewUserError']);

login.operations = {
  attemptLogin(loginCredentials) {
    return async (dispatch) => {
      dispatch(login.actions.loginInProgress());
      try {
        await dispatch(authUserSlice.operations.getAccessToken(loginCredentials));
        dispatch(login.actions.loginCompleted());
      } catch (e) {
        dispatch(login.actions.loginFailed(e.response.data));
      }
    };
  },
  createUser(userInfo) {
    return async (dispatch) => {
      dispatch(login.actions.createNewUserInProgress());
      try {
        await createUser(userInfo);
        await dispatch(authUserSlice.operations.getAccessToken(userInfo));
        dispatch(login.actions.createNewUserCompleted());
      } catch (e) {
        dispatch(login.actions.createNewUserFailed(e.response.data));
      }
    };
  },
};

export default login;
